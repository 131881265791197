import { f7 } from "framework7-vue";
import _ from "lodash";
export const scanQRCodeGeneral = {
  mounted() {
    navigator.getUserMedia =
      navigator.getUserMedia ||
      navigator.webkitGetUserMedia ||
      navigator.mozGetUserMedia ||
      navigator.msGetUserMedia;
  },
  methods: {
    checkPermission(permissionName) {
      try {
        navigator.permissions
          .query(Object.assign({ name: permissionName }))
          .then((permission) => {
            console.log(permission);
            this.techPermission = {
              name: permissionName,
              state: permission.state,
            };
            console.log(this.techPermission);
            if (permission.state == "granted") {
            } else if (permission.state == "prompt") {
              switch (permissionName) {
                case "camera":
                  navigator.getUserMedia({ video: true, audio: true }, noop);
                  break;
                case "microphone":
                  navigator.getUserMedia({ audio: true });
                  break;
                case "geolocation":
                  navigator.geolocation.getCurrentPosition();
                  break;
                case "notifications":
                  Notification.requestPermission();
                  break;
              }
            } else if (permission.state == "denied") {
            }
            permission.addEventListener("change", (e) => {
              // document.getElementById(`${permissionName}-status`).innerHTML = permission.state;
              // handleChange(permissionName, permission.state);
              this.techPermission = {
                name: permissionName,
                state: permission.state,
              };
            });
          });
      } catch (e) {}
    },

    requestCamera() {
      navigator.getUserMedia({ video: true });
    },

    async startBrowserScanner() {
      console.log(this.techPermission);
      if (
        !(
          this.techPermission &&
          this.techPermission.name === "camera" &&
          this.techPermission.state === "granted"
        )
      ) {
        this.checkPermission("camera");
      }
      f7.popup.open(".ScanQrCodeNoCordova");
    },

    async scanQRCode() {
      if (f7.device.cordova == false) {
        f7.popup.open(".ScanQrCodeNoCordova");
      } else if (f7.device.cordova) {
        await cordova.plugins.barcodeScanner.scan(
          (result) => {
            try {
              JSON.parse(result.text);
              const decodedQR = JSON.parse(result.text);
              const code = decodedQR.code;
              const type = decodedQR.type;
              if (!code || !type || !decodedQR) {
                this.resultQR = decodedQR;

                f7.sheet.open(".notAvocashQRCODE");
                // f7.dialog.alert(self.$t("common.notAvocashQRCODE"));
              } else {
                this.qrCodeRedirect(code, type);
              }
            } catch (e) {
              this.resultQR = result;

              f7.sheet.open(".notAvocashQRCODE");
              // f7.dialog.alert(self.$t("common.notAvocashQRCODE"));
              return false;
            }
          },

          (error) => {
            f7.dialog.alert(this.$t("common.failedScanning"));
          },
          {
            preferFrontCamera: false, // iOS and Android
            showFlipCameraButton: true, // iOS and Android
            showTorchButton: true, // iOS and Android
            torchOn: false, // Android, launch with the torch switched on (if available)
            // saveHistory: true, // Android, save scan history (default false)
            prompt: this.$t("common.scannerPrompt"), // Android
            resultDisplayDuration: 0, // Android, display scanned text for X ms. 0 suppresses it entirely, default 1500
            formats: "QR_CODE,PDF_417", // default: all but PDF_417 and RSS_EXPANDED
            orientation: "portrait", // Android only (portrait|landscape), default unset so it rotates with the device
            disableAnimations: false, // iOS
            disableSuccessBeep: true,
          }
        );
      }
    },
    onDecode(decodedQR) {
      try {
        const decodedQR1 = JSON.parse(decodedQR);
        const code = decodedQR1.code;
        const type = decodedQR1.type;
        if (!code || !type || !decodedQR1) {
          this.resultQR = decodedQR;
        } else {
          f7.popup.close(".ScanQrCodeNoCordova", true);
          this.qrCodeRedirect(code, type);
        }
      } catch (e) {
        this.resultQR = decodedQR;

        return false;
      }
    },
    logingDialog() {
      const self = this;

      f7.dialog
        .create({
          title:
            "<div class='box' style='align-items:justify;'><img src='/32x32.png' alt='avocash icon' width='32' height='32' style='margin-right:8px; vertical-align: middle;' class='elevation-3'/><span class='avocash primary' style='text-transform:lowercase;'>avo¢ash</span></div>",
          text: self.$t("common.loginOrRegisterToProceed"),
          buttons: [
            {
              text: self.$t("common.login"),
              color: "red",
              bold: true,
              onClick() {
                self.$keycloak.login();
              },
            },
            {
              text: self.$t("common.register"),
              color: "auto",
              bold: true,
              onClick() {
                self.$keycloak.register();
              },
            },
            {
              text: self.$t("common.close"),
              bold: true,
              color: "red",
            },
          ],
          verticalButtons: true,
        })
        .open();
    },
    async qrCodeRedirect(code, type) {
      f7.preloader.show("multi");
      switch (type) {
        case "wallet top-up":
          if (code.isPublic) {
            await this.$store.dispatch("wallet/readPublicQRcode", code);
          } else {
            await this.$store.dispatch("wallet/readQRcode", code);
          }

          setTimeout(() => {
            f7.views.main.router.navigate(`/wallet/detail/${this.wallet.id}`);
            f7.preloader.hide();
          }, 1000);

          break;
        case "tontine top-up":
          await this.$store.dispatch("tontine/readQRcode", code);
          setTimeout(() => {
            f7.views.main.router.navigate(`/tontine/${this.tontine.id}`);
            f7.sheet.open(".goToContributeNoRound");
            f7.preloader.hide();
          }, 1000);
          break;
        case "association top-up":
          await this.$store.dispatch("asso/readQRcode", code);
          setTimeout(() => {
            f7.views.main.router.navigate(
              `/association/${this.association.id}`
            );
            f7.sheet.open(".goToContributeNocampaign");
            f7.preloader.hide();
          }, 1000);

          break;
        case "avo¢ash pick-up":
          await this.$store.dispatch("remit/decodeQRCode", code);
          await this.$store.dispatch("remit/getPickupTransfer", {
            transferCode: this.remitQRCode.transferCode,
          });
          setTimeout(() => {
            f7.views.main.router.navigate("/atm/pickup");
            f7.preloader.hide();
          }, 1000);

          break;
        case "avo¢ash top-up":
          this.$store.dispatch("remit/decodeQRCode", code);
          this.$store.dispatch("remit/getPickupTransfer", {
            transferCode: this.remitQRCode.transferCode,
          });
          setTimeout(() => {
            f7.views.main.router.navigate("/atm/pickup");
            f7.preloader.hide();
          }, 1000);

          break;
        case "avo¢ash claim":
          await this.connectPayService();
          await this.$store.dispatch("payment/decodeQRCode", code);
          setTimeout(() => {
            f7.views.main.router.navigate("/pay/with-QRCode-scan");
            f7.preloader.hide();
          }, 1000);

          break;

        case "avo¢ash vendor":
          await this.connectPayService();
          await this.$store.dispatch("payment/decodeVendorQRCode", code);
          setTimeout(() => {
            f7.views.main.router.navigate("/pay/with-QRCode-scan");
            f7.preloader.hide();
          }, 1000);

          break;

        case "avo¢ash pay":
          await this.connectPayService();
          f7.preloader.show("multi");
          await this.$store.dispatch("payment/decodeQRCode", code);
          setTimeout(() => {
            f7.views.main.router.navigate("/accept-cash/with-QRCode_scan");
            f7.preloader.hide();
          }, 1000);

          break;
        case "avo¢ash ATM":
          await this.$store.dispatch("payment/decodeQRCode", code);
          setTimeout(() => {
            f7.views.main.router.navigate("/atm/with-QRCode-scan");
            f7.preloader.hide();
          }, 1000);

          break;
        case "avo¢ash out":
          await this.$store.dispatch("payment/decodeQRCode", code);
          setTimeout(() => {
            f7.views.main.router.navigate("/cash-out/with-QRCode-scan");
            f7.preloader.hide();
          }, 1000);

          break;
        case "avo¢ash shop":
          await this.$store.dispatch("shop/decodeShopQRCode", code);
          setTimeout(() => {
            f7.views.main.router.navigate(`/shop/${this.shop.id}`);
          }, 1000);
          break;
        case "avo¢ash product":
          await this.$store.dispatch("shop/decodeProductQRCode", code);
          setTimeout(() => {
            f7.views.main.router.navigate(`/product/${this.product.id}`);
          }, 1000);
          break;
        case "avo¢ash shopOrder":
          await this.$store.dispatch("shopOrder/decodeShopOrdersQRCode", code);
          setTimeout(() => {
            f7.views.main.router.navigate(`/order/${this.shopOrder.id}`);
            f7.preloader.hide();
          }, 1000);

          break;
        case "avo¢ash card":
          await this.connectCardService();
          await this.$store.dispatch("card/decodeCardQRCode", code);
          setTimeout(() => {
            if (
              this.scannedCard.cardStatus === "active" ||
              this.QRtext.cardStatus === "active"
            ) {
              f7.preloader.hide();
              this.selectCurrentPage();
            }
          }, 1000);
          break;
        /*   case 'avo¢ash shopOrder':
                                                                                                                                      await self.$store
                                                                                                                                          .dispatch("shopOrder/decodeShopOrdersQRCode", code);
                                                                                                                                      await f7.views.main.router.navigate("/shopOrder/" + this.shopOrder.id);
                                                                                                                                      break; */
      }
    },

    /*   if (type === "wallet top-up") {
                                                  await self.$store
                                                      .dispatch("wallet/readQRcode", code);
                                                  await f7.views.main.router.navigate("/walletDetail/" + this.wallet.id + "/" + this.wallet.isPublic);
                                              } else
                                              if (type === "tontine top-up") {
                                                  await self.$store
                                                      .dispatch("tontine/readQRcode", code);
                                                  await f7.views.main.router.navigate("/tontineDetail/" + this.tontine.id);
                                                  await f7.sheet.open('.goToContributeNoRound');
                                              } else if (type === "association top-up") {
                                                  await self.$store
                                                      .dispatch("asso/readQRcode", code);
                                                  await f7.views.main.router.navigate("/associationDetail/" + this.association.id);
                                                  await f7.sheet.open('.goToContributeNocampaign');
                                              } else if (type === "avo¢ash pick-up") {
                                                  await self.$store
                                                      .dispatch("remit/decodeQRCode", code);
                                                  await self.$store.dispatch("remit/getPickupTransfer", {
                                                      transferCode: this.remitQRCode.transferCode
                                                  });
                                                  await f7.views.main.router.navigate("/atm/pickup");
                                              } else if (type === "avo¢ash top-up") {
                                                  // self.$store
                                                  //     .dispatch("remit/decodeQRCode", code)
                                                  //     .then(() => {
                                                  //         setTimeout(() => {
                                                  //             self.$store.dispatch("remit/getPickupTransfer", {
                                                  //                 transferCode: this.remitQRCode.transferCode,
                                                  //             }).then((result) => {
                                                  //                 f7.views.main.router.navigate("/atm/pickup");
                                                  //             }).catch((err) => {

                                                  //             });;
                                                  //         }, timeOut);
                                                  //     });
                                              } else if (type === "avo¢ash claim") {
                                                  self.$store
                                                      .dispatch("payment/decodeQRCode", code);
                                                  await f7.views.main.router.navigate("/pay/with-QRCode-scan");
                                              } else if (type === "avo¢ash pay") {
                                                  f7.preloader.show("multi");
                                                  await self.$store
                                                      .dispatch("payment/decodeQRCode", code);
                                                  setTimeout(() => {
                                                      f7.views.main.router.navigate("/acceptcashWithQRCodeScan/");
                                                      f7.preloader.hide();

                                                  }, 2000);
                                              } else if (type === "avo¢ash ATM") {
                                                  await self.$store
                                                      .dispatch("payment/decodeQRCode", code);
                                                  await f7.views.main.router.navigate("/avocashATMWithQRCodeScan.vue/");
                                              } else if (type === "avo¢ash out") {
                                                  await self.$store
                                                      .dispatch("payment/decodeQRCode", code);
                                                  await f7.views.main.router.navigate("/avocashOutWithQRCodeScan/");
                                              } else if (type === "avo¢ash shop") {
                                                  await self.$store
                                                      .dispatch("shop/decodeShopQRCode", code);
                                                  await f7.views.main.router.navigate("/shopDetail/" + this.shop.id);
                                              } else if (type === "avo¢ash product") {
                                                  await self.$store
                                                      .dispatch("shop/decodeProductQRCode", code);
                                                  await f7.views.main.router.navigate("/productDetail/" + this.product.id);
                                              } else if (type === "avo¢ash shopOrder") {
                                                  await self.$store
                                                      .dispatch("shopOrder/decodeShopOrdersQRCode", code);
                                                  await f7.views.main.router.navigate("/shopOrder/" + this.shopOrder.id);
                                              } else if (type === "avo¢ash card") {
                                                  this.connectCardService();
                                                  await self.$store
                                                      .dispatch("card/decodeCardQRCode", code);
                                                  if (this.scannedCard.cardStatus === "active" || this.QRtext.cardStatus === "active") {
                                                      await this.selectCurrentPage();
                                                  }
                                              } */

    async selectCurrentPage() {
      if (
        f7.views.main.router.currentRoute.path ===
          `/pickup/with-recipient/${this.recipient.id}/pick-up` ||
        f7.views.main.router.currentRoute.url ===
          `/topupwithrecipient/${this.recipient.id}/top-up`
      ) {
        f7.tab.show("#withnewcards");
      } else if (
        f7.views.main.router.currentRoute.path === "/send-cash" ||
        f7.views.main.router.currentRoute.path === "/"
      ) {
        f7.views.main.router.navigate("/send-cash", {
          ignoreCache: true,
          reloadCurrent: true,
        });
      } else {
        if (f7.views.main.router.currentRoute.path === "/topup/my-account") {
          f7.tab.show("#topupWithAvocash");
        }
        f7.views.main.router.refreshPage();
      }
    },
  },
  computed: {
    // ...mapState("card", ["scannedCard", "QRtext"]),
  },
};
