<template>
  <f7-page class="onboardingDetailsPage">
    <f7-navbar no-shadow no-border no-outline>
      <f7-nav-left><img src="/32x32.png" alt="avocash logo" width="32" height="32" style="vertical-align: middle" />
        <span class="avocash padding-left-half text-color-primary" style="
            letter-spacing: var(--f7-tabbar-label-letter-spacing);
            font-weight: bold;
            font-size: large;
          ">avo¢ash</span></f7-nav-left>
      <f7-nav-right>
        <f7-link popup-close=".onbordindDetail" transition="f7-cover">
          <f7-icon ios="f7:multiply" md="material:close" color="primary"></f7-icon>
        </f7-link>
      </f7-nav-right>
    </f7-navbar>
    <div class="layout_content">
      <div class="left_layout"></div>
      <div class="main_layout">
        <f7-block class="grid grid-cols-1">
          <f7-card v-for="(product, index) in this.landingPage" :key="index" class="cardExpandable"
            @click="learnMore(product)" :id="`cardExpandable${index}`" :padding="false">
            <div :class="'bg-color-' + product.color" :style="{ height: '300px' }">
              <f7-card-header text-color="white" class="display-block">
                {{ product.title }}
                <br />
                <small :style="{ opacity: 0.7 }">{{ product.subtitle }}</small>
              </f7-card-header>
              <f7-card-content text-color="white" class="display-block">
                {{ product.detail }}
              </f7-card-content>
            </div>
          </f7-card>
        </f7-block>
      </div>
      <div class="right_layout">
        <div class="sticky with_content" v-if="this.isTablet"></div>
      </div>
    </div>
    <f7-popup :close-by-backdrop-click="false" :close-by-outside-click="false" tablet-fullscreen class="productDetails"
      :backdrop="false">
      <f7-page style="background-color: white" hide-bars-on-scroll>
        <f7-navbar no-shadow no-border no-outline>
          <f7-nav-left> </f7-nav-left><f7-nav-title> {{ this.selectedProduct["title"] }}</f7-nav-title>
          <f7-nav-right>
            <f7-link popup-close=".productDetails">
              <f7-icon ios="f7:multiply" md="material:close" color="primary"></f7-icon>
            </f7-link>
          </f7-nav-right>
        </f7-navbar>
        <div class="layout_content">
          <div class="left_layout"></div>
          <div class="main_layout margin">
            <h1>
              {{ this.selectedProduct["title"] }}
            </h1>
            <h2>
              {{ this.selectedProduct["subtitle"] }}
            </h2>
            <h3>
              <p>{{ this.selectedProduct["detail"] }}</p>
            </h3>

            <div class="card-content-padding onboarding-content-image">
              <div class="wt-hero-phone">
                <div class="wt-phone">
                  <div class="wt-phone-case" style=""></div>
                  <div class="wt-phone-screen">
                    <div class="wt-phone-screen-img">
                      <img :src="this.selectedProduct['img']" />
                    </div>
                  </div>
                </div>
              </div>
              <div class="wt-hero-phone margin-top">
                <div class="wt-phone">
                  <div class="wt-phone-case" style=""></div>
                  <div class="wt-phone-screen">
                    <div class="wt-phone-screen-video">
                      <video preload="auto" loop="" muted="" playsinline="" style=""
                        :class="`video${this.selectedProduct['id']}`">
                        <source :src="this.selectedProduct['video']" />
                      </video>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="right_layout">
            <div class="sticky with_content" v-if="this.isTablet"></div>
          </div>
        </div>
      </f7-page>
    </f7-popup>
  </f7-page>
</template>

<style scoped>
.cardExpandable {
  overflow: hidden;
  height: 300px;
  background: var(--f7-card-expandable-bg-color);
  position: relative;
  transform-origin: center center;

  margin-left: calc(var(--f7-card-expandable-margin-horizontal) + var(--f7-safe-area-left));
  margin-right: calc(var(--f7-card-expandable-margin-horizontal) + var(--f7-safe-area-right));
  margin-top: var(--f7-card-expandable-margin-vertical);
  margin-bottom: var(--f7-card-expandable-margin-vertical);
  /* font-size: var(--f7-card-expandable-font-size); */
}

.cardExpandable .card-header {
  font-size: var(--f7-card-expandable-header-font-size);
  font-weight: var(--f7-card-expandable-header-font-weight);
}

@media screen and (max-width: 568px) {
  .onboarding-content-image {
    /* width: 70%; */
    height: -webkit-fill-available;
    /* display: flex; */
    margin: auto;
    /* flex-direction: column; */
    justify-content: center;
    /* align-items: center; */
    text-align: center;
  }

  .onboarding-content {
    /* width: 70%; */
    height: 90%;
    /* display: flex; */
    margin: auto;
    /* flex-direction: column; */
    justify-content: center;
    /* align-items: center; */
    text-align: center;
  }
}

@media screen and (min-width: 569px) {
  .onboarding-content-image {
    /* width: 70%; */
    height: -webkit-fill-available;
    /* display: flex; */
    margin: auto;
    /* flex-direction: column; */
    justify-content: center;
    align-items: center;
    text-align: center;
  }
}

.onboarding-content1 {
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: auto;
  height: 90%;
}

.onboarding-content {
  /* width: 70%; */
  height: 90%;
  /* display: flex; */
  margin: auto;
  /* flex-direction: column; */
  justify-content: center;
  align-items: center;
  text-align: center;
}

.onboarding-content-swiper {
  width: 70%;
  margin: auto;
  height: 100%;
  /* display: flex; */
  /* flex-direction: column; */
  align-items: center;
}

.firstSlide {
  /* background-image: url(/images/avocash_bg2.png); */
  -webkit-background-size: cover;
  -moz-background-size: cover;
  background-size: cover;
  background-position: center center;
  /* background-attachment: fixed; */
  background-repeat: no-repeat;
  text-align: center;
}

.onboarding_title,
.slideTitle,
.slideSubtitle {
  text-align: center;
}

.headline {
  font-stretch: 150%;
  letter-spacing: -0.02em;
  font-size: clamp(2.0875rem, 1.2329545455rem + 2.0727272727vw, 2.1875rem);
  letter-spacing: -0.04;
  font-weight: 600;
  margin-bottom: 0.4em;
}

@media (min-width: 1200px) {
  .headline {
    font-weight: 520;
    font-stretch: 150%;
    letter-spacing: -0.02em;
    font-size: clamp(4.1875rem, 0.1875rem + 6.25vw, 6.75rem);
    line-height: 1.05;
  }
}

@media (min-width: 1024px) {
  .headline {
    font-weight: 520;
    font-stretch: 150%;
    letter-spacing: -0.02em;
    font-size: clamp(4.1875rem, 0.1875rem + 6.25vw, 6.75rem);
    line-height: 1.05;
  }
}

@media (min-width: 768px) {
  .headline {
    font-weight: 520;
    font-stretch: 150%;
    letter-spacing: -0.02em;
    font-size: clamp(2.1875rem, 0.1875rem + 4.25vw, 4.75rem);
    line-height: 1.25;
  }

  .headline0 {
    display: block;
  }
}

@media (min-width: 568px) {
  .onboarding-content-image .slideSubtitle {
    display: none;
  }
}

.wt-phone {
  position: relative;
  display: inline-block;
  contain: layout style;
}

.wt-phone-shadow {
  position: absolute;
  top: -8.577%;
  left: -41.289%;
  right: -14.68%;
  bottom: -11.483%;
  opacity: 0.8;
  background-image: url(/phone-shadow.png);
  -webkit-background-size: contain;
  -moz-background-size: contain;
  background-size: contain;
  /* background-repeat:no-repeat; */
}

.wt-phone-case {
  position: relative;
  width: 212.55px;
  height: 447.2px;
  background-image: url(/phone.png);
  -webkit-background-size: cover;
  -moz-background-size: cover;
  background-size: cover;
}

@media (min-width: 768px) and (-webkit-min-device-pixel-ratio: 2),
(min-width: 768px) and (-o-min-device-pixel-ratio: 2/1),
(min-width: 768px) and (min-resolution: 192dpi) {
  .wt-phone-case {
    background-image: url(/phone@2x.png);
  }
}

.wt-phone-screen {
  position: absolute;
  top: 8.45px;
  left: 6.5px;
  width: 199.55px;
  height: 430.3px;
  overflow: hidden;
  -webkit-border-radius: 19px;
  -moz-border-radius: 19px;
  border-radius: 19px;
  background: #fff;
}

@media (min-width: 768px) {
  .wt-phone-screen {
    top: 11.05px;
    left: 8.5px;
    width: 260.95px;
    height: 562.7px;
    -webkit-border-radius: 25px;
    -moz-border-radius: 25px;
    border-radius: 25px;
  }

  .wt-phone-case {
    width: 277.95px;
    height: 584.8px;
  }
}

@media (min-width: 1600px) {
  .wt-phone-screen {
    top: 13px;
    left: 10px;
    width: 307px;
    height: 662px;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    border-radius: 30px;
  }

  .wt-phone-case {
    width: 327px;
    height: 688px;
  }
}

.wt-phone-screen-video {
  height: 100%;
  width: 100%;
  line-height: 0;
  contain: content;
}

.wt-phone-screen-video video {
  height: 100%;
  width: 100%;
}

.ie .wt-phone-screen-video video {
  -webkit-transform: scale(1.011);
  -moz-transform: scale(1.011);
  -ms-transform: scale(1.011);
  -o-transform: scale(1.011);
  transform: scale(1.011);
}

.wt-phone-screen-img {
  height: 100%;
  width: 100%;
  line-height: 0;
  contain: content;
}

.wt-phone-screen-img img {
  height: 100%;
  width: 100%;
}

.wt-phone-screen-video+.wt-phone-screen-img {
  display: none;
}

.swiper-slide,
.onboardingSlides {
  height: -webkit-fill-available;
  min-height: calc(100vh - var(--f7-navbar-height)) !important;
}

.navbar {
  top: 0;
  background-color: transparent;
}
</style>

<script>
import {
  f7,
  theme,
  f7Popup,
  f7Block,
  f7Button,
  f7Link,
  f7PageContent,
} from "framework7-vue";
import { mapGetters, mapState } from "vuex";
import _ from "lodash";
export default {
  props: {
    f7route: Object,
    f7router: Object,
  },
  components: {
    f7Popup,
    f7Block,

    f7Button,
    f7Link,

    f7PageContent,
  },
  data() {
    return {
      theme,
      f7,
      selectedProduct: "",
    };
  },
  mounted() { },

  methods: {
    learnMore(product) {
      this.selectedProduct = product;
      if (this.selectedProduct !== "") {
        f7.popup.open(".productDetails", true);
      }
    },
    backToLandingPage() {
      this.f7router.back();
      f7.views.main.router.back();
      f7.views.main.router.navigate("/");
      console.log("backToLandingPage");
      // this.selectedProduct = "";
      // f7.popup.close(".productDetails");
    },
  },

  computed: {
    ...mapState("auth", [
      "video",
      "isDeskTop",
      "isTablet",
      "device",
      "lastSwiperIsActive",
      "landingPage",
    ]),
  },
};
</script>
