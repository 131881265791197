<template>
  <f7-page class="page-with-subnavbar" @page:beforeout="beforeOut" @page:init="onPageInit">
    <f7-navbar sliding no-shadow :back-link="$t('common.backButton')" :back-link-show-text="false">
      <f7-nav-title>{{ $t("shoppingcart.checkoutNow") }}</f7-nav-title>
      <f7-nav-right>
        <!-- <f7-link popup-open=".shoppingCart-popup" class="margin-right-half">
          <f7-icon
            ios="f7:cart"
            aurora="f7:cart"
            md="material:shopping_cart"
            color="none"
            v-if="this.cart && this.cart.length > 0"
          >
            <f7-badge
              bg-color="none"
              class="text-align-center"
              style="font-size: 50%; margin-left: -23px; top: -5px; font-weight: bold"
              text-color="red"
              v-if="this.cart && this.cart.length > 0 && this.totalItemCount > 9"
              >{{ this.totalItemCount }}</f7-badge
            >
            <f7-badge
              bg-color="none"
              class="text-align-center"
              style="font-size: 50%; margin-left: -20px; top: -5px; font-weight: bold"
              text-color="red"
              v-if="this.cart && this.cart.length > 0 && this.totalItemCount < 10"
              >{{ this.totalItemCount }}</f7-badge
            >
          </f7-icon>
        </f7-link> -->
        <navbarright></navbarright>
      </f7-nav-right>

      <f7-subnavbar>{{
        $filtersToCurrency.toCurrency(this.totalCartAmount, "€", "EUR")
        }}</f7-subnavbar>
    </f7-navbar>
    <div class="layout_content">
      <div class="left_layout"></div>
      <div class="main_layout">
        <f7-card :padding="false" outline>
          <f7-card-content v-for="(storeCart, index) in this.cartCheckOut" :key="index">
            <f7-list dividers-ios strong-ios media-list>
              <f7-list-item accordion-item :title="storeCart.storeName"
                :after="$filtersToCurrency.toCurrency(storeCart.storeTotal, '€', 'EUR')">
                <f7-accordion-content>
                  <f7-list dividers-ios strong-ios media-list>
                    <li v-for="cartItem in storeCart.items" :key="cartItem.Handle">
                      <a :href="itemUrl(cartItem)" class="item-content item-link">
                        <div class="item-media">
                          <img :src="cartItem.ImageSrc || cartItem['Variant Image']" width="44" />
                        </div>
                        <div class="item-inner" style="font-size: 80%; color: gray">
                          <div class="item-title-row">
                            <div class="item-title">
                              {{ cartItem.Title }}
                            </div>
                            <div class="item-title">
                              {{ cartItem.quantity }} x
                              <span v-html="$filtersToCurrency.toCurrency(
                                cartItem['Variant Price'],
                                '€',
                                ''
                              )
                                "></span>
                            </div>
                            <div class="item-title">
                              <span v-html="$filtersToCurrency.toCurrency(
                                cartItem.quantity * cartItem['Variant Price'],
                                '€',
                                ''
                              )
                                "></span>
                            </div>
                          </div>
                        </div>
                      </a>
                    </li>
                  </f7-list>
                </f7-accordion-content>
              </f7-list-item>
            </f7-list>
          </f7-card-content>
        </f7-card>
        <f7-card :padding="false" outline>
          <f7-card-content>
            <f7-list dividers-ios strong-ios media-list>
              <li v-if="this.cart && this.cart.length > 0">
                <div class="item-content">
                  <div class="item-inner">
                    <div class="item-title-row">
                      <div class="item-title" style="font-size: 80%">
                        {{ $t("checkout.totalBasket") }}
                      </div>
                      <div class="item-title" style="font-weight: bold">
                        <span v-html="$filtersToCurrency.toCurrency(
                          this.totalCartAmount,
                          '€',
                          'EUR'
                        )
                          "></span>
                      </div>
                    </div>
                    <div class="item-title-row">
                      <div class="item-title" style="font-size: 80%">
                        {{ $t("checkout.shippingCost") }}
                      </div>
                      <div class="item-title">
                        <span v-html="$filtersToCurrency.toCurrency(this.shippingCost, '€', 'EUR')
                          "></span>
                      </div>
                    </div>

                    <div class="item-title-row">
                      <div class="item-title">{{ $t("checkout.totalCost") }}</div>
                      <div class="item-title" style="font-weight: bold">
                        <span v-html="$filtersToCurrency.toCurrency(
                          this.shippingCost + this.totalCartAmount,
                          '€',
                          'EUR'
                        )
                          "></span>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            </f7-list>
          </f7-card-content>
        </f7-card>

        <f7-list>
          <f7-block-header>{{ $t("checkout.deliverTo") }}</f7-block-header>
          <f7-list-group>
            <span class="grid grid-cols-2">
              <f7-list-item name="deliveryAddress" value="home" :checked="deliveryAddress === 'home'" @change="
                (e) => {
                  if (e.target.checked) deliveryAddress = 'home';
                }
              " radio :title="$t('checkout.home')"></f7-list-item>

              <f7-list-item radio :title="$t('checkout.newAddress')" name="deliveryAddress" value="newAddress"
                :checked="deliveryAddress === 'newAddress'" @change="
                  (e) => {
                    if (e.target.checked) deliveryAddress = 'newAddress';
                  }
                "></f7-list-item></span>
          </f7-list-group>
          <f7-list-group v-if="this.deliveryAddress === 'home' && this.profile">
            <li class="item-content">
              <div class="item-inner">
                <div class="item-title-row">
                  <div class="item-title">
                    {{
                      this.profile.address.street_address +
                      " " +
                      this.profile.address.postal_code +
                      " " +
                      this.profile.address.locality
                    }}
                  </div>
                </div>
                <div class="item-title">
                  {{ this.profile.address.region + ", " + this.profile.address.country }}
                </div>
              </div>
            </li>
          </f7-list-group>
          <f7-list-group v-else>
            <li>
              <a id="autocomplete-searchAddress" class="item-link item-content autocomplete-opener"
                @click="openAddressSearch()">
                <div class="item-media">
                  <i class="f7-icons if-not-md">search</i>
                  <i class="material-icons md-only">search</i>
                </div>
                <input type="hidden" />
                <div class="item-inner">
                  <div class="item-title">{{ $t("common.searchAnAddress") }}</div>
                  <div class="item-after"></div>
                </div>
              </a>
            </li>
            <f7-list-input :label="$t('checkout.myDeliveryAddress')" floating-label type="text" name="addressLine1"
              :value="this.address" :placeholder="$t('checkout.myDeliveryAddress')"
              @input="address = $event.target.value" :error-message="$t('checkout.pleaseEnterAddress')" required
              validate clear-button></f7-list-input>
            <span class="grid cols30_70">
              <f7-list-input :label="$t('checkout.myPostCode')" floating-label type="text" name="postalCode"
                :value="this.postalCode" @input="postalCode = $event.target.value" required
                :error-message="$t('checkout.pleaseEnterdeliveryCity')" validate clear-button></f7-list-input>

              <f7-list-input :label="$t('checkout.myDeliveryCity')" floating-label type="text" name="recipientCity"
                :value="this.recipientCity" :placeholder="$t('checkout.myDeliveryCity')"
                @input="recipientCity = $event.target.value" :error-message="$t('checkout.myDeliveryCity')" required
                validate clear-button></f7-list-input>
            </span>
          </f7-list-group>
          <f7-block-title class="item-header">{{
            $t("common.description")
            }}</f7-block-title>
          <f7-text-editor :placeholder="$t('common.description')" :value="description"
            @texteditor:change="(v) => (description = v)" resizable required validate type="texteditor" :style="f7.device.cordova || !this.isTablet ? '--f7-text-editor-height: 150px' : ''
              " />
        </f7-list>

        <f7-toolbar tabbar bottom bg-color="red" :style="{ bottom: 0, position: isTablet ? 'initial' : 'fixed' }">
          <f7-link raised large small fill class="link buttons" ripple strong text-color="white" bg-color="red"
            @click="payNow()">{{ $t("common.pay") }}</f7-link>
        </f7-toolbar>
      </div>
      <div class="right_layout"></div>
    </div>
    <f7-popup class="shoppingCart-popup" swipe-to-close backdrop tablet-fullscreen style="--f7-sheet-bg-color: #fff">
      <f7-page>
        <f7-navbar no-shadow>
          <f7-nav-right>
            <f7-link popup-close icon-f7="multiply"></f7-link>
          </f7-nav-right>
          <f7-nav-title class="margin-left">{{ $t("checkout.totalBasket") }}
            <span v-html="$filtersToCurrency.toCurrency(this.totalCartAmount, '€', 'EUR')"></span>
          </f7-nav-title>
        </f7-navbar>
        <div class="layout_content">
          <div class="left_layout"></div>
          <div class="main_layout">
            <shoppingCartComponent></shoppingCartComponent>
          </div>
          <div class="right_layout"></div>
        </div>
      </f7-page>
    </f7-popup>
  </f7-page>
</template>
<style scoped>
div.toolbar-inner {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

div.item-media>img {
  /* width: 25px;
  height: 25px; */
  border: none;
}
</style>
<script>
import {
  f7Page,
  f7Navbar,
  f7NavLeft,
  f7NavRight,
  f7NavTitle,
  f7Link,
  f7Icon,
  f7Badge,
  f7Block,
  f7Card,
  f7CardContent,
  f7AccordionContent,
  f7BlockHeader,
  f7List,
  f7ListGroup,
  f7ListItem,
  f7ListInput,
  f7BlockTitle,
  f7TextEditor,
  f7Toolbar,
  f7Popup,
  theme,
  f7,
} from "framework7-vue";
import { mapGetters, mapState } from "vuex";
import Navbarright from "../components/navbarright.vue";
import ShoppingCartComponent from "../components/shoppingCartComponent.vue";
import axios from "axios";
import $ from "dom7";
import _ from "lodash";
export default {
  props: {
    f7route: Object,
    f7router: Object,
  },
  components: {
    navbarright: Navbarright,
    shoppingCartComponent: ShoppingCartComponent,
    f7Page,
    f7Navbar,
    f7NavLeft,
    f7NavRight,
    f7NavTitle,
    f7Link,
    f7Icon,
    f7Badge,
    f7Block,
    f7Card,
    f7CardContent,
    f7AccordionContent,
    f7BlockHeader,
    f7List,
    f7ListGroup,
    f7ListItem,
    f7ListInput,
    f7BlockTitle,
    f7TextEditor,
    f7Toolbar,
    f7Popup,
  },
  mixins: [],
  data() {
    return {
      theme,
      f7,
      address: "",
      postalCode: "",
      recipientCity: "",
      recipientCountry: "",
      cityId: "",
      cityName: "",
      stateId: "",
      stateName: "",
      countryId: "",
      countryName: "",
      description: "",
      state: "",
      city: "",
      deliveryAddress: "home",
      shippingCost: 5,
      autocompleteDropdownAjax: "",
    };
  },

  mounted() {
    this.$store.dispatch("location/loadDestinationCity", this.profile.countryCode);
    f7.$(".selectDeliveryCity .item-after").html("Select delivery city");
    this.deliveryAddress === "home";
  },
  methods: {
    itemUrl(cartItem) {
      return `/itemDetail/${cartItem.storeId}/${cartItem.Handle}`;
    },
    async getCountryInfo() {
      await this.$store.dispatch(
        "location/loadDestinationCity",
        this.profile.countryCode
      );
    },
    async getStateInfo() {
      const city = document.querySelector("#stateid");
      this.city = await city.value;
      if (!this.city) {
        return;
      }
      this.stateId = parseFloat(
        city.options[city.selectedIndex].parentNode.dataset.stateid
      );
      this.stateName = await city.options[city.selectedIndex].parentNode.label;
      this.cityName = await city.options[city.selectedIndex].dataset.cityname;
    },
    payNow() {
      const self = this;
    },
    beforeOut() {
      this.autocompleteDropdownAjax.destroy();
    },
    openAddressSearch() {
      const self = this;
      // this.autocompleteDropdownAjax.open();
      self.autocompleteDropdownAjax = f7.autocomplete.create({
        openIn: "popup",
        openerEl: "#autocomplete-searchAddress",
        preloaderColor: "multi",
        preloader: true, //enable preloader
        /* If we set valueProperty to "id" then input value on select will be set according to this property */
        valueProperty: "label", //object's "value" property name
        textProperty: "label", //object's "text" property name
        limit: 5, //limit to 20 results
        dropdownPlaceholderText: self.$t("common.searchAnAddress"),
        pageBackLinkText: "",
        closeOnSelect: true,
        searchbarPlaceholder: self.$t("common.searchAnAddress"),
        pageTitle: self.$t("common.searchAnAddress"),
        source(query, render) {
          const autocomplete = this;
          const results = [];
          if (query.length === 0) {
            render(results);
            return;
          }
          autocomplete.preloaderShow();

          self
            .axios({
              url: "https://autocomplete.search.hereapi.com/v1/autocomplete",
              method: "GET",
              data: {
                q: query,
                apiKey: import.meta.env.VITE_ADDRESS_APIKEY,
                at: `${self.latitude},${self.longitude}`,
                lang: self.locale ? self.locale : navigator.language,
                //  limit:5
              },
            })
            .then((r) => r.data)
            .then((jsData) => {
              console.log("address data ", jsData);
              const items = jsData.items;
              for (const item of items) {
                results.push(item.address);
              }
              render(results);
            });
        },
        on: {
          change(value) {
            self.address = value[0].houseNumber
              ? `${value[0].houseNumber} ${value[0].street}`
              : value[0].street;
            self.postalCode = value[0].postalCode;
            self.recipientCity =
              value[0].city +
              ", " +
              value[0].county +
              ", " +
              value[0].state +
              ", " +
              value[0].countryName;
          },
        },
      });
      this.autocompleteDropdownAjax.open();
    },
    onPageInit() { },
  },

  computed: {
    ...mapState("shoppingCart", ["cart", "cartItemCount"]),
    ...mapGetters("shoppingCart", ["totalCartAmount", "totalItemCount"]),
    ...mapState("transaction", [
      "destinationCountry",
      "grossexchangerate",
      "transferDetail",
      "fromCurrencyRate",
      "toCurrencyRate",
      "qrcodeDecoded",
      "QRtext",
      "extraTransferDetail",
    ]),
    ...mapState("location", [
      "destinationCity",
      "country",
      "countryStates",
      "countryCities",
      "currentUserCountry",
      "latitude",
      "longitude",
    ]),
    ...mapState("auth", [
      "phoneNumberDetail",
      "mobilePhoneError",
      "profile",
      "isDeskTop",
      "isTablet",
      "locale",
    ]),
    ...mapState("card", ["cardlist"]),
    ...mapState("account", ["primaryAccount", "businessAccount", "userDetail"]),

    ...mapGetters("remit", ["recipients", "recipientsList"]),
    ...mapGetters("transaction", [
      "allCountriesByCountryId",
      "allCountriesByCountryName",
      "fromCurrencyRate",
      "toCurrencyRate",
      "grossexchangerate2",
    ]),
    cartCheckOut() {
      const currentCart = this.cart;
      return _.chain(currentCart.slice())
        .map("item")
        .flatten()
        .map((item) =>
          _.extend({ subTotal: item.quantity * item["Variant Price"] }, item)
        )
        .groupBy("storeName")
        .map((objs, key, items) => ({
          storeName: key,
          storeTotal: _.sumBy(objs, "subTotal"),
          items: objs,
        }))
        .value();
    },
  },
};
</script>
