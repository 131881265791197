<template>
  <f7-page hide-navbar-on-scroll :infinite-preloader="showPreloader" infinite :infinite-distance="10"
    @infinite="loadMore" @page:init="pageInit()">
    <f7-navbar sliding no-shadow>
      <f7-nav-left :back-link="$t('common.backButton')" :back-link-show-text="false"></f7-nav-left>
      <f7-nav-title>{{ $t("shopdetail.myShops") }}</f7-nav-title>
      <f7-nav-right>
        <f7-link class="searchbar-enable" data-searchbar=".searchbar-demo" icon-ios="f7:search" icon-aurora="f7:search"
          icon-md="material:search"></f7-link>
        <navbarright></navbarright>
      </f7-nav-right>

      <f7-searchbar class="searchbar-demo" :placeholder="$t('shopdetail.searchAShop')" expandable
        search-container=".search-list" search-item="div.card" search-in=".shopName"
        search-group-title=".list-group-title" hide-groups:true :backdrop="false" :disable-button="!theme.aurora"
        clear-button></f7-searchbar>
    </f7-navbar>
    <div class="layout_content">
      <div class="left_layout"></div>
      <div class="main_layout">
        <f7-card class="demo-facebook-card elevation-3 skeleton-text skeleton-effect-blink" v-if="!this.shopList"
          v-for="n in 6" :key="n">
          <f7-card-header class="no-border">
            <div class="demo-facebook-avatar padding-right">
              <f7-badge slot="media" color="gray"
                style="height: 40px; width: 40px; font-size: 16px; border-radius: 50%">GR</f7-badge>
            </div>
            <div class="shopName demo-facebook-name">shop name</div>
            <div class="demo-facebook-date shopName">Shop tag</div>
          </f7-card-header>

          <f7-card-content :padding="false" style="width: 100%; height: 400px" class="align-content-center">
            >
          </f7-card-content>
          <f7-list-item accordion-item :title="$t('common.description')" :padding="false">
            <f7-accordion-content>
              <f7-block>
                <p></p>
              </f7-block>
            </f7-accordion-content>
          </f7-list-item>
          <f7-button fill large style="border-radius: 0px" color="gray" class="buttons">{{
            $t("common.seeDetail")
          }}</f7-button>
        </f7-card>

        <div v-if="this.shopList === '' || this.shopList.length < 1">
          <span class="padding-left" style="text-align: center">{{
            $t("common.nothingFound")
          }}</span>
          <a href="#" style="display: flex; justify-content: center">
            <f7-chip color="red" outline :text="$t('shopdetail.addNewShop')" href="/shop/new">
              <template #media>
                <f7-icon color="red" ios="f7:plus_circle_fill" aurora="f7:plus_circle_fill" md="material:add_circle">
                </f7-icon>
              </template>
            </f7-chip>
          </a>
        </div>
        <f7-list dividers-ios strong-iosrecipients-list class="searchbar-not-found" media-list>
          <f7-list-item :title="$t('common.nothingFound')" style="font-size: 18px; color: red"></f7-list-item>
        </f7-list>
        <f7-list dividers-ios strong-ios class="search-list searchbar-found" media-list style="padding-bottom: 80px"
          v-if="!!this.shopList || this.shopList.length >= 1"
          v-for="(shopGroup, ownership, index) in this.shopGroupList" :key="index">
          <!-- <f7-list-group
      > -->
          <f7-list-item :title="ownership" group-title text-color="primary" style="font-size: 18px"></f7-list-item>

          <f7-card class="demo-facebook-card" v-for="(shop, index) in shopGroup" :key="index">
            <a href="#" @click="viewDetail(shop)" class="tontineLink">
              <f7-card-header class="no-border">
                <div class="demo-facebook-avatar padding-right">
                  <img v-if="!!shop.shopLogo" :src="`${shop.logo}`" width="40" height="40" style="border-radius: 50%" />
                  <f7-badge v-else slot="media" color="auto"
                    style="height: 40px; width: 40px; font-size: 16px; border-radius: 50%">
                    {{ $filtersGetInitial.getInitial(shop.name) }}</f7-badge>
                </div>
                <div class="shopName demo-facebook-name">
                  {{ shop.name }}
                </div>
                <div class="demo-facebook-date" v-html="shop.tag"></div>
              </f7-card-header>

              <f7-card-content :padding="false" v-if="shopList">
                <swiper-container pagination :slidesPerView="1" :spaceBetween="1" :centeredSlides="true"
                  :disableOnInteraction="true">
                  <swiper-slide v-for="(imageUrl, index) in shop.shopImagesList" :key="index"
                    style="width: 100%; height: 400px" class="align-content-center">
                    <img v-if="imageUrl.url !== null && imageUrl.fileType === 'image'" :src="`${imageUrl.url}`"
                      max-height="100%" style="display: block; margin: auto; width: 100%" />
                    <video v-if="imageUrl.url !== null && imageUrl.fileType === 'video'" :src="`${imageUrl.url}`"
                      controls max-height="100%" style="display: block; margin: auto; width: 100%" />
                  </swiper-slide>
                  <swiper-slide v-if="shop.qr !== null" style="width: 100%; height: 400px" class="align-content-center">
                    <img :src="`${shop.qr}`" max-height="100%" style="display: block; margin: auto; width: auto" />
                  </swiper-slide>
                </swiper-container>
              </f7-card-content>
            </a>
            <f7-list dividers-ios strong-ios accordion-list media-list>
              <f7-list-item accordion-item :title="$t('common.description')" :padding="false">
                <f7-accordion-content>
                  <f7-block>
                    <p v-html="shop.about"></p>
                  </f7-block>
                </f7-accordion-content>
              </f7-list-item>
            </f7-list>
            <f7-button raised fill ripple large class="card_button buttons" color="red" @click="viewDetail(shop)">{{
              $t("common.seeDetail") }}</f7-button>
          </f7-card>

          <!-- </f7-list-group> -->
        </f7-list>
      </div>
      <div class="right_layout"></div>
    </div>
    <template #fixed>
      <f7-fab position="center-bottom" :text="$t('shopdetail.addNewShop')" color="red" href="/shop/new">
        <f7-icon ios="f7:plus" aurora="f7:plus" md="material:add"></f7-icon>
      </f7-fab>
    </template>
  </f7-page>
</template>
<style scoped>
.circle-container li i.icon.f7- {
  color: blue;
}

.circle-container li i.icon.material- {
  color: blue;
}

.tontineLink {
  color: inherit;
}

.demo-facebook-card .card-header {
  display: block;
  padding: 10px;
}

.demo-facebook-card .demo-facebook-avatar {
  float: left;
}

.demo-facebook-card .demo-facebook-name {
  margin-left: 44px;
  font-size: 14px;
  font-weight: 500;
}

.demo-facebook-card .demo-facebook-date {
  margin-top: 8px;
  font-size: 12px;
  color: #8e8e93;
}

.demo-facebook-card .card-content img {
  display: block;
}

.demo-facebook-card .card-content-padding {
  padding: 15px 10px;
}

.demo-facebook-card .card-content-padding .likes {
  color: #8e8e93;
}
</style>

<script>
import { f7, theme } from "framework7-vue";
import { mapGetters, mapState } from "vuex";
import Navbarright from "../components/navbarright.vue";
//import { calculateTransferPayment } from "../js/mixins/transaction.js";
import { shop } from "../js/mixins/shop.js";
import _ from "lodash";
export default {
  props: {
    f7route: Object,
    f7router: Object,
  },
  components: {
    navbarright: Navbarright,
  },
  mixins: [shop],
  data() {
    return {
      theme,
      f7,
      visibleItem: 6,
      allowInfinite: true,
      showPreloader: false,
      theme,
    };
  },

  mounted: function () {
    const self = this;

    if (!this.userAccount && this.$keycloak.authenticated) {
      self.$store.dispatch("account/loadAccount", this.profile.sub);
    }
    if (this.country === "" && !this.country) {
      self.$store.dispatch("location/getCountry");
    }
  },
  methods: {
    async viewDetail(shop) {
      const self = this;

      await f7.preloader.show("multi");
      if ((shop.shopOwnerId = this.profile.sub)) {
        await self.$store.dispatch("shop/getShopById", shop.id);

        setTimeout(() => {
          this.f7router.navigate("/shop/" + shop.id);
          f7.preloader.hide();
        }, 300);
      } else {
        await self.$store.dispatch("shop/getActiveShopById", shop.id);
        setTimeout(() => {
          this.f7router.navigate("/shop/" + shop.id);
          f7.preloader.hide();
        }, 300);
      }
    },
    loadMore() {
      const self = this;
      if (!self.allowInfinite) return;
      self.allowInfinite = false;
      setTimeout(() => {
        this.visibleItem += 6;
        self.$store.dispatch("shop/getUserShopList", this.visibleItem);
        self.allowInfinite = true;
      }, 1000);
    },
    pageInit() {
      const self = this;

      self.$store.dispatch("shop/getUserShopList", this.visibleItem);
    },
  },

  computed: {
    ...mapState("shop", ["categorylist", "categories", "shop", "shopList"]),

    ...mapState("location", [
      "destinationCity",
      "country",
      "countryStates",
      "countryCities",
      "currentUserCountry",
    ]),
    ...mapState("account", ["userAccount", "userDetail", "searchedUser"]),
    ...mapState("auth", ["phoneNumberDetail", "mobilePhoneError", "profile"]),
    ...mapState("transaction", ["destinationCountry", "originCountry"]),

    ...mapGetters("auth", [
      "getProfile",
      "isAuthenticated",
      "isProfileLoaded",
      "getusererror",
    ]),
    ...mapGetters("transaction", [
      "getusererror",
      "fromCurrencyRate",
      "toCurrencyRate",
      "grossexchangerate2",
    ]),

    ...mapGetters("remit", ["recipients"]),
    ...mapGetters("account", ["userByAccountNumber"]),

    destinationCtry() {
      return _.groupBy(this.country, "continentName");
    },
  },
};
</script>
