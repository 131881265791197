<template>
  <div v-if="!this.f7.device.cordova">
    <f7-popup class="ScanQrCodeNoCordova" swipe-to-close tablet-fullscreen :backdrop="false" @popup:open="scanImage()"
      @popup:close="stopScan()">
      <f7-page>
        <div class="layout_content">
          <div class="left_layout"></div>
          <div class="main_layout">
            <div id="video-container" class="avocash-style">
              <video id="qr-video"></video>
            </div>
            <f7-block v-if="this.resultQR">
              <p>{{ this.resultQR }}</p>
            </f7-block>
            <f7-link @click="scanQRCodeFile()" icon-ios="f7:qrcode_viewfinder" icon-aurora="f7:qrcode_viewfinder"
              icon-md="material:qr_code"
              style="position: absolute; right: 16px; top: 16px; margin-right: auto"></f7-link>
            <input type="file" id="qr-input-file" accept="image/*" style="display: none" />
          </div>
          <div class="right_layout"></div>
        </div>
        <template #fixed>
          <f7-fab v-if="this.hasTorch" :style="{
            bottom: '0px',
          }" position="left-bottom" @click="activateTorch()" style="background-color: transparent">
            <f7-icon v-if="this.torchActive" ios="material:flash_on" aurora="material:flash_on" md="material:flash_on"
              color="white"></f7-icon>
            <f7-icon v-else ios="material:flash_off" aurora="material:flash_off" md="material:flash_off" color="white">
            </f7-icon>
          </f7-fab>
          <f7-fab position="center-bottom" @click="closePopup()" :style="{
            bottom: '0px',
          }">
            <f7-icon ios="f7:multiply" md="material:close" color="white"></f7-icon>
          </f7-fab>
          <f7-fab :style="{
            bottom: '0px',
          }" v-if="this.cameraSupport2Cameras && this.cameraSupport2Cameras.length >= 2" position="right-bottom"
            color="none" @click="switchCamera()" style="background-color: transparent">
            <f7-icon ios="f7:camera_rotate" aurora="f7:camera_rotate" md="f7:camera_rotate" color="white"></f7-icon>
          </f7-fab>
        </template>
      </f7-page>
    </f7-popup>
    <f7-sheet v-if="this.resultQR" class="notAvocashQRCODE" style="height: auto; --f7-sheet-bg-color: #fff"
      swipe-to-close backdrop close-on-escape close-by-outside-click>
      <div class="layout_content">
        <div class="left_layout"></div>
        <div class="main_layout">
          <f7-block>
            <p>{{ this.resultQR }}</p>
          </f7-block>
        </div>
        <div class="right_layout"></div>
      </div>
    </f7-sheet>
  </div>
</template>
<style scoped>
.fab>a,
.fab-buttons a {
  background: none;
  box-shadow: none;
}

.frame {
  border-style: solid;
  border-width: 3px;
  border-color: var(--f7-theme-color);
  height: 200px;
  width: 200px;
  position: absolute;
  top: 0px;
  bottom: 0px;
  right: 0px;
  left: 0px;
  margin: auto;
}

.scan-confirmation {
  position: absolute;
  width: 100%;
  height: 100%;

  background-color: rgba(255, 255, 255, 0.8);

  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
}

#video-container.avocash-style .scan-region-highlight-svg,
#video-container.avocash-style .code-outline-highlight {
  stroke: var(--f7-theme-color) !important;
}

.scan-region-highlight-svg {
  stroke: var(--f7-theme-color) !important;
}

.code-outline-highlight {
  stroke: var(--f7-theme-color) !important;
}

#qr-video {
  width: 100vw !important;
  height: 60vh !important;
  object-fit: cover;
}
</style>
<script>
import {
  f7Popup,
  f7Page,
  f7Link,
  f7Button,
  f7Block,
  f7Toolbar,
  f7Fab,
  f7Icon,
  f7Sheet,
  theme,
  f7,
} from "framework7-vue";
// import { scanQRCodeGeneral } from "../js/mixins/scanPayQRCode.js";
// import { cardConnect } from "../js/websocket/cardWebsocket.js";
// import { paymentConnect } from "../js/websocket/paymentWebsocket.js";
import { mapGetters, mapState } from "vuex";
import QrScanner from "qr-scanner";
import _ from "lodash";
export default {
  props: {
    f7route: Object,
    f7router: Object,
  },
  components: {
    f7Popup,
    f7Page,
    f7Link,
    f7Button,
    f7Block,
    f7Toolbar,
    f7Fab,
    f7Icon,
    f7Sheet,
  },
  // mixins: [scanQRCodeGeneral, cardConnect /* paymentConnect/*/],
  data() {
    return {
      theme,
      f7,
      resultQR: "",
      torchActive: false,
      hasTorch: false,
      cameraSupport2Cameras: [],
      permission_name: "camera",
      permission: false,
      cameraPermissionStatus: "",
      techPermission: {},
      camera: "",
      qrUrl: "",
      front: "false",
      qrcode: "",
      videoContainer: "",
      video: "",
      scanner: "",
      camnum: 0,
    };
  },
  mounted() {
    if (!(!f7.device.cordova && typeof QrScanner !== "undefined")) {
      return;
    }
    this.video = document.getElementById("qr-video");

    this.scanner = new QrScanner(this.video, (result) => this.onDecode(result.data), {
      onDecodeError: (error) => {
        console.log(error);
      },
      highlightScanRegion: true,
      highlightCodeOutline: true,
    });
    this.videoContainer = document.getElementById("video-container");
    this.updateStyle();
  },
  methods: {
    updateStyle() {
      if (f7.device.cordova) {
        return;
      }
      this.videoContainer.className = "avocash-style";
      this.scanner._updateOverlay();
      this.scanner.setInversionMode("both");
    },
    activateTorch() {
      if (!f7.device.cordova) {
        this.torchActive = !this.torchActive;
        this.scanner
          .toggleFlash()
          .then(() => (this.torchActive = this.scanner.isFlashOn()));
      }
    },
    updateFlashAvailability() {
      if (!f7.device.cordova) {
        this.scanner.hasFlash().then((hasFlash) => {
          this.hasTorch = hasFlash;
        });
      }
    },
    scanImage() {
      if (!f7.device.cordova) {
        this.scanner.start().then(() => {
          this.updateFlashAvailability();
          QrScanner.listCameras(true).then((cameras) => {
            this.cameraSupport2Cameras = cameras;
          });
        });
      }
    },
    switchCamera() {
      if (!f7.device.cordova) {
        this.scanner
          .setCamera(this.cameraSupport2Cameras[this.camnum++].id)
          .then(this.updateFlashAvailability());
        if (this.camnum === this.cameraSupport2Cameras.length) {
          this.camnum = 0;
        }
      }
    },
    closePopup() {
      if (f7.device.cordova) {
        return;
      }
      this.resultQR = "";
      if (f7.$("#qr-canvas-visible")) {
        f7.$("#qr-canvas-visible").hide();
      }
      f7.popup.close(".ScanQrCodeNoCordova");
      this.scanner.stop();
    },
    async scanQRCodeFile() {
      if (f7.device.cordova) {
        return;
      }
      if (f7.$("#qr-canvas-visible")) {
        f7.$("#qr-canvas-visible").hide();
        this.resultQR = "";
      }
      this.stopScan();
      document.querySelector("#qr-input-file").click();
      const fileinput = document.getElementById("qr-input-file");
      fileinput.addEventListener("change", (e) => {
        const imageFile = e.target.files[0];
        if (e.target.files.length == 0) {
          return;
        }
        console.log(imageFile);
        QrScanner.scanImage(imageFile, { returnDetailedScanResult: true })
          .then((result) => this.onDecode(result.data))
          .catch((err) => {
            console.log(`Error scanning file. Reason: ${err}`);
          });
      });
    },
    stopScan() {
      if (f7.device.cordova) {
        return;
      }
      this.scanner.stop();
      f7.$("#qr-canvas-visible").hide();
      this.resultQR = "";
    },
  },
  computed: {
    ...mapState("account", ["primaryAccount", "businessAccount"]),
    ...mapGetters("auth", ["getProfile", "isAuthenticated", "isProfileLoaded"]),
    ...mapState("auth", ["profile", "isCordova"]),
    ...mapState("wallet", ["wallet"]),
    ...mapState("tontine", ["tontine"]),
    ...mapState("asso", ["association"]),
    ...mapState("product", ["product"]),
    ...mapState("shop", ["shop"]),
  },
};
</script>
