<template>
  <f7-page @page:beforeout="beforeOut">
    <f7-navbar sliding no-shadow>
      <f7-nav-left :back-link="$t('common.backButton')" :back-link-show-text="false"></f7-nav-left>
      <f7-nav-title>{{ $t("shoppingcart.myShoppingCart") }}</f7-nav-title>
      <f7-nav-right>
        <navbarright v-if="this.$keycloak.authenticated"></navbarright>
      </f7-nav-right>
      <f7-subnavbar>
        <p>
          {{ $t("shoppingcart.totalBasket") }}
          {{ $filtersToCurrency.toCurrency(this.totalCartAmount, "€", "EUR") }}
        </p>
        <f7-link icon-ios="f7:bin_xmark" icon-aurora="f7:bin_xmark" icon-md="material:remove_shopping_cart" color="red"
          @click="deleteCart" v-if="this.cart.length"></f7-link>
      </f7-subnavbar>
    </f7-navbar>
    <div class="layout_content">
      <div class="left_layout"></div>
      <div class="main_layout">
        <shoppingCartComponent></shoppingCartComponent>
        <!-- <f7-card :padding="false" v-for="(cartItem, index) in this.cart" :key="index">
      <f7-card-header>
        {{cartItem.item.Title}}
        <span
          class="text-align-left"
        >{{(cartItem.item['Variant Price'] * cartItem.item.quantity) | currency('€', 'EUR')}}</span>
      </f7-card-header>
      <f7-card-content>
        <f7-list dividers-ios strong-ios media-list>
          <f7-list-item
            :link="itemUrl(cartItem)"
            popup-close
            :title="cartItem.item['Option1 Name'] +
              ': ' +
              cartItem.item['Option1 Value']+' '+cartItem.item['Option2 Name'] +
              ': ' +
              cartItem.item['Option2 Value']"
            :after="cartItem.item['Variant Price'] | currency('€', 'EUR')"
          >
            <div accordion-list class="margin-bottom">
              <f7-accordion-item>
                <f7-accordion-toggle>
                  <a>
                    <i>{{ $t('shoppingcart.more') }}</i>
                  </a>
                </f7-accordion-toggle>
                <f7-accordion-content>
                  <p v-html="cartItem.item['Body (HTML)']"></p>
                </f7-accordion-content>
              </f7-accordion-item>
            </div>
            <img
              slot="media"
              :src="cartItem.item.ImageSrc || cartItem.item['Variant Image']"
              width="80"
            />
          </f7-list-item>
        </f7-list>
      </f7-card-content>
      <f7-card-footer>
        <f7-link
          @click="deleteFromCart(cartItem)"
          icon-color="red"
          icon-ios="f7:trash"
          icon-aurora="f7:trash"
          icon-md="material:delete"
        ></f7-link>
        <f7-stepper
          fill
          small
          raised
          readonly
          color="auto"
          :value="cartItem.item.quantity"
          :min="0"
          :max="cartItem.item['Variant Inventory Qty']"
          @stepper:plusclick="incrementCartItem(cartItem)"
          @stepper:minusclick="decrementCartItem(cartItem)"
          :step="1"
        ></f7-stepper>
      </f7-card-footer>
    </f7-card>
    <f7-list dividers-ios strong-ios media-list>
      <li v-if="this.cart.length>0">
        <div class="item-content">
          <div class="item-inner">
            <div class="item-title-row">
              <div class="item-title" style="font-size:80%">{{ $t('shoppingcart.totalAmount') }}</div>
              <div class="item-title" style="font-weight:bold">
                {{
                this.totalCartAmount
                | currency("€", "EUR")
                }}
              </div>
            </div>
          </div>
        </div>
      </li>
    </f7-list>-->

        <f7-toolbar tabbar bottom bg-color="red" :style="{ bottom: 0, position: isTablet ? 'initial' : 'fixed' }">
          <f7-link raised large small fill class="link buttons" ripple strong text-color="white" bg-color="red"
            @click="checkOut()">{{ $t("shoppingcart.checkoutNow") }}</f7-link>
        </f7-toolbar>
      </div>
      <div class="right_layout"></div>
    </div>
    <template #fixed>
      <f7-fab position="right-bottom" class="shareButton" color="auto" @click="socialShare(sharedContent)">
        <f7-icon aurora="f7:square_arrow_up" ios="f7:square_arrow_up" md="material:share"></f7-icon> </f7-fab></template>
  </f7-page>
</template>
<style scoped>
div.item-media>img {
  width: 50px;
  height: 50px;
  border: none;
}
</style>
<script>
import Navbarright from "../components/navbarright.vue";
import ShoppingCartComponent from "../components/shoppingCartComponent.vue";
import { mapGetters, mapState } from "vuex";
import { shopping } from "../js/mixins/shopping.js";
import { socialShare } from "../js/mixins/cordovaPlugins.js";
import { f7, theme } from "framework7-vue";
import _ from "lodash";
export default {
  props: {
    f7route: Object,
    f7router: Object,
  },
  components: {
    navbarright: Navbarright,
    shoppingCartComponent: ShoppingCartComponent,
  },
  mixins: [shopping, socialShare],
  data() {
    return { theme, f7, path: "", sharedContent: "", fileToSave: [] };
  },

  mounted() {
    const self = this;

    this.path = this.f7route.path;
  },
  methods: {
    async checkOut() {
      const self = this;
      await f7.popup.close();
      await this.f7router.navigate("/check-out");
    },
    beforeOut() {
      const self = this;
      f7.$(".mainToolbar").css("display", "block");
    },
  },

  computed: {
    ...mapState("shoppingCart", ["cart", "cartItemCount"]),
    ...mapGetters("shoppingCart", ["totalCartAmount", "totalItemCount"]),
  },
};
</script>
