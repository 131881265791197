import { f7 } from "framework7-vue";
import { mapGetters, mapState } from "vuex";
import _ from "lodash";
export const contributeRedeem = {
  props: {
    f7route: Object,
    f7router: Object,
  },
  data() {
    return {
      description: "",
      fundingTarget: "",
      amount: "",
      setAmount: null,
      paymentCurrency: "",
      walletCampaignBeneficiary: "",
      type: "",
      project_type: "",
      entityId: "",
      projectId: "",
      entity: "",
      project: "",
      isAdmin: "",
      isMember: "",
      trxType: "",
      referenceId: "",
      receiver_wallet_id: "",
      sender_wallet_id: "",
      tag: "",
      return_url: "",
      return_url_fail: "",
      countryCode: "",
      project: "",
      entity: "",
      cpgn: "",
    };
  },

  mounted() {
    if (!this.userAccount && this.$keycloak.authenticated) {
      this.$store.dispatch("account/loadAccount", this.profile.sub);
    }
    this.project = this.project;
    this.entity = this.entity;
    if (this.project && this.project.currencyCode !== "") {
      this.paymentCurrency = this.project.currencyCode;
      this.countryCode = this.project.countryCode;
    } else if (this.entity && this.entity.currencyCode !== "") {
      this.paymentCurrency = this.entity.currencyCode;
      this.countryCode = this.entity.countryCode;
    }
  },
  methods: {
    async contribute() {
      f7.preloader.show("multi");
      const idempotency_key = f7.utils.id(
        "xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx"
      );
      // await this.preparePayment(this.project);
      const baseTransfer = {
        amount: this.amount,
        trxType: this.trxType,
        currency:
          this.project.currencyCode === null
            ? this.entity.currencyCode
            : this.project.currencyCode,

        countryCode:
          !this.project.countryCode || this.project.countryCode === null
            ? this.entity.countryCode
            : this.project.countryCode,
        lang: this.locale,
        referenceId: this.referenceId,
        receiver_wallet_id: this.receiver_wallet_id,
        sender_wallet_id: this.$keycloak.authenticated
          ? this.userAccount.standardWallet.id
          : "",
        payer_account_id: this.$keycloak.authenticated
          ? this.userAccount.standard.id
          : "",
        description: this.description,
        tag: this.tag,
        partner_ref: idempotency_key,
        idempotency_key,
        return_url: this.return_url,
        return_url_fail: this.return_url_fail,
      };
      await this.$store.dispatch("apimoney/setBaseTransfer", baseTransfer);
      const url = await this.f7router.generateUrl({
        name: "paymentPage",
        props: {
          payment_detail: {
            paymentCurrency:
              this.project.currencyCode === null
                ? this.entity.currencyCode
                : this.project.currencyCode,
            entity: this.entity,
            project: this.project,
            transaction_type: "cash_in",
            tag: this.tag,
            wallet: this.apiWallet,
            setAmount: this.amount,
          },
        },
        query: {
          transfer_detail: this.$encodeData.encode([
            {
              paymentCurrency:
                this.project.currencyCode === null
                  ? this.entity.currencyCode
                  : this.project.currencyCode,
              entity: this.entity,
              project: this.project,
              transaction_type: "cash_in",
              tag: this.tag,
              wallet: this.apiWallet,
              setAmount: this.amount,
            },
            baseTransfer,
          ]),
        },
      });
      console.log("url", url);
      console.log("baseTransfer", baseTransfer);
      await this.f7router.navigate(url);
      f7.preloader.hide();

      // this.f7router.navigate("/pay/", {
      //   props: {
      //     payment_detail: {
      //       paymentCurrency: this.paymentCurrency
      //         ? this.paymentCurrency
      //         : this.userAccount.currencyCode,
      //       entity: this.entity,
      //       project: this.project,
      //       tranaction_type: "cash_in",
      //       tag: this.tag,
      //       wallet: this.apiWallet,
      //     },
      //   },
      // });
    },

    async preparePayment(campaign) {
      const baseUrl = f7.device.cordova
        ? import.meta.env.VITE_AVOCASH_SITE_URL
        : window.location.origin;
      this.return_url = `${baseUrl}/transaction/success`;
      this.return_url_fail = `${baseUrl}/transaction/failure`;
      const findUser = (cp) => cp.userId === this.profile.sub;
      console.log("campaign", campaign);
      switch (this.type) {
        case "wallet":
          setTimeout(() => {
            this.entity = this.wallet;
            this.project = campaign;
            this.trxType = "WALL";
            this.referenceId = campaign.id;
            this.tag = `${campaign.name} - ${this.wallet.name}`;
            this.description = campaign.description;
            if (this.$keycloak.authenticated) {
              // const findUser = (cp) => cp.userId === this.profile.sub;
              this.isAdmin = this.wallet.walletAdminList.some(findUser);
              this.isMember = this.wallet.walletOwnerList.some(findUser);
            }

            const isContribute = this.project_type === "contribute";
            this.setAmount = isContribute
              ? campaign.fundingTarget
              : campaign.accountBalance;
            this.amount = this.setAmount;
            this.receiver_wallet_id =
              campaign.walletId === null
                ? this.wallet.walletId
                : campaign.walletId;

            if (!isContribute) {
              this.sender_wallet_id =
                campaign.walletId === null
                  ? this.wallet.walletId
                  : campaign.walletId;
              if (campaign.beneficiarywalletId !== null) {
                this.receiver_wallet_id = campaign.beneficiarywalletId;
              }
            }
          }, 300);
          console.log(
            "this.trxType",
            this.trxType,
            this.project,
            this.receiver_wallet_id
          );
          break;
        case "association":
          this.entity = this.association;
          this.project = campaign;
          this.referenceId = campaign.id;
          this.trxType = "ASSO";

          this.tag = `${campaign.name} - ${this.association.name}`;
          this.description = campaign.description;
          setTimeout(() => {
            // const findUser = (cp) => cp.userId === this.profile.sub;
            this.isAdmin = this.association.associationAdminList.some(findUser);
            this.isMember =
              this.association.associationMemberList.some(findUser);
            const isContribute = this.project_type === "contribute";
            this.setAmount = isContribute
              ? this.association.campaignAmount
              : campaign.accountBalance;
            this.amount = this.setAmount;
            this.receiver_wallet_id =
              campaign.walletId === null
                ? this.association.walletId
                : campaign.walletId;
            if (!isContribute) {
              this.sender_wallet_id =
                campaign.walletId === null
                  ? this.association.walletId
                  : campaign.walletId;

              if (campaign.campaignBeneficiary !== null) {
                this.getSelectedUser(campaign.campaignBeneficiary);
                setTimeout(() => {
                  this.receiver_wallet_id =
                    this.userDetail.standardWallet.length > 0
                      ? this.userDetail.standardWallet.id
                      : "";
                }, 200);
              }
            }
          }, 300);
          console.log(
            "this.trxType",
            this.trxType,
            this.project,
            this.receiver_wallet_id
          );
          break;
        case "tontine":
          this.entity = this.tontine;
          this.project = campaign;
          this.referenceId = campaign.id;
          this.trxType = "TONT";
          setTimeout(() => {
            // const findUser = (cp) => cp.userId === this.profile.sub;
            this.isAdmin = this.tontine.tontineAdminList.some(findUser);
            this.isMember = this.tontine.tontineParticipantList.some(findUser);
            const roundTaker = this.tontine.tontineParticipantList.find(
              (cp) => cp.id === campaign.roundTaker
            );
            this.tag = `${this.$t("tontinedetail.roundTaker")}: ${
              roundTaker.name
            } ${this.tontine.name} ${this.$t("tontinedetail.round")}-${
              campaign.roundNumber
            }`;
            this.description = roundTaker.name;

            const isContribute = this.project_type === "contribute";
            this.setAmount = isContribute
              ? campaign.tontineRoundAmount
              : campaign.accountBalance;
            this.amount = this.setAmount;
            this.receiver_wallet_id =
              campaign.walletId === null
                ? this.tontine.walletId
                : campaign.walletId;
            if (!isContribute) {
              this.sender_wallet_id =
                campaign.walletId === null
                  ? this.tontine.walletId
                  : campaign.walletId;
              if (this.$keycloak.authenticated) {
                this.getSelectedUser(roundTaker.userId);
                setTimeout(() => {
                  this.receiver_wallet_id =
                    this.userDetail.standardWallet.length > 0
                      ? this.userDetail.standardWallet.id
                      : "";
                }, 200);
              }
            }
          }, 300);
          break;
        case "savings":
          this.entity = this.savings;
          this.project = campaign;
          this.referenceId = campaign.id;
          this.trxType = "SAVB";
          const participant = campaign.participantList.find(
            (cp) => cp.userId === this.profile.sub
          );
          setTimeout(() => {
            this.setAmount = this.amount = participant.contribAmount;
            this.isAdmin = this.savings.savingsGroupAdminList.some(findUser);
            this.isMember = campaign.participantList.some(findUser);

            this.tag = `${campaign.name} - ${this.savings.name}`;
            this.description = campaign.description;
            this.receiver_wallet_id = participant.walletId;
          }, 300);
          break;
        case "groupBuy":
          this.entity = this.groupBuy;
          this.project = campaign;
          this.referenceId = campaign.id;
          this.trxType = "SAVB";
          console.log("campaign", campaign);
          const participantGB = campaign.participantList.find(
            (cp) => cp.userId === this.profile.sub
          );
          console.log("participantGB", participantGB);
          setTimeout(() => {
            this.setAmount = this.amount = participantGB.contribAmount;
            this.isAdmin = this.groupBuy.adminList.some(findUser);
            this.isMember = campaign.participantList.some(findUser);
            this.trxType = "GBUY";
            this.referenceId = participantGB.id;
            this.receiver_wallet_id = this.groupBuy.walletId;
            this.tag = `${campaign.name} - ${this.groupBuy.name}`;
            this.description =
              campaign.description !== null
                ? campaign.description
                : campaign.tag;
          }, 300);
          break;
        default:
          console.log("No entity found");
          break;
      }
      setTimeout(() => {
        if (this.receiver_wallet_id !== null) {
          this.getReceiverApiWallet();
        }
      }, 500);
    },
    selectWalletBeneficiary() {
      if (
        this.$keycloak.authenticated &&
        this.walletCampaignBeneficiary !== null
      ) {
        this.getSelectedUser(this.walletCampaignBeneficiary);
      }
      setTimeout(() => {
        this.receiver_wallet_id =
          this.userDetail.standardWallet === null
            ? ""
            : this.userDetail.standardWallet.id;
      }, 500);
    },

    getSelectedUser(userId) {
      this.$store.dispatch("account/loadUser", { userId, type: "userId" });
    },
    getReceiverApiWallet() {
      if (this.receiver_wallet_id !== null) {
        this.$store.dispatch("apimoney/getApiWallet", {
          walletId: this.receiver_wallet_id,
        });
      }
    },
    getAccountType(type) {
      return type === "STANDARD"
        ? this.$t("common.standard")
        : this.$t("common.business");
    },
    changeAmountRedeem() {
      if (this.setAmount != null && this.setAmount < this.amount) {
        this.amount = this.setAmount;
      }
    },
    async redeem() {
      const idempotency_key = f7.utils.id(
        "xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx"
      );
      const baseTransfer = {
        amount: this.amount,
        trxType: this.trxType,
        referenceId: this.referenceId,
        lang: this.locale,
        currency:
          this.project.currencyCode === null
            ? this.entity.currencyCode
            : this.project.currencyCode,
        countryCode:
          !this.project.countryCode || this.project.countryCode === null
            ? this.entity.countryCode
            : this.project.countryCode,
        receiver_wallet_id: this.receiver_wallet_id,
        sender_wallet_id: this.sender_wallet_id,
        description: this.description,
        tag: this.tag,
        partner_ref: idempotency_key,
        idempotency_key,
        return_url: this.return_url,
        return_url_fail: this.return_url_fail,
      };
      await this.$store.dispatch("apimoney/setBaseTransfer", baseTransfer);
      const url = await this.f7router.generateUrl({
        name: "paymentPage",
        props: {
          payment_detail: {
            paymentCurrency:
              this.project.currencyCode === null
                ? this.entity.currencyCode
                : this.project.currencyCode,
            entity: this.entity,
            project: this.project,
            transaction_type: "cash_out",
            tag: this.tag,
            wallet: this.apiWallet,
            setAmount: this.amount,
          },
        },
        query: {
          transfer_detail: this.$encodeData.encode([
            {
              paymentCurrency:
                this.project.currencyCode === null
                  ? this.entity.currencyCode
                  : this.project.currencyCode,
              entity: this.entity,
              project: this.project,
              transaction_type: "cash_out",
              tag: this.tag,
              wallet: this.apiWallet,
              setAmount: this.amount,
            },
            baseTransfer,
          ]),
        },
      });
      console.log("url", url);
      await this.f7router.navigate(url);
      f7.preloader.hide();
      // await this.f7router.navigate(`/pay`, {
      //   props: {
      //     payment_detail: {
      //       paymentCurrency: this.paymentCurrency
      //         ? this.paymentCurrency
      //         : this.userAccount.currencyCode,
      //       entity: this.entity,
      //       project: this.project,
      //       tranaction_type: "transfer",
      //       tag: this.tag,
      //       wallet: this.apiWallet,
      //     },
      //   },
      // });
    },
  },

  computed: {
    ...mapState("account", ["userAccount", "searchedUser", "userDetail"]),
    ...mapState("auth", [
      "phoneNumberDetail",
      "mobilePhoneError",
      "profile",
      "isTablet",
      "authenticated",
      "locale",
    ]),
    ...mapState("savings", ["savings", "savingsList"]),
    ...mapState("groupBuy", ["groupBuy", "groupBuyList"]),
    ...mapState("wallet", ["wallet"]),
    ...mapState("tontine", ["tontine"]),
    ...mapState("asso", ["association"]),
    ...mapState("apimoney", ["baseTransfer", "apiWallet"]),
    ...mapState("location", ["country", "apiCode", "currentUserCountry"]),
    ...mapGetters("transaction", [
      "getusererror",
      "fromCurrencyRate",
      "toCurrencyRate",
      "grossexchangerate2",
    ]),
  },
};
