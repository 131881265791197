<template>
  <f7-block-title style="white-space: normal; word-wrap: break-word; font-weight: 90%"
    v-html="$t('common.addOrUpdateImages', { theType: this.type })"></f7-block-title>
  <div :class="'padding-bottom-half' + f7.device.desktop && 'dropzone-container margin'" v-if="!this.doc_type"
    @dragover="dragover" @dragleave="dragleave" @drop="drop"
    :style="{ 'border-color': this.isDragging ? 'primary' : 'gray' }">
    <input type="file" multiple name="file" :value="null" id="fileInput" style="display: none" @change="onChange"
      ref="file" accept="*" />
    <swiper-container v-if="this.documentsUrl && this.documentsUrl.length > 0"
      class="dropbox margin-horizontal padding-top" :slidesPerView="parseInt(f7.width / 150) + 1" :spaceBetween="20"
      :navigation="this.isTablet && this.documentsUrl.length > 0 ? true : false" :params="{
        disableOnInteraction: true,
      }">
      <f7-photo-browser :photos="this.photoList()" ref="popup"></f7-photo-browser>

      <swiper-slide style="width: 150px; height: 180px" v-for="(imageItem, index) in this.documentsUrl" :key="index">
        <a link="#" @click="$refs.popup.open()">
          <img v-if="imageItem.fileType !== null && imageItem.fileType === 'image'" :src="imageItem.url"
            alt="file to upload" class="margin-half" style="
              width: 100%;
              max-height: 160px;
              position: relative;
              top: 50%;
              transform: translateY(-50%);
            " /></a>
        <iframe v-if="imageItem.fileType !== 'image'" :src="imageItem.url" :title="imageItem.fileName" max-height="160"
          class="margin-half" allowfullscreen style="width: 100%"></iframe>
        <!-- <f7-block-footer>{{ imageItem.name }}</f7-block-footer> -->
        <f7-link raised :style="{
          position: 'absolute',
          left: '8px',
          top: '8px',
          color: 'red',
        }" icon-f7="xmark_circle_fill" @click="removeDoc(index)"></f7-link>
        <f7-link v-if="imageItem.fileType === 'image'" raised :style="{
          position: 'absolute',
          right: '-8px',
          top: '8px',
        }" icon-f7=" pencil_circle" @click="editImage(imageItem.fileId)"></f7-link>
        <f7-link v-if="imageItem.fileType !== 'image'" raised :style="{
          position: 'absolute',
          right: '-8px',
          top: '8px',
        }" icon-f7="arrow_up_right_square" @click="viewOtherFiles(imageItem)"></f7-link>
      </swiper-slide>
      <swiper-slide style="width: 150px; height: 150px">
        <div href="#" class="margin attach" width="auto" @click="attachFileOpenActions()">
          <f7-link icon-size="33" class="margin-top attachFileButton" icon-ios="f7:photo_on_rectangle"
            icon-aurora="f7:photo_on_rectangle" icon-md="material:add_to_photos">{{ $t("attachdocs.addDocphotos")
            }}</f7-link>
        </div>
      </swiper-slide>
    </swiper-container>

    <div v-else href="#" class="dropbox margin-horizontal" width="auto" outline novalidate
      @click="attachFileOpenActions()">
      <f7-link class="margin-top attachFileButton" icon-size="50" icon-ios="f7:photo_on_rectangle"
        icon-aurora="f7:photo_on_rectangle" icon-md="material:add_to_photos"
        :text="$t('attachdocs.addDocphotos')"></f7-link>
    </div>
    <f7-block class="no-margin-vertical"><span v-for="(docItem, index) in this.documentsUrl" :key="index">
        <f7-block-title href="#" @click="viewOtherFiles(docItem)" style="
            overflow: hidden;
            white-space: nowrap;
            max-width: calc(100% - 32px);
            text-overflow: ellipsis;
            display: inline-flex;
          ">{{ docItem.fileName }}
        </f7-block-title>
        <f7-link raised color="red" style="position: absolute; right: 16px; bottom: 40px" icon-f7="xmark_circle_fill"
          @click="removeDoc(index)"></f7-link>
      </span>
    </f7-block>
  </div>
</template>
<script>
import {
  f7BlockTitle,
  f7List,
  f7PhotoBrowser,
  f7Link,
  f7BlockFooter,
  f7Input,
  f7Actions,
  f7ActionsButton,
  f7Icon,
  f7ActionsGroup,
  f7Fab,
  f7FabButtons,
  f7FabButton,
  f7Popup,
  f7Page,
  f7PageContent,
  f7Navbar,
  f7NavLeft,
  f7NavRight,
  f7Block,
  theme,
  f7,
} from "framework7-vue";
import { mapGetters, mapState } from "vuex";
import { attachDocs } from "../js/mixins/attachDocs.js";
import { cordovaCamera } from "../js/mixins/cordovaCamera.js";
import _ from "lodash";
export default {
  props: {
    f7route: Object,
    f7router: Object,
    type: String,
  },
  mixins: [attachDocs, cordovaCamera],
  components: {
    f7BlockTitle,
    f7List,

    f7PhotoBrowser,
    f7Link,
    f7BlockFooter,
    f7Input,
    f7Actions,
    f7ActionsButton,
    f7Icon,
    f7ActionsGroup,
    f7Fab,
    f7FabButtons,
    f7FabButton,
    f7Popup,
    f7Page,
    f7PageContent,
    f7Navbar,
    f7NavLeft,
    f7NavRight,
    f7Block,
  },
  data() {
    return {
      theme,
      f7,
    };
  },
  mounted() { },

  methods: {},

  computed: {
    ...mapState("account", ["primaryAccount", "businessAccount"]),
    ...mapGetters("auth", ["getProfile", "isAuthenticated", "isProfileLoaded"]),
    ...mapState("auth", [
      "profile",
      "documentsUrl",
      "document",
      "isDeskTop",
      "isTablet",
      "isCordova",
      "locale",
    ]),
    doc_type() {
      console.log("path f7.views.main.router.currentRoute.path ", f7.views.main.router);
      if (f7.views.main.router.currentRoute.path) {
        return f7.views.main.router.currentRoute.path.includes("/message/");
      } else {
        return false;
      }
    },
  },
};
</script>
