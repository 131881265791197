// import base_url from "../base_url.js";
import axios from "axios";
const path = import.meta.env.VITE_APP_URL + "product";
//  const product ={
export default (app) => {
  return {
    namespaced: true,
    state: {
      productParent: "",
      product: "",
      images: "",
      queryerror: "",
      optionNames: "",
      shopImages: [],
      allProduct: "",
      productByList: "",
      productByListOwner: "",
      productByShopId: "",
      productByShopNumber: "",
      productByCategory: "",
      productBySubCategory: "",
      allProductParent: "",
      productParentByList: "",
      productParentByListOwner: "",
      productParentByShopId: "",
      productParentByShopNumber: "",
      productParentByCategory: "",
      productParentBySubCategory: "",
      negoPrice: "",
      groupBuy: "",
      userGroupBuyList: "",
      productParentGroupBuyList: "",
    },
    getters: {
      // totalshopbasket() {
      //     return _.reduce(shopitemlist, function(amount, shopitem) {
      //         return amount + parseFloat(shopitem.shopAmount || 0);
      //     }, 0);
      // },
    },
    mutations: {
      CREATE_PRODUCT_PARENT(state, productParent) {
        state.productParent = productParent;
      },
      EDIT_PRODUCT_PARENT(state, productParent) {
        state.productParent = productParent;
      },
      DELETE_PRODUCT_PARENT(state, productParent) {
        state.productParent = productParent;
      },
      GET_PRODUCT_PARENT(state, productParent) {
        state.productParent = productParent;
      },

      CREATE_PRODUCT(state, product) {
        state.product = product;
      },
      EDIT_PRODUCT(state, product) {
        state.product = product;
      },
      DELETE_PRODUCT(state, product) {
        state.product = product;
      },
      GET_PRODUCTBYID(state, product) {
        state.product = product;
      },
      GET_ALLPRODUCT(state, allProduct) {
        state.allProduct = allProduct;
      },
      GET_PRODUCTBYLIST(state, productByList) {
        state.productByList = productByList;
      },
      GET_PRODUCTBYLISTOWNER(state, productByListOwner) {
        state.productByListOwner = productByListOwner;
      },
      GET_PRODUCTBYSHOPID(state, productByShopId) {
        state.productByShopId = productByShopId;
      },
      GET_PRODUCTBYSHOPNUMBER(state, productByShopNumber) {
        state.productByShopNumber = productByShopNumber;
      },
      GET_PRODUCTBYCATEGORY(state, productByCategory) {
        state.productByCategory = productByCategory;
      },
      GET_PRODUCTBYSUBCATEGORY(state, productBySubCategory) {
        state.productBySubCategory = productBySubCategory;
      },
      GET_ALLPRODUCTPARENT(state, allProductParent) {
        state.allProductParent = allProductParent;
      },
      GET_PRODUCTPARENTBYLIST(state, productParentByList) {
        state.productParentByList = productParentByList;
      },
      GET_PRODUCTPARENTBYLISTOWNER(state, productParentByListOwner) {
        state.productParentByListOwner = productParentByListOwner;
      },
      GET_PRODUCTPARENTBYSHOPID(state, productParentByShopId) {
        state.productParentByShopId = productParentByShopId;
      },
      GET_PRODUCTPARENTBYSHOPNUMBER(state, productParentByShopNumber) {
        state.productParentByShopNumber = productParentByShopNumber;
      },
      GET_PRODUCTPARENTBYCATEGORY(state, productParentByCategory) {
        state.productParentByCategory = productParentByCategory;
      },
      GET_PRODUCTPARENTBYSUBCATEGORY(state, productParentBySubCategory) {
        state.productParentBySubCategory = productParentBySubCategory;
      },

      GET_NEGOPRICE(state, negoPrice) {
        state.negoPrice = negoPrice;
      },

      CREATE_GROUPBUY(state, groupBuy) {
        state.groupBuy = groupBuy;
      },
      ADD_CUSTOMERTOGROUPBUY(state, groupBuy) {
        state.groupBuy = groupBuy;
      },
      REMOVE_CUSTOMERTOGROUPBUY(state, groupBuy) {
        state.groupBuy = groupBuy;
      },
      DELETE_GROUPBUY(state, payload) {
        state.payload = payload;
      },
      GET_GROUPBUY(state, groupBuy) {
        state.groupBuy = groupBuy;
      },
      GET_USERGROUPBUYLIST(state, userGroupBuyList) {
        state.userGroupBuyList = userGroupBuyList;
      },

      GET_PRODUCTPARENTGROUPBUYLIST(state, productParentGroupBuyList) {
        state.productParentGroupBuyList = productParentGroupBuyList;
      },

      GET_SHOPIMAGES(state, shopImages) {
        state.shopImages = shopImages;
      },

      GET_OPTIONNAMES(state, optionNames) {
        state.optionNames = optionNames;
      },
    },
    actions: {
      getOptionNames({ commit }) {
        axios({
          url: `${path}/info/get/OptionName`,
          method: "GET",
        })
          .then((r) => r.data)
          .then((optionNames) => {
            commit("GET_OPTIONNAMES", optionNames);
            console.log(optionNames);
          })
          .catch((err) => {
            console.log(err.response.data);
          });
      },
      createProductParent({ commit, dispatch }, payload) {
        axios({
          url: `${path}/parent/create`,
          method: "POST",
          data: payload,
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((productParent) => {
            commit("CREATE_PRODUCT_PARENT", productParent);
            setTimeout(() => {
              dispatch("getProductParent", productParent.id);
            }, 1000);
          })
          .catch((err) => {
            console.log(err);
          });
      },
      editProductParent({ commit, dispatch }, payload) {
        axios({
          url: `${path}/parent/edit/${payload.productParentId}`,
          method: "PUT",
          data: payload.formData,
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((productParent) => {
            commit("GET_PRODUCT_PARENT", productParent);
            setTimeout(() => {
              dispatch("getProductParent", productParent.id);
            }, 1000);
          })
          .catch((err) => {
            console.log(err);
          });
      },
      getProductParent({ commit, dispatch }, payload) {
        axios({
          url: `${path}/parent/findById/${payload.productParentId}`,
          method: "GET",
          // headers: {
          //     Authorization: `Bearer ${ app.config.globalProperties.$keycloak.token}`
          // }
        })
          .then((r) => r.data)
          .then((productParent) => {
            commit("GET_PRODUCT_PARENT", productParent);
          })
          .catch((err) => {
            console.log(err);
          });
      },

      createProduct({ commit, dispatch }, payload) {
        axios({
          url: `${path}/create`,
          method: "POST",
          data: payload,
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((product) => {
            commit("CREATE_PRODUCT", product);
            setTimeout(() => {
              dispatch("getProduct", product.id);
            }, 1000);
          })
          .catch((err) => {
            console.log(err);
          });
      },
      editProduct({ commit, dispatch }, payload) {
        axios({
          url: `${path}/edit/${payload.productId}`,
          method: "PUT",
          data: payload.formData,
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((product) => {
            commit("GET_PRODUCT", product);
            setTimeout(() => {
              dispatch("getProduct", product.id);
            }, 1000);
          })
          .catch((err) => {
            console.log(err);
          });
      },
      getProductById({ commit, dispatch }, payload) {
        axios({
          url: `${path}/findById/${payload.productId}`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((product) => {
            commit("GET_PRODUCTBYID", product);
          })
          .catch((err) => {
            console.log(err);
          });
      },

      updateProductImage({ commit }, payload) {
        axios({
          url: `${path}/image/update`,
          method: "PUT",
          data: payload,
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((shopImages) => {
            commit("GET_SHOPIMAGES", shopImages);
          })
          .catch((err) => {
            console.log(err);
          });
      },
      deleteProductImage({ commit, dispatch }, payload) {
        axios({
          url: `${path}/image/delete/${payload}`,
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((shopImages) => {
            commit("GET_SHOPIMAGES", shopImages);
          })
          .catch((err) => {
            console.log(err);
          });
      },

      getAllProduct({ commit }) {
        axios({
          url: `${path}/info/getAll`,
          method: "GET",
        })
          .then((r) => r.data)
          .then((allProduct) => {
            commit("GET_ALLPRODUCT", allProduct);
          })
          .catch((err) => {
            console.log(err);
          });
      },
      getProductByListType({ commit }, payload) {
        axios({
          url: `${path}/info/listType`,
          method: "GET",
          params: {
            listType: payload,
          },
        })
          .then((r) => r.data)
          .then((productByList) => {
            commit("GET_PRODUCTBYLIST", productByList);
          })
          .catch((err) => {
            console.log(err);
          });
      },
      getProductByListOwner({ commit }, payload) {
        axios({
          url: `${path}/info/listingOwner`,
          method: "GET",
          params: {
            listingOwnerId: payload,
          },
        })
          .then((r) => r.data)
          .then((productByListOwner) => {
            commit("GET_PRODUCTBYLISTOWNER", productByListOwner);
          })
          .catch((err) => {
            console.log(err);
          });
      },
      getProductByShopId({ commit }, payload) {
        axios({
          url: `${path}/info/shopId`,
          method: "GET",
          params: {
            shopId: payload,
          },
        })
          .then((r) => r.data)
          .then((productByShopId) => {
            commit("GET_PRODUCTBYSHOPID", productByShopId);
          })
          .catch((err) => {
            console.log(err);
          });
      },
      getProductByShopNumber({ commit }, payload) {
        axios({
          url: `${path}/info/hopNumber`,
          method: "GET",
          params: {
            shopNumber: payload,
          },
        })
          .then((r) => r.data)
          .then((productByShopNumber) => {
            commit("GET_PRODUCTBYSHOPNUMBER", productByShopNumber);
          })
          .catch((err) => {
            console.log(err);
          });
      },
      getProductByCategory({ commit }, payload) {
        axios({
          url: `${path}/info/findByCategory`,
          method: "GET",
          params: {
            categoryId: payload,
          },
        })
          .then((r) => r.data)
          .then((productByCategory) => {
            commit("GET_PRODUCTBYCATEGORY", productByCategory);
          })
          .catch((err) => {
            console.log(err);
          });
      },
      getProductBySubCategory({ commit }, payload) {
        axios({
          url: `${path}/info/findBySubCategory`,
          method: "GET",
          params: {
            subCategoryId: payload,
          },
        })
          .then((r) => r.data)
          .then((productBySubCategory) => {
            commit("GET_PRODUCTBYSUBCATEGORY", productBySubCategory);
          })
          .catch((err) => {
            console.log(err);
          });
      },

      getAllProductParent({ commit }) {
        axios({
          url: `${path}/parent/info/getAll`,
          method: "GET",
        })
          .then((r) => r.data)
          .then((allProductParent) => {
            commit("GET_ALLPRODUCTPARENT", allProductParent);
          })
          .catch((err) => {
            console.log(err);
          });
      },
      getProductParentByListType({ commit }, payload) {
        axios({
          url: `${path}/parent/info/listType`,
          method: "GET",
          params: {
            listType: payload,
          },
        })
          .then((r) => r.data)
          .then((productParentByList) => {
            commit("GET_PRODUCTPARENTBYLIST", productParentByList);
          })
          .catch((err) => {
            console.log(err);
          });
      },
      getProductParentByListOwner({ commit }, payload) {
        axios({
          url: `${path}/parent/info/istOwner`,
          method: "GET",
          params: {
            listOwnerId: payload,
          },
        })
          .then((r) => r.data)
          .then((productParentByListOwner) => {
            commit("GET_PRODUCTPARENTBYLISTOWNER", productParentByListOwner);
          })
          .catch((err) => {
            console.log(err);
          });
      },
      getProductParentByShopId({ commit }, payload) {
        axios({
          url: `${path}/parent/info/shopId`,
          method: "GET",
          params: {
            shopId: payload,
          },
        })
          .then((r) => r.data)
          .then((productParentByShopId) => {
            commit("GET_PRODUCTPARENTBYSHOPID", productParentByShopId);
          })
          .catch((err) => {
            console.log(err);
          });
      },
      getProductParentByShopNumber({ commit }, payload) {
        axios({
          url: `${path}/parent/info/shopNumber`,
          method: "GET",
          params: {
            shopNumber: payload,
          },
        })
          .then((r) => r.data)
          .then((productParentByShopNumber) => {
            commit("GET_PRODUCTPARENTBYSHOPNUMBER", productParentByShopNumber);
          })
          .catch((err) => {
            console.log(err);
          });
      },
      getProductParentByCategory({ commit }, payload) {
        axios({
          url: `${path}/parent/info/findByCategory`,
          method: "GET",
          params: {
            categoryId: payload,
          },
        })
          .then((r) => r.data)
          .then((productParentByCategory) => {
            commit("GET_PRODUCTPARENTBYCATEGORY", productParentByCategory);
          })
          .catch((err) => {
            console.log(err);
          });
      },
      getProductParentBySubCategory({ commit }, payload) {
        axios({
          url: `${path}/parent/info/findBySubCategory`,
          method: "GET",
          params: {
            subCategoryId: payload,
          },
        })
          .then((r) => r.data)
          .then((productParentBySubCategory) => {
            commit(
              "GET_PRODUCTPARENTBYSUBCATEGORY",
              productParentBySubCategory
            );
          })
          .catch((err) => {
            console.log(err);
          });
      },

      getProductNegoPrice({ commit, dispatch }, payload) {
        axios({
          url: `${path}/info/checkNegotiablePrice`,
          method: "GET",
          params: {
            productId: payload.productId,
            proposedPrice: payload.proposedPrice,
            userId: payload.userId,
            userIp: ipAddress,
          },
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((productsBySubCategory) => {
            commit("GET_NEGOPRICE", negoPrice);
          })
          .catch((err) => {
            console.log(err.response.data);
          });
      },

      createGroupBuy({ commit, dispatch }, payload) {
        axios({
          url: `${path}/create/groupBuy`,
          method: "POST",
          params: {
            productParentId: payload.productParentId,
            userId: rootState.auth.profile.sub,
            groupBuySize: payload.groupBuySize,
          },
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((groupBuy) => {
            commit("CREATE_GROUPBUY", groupBuy);
          })
          .catch((err) => {
            console.log(err.response.data);
          });
      },
      addCustomerToGroupBuy({ commit, dispatch }, payload) {
        axios({
          url: `${path}/groupBuy/add/customer`,
          method: "POST",
          params: {
            groupBuyId: payload.groupBuyId,
            userId: payload.userId,
          },
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((groupBuy) => {
            commit("ADD_CUSTOMERTOGROUPBUY", groupBuy);
          })
          .catch((err) => {
            console.log(err.response.data);
          });
      },
      removeCustomerFromGroupBuy({ commit, dispatch }, payload) {
        axios({
          url: `${path}/groupBuy/remove/customer`,
          method: "DELETE",
          params: {
            groupBuyId: payload.groupBuyId,
            userId: payload.userId,
          },
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((groupBuy) => {
            commit("REMOVE_CUSTOMERTOGROUPBUY", groupBuy);
          })
          .catch((err) => {
            console.log(err.response.data);
          });
      },

      deleteGroupBuy({ commit, dispatch }, payload) {
        axios({
          url: `${path}/groupBuy/delete`,
          method: "DELETE",
          params: {
            groupBuyId: payload.groupBuyId,
            userId: rootState.auth.profile.sub,
          },
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((groupBuy) => {
            commit("DELETE_GROUPBUY", payload);
          })
          .catch((err) => {
            console.log(err.response.data);
          });
      },
      getGroupBuy({ commit, dispatch }, payload) {
        axios({
          url: `${path}/get/groupBuy`,
          method: "GET",
          params: {
            groupBuyId: payload.groupBuyId,
            // userId: rootState.auth.profile.sub,
          },
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((groupBuy) => {
            commit("GET_GROUPBUY", groupBuy);
          })
          .catch((err) => {
            console.log(err.response.data);
          });
      },
      getUserActiveGroupBuy({ commit, dispatch }, payload) {
        axios({
          url: `${path}/user/active/groupBuy`,
          method: "GET",
          params: {
            userId: payload.userId,
            // userId: rootState.auth.profile.sub,
          },
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((userGroupBuyList) => {
            commit("GET_USERGROUPBUYLIST", userGroupBuyList);
          })
          .catch((err) => {
            console.log(err.response.data);
          });
      },
      getProductActiveGroupBuy({ commit, dispatch }, payload) {
        axios({
          url: `${path}/parent/active/groupBuy`,
          method: "GET",
          params: {
            productParentId: payload.productParentId,
            // userId: rootState.auth.profile.sub,
          },

          // headers: {
          //     Authorization: `Bearer ${ app.config.globalProperties.$keycloak.token}`
          // }
        })
          .then((r) => r.data)
          .then((productParentGroupBuyList) => {
            commit("GET_PRODUCTPARENTGROUPBUYLIST", productParentGroupBuyList);
          })
          .catch((err) => {
            console.log(err.response.data);
          });
      },

      decodeProductQRCode({ commit }, payload) {
        axios({
          url: `${path}/decodeQRCode`,
          method: "GET",
          params: {
            qrCodeText: payload,
          },
        })
          .then((r) => r.data)
          .then((product) => {
            commit("GET_PRODUCT", product);
          })
          .catch((err) => {
            console.log(err);
            console.log(err.response.data);
          });
      },
    },
  };
};
// export default product;
