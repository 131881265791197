import SockJS from "sockjs-client/dist/sockjs.min.js";
import Stomp from "webstomp-client";
import { f7, theme } from "framework7-vue";

import { mapState, mapGetters } from "vuex";

import _ from "lodash";
export const messageSocket = {
  // mixins: [messageConnect],
  data() {
    return {
      socketClient: "",
      // typingMessage: "",
    };
  },
  mounted() {},
  methods: {
    connectMessageSocket() {
      const self = this;
      const path = `${import.meta.env.VITE_APP_URL}ws`;
      const socket = new SockJS(path);
      socket.binaryType = "blob";
      this.socketClient = Stomp.over(socket);

      self.$store.dispatch("message/msgConnectSocketClient", this.socketClient);
      // if (self.$store.state.auth.csrfToken === "") {

      const headers = {};
      // setTimeout(() => {
      headers[self.csrfToken.headerName] = self.csrfToken.token;
      headers["Authorization"] = `Bearer ${this.$keycloak.token}`;
      console.log(" headers", headers);

      console.log(" this.messageSocketClient", this.socketClient);
      this.socketClient.connect(
        headers,
        // {
        //   Authorization: `Bearer ${this.$keycloak.token}`,
        //   "X-CSRF-TOKEN": self.csrfToken.token,
        // },

        (frame) => {
          self.$store.dispatch(
            "message/msgConnectSocketClient",
            self.socketClient
          );
          console.log(frame);
          self.$store.dispatch("message/msgConnectSocketed", true);

          console.log(self.socketClient);
          // setTimeout(() => {
          self.connectMessageClient();
          self.connectCardService();
          self.notificationsServiceConnect();
          // }, 2000);
        },
        (error) => {
          console.log(error);
          self.$store.dispatch(
            "message/msgConnectSocketClient",
            self.socketClient
          );
          self.$store.dispatch("message/msgConnectSocketed", false);
        }
      );
      // }, 5000);
    },
    connectMessageClient() {
      if (!this.authenticated) {
        return;
      }
      if (!this.chatUser) {
        this.$store.dispatch("message/getChatUser");
      }
      if (!this.groupList) {
        this.$store.dispatch("message/getUserGroups");
        console.log("this.groupList websocketConnects", this.groupList);
      }
      // this.connectMessageSocket();
      setTimeout(() => {
        // if (!this.msgCltConnected) {
        //   return;
        // }
        this.connectMsgdService();
        // this.connectMsgdService();
        this.messageSocketClient.subscribe(
          "/user/queue/onlineStatus",
          (data) => {
            const myChatUser = JSON.parse(data.body);
            console.log(
              `${myChatUser.name} is ${myChatUser.online}` == true
                ? " online"
                : ` offline since ${myChatUser.timeOffline}`
            );
            this.$store.dispatch(
              "message/setCounterPartUSerOnlineStatus",
              myChatUser
            );
          }
        );
        this.messageSocketClient.subscribe("/user/queue/errors", (data) => {
          const msg = JSON.parse(data.body);
          console.log(msg);
        });
        console.log("Connect group list");

        this.messageSocketClient.subscribe("/topic/outgoing", (data) => {
          const msg = data.body;
          // f7.alert(msg);
          console.log(msg);
        });
      }, 1000);
    },
    connectMsgdService() {
      console.log("Subscribe to channel init");
      for (const myChatGroup of this.groupList) {
        if (myChatGroup.groupType !== "PERSONAL") {
          this.messageSocketClient.subscribe(
            `/topic/message.group.${myChatGroup.id}`,
            (data) => {
              const newMessage = JSON.parse(data.body);
              if (newMessage.receiverId === this.group.id) {
                if (newMessage.msgType === "text") {
                  this.$store.dispatch("message/setMsg", newMessage);
                } else {
                  this.$store.dispatch("message/getUserGroupMessages", {
                    requestId: this.group.id || newMessage.receiverId,
                    displayedMsg: 10,
                  });
                }
              } else if (newMessage.receiverId !== this.group.id) {
                // self.$store.state.message.messageList.push(newMessage);
                // f7.$(`.isTypingFalse${newMessage.receiverId}`).html(newMessage.senderId === self.profile.sub ? '' : newMessage.senderName.split(" ")[0] + ': ' + newMessage.text);
                const indexOfGroup = this.groupList.findIndex(
                  (x) => x.id === newMessage.receiverId
                );
                this.groupList[indexOfGroup].groupMessageList.unshift(
                  newMessage
                );
                this.$store.dispatch("message/setUnReadMsgCount", {
                  id: newMessage.groupId || newMessage.receiverId,
                  count: 1,
                });
                var date = new Date(newMessage.sentAt);
                var t = `${date.getHours()}:${
                  date.getMinutes() < 10 ? "0" : ""
                }${date.getMinutes()}`;
                f7.$(`.newMessageTime${newMessage.receiverId}`).html(t);
              }
            }
          );

          this.messageSocketClient.subscribe(
            `/topic/message.typing.${myChatGroup.id}`,
            (data) => {
              const typingData = JSON.parse(data.body);
              console.log(typingData);
              if (typingData.typing) {
                if (
                  typingData.receiverId === this.group.id &&
                  typingData.senderId !== this.chatUser.userId
                ) {
                  this.$store.dispatch("message/setTypingMessage", typingData);
                  console.log("typingMessage", this.typingMessage);
                } else if (
                  typingData.receiverId !== this.group.id &&
                  typingData.senderId !== this.chatUser.userId
                ) {
                  f7.$(`.isTypingFalse${typingData.groupId}`).css(
                    "display",
                    "none"
                  );
                  f7.$(`.isTypingTrue${typingData.groupId}`).css(
                    "display",
                    "block"
                  );
                  console.log(
                    this.$t("tamtammessage.isTyping", {
                      isTyping: `${typingData.name}`,
                    })
                  );
                  f7.$(`.isTypingTrue${typingData.groupId}`).text(
                    this.$t("tamtammessage.isTyping", {
                      isTyping: `${typingData.name}`,
                    })
                  );
                  f7.$(`.isTypingTrue${typingData.groupId}`).css(
                    "color",
                    "var(--f7-theme-color)"
                  );
                }
                return;
              }
              this.$store.dispatch("message/setTypingMessage", "");
              f7.$(`.isTypingTrue${typingData.groupId}`).html("");
              f7.$(`.isTypingTrue${typingData.groupId}`).css("display", "none");
              f7.$(`.isTypingFalse${typingData.groupId}`).css(
                "display",
                "block"
              );
            }
          );

          if (myChatGroup.subGroupList && myChatGroup.subGroupList.length > 0) {
            for (const myChatSubGroup of myChatGroup.subGroupList) {
              this.messageSocketClient.subscribe(
                `/topic/message.group.${myChatSubGroup.id}`,
                (data) => {
                  const newMessage = JSON.parse(data.body);
                  if (newMessage.receiverId === this.group.id) {
                    // f7.messages.addMessage(newMessage, 'append', true);
                    // self.$store.state.message.messageList.push(newMessage);
                    if (newMessage.msgType === "text") {
                      this.$store.dispatch("message/setMsg", newMessage);
                    } else {
                      this.$store.dispatch("message/getUserGroupMessages", {
                        requestId: this.group.id || newMessage.receiverId,
                        displayedMsg: 10,
                      });
                    }
                  } else if (newMessage.receiverId !== this.group.id) {
                    // self.$store.state.message.messageList.push(newMessage);
                    // f7.$(`.isTypingFalse${newMessage.receiverId}`).html(newMessage.senderId === self.profile.sub ? '' : newMessage.senderName.split(" ")[0] + ': ' + newMessage.text);
                    const currentGroup = this.groupList.find(
                      (currentGp) => currentGp.id === newMessage.groupId
                    );
                    const currentGroupIndex = this.groupList.findIndex(
                      (currentGp) => currentGp.id === newMessage.groupId
                    );

                    const currentSubGrpIndex = currentGroup.findIndex(
                      (x) => x.id === newMessage.receiverId
                    );

                    this.groupList[currentGroupIndex].subGroupList[
                      currentSubGrpIndex
                    ].subGroupMessageList.unshift(newMessage);
                    this.$store.dispatch("message/setUnReadMsgCount", {
                      id: newMessage.receiverId || newMessage.groupId,
                      count: 1,
                    });
                    var date = new Date(newMessage.sentAt);
                    var t = `${date.getHours()}:${
                      date.getMinutes() < 10 ? "0" : ""
                    }${date.getMinutes()}`;
                    f7.$(`.newMessageTime${newMessage.receiverId}`).html(t);
                  }
                }
              );

              this.messageSocketClient.subscribe(
                `/topic/message.typing.${myChatSubGroup.id}`,
                (data) => {
                  const typingData = JSON.parse(data.body);
                  console.log(typingData);
                  if (typingData.typing) {
                    console.log("user is typing in group", typingData);
                    if (
                      typingData.receiverId === this.group.id &&
                      typingData.senderId !== this.profile.sub
                    ) {
                      this.$store.dispatch(
                        "message/setTypingMessage",
                        typingData
                      );
                      console.log("typingMessage", this.typingMessage);
                    } else if (
                      typingData.senderId !== this.profile.sub &&
                      typingData.receiverId !== this.group.id
                    ) {
                      f7.$(`.isTypingFalse${typingData.groupId}`).css(
                        "display",
                        "none"
                      );
                      f7.$(`.isTypingTrue${typingData.groupId}`).css(
                        "display",
                        "block"
                      );
                      console.log(
                        this.$t("tamtammessage.isTyping", {
                          isTyping: `${typingData.name}`,
                        })
                      );
                      f7.$(`.isTypingTrue${typingData.groupId}`).text(
                        this.$t("tamtammessage.isTyping", {
                          isTyping: `${typingData.name}`,
                        })
                      );
                      f7.$(`.isTypingTrue${typingData.groupId}`).css(
                        "color",
                        "var(--f7-theme-color)"
                      );
                    }
                  } else {
                    this.$store.dispatch("message/setTypingMessage", "");
                    f7.$(`.isTypingTrue${typingData.groupId}`).html("");
                    f7.$(`.isTypingTrue${typingData.groupId}`).css(
                      "display",
                      "none"
                    );
                    f7.$(`.isTypingFalse${typingData.groupId}`).css(
                      "display",
                      "block"
                    );
                  }
                }
              );
            }
          }
        }
      }
      this.messageSocketClient.subscribe("/user/queue/message.in", (data) => {
        console.log(data);
        const newMessage = JSON.parse(data.body);
        if (newMessage.msgOrigin === "invitation") {
          this.$store.dispatch("message/setInfoMsg", newMessage);
          console.log(this.infoMessage);
        } else if (
          newMessage.msgOrigin !== "invitation" &&
          this.group.id === newMessage.groupId
        ) {
          console.log(this.messageList);
          if (newMessage.msgType === "text") {
            this.$store.dispatch("message/setMsg", newMessage);
          } else {
            this.$store.dispatch("message/getUserGroupMessages", {
              requestId: this.group.id || newMessage.groupId,
              displayedMsg: 10,
            });
          }
        } else if (
          newMessage.msgOrigin !== "invitation" &&
          this.group.id !== newMessage.groupId
        ) {
          // f7.$(`.isTypingFalse${newMessage.receiverId}`).html(newMessage.senderId === self.profile.sub ? '' : newMessage.senderName.split(" ")[0] + ': ' + newMessage.text);
          const indexOfGroup = this.groupList.findIndex(
            (x) => x.id === newMessage.groupId
          );
          this.groupList[indexOfGroup].groupMessageList.unshift(newMessage);
          this.$store.dispatch("message/setUnReadMsgCount", {
            id: newMessage.groupId,
            count: 1,
          });
          const date = new Date(newMessage.sentAt);
          const t = `${date.getHours()}:${
            date.getMinutes() < 10 ? "0" : ""
          }${date.getMinutes()}`;
          f7.$(`.newMessageTime${newMessage.receiverId}`).html(t);
        }

        /*  else {
                                                                                                                                                                                                                                             this.groupList.filter();
                                                                                                                                                                                                                                         } */
        // var notificationCardIsScanned = f7.notification.create({
        //     titleRightText: new Date().toLocaleString(),
        //     subtitle: 'Card ' + cardData.cardUID + ' amount: ' + '<b>' + cardData.currencySymbol + '' + cardData.amount + ' ' + cardData.currencyCode + '</b>',
        //     text: 'Card status: ' + '<b>' + cardData.status + '</b>.' + ' Please get the top-up amount from customer before validating.',
        //     closeButton: true,
        // });
        // notificationCardIsScanned.open();
      });
      // if (
      //   self.group &&
      //   self.group !== null &&
      //   self.group.groupType !== "PERSONAL"
      // ) {
      //   this.stompClient.subscribe(
      //     `/topic/typing.${self.group.id}`,
      //     function (data) {
      //       const typingData = JSON.parse(data.body);
      //       console.log(typingData);
      //       if (typingData.typing == true) {
      //         if (
      //           typingData.receiverId === self.group.id &&
      //           typingData.senderId !== self.chatUser.userId
      //         ) {
      //           self.typingMessage = typingData;
      //         } else if (typingData.senderId !== self.chatUser.userId) {
      //           f7.$(`.isTypingFalse${typingData.groupId}`).css(
      //             "display",
      //             "none"
      //           );
      //           f7.$(`.isTypingTrue${typingData.groupId}`).css(
      //             "display",
      //             "block"
      //           );
      //           f7.$(`.isTypingTrue${typingData.groupId}`).html(
      //             typingData.name + " is typing..."
      //           );
      //           f7.$(`.isTypingTrue${typingData.groupId}`).css(
      //             "color",
      //             "var(--f7-theme-color)"
      //           );
      //         }
      //       } else {
      //         self.typingMessage = "";
      //         f7.$(`.isTypingTrue${typingData.groupId}`).html("");
      //         f7.$(`.isTypingTrue${typingData.groupId}`).css("display", "none");
      //         f7.$(`.isTypingFalse${typingData.groupId}`).css(
      //           "display",
      //           "block"
      //         );
      //       }
      //     }
      //   );
      // }
      this.messageSocketClient.subscribe(
        "/user/queue/message.typing",
        (data) => {
          const typingData = JSON.parse(data.body);
          console.log(typingData);

          if (typingData.typing) {
            console.log("user is typing in direct mesage", typingData);
            if (
              typingData.senderId === this.counterPartUser.userId &&
              typingData.groupId === this.group.id
            ) {
              this.$store.dispatch("message/setTypingMessage", typingData);
              console.log("typingMessage", this.typingMessage);
            } else {
              f7.$(`.isTypingFalse${typingData.groupId}`).css(
                "display",
                "none"
              );
              f7.$(`.isTypingTrue${typingData.groupId}`).css(
                "display",
                "block"
              );
              console.log(
                this.$t("tamtammessage.isTyping", {
                  isTyping: `${typingData.name}`,
                })
              );
              f7.$(`.isTypingTrue${typingData.groupId}`).text(
                this.$t("tamtammessage.isTyping", {
                  isTyping: `${typingData.name}`,
                })
              );
              f7.$(`.isTypingTrue${typingData.groupId}`).css(
                "color",
                "var(--f7-theme-color)"
              );
            }
            return;
          }
          this.$store.dispatch("message/setTypingMessage", "");
          f7.$(`.isTypingTrue${typingData.groupId}`).html("");
          f7.$(`.isTypingTrue${typingData.groupId}`).css("display", "none");
          f7.$(`.isTypingFalse${typingData.groupId}`).css("display", "block");
        }
      );

      this.messageSocketClient.subscribe("/user/queue/onlineStatus", (data) => {
        const myChatUser = JSON.parse(data.body);
        console.log(
          `${myChatUser.name} is ${myChatUser.online}` == true
            ? " online"
            : ` offline since ${myChatUser.timeOffline}`
        );
        this.$store.dispatch(
          "message/setCounterPartUSerOnlineStatus",
          myChatUser
        );
      });
      // self.messageSocketClient.subscribe("/user/queue/errors", (data) => {
      //   const msg = JSON.parse(data.body);
      //   console.log(msg);
      // });
      /*       },
                                                                                                                                                                                    error => {
                                                                                                                                                                                        console.log(error);
                                                                                                                                                                                        this.messageConnected = false;
                                                                                                                                                                                    }
                                                                                                                                                                                ); */
    },
    sendChatMessage(messageTosend) {
      console.log(messageTosend);
      if (this.attachments && this.attachments.length > 0) {
        console.log(this.attachments);
        for (const file of this.attachments) {
          /*    const reader = new FileReader();
                                                                                                                                                                                                                                                                                           reader.onerror = console.error;
                                                                                                                                                                                                                                                                                           reader.onloadend = () => {
                                                                                                                                                                                                                                                                                               message.attachment = reader.result.split(",")[1];
                                                                                                                                                                                                                                                                                           };
                                                                                                                                                                                                                                                                                           reader.readAsDataURL(file.blob); */
          messageTosend.msgType = file.msgtype;
          messageTosend.fileName = file.filename;
          messageTosend.mimetype = file.mimetype;

          /*     setTimeout(() => {
                                                                                                                                                                                                                                                                                                console.log(message);
                                                                                                                                                                                                                                                                                                if (message.groupType !== "PERSONAL") {
                                                                                                                                                                                                                                                                                                    self.messageSocketClient.send(
                                                                                                                                                                                                                                                                                                        `/message/group/${message.receiverId}`,
                                                                                                                                                                                                                                                                                                        JSON.stringify(message)
                                                                                                                                                                                                                                                                                                    );
                                                                                                                                                                                                                                                                                                } else if (!self.group ||
                                                                                                                                                                                                                                                                                                    self.group.groupType === "PERSONAL" ||
                                                                                                                                                                                                                                                                                                    message.groupType === "PERSONAL"
                                                                                                                                                                                                                                                                                                ) {
                                                                                                                                                                                                                                                                                                    self.messageSocketClient.send(
                                                                                                                                                                                                                                                                                                        "/message/single/" + message.chatGroup.id,
                                                                                                                                                                                                                                                                                                        JSON.stringify(message)
                                                                                                                                                                                                                                                                                                    );
                                                                                                                                                                                                                                                                                                }
                                                                                                                                                                                                                                                                                            }, 1000); */

          const forms = new FormData();
          forms.append("file", file.blob, file.filename);
          forms.append("newChatMessage", JSON.stringify(messageTosend));
          if (messageTosend.groupType !== "PERSONAL") {
            forms.append("groupId", messageTosend.receiverId);
          } else if (
            this.group ||
            this.group.groupType === "PERSONAL" ||
            messageTosend.groupType === "PERSONAL"
          ) {
            forms.append("groupId", messageTosend.chatGroup.id);
          }
          this.$store.dispatch("message.sendFileMessage", { forms });
          messageTosend.text = "";
          this.deleteAttachment(file);
        }
        return;
      }
      messageTosend.msgType = "text";
      messageTosend.attachment = "";
      messageTosend.fileName = "";
      messageTosend.mimetype = "";
      // f7.utils.extend(messagesToSend, messageTosendB);

      console.log(messageTosend);
      if (messageTosend.groupType !== "PERSONAL") {
        this.messageSocketClient.send(
          `/app/message.group.${messageTosend.receiverId}`,
          JSON.stringify(messageTosend)
        );
      } else if (
        !this.group ||
        this.group.groupType === "PERSONAL" ||
        messageTosend.groupType === "PERSONAL"
      ) {
        this.messageSocketClient.send(
          `/app/message.single.${messageTosend.chatGroup.id}`,
          JSON.stringify(messageTosend)
        );
      }
    },

    connectCardService() {
      const self = this;
      window.localStorage.setItem("scannedCard", "");
      this.messageSocketClient.subscribe("/topic/card", (data) => {
        setTimeout(() => {
          if (data.body.id !== null) {
            f7.$(".buttonIssue").hide();
          }
          f7.dialog.alert(data.body);
        }, 2000);
      });

      this.messageSocketClient.subscribe(
        "/user/queue/card.isCreated",
        (data) => {
          if (data.body.id === null) {
            return;
          }
          console.log(data.body);
          const cardData = JSON.parse(data.body);
          const notificationCardIsScanned = f7.notification.create({
            titleRightText: new Date().toLocaleString(),
            subtitle: `Card ${cardData.cardUID} amount: <b>${cardData.currencySymbol}${cardData.amount} ${cardData.currencyCode}</b>`,
            text: `Card status: <b>${cardData.status}</b>. Please get the top-up amount from customer before validating.`,
            closeButton: true,
          });
          notificationCardIsScanned.open();
        }
      );

      this.messageSocketClient.subscribe(
        "/user/queue/card.notCreated",
        (data) => {
          if (data.body.id === null) {
            return;
          }
          console.log(data.body);
          const cardData = JSON.parse(data.body);
          const notificationCardIsScanned = f7.notification.create({
            titleRightText: new Date().toLocaleString(),
            text: `<b>${cardData.status}</b>`,
            closeButton: true,
          });
          notificationCardIsScanned.open();
        }
      );

      this.messageSocketClient.subscribe(
        "/user/queue/card.isScanned",
        (data) => {
          if (data.body.cardId === null) {
            return;
          }
          const cardData = JSON.parse(data.body);
          store.dispatch("card/setScannedCard", JSON.parse(data.body));
          if (self.profile.sub === cardData.cardIssuerId) {
            const notificationCardIsScanned2 = f7.notification.create({
              titleRightText: new Date().toLocaleString(),
              subtitle: `Card ${cardData.cardUID} amount: <b>${cardData.currencySymbol}${cardData.amount} ${cardData.currencyCode}</b>`,
              text: `Card status: <b>${cardData.status}</b>. Please get the top-up amount from customer before validating.<br/>Click to validate top-up or cancel it!`,
              closeOnClick: true,
              on: {
                close() {
                  f7.dialog
                    .create({
                      text: "Validate to confirm top-up!",
                      buttons: [
                        {
                          text: "Validate top-up",
                          color: "auto",
                          bold: true,
                          onClick() {
                            self.validateTopUp();
                          },
                        },
                        {
                          text: "Cancel top-up",
                          color: "red",
                          onClick() {
                            self.deleteQRCode();
                          },
                        },
                        {
                          text: "Close",
                          close: true,
                        },
                      ],
                      verticalButtons: true,
                    })
                    .open();
                },
              },
            });
            notificationCardIsScanned2.open();
          } else if (self.profile.sub === cardData.recipientUserId) {
            const notificationCardIsScanned = f7.notification.create({
              titleRightText: new Date().toLocaleString(),
              subtitle: `Card ${cardData.cardUID} amount: <b>${cardData.currencySymbol}${cardData.amount} ${cardData.currencyCode}</b>`,
              text: `Card status: <b>${cardData.status}</b>. Please wait validation for your account to be topped-up!`,
              closeButton: true,
            });
            notificationCardIsScanned.open();
          }
        }
      );
      this.messageSocketClient.subscribe(
        "/user/queue/card.isLocked",
        (data) => {
          if (data.body.id === null) {
            return;
          }
          console.log(data.body);
          const cardData = JSON.parse(data.body);
          const notificationCardIsScanned = f7.notification.create({
            titleRightText: new Date().toLocaleString(),
            text: `<b>${cardData.status}</b>`,
            closeButton: true,
          });
          notificationCardIsScanned.open();
        }
      );

      this.messageSocketClient.subscribe(
        "/user/queue/card.topUpValidated",
        (data) => {
          if (data.body.cardId === null) {
            return;
          }
          const cardData = JSON.parse(data.body);
          store.dispatch("card/setScannedCard", JSON.parse(data.body));
          if (self.profile.sub === cardData.userId) {
            const notificationCardIsScanned1 = f7.notification.create({
              titleRightText: new Date().toLocaleString(),
              subtitle: `Card ${cardData.cardUID} amount: <b>${cardData.currencySymbol}${cardData.amount} ${cardData.currencyCode}</b>`,
              text: `Card status: <b>${cardData.status}</b>`,
              closeButton: true,
            });
            notificationCardIsScanned1.open();
          } else if (self.profile.sub === cardData.recipientId) {
            const notificationCardIsScanned = f7.notification.create({
              titleRightText: new Date().toLocaleString(),
              subtitle: `Card ${cardData.cardUID} amount: <b>${cardData.currencySymbol}${cardData.amount} ${cardData.currencyCode}</b>`,
              text: `Card status: <b>${cardData.status}</b><br/> Go to my account!`,
              closeOnClick: true,
              on: {
                close() {
                  // this.f7router.navigate("/dashboard/");
                  self.goToPrimaryAccount();
                },
              },
            });
            notificationCardIsScanned.open();
          }
        }
      );

      this.messageSocketClient.subscribe(
        "/user/queue/card.notActive",
        (data) => {
          if (data.body.id === null) {
            return;
          }
          console.log(data.body);
          const cardData = JSON.parse(data.body);
          const notificationCardIsScanned = f7.notification.create({
            titleRightText: new Date().toLocaleString(),
            text: `<b>${cardData.status}</b>`,
            closeButton: true,
          });
          notificationCardIsScanned.open();
        }
      );
    },
    async goToPrimaryAccount() {
      f7.preloader.show("multi");
      await this.$store.dispatch("account/loadPrimaryAccount");
      f7.preloader.hide();
      await this.f7router.navigate(
        "/my-transactions/" +
          this.primaryAccount.accountNumber +
          "/primaryTransactionList"
      );
    },

    connectPayService() {
      const self = this;
      this.messageSocketClient.subscribe("/topic/payment", (data) => {
        setTimeout(() => {
          if (data.body !== null) {
            f7.$(".buttonIssue").hide();
          }
          f7.dialog.alert(data.body);
        }, 2000);
      });

      this.messageSocketClient.subscribe(
        "/user/queue/payment.validate",
        (data) => {
          if (data.body === null) {
            return;
          }
          const payData = JSON.parse(data.body);
          console.log(data.body);
          self.$store.dispatch("payment/setDecodedQRCode", payData);
          f7.dialog
            .create({
              text: `Pay <b>${payData.amount} ${payData.paymentCurrency}</b> to <b>${payData.payee.name}</b>`,
              buttons: [
                {
                  text: "Pay",
                  color: "auto",
                  bold: true,
                  onClick() {
                    self.payNow();
                  },
                },
                {
                  text: "Cancel",
                  color: "red",
                  onClick() {
                    self.deleteQRCode();
                  },
                },
                {
                  text: "Close",
                  color: "gray",
                  close: true,
                },
              ],
              verticalButtons: true,
            })
            .open();
        }
      );

      this.messageSocketClient.subscribe(
        "/user/queue/payment.inform",
        (data) => {
          if (data.body === null) {
            return;
          }
          console.log(data.body);
          const payData = JSON.parse(data.body);
          self.$store.dispatch("payment/setDecodedQRCode", payData);
          const notificationpayIsScanned = f7.notification.create({
            titleRightText: new Date().toLocaleString(),
            text: `<b>${payData.status}</b>`,
            closeButton: true,
          });
          notificationpayIsScanned.open();
        }
      );
      this.messageSocketClient.subscribe(
        "/user/queue/payment.code.expired",
        (data) => {
          if (data.body === null) {
            return;
          }
          console.log(data.body);
          const paymentCodeNotValid = f7.toast.create({
            text: data.body,
            cssClass: "checkCardToast",
            position: "center",
            closeButton: true,
          });

          paymentCodeNotValid.open();
        }
      );

      this.messageSocketClient.subscribe("/user/queue/payIsScanned", (data) => {
        if (data.body.payId === null) {
          return;
        }
        const payData = JSON.parse(data.body);
        self.$store.dispatch("pay/setScannedpay", JSON.parse(data.body));
        if (self.profile.sub === payData.payIssuerId) {
          const notificationpayIsScanned = f7.notification.create({
            titleRightText: new Date().toLocaleString(),
            subtitle: `pay ${payData.payUID} amount: <b>${payData.currencySymbol}${payData.amount} ${payData.currencyCode}</b>`,
            text: `pay status: <b>${payData.status}</b>. Please get the top-up amount from customer before validating.<br/>Click to validate top-up or cancel it!`,
            closeOnClick: true,
            on: {
              close() {
                f7.dialog
                  .create({
                    text: "Validate to confirm top-up!",
                    buttons: [
                      {
                        text: "Validate top-up",
                        color: "auto",
                        bold: true,
                        onClick() {
                          self.validateTopUp();
                        },
                      },
                      {
                        text: "Cancel top-up",
                        color: "red",
                        onClick() {
                          self.deleteQRCode();
                        },
                      },
                      {
                        text: "Close",
                        close: true,
                      },
                    ],
                    verticalButtons: true,
                  })
                  .open();
              },
            },
          });
          notificationpayIsScanned.open();
        } else if (self.profile.sub === payData.recipientUserId) {
          const notificationpayIsScanned0 = f7.notification.create({
            titleRightText: new Date().toLocaleString(),
            subtitle: `pay ${payData.payUID} amount: <b>${payData.currencySymbol}${payData.amount} ${payData.currencyCode}</b>`,
            text: `pay status: <b>${payData.status}</b>. Please wait validation for your account to be topped-up!`,
            closeButton: true,
          });
          notificationpayIsScanned0.open();
        }
      });
      this.messageSocketClient.subscribe("/user/queue/payIsLocked", (data) => {
        if (data.body.id !== null) {
          console.log(data.body);
          const payData = JSON.parse(data.body);
          const notificationpayIsScanned = f7.notification.create({
            titleRightText: new Date().toLocaleString(),
            text: `<b>${payData.status}</b>`,
            closeButton: true,
          });
          notificationpayIsScanned.open();
        }
      });

      this.messageSocketClient.subscribe(
        "/user/queue/topUpValidated",
        (data) => {
          if (data.body.payId === null) {
            return;
          }
          const payData = JSON.parse(data.body);
          self.$store.dispatch("pay/setScannedpay", JSON.parse(data.body));
          if (self.profile.sub === payData.userId) {
            const notificationpayIsScanned = f7.notification.create({
              titleRightText: new Date().toLocaleString(),
              subtitle: `pay ${payData.payUID} amount: <b>${payData.currencySymbol}${payData.amount} ${payData.currencyCode}</b>`,
              text: `pay status: <b>${payData.status}</b>`,
              closeButton: true,
            });
            notificationpayIsScanned.open();
          } else if (self.profile.sub === payData.recipientId) {
            const notificationpayIsScanned1 = f7.notification.create({
              titleRightText: new Date().toLocaleString(),
              subtitle: `pay ${payData.payUID} amount: <b>${payData.currencySymbol}${payData.amount} ${payData.currencyCode}</b>`,
              text: `pay status: <b>${payData.status}</b><br/> Go to my account!`,
              closeOnClick: true,
              on: {
                close() {
                  // this.f7router.navigate("/dashboard/");
                  self.goToPrimaryAccount();
                },
              },
            });
            notificationpayIsScanned1.open();
          }
        }
      );

      this.messageSocketClient.subscribe("/user/queue/payNotActive", (data) => {
        if (data.body.id === null) {
          return;
        }
        console.log(data.body);
        const payData = JSON.parse(data.body);
        const notificationpayIsScanned = f7.notification.create({
          titleRightText: new Date().toLocaleString(),
          text: `<b>${payData.status}</b>`,
          closeButton: true,
        });
        notificationpayIsScanned.open();
      });

      this.messageSocketClient.subscribe(
        "/user/queue/vendor.payment.inform",
        (data) => {
          if (data.body.id === null) {
            return;
          }
          console.log(data.body);
          // const payData = JSON.parse(data.body);
          const notificationpayIsScanned = f7.notification.create({
            titleRightText: new Date().toLocaleString(),
            text: `<b>${data.body}</b>`,
            closeButton: true,
          });
          notificationpayIsScanned.open();
        }
      );

      this.messageSocketClient.subscribe(
        "/user/queue/vendor.payment.updateQrCode",
        (data) => {
          if (data.body.id === null) {
            return;
          }
          console.log(data.body);
          const payData = JSON.parse(data.body);
          const notificationpayIsScanned = f7.notification.create({
            titleRightText: new Date().toLocaleString(),
            text: `<b>${payData.amount}</b>`,
            closeButton: true,
          });
          notificationpayIsScanned.open();
        }
      );
    },
    notificationsServiceConnect() {
      this.messageSocketClient.subscribe("/user/queue/notification", (data) => {
        if (data.body.id === null) {
          return;
        }
        console.log(data.body);
        self.$store.dispatch(
          "message/updateNotificationList",
          JSON.parse(data.body)
        );
      });
    },

    postServiceConnect() {
      const handlePostData = (data) => {
        if (data.body.id === null) {
          return;
        }
        console.log(data.body);
        self.$store.dispatch("post/updatePostList", JSON.parse(data.body));
      };

      this.messageSocketClient.subscribe(
        "/user/queue/post/new",
        handlePostData
      );
      this.messageSocketClient.subscribe("/post/new", handlePostData);
    },
    commentServiceConnect() {
      const handlePostData = (data) => {
        if (data.body.id === null) {
          return;
        }
        console.log(data.body);
        self.$store.dispatch("post/commentList", JSON.parse(data.body));
      };

      this.messageSocketClient.subscribe(
        "/user/queue/post/comment/new",
        handlePostData
      );
      this.messageSocketClient.subscribe("/post/comment/new", handlePostData);
    },
  },
  computed: {
    ...mapState("auth", [
      "profile",
      "token",
      "user",
      "authenticated",
      "topath",
      "dynamicLink",
      "csrfToken",
    ]),
    ...mapState("message", [
      "chatUser",
      "message",
      "chatUserList",
      "messageList",
      "group",
      "groupList",
      "subGroup",
      "subGroupList",
      "blockedUser",
      "blockedUserList",
      "blockedUserMsg",
      "groupAdmin",
      "groupAdminList",
      "counterPartUser",
      "counterPartUserList",
      "groupParticipantList",
      "infoMessage",
      "messagesData",
      "groupUnReadMsg",
      "allGroupsUnReadMsg",
      "messageSocketClient",
      "msgCltConnected",
      "typingMessage",
    ]),
  },
};
