<template>
  <f7-page :page-content="false" @page:init="pageInit()" hide-bars-on-scroll>
    <f7-navbar :sliding="false" no-shadow :back-link="$t('common.backButton')" :back-link-show-text="false">
      <f7-nav-title><span v-html="this.transactionTitle"
          style="white-space: nowrap; text-overflow: ellipsis"></span></f7-nav-title>
    </f7-navbar>

    <!-- :navigation="isTablet == true ? true : false" -->
    <f7-tabs swipeable animated>
      <!-- :tab-active="index === transactionIndex" -->
      <div class="page-content tab" data-effect="flip" v-for="(transaction, index) in recipientTransactionListSorted"
        :id="`tab-${index}`" :key="index" @tab:show="selectedTransactionTabShow(index, transaction)"
        @tab:hide="selectedTransactionTabHide(index)">
        <f7-card :class="`card-${index}`" expandable>
          <f7-card-content :padding="false">
            <f7-link card-close color="auto" class="card-opened-fade-in"
              :style="{ position: 'absolute', right: '15px', top: '15px' }" icon-f7="multiply"></f7-link>
            <f7-card-header v-if="transaction.status === 'Paid' || transaction.status === 'Complete'"
              class="display-block bg-color-green" text-color="white" :style="{ height: '100px' }">
              {{ transaction.transactionType }}
              {{ recipient.name }}
            </f7-card-header>
            <f7-card-header v-else-if="transaction.status === 'Pending'" class="bg-color-orange display-block"
              text-color="white" :style="{ height: '100px' }">
              {{ transaction.type }}
              {{ recipient.name }}
            </f7-card-header>
            <f7-card-header v-else-if="transaction.status === 'Cancelled'" class="bg-color-red display-block"
              text-color="white" :style="{ height: '100px' }">
              {{ transaction.transactionType }}
              {{ recipient.name }}
              >
            </f7-card-header>
            <f7-card-header text-color="white" :style="{
              background: 'rgb(131, 58, 180)',
              background:
                'linear-gradient(90deg, rgba(131,58,180,1) 0%, rgba(29,253,36,1) 50%, rgba(252,176,69,1) 100%)',
            }">
              <small :style="{
                height: '200px',
                opacity: 0.7,
              }">{{ $t("recipientdetail.sent") }}
                <span v-html="$filtersToCurrency.toCurrency(
                  transaction.grossTransferAmount,
                  transaction.fromCurrencySymbol,
                  transaction.fromCurrencyCode
                )
                  "></span><br />{{ $t("recipientdetail.received") }}
                <span v-html="$filtersToCurrency.toCurrency(
                  transaction.receivedAmount,
                  transaction.toCurrencySymbol,
                  transaction.toCurrencyCode
                )
                  "></span><br />{{ $t("recipienttransactiondetail.transactionDate") }}
                {{ $filtersToDate.toDateTime(transaction.transactionDate) }}<br />{{
                  $t("recipienttransactiondetail.status")
                }}
                {{ transactionStatus(transaction) }}</small>
            </f7-card-header>
            <swiper-container :navigation="isTablet == true && !!transaction.qrCode ? true : false">
              <swiper-slide>
                <f7-list dividers-ios strong-ios medial-list>
                  <f7-list-item :title="$t('common.transferCode')" :after="transaction.transferCode"></f7-list-item>
                  <f7-list-item :title="$t('common.transferPin')" :after="transaction.transferPIN"></f7-list-item>
                  <f7-list-item :title="$t('recipienttransactiondetail.amountSent')" :after="$filtersToCurrency.toCurrency(
                    transaction.grossTransferAmount,
                    null,
                    transaction.fromCurrencyCode
                  )
                    "></f7-list-item>
                  <f7-list-item :title="$t('recipienttransactiondetail.topupAmount')" :after="$filtersToCurrency.toCurrency(
                    transaction.receivedAmount,
                    null,
                    transaction.toCurrencyCode
                  )
                    "></f7-list-item>
                  <f7-list-item v-if="transaction.transactionDate != null"
                    :title="$t('recipienttransactiondetail.transactionDate')"
                    :after="$filtersToDate.toDateTime(transaction.transactionDate)"></f7-list-item>
                  <!-- <f7-list-item v-else :title="$t('recipienttransactiondetail.date')" after></f7-list-item>
                      <f7-list-item
                        v-if="transaction.transactionDate != null"
                        :title="$t('recipienttransactiondetail.time')"
                        :after="transaction.transactionDate.substr(11, 8)"
                      ></f7-list-item>
                      <f7-list-item v-else :title="$t('recipienttransactiondetail.time')" after></f7-list-item> -->
                  <f7-list-item :title="$t('recipienttransactiondetail.type')" :after="transaction.type"></f7-list-item>
                  <f7-list-item>{{ $t("recipienttransactiondetail.status")
                    }}<f7-chip v-if="
                      transaction.status === 'Paid' || transaction.status === 'Complete'
                    " :text="transactionStatus(transaction)" color="green"></f7-chip>
                    <f7-chip v-else-if="transaction.status === 'Pending'" :text="transactionStatus(transaction)"
                      color="orange"></f7-chip>
                    <f7-chip v-else-if="transaction.status === 'Cancelled'" :text="transactionStatus(transaction)"
                      color="red"></f7-chip>
                  </f7-list-item>
                </f7-list>
              </swiper-slide>
              <swiper-slide v-if="!!transaction.qrCode">
                <f7-block v-if="!!transaction.qrCode" class="margin">
                  <img v-if="!!transaction.qrCode" :src="`data:image/png;base64,${transaction.qrCode}`"
                    style="display: block; margin: auto" />
                </f7-block>
              </swiper-slide>
            </swiper-container>
          </f7-card-content>
        </f7-card>

        <f7-fab position="center-bottom" slot="fixed" :text="$t('recipientdetail.sendAgain')" color="red" popup-close
          @click="sendAgain(transaction, transaction.mainRecipient)"
          v-if="transaction.transactionType === 'avo¢ash pick-up'">
          <f7-icon aurora="f7:paperplane" ios="f7:paperplane" md="material:send"></f7-icon>
        </f7-fab>
        <f7-fab position="center-bottom" slot="fixed" :text="$t('recipientdetail.topupAgain')" color="red" popup-close
          @click="topupAgain(transaction, transaction.mainRecipient)"
          v-if="transaction.transactionType === 'avo¢ash top-up'">
          <f7-icon aurora="f7:paperplane" ios="f7:paperplane" md="material:send"></f7-icon>
        </f7-fab>
        <f7-fab position="right-bottom" class="shareButton" slot="fixed" color="auto"
          @click="socialShare(sharedContent)">
          <f7-icon aurora="f7:square_arrow_up" ios="f7:square_arrow_up" md="material:share"></f7-icon>
        </f7-fab>

        <span :class="'msg' + transaction.id" style="display: none">{{ transaction.description }}
          <br />
          {{ $t("recipienttransactiondetail.transactionDate")
          }}{{ $filtersToDate.toDateTime(transaction.transactionDate) }} <br />{{
            $t("recipienttransactiondetail.amount")
          }}
          <b>
            <span v-html="$filtersToCurrency.toCurrency(
              transaction.receivedAmount,
              null,
              transaction.toCurrencyCode
            )
              "></span>
          </b>
          <br />
          {{ recipient.name }}
          <br />{{ $t("common.transferCode") }}
          <b>{{ transaction.transferCode }}</b></span>
        <span :class="'subject' + transaction.id" style="display: none">
          {{ transaction.transactionType }} <br />
          {{ $t("recipienttransactiondetail.topupAmount") }}
          <b>
            <span v-html="$filtersToCurrency.toCurrency(
              transaction.receivedAmount,
              null,
              transaction.toCurrencyCode
            )
              "></span>
          </b>
          {{ $t("recipienttransactiondetail.status") }}
          {{ transactionStatus(transaction) }}</span>
      </div>
    </f7-tabs>
  </f7-page>
</template>
<script>
import {
  f7Page,
  f7Navbar,
  f7NavTitle,
  f7Tabs,
  f7Tab,
  f7Card,
  f7CardContent,
  f7Link,
  f7CardHeader,
  f7List,
  f7ListItem,
  f7Chip,
  f7Block,
  f7Fab,
  f7Icon,
  theme,
  f7,
} from "framework7-vue";
import { mapGetters, mapState } from "vuex";
import Navbarright from "../components/navbarright.vue";
import { socialShare } from "../js/mixins/cordovaPlugins.js";
// import { transferAgain } from "../js/mixins/transaction.js";

import _ from "lodash";
export default {
  props: {
    f7route: Object,
    f7router: Object,
  },
  components: {
    navbarright: Navbarright,
    f7Page,
    f7Navbar,
    f7NavTitle,
    f7Tabs,
    f7Tab,

    f7Card,
    f7CardContent,
    f7Link,
    f7CardHeader,

    f7List,
    f7ListItem,
    f7Chip,
    f7Block,
    f7Fab,
    f7Icon,
  },
  mixins: [socialShare],
  data() {
    return { theme, f7, sharedContent: "", transactionTitle: "" };
  },
  mounted() {
    const self = this;
    const currentSwiper = f7.swiper.get(".tabs-animated-wrap.swiper");
    console.log("currentSwiper", currentSwiper);
    if (currentSwiper) {
      currentSwiper.mousewheel.enable();
      currentSwiper.keyboard.enable();
    }
  },
  methods: {
    pageInit() {
      const self = this;

      f7.tab.show(`#tab-${this.transactionIndex}`);
      this.share(this.currentTransaction);
      const currentSwiper = f7.swiper.get(".tabs-animated-wrap.swiper");
      console.log("currentSwiper", currentSwiper);
      if (currentSwiper) {
        currentSwiper.mousewheel.enable();
        currentSwiper.keyboard.enable();
      }
    },
    selectedTransactionTabShow(index, transaction) {
      const self = this;

      this.transactionTitle = transaction.description;
      this.share(transaction);
      setTimeout(() => {
        f7.card.close(`.card-${index - 1}`);
        f7.card.close();
      }, 500);
    },
    selectedTransactionTabHide(index) {
      const self = this;

      setTimeout(() => {
        f7.card.close(`.card-${index}`);
        f7.card.close();
      }, 500);
    },
    async share(myTransaction) {
      const self = this;

      const files = [];
      const base64Files = [];
      if (myTransaction && myTransaction.qrCode && myTransaction.qrCode !== null) {
        const blob = await (
          await fetch(`data:image/png;base64,${myTransaction.qrCode}`)
        ).blob();
        const termination = blob.type.split("/")[1];
        const file = new File([blob], myTransaction.date + "." + termination, {
          type: blob.type,
        });
        files.push(file);
        base64Files.push(`data:image/png;base64,${myTransaction.qrCode}`);
      }

      this.sharedContent = {
        message: f7.$(`.msg${myTransaction.id}`).text(),
        subject: f7.$(`.subject${myTransaction.id}`).text(),
        files,
        base64Files,
        url: `https://www.avocash.com/#/recipient/transaction/detail/${myTransaction.id}`,
      };
      console.log(this.sharedContent);
    },
  },

  computed: {
    ...mapState("transaction", [
      "primaryTransactionList",
      "businessTransactionList",
      "destinationCountry",

      "extraTransferDetail",
    ]),
    ...mapState("account", ["primaryAccount", "businessAccount", "userDetail"]),
    ...mapState("remit", [
      "recipients",
      "allrecipients",
      "recipient",
      "recipientTransactionList",
    ]),
    ...mapState("auth", ["profile", "isTablet", "isDeskTop"]),

    ...mapGetters("auth", [
      "getProfile",
      "isAuthenticated",
      "isProfileLoaded",
      "getusererror",
      "allCountriesByCountryId",
      "allCountriesByCountryName",
    ]),
    ...mapGetters("transaction", [
      "getusererror",
      "fromCurrencyRate",
      "toCurrencyRate",
      "grossexchangerate2",
    ]),

    ...mapGetters("remit", ["recipients", "recipientsList"]),
    recipientTransactionListSorted() {
      return _.orderBy(
        this.recipientTransactionList.slice(),
        ["transactionDate"],
        ["desc"]
      );
    },
    currentTransaction() {
      const self = this;

      const id = this.f7route.params.id;
      for (const recipientTransactionItem of this.recipientTransactionList) {
        if (recipientTransactionItem.id == id) {
          return recipientTransactionItem;
        }
      }
    },
    transactionIndex() {
      return _.findIndex(
        this.recipientTransactionListSorted,
        (transaction) => transaction.id == this.f7route.params.id
      );
    },
  },
};
</script>
