// import base_url from "../base_url.json";
import axios from "axios";

const path = import.meta.env.VITE_APP_URL + "account/location";
import _ from "lodash";
//  const location ={
export default (app) => {
  const country =
    app.config.globalProperties.$lcStorage.get("L5ET4juMXyWmUgzY9");
  const currencyList =
    app.config.globalProperties.$lcStorage.get("f7XquQcXg9d7ME");
  const emoneyApiList =
    app.config.globalProperties.$lcStorage.get("Jbqblql82uFocCb2h");
  const apiCode =
    app.config.globalProperties.$lcStorage.get("yln0qkUJkct32OVdt4Yk") !==
    undefined
      ? app.config.globalProperties.$lcStorage.get("yln0qkUJkct32OVdt4Yk")
      : {};
  return {
    namespaced: true,
    state: {
      country: country ? JSON.parse(country) : [],
      cityList: "",
      stateList: "",
      countryStates: "",
      countryList: "",
      destinationCity: "",
      userAddress: "",
      currentUserCountry: "",
      latitude: "",
      longitude: "",
      ipAddress: "",
      currencyList: currencyList ? JSON.parse(currencyList) : [],
      emoneyApiList: emoneyApiList ? JSON.parse(emoneyApiList) : [],
      apiCode: apiCode ? apiCode : {},
      phoneNumberDetail: "",
      mobilePhoneError: "",
    },
    getters: {
      allCountriesByCountryId: (state) => _.groupBy(state.country, "countryId"),
      allCountriesByCountryName: (state) =>
        _.groupBy(state.country, "countryName"),
      allCountriesByCountryCode2: (state) =>
        _.groupBy(state.country, "countryCode2"),
    },
    mutations: {
      GET_COUNTRY(state, country) {
        state.country = country;
        app.config.globalProperties.$lcStorage.set(
          "L5ET4juMXyWmUgzY9",
          JSON.stringify(state.country)
        );
      },
      GET_CURRENCY_LIST(state, currencyList) {
        state.currencyList = currencyList;
        app.config.globalProperties.$lcStorage.set(
          "f7XquQcXg9d7ME",
          JSON.stringify(state.currencyList)
        );
      },
      GET_EMONEY_API_LIST(state, emoneyApiList) {
        state.emoneyApiList = emoneyApiList;
        app.config.globalProperties.$lcStorage.set(
          "Jbqblql82uFocCb2h",
          JSON.stringify(state.emoneyApiList)
        );
      },
      SET_CURRENTUSER_EMONEY_API_CODE(state, currentUserCountry) {
        const api_code = _.find(
          state.emoneyApiList,
          (api) => api.id === currentUserCountry.countryCode3
        );
        console.log(" api_code ", api_code);
        state.apiCode = api_code;
        if (api_code !== undefined) {
          app.config.globalProperties.$lcStorage.set(
            "yln0qkUJkct32OVdt4Yk",
            JSON.stringify(api_code)
          );
        }
      },
      SET_DESTINATIONCITY(state, destinationCity) {
        state.destinationCity = destinationCity;
      },
      GET_COUNTRYSTATES(state, countryStates) {
        state.countryStates = countryStates;
      },
      GET_CITYLIST(state, cityList) {
        state.cityList = cityList;
      },
      GET_STATELIST(state, stateList) {
        state.stateList = stateList;
      },
      GET_COUNTRYLIST(state, countryList) {
        state.countryList = countryList;
      },
      GET_CURRENTUSERADDRESS(state, userAddress) {
        state.userAddress = userAddress;
      },
      GET_CURRENTUSERCOUNTRY(state, currentUserCountry) {
        state.currentUserCountry = currentUserCountry;
      },
      GET_CURRENTUSERIPADDRESS(state, ipAddress) {
        state.ipAddress = ipAddress;
      },
      CHECK_MOBILEPHONE(state, phoneNumberDetail) {
        state.phoneNumberDetail = phoneNumberDetail;
      },
      CHECK_MOBILEPHONE_ERROR(state, mobilePhoneError) {
        state.mobilePhoneError = mobilePhoneError;
      },
      SET_USER_GEOLOC(state, payload) {
        state.latitude = payload.latitude;
        state.longitude = payload.longitude;
      },
    },
    actions: {
      getCountry({ commit }) {
        axios
          .get(`${path}/allCountries`)
          .then((r) => r.data)
          .then((country) => {
            commit("GET_COUNTRY", country);
          })
          .catch((err) => {
            console.log(err.data);
            console.log(err);
          });
      },
      setCountries({ commit }, payload) {
        commit("GET_COUNTRY", payload);
      },
      getCountryStates({ commit }) {
        axios
          .get(`${path}/allStates`)
          .then((r) => r.data)
          .then((countryStates) => {
            commit("GET_COUNTRYSTATES", countryStates);
          })
          .catch((err) => {
            console.log(err.data);
            console.log(err);
          });
      },
      getCurrencyList({ commit, dispatch }) {
        axios
          .get(`${path}/currency`)
          .then((r) => r.data)
          .then((currencyList) => {
            commit("GET_CURRENCY_LIST", currencyList);
            dispatch("getEmoneyApiList");
          })
          .catch((err) => {
            console.log(err.data);
            console.log(err);
          });
      },
      getEmoneyApiList({ commit, state }) {
        if (state.emoneyApiList && !state.emoneyApiList.length > 0) {
          axios
            .get(`${path}/emoney/api`)
            .then((r) => r.data)
            .then((emoneyApiList) => {
              console.log(emoneyApiList);
              commit("GET_EMONEY_API_LIST", emoneyApiList);
            })
            .catch((err) => {
              console.log(err.data);
              console.log(err);
            });
        }
      },

      getCityList({ commit }) {
        axios
          .get(`${path}/city`)
          .then((r) => r.data)
          .then((cityList) => {
            commit("GET_CITYLIST", cityList);
            console.log(cityList);
          })
          .catch((err) => {
            console.log(err.data);
            console.log(err);
          });
      },
      getStateList({ commit }) {
        axios
          .get(`${path}/state`)
          .then((r) => r.data)
          .then((stateList) => {
            commit("GET_STATELIST", stateList);
            console.log(stateList);
          })
          .catch((err) => {
            console.log(err.data);
            console.log(err);
          });
      },

      getCountryList({ commit }) {
        axios
          .get(`${path}/country`)
          .then((r) => r.data)
          .then((countryList) => {
            commit("GET_COUNTRYLIST", countryList);
          })
          .catch((err) => {
            console.log(err.data);
            console.log(err);
          });
      },
      loadDestinationCity({ commit }, payload) {
        const country = payload !== null ? payload : state.currentUserCountry;
        axios({
          url: `${path}/country/find`,
          method: "GET",
          params: { toCountryCode: country },
        })
          .then((r) => r.data)
          .then((destinationCity) => {
            commit("SET_DESTINATIONCITY", destinationCity);
            console.log(destinationCity);
          })
          .catch((err) => {
            console.log(err.data);
            console.log(err);
          });
      },
      getCurrentUserAddress({ commit, dispatch }, payload) {
        const instance = axios.create();
        instance({
          url: "https://nominatim.openstreetmap.org/reverse",
          type: "GET",
          params: {
            lat: payload.latitude,
            lon: payload.longitude,
            format: "json",
          },
          dataType: "json",
        })
          .then((r) => r.data)
          .then((userAddress) => {
            commit("GET_CURRENTUSERADDRESS", userAddress);
            const countryCode =
              app.config.globalProperties.$keycloak &&
              app.config.globalProperties.$keycloak.authenticated
                ? app.config.globalProperties.$keycloak.userInfo.countryCode
                : userAddress.address.country_code;
            console.log(payload);

            dispatch("getCurrentUserCountry", countryCode);
          })
          .catch((err) => {
            console.log(err.data);
            console.log(err);
          });
      },

      getCurrentUserIpAddress({ commit, dispatch }) {
        const instance = axios.create();
        instance({
          // url: "https://ipapi.co/json/",
          url:
            "https://ipapi.co/json/?key=" + import.meta.env.VITE_IPAPI_APIKEY,
          type: "GET",
          dataType: "json",
        })
          .then((r) => r.data)
          .then((response) => {
            console.log(response);
            console.log("response", response.ip);
            commit("GET_CURRENTUSERIPADDRESS", response.ip);
            commit("SET_USER_GEOLOC", {
              latitude: response.latitude,
              longitude: response.longitude,
            });
            dispatch("getCurrentUserCountry", response.country);
          })
          .catch((err) => {
            console.log(err);
          });
      },
      setUserGeolocation({ commit }, payload) {
        commit("SET_USER_GEOLOC", payload);
      },

      checkMobilePhone({ commit }, payload) {
        axios({
          url: `${path}/checkPhoneNumber`,
          method: "GET",
          params: {
            phone: payload.phone,
            countryCode2: payload.countryCode2,
          },
        })
          .then((r) => r.data)
          .then((phoneNumberDetail) => {
            console.log(phoneNumberDetail);
            console.log(payload);
            commit("CHECK_MOBILEPHONE_ERROR", "");
            commit("CHECK_MOBILEPHONE", phoneNumberDetail);
          })
          .catch((err) => {
            commit("CHECK_MOBILEPHONE", "");
            commit("CHECK_MOBILEPHONE_ERROR", err.response.data);
            console.log(err.response.data);
          });
      },

      getCurrentUserCountry({ commit, dispatch }, payload) {
        axios({
          url: `${path}/userCountry`,
          method: "GET",
          params: { countryCode2: payload },
        })
          .then((r) => r.data)
          .then((currentUserCountry) => {
            commit("GET_CURRENTUSERCOUNTRY", currentUserCountry);
            commit("SET_CURRENTUSER_EMONEY_API_CODE", currentUserCountry);
            console.log(payload);
            console.log(currentUserCountry);
            const countryCode =
              app.config.globalProperties.$keycloak &&
              app.config.globalProperties.$keycloak.authenticated &&
              app.config.globalProperties.$keycloak.userInfo
                ? app.config.globalProperties.$keycloak.userInfo.countryCode
                : currentUserCountry.countryCode2;

            // dispatch("transaction/loadDestinationCtry", countryCode, {
            //   root: true,
            // });
            // dispatch("transaction/loadOriginCountry", countryCode, {
            //   root: true,
            // });
          })
          .catch((err) => {
            console.log(err.data);
            console.log(err);
          });
      },
    },
  };
};
// export default location;
