<template>
  <f7-panel left resizable :backdrop="false" class="createGroupeLeftPanel" :style="{
    'min-width': f7.width >= 768 ? '418px' : '100%',
    width: this.f7.width >= 768 ? '418px' : '100%',
    'border-right':
      'var(--f7-page-master-border-width) solid var(--f7-page-master-border-color)',
  }"><f7-view>
      <f7-page page-with-subnavbar>
        <f7-navbar no-shadow>
          <div class="left panel-close">
            <a href="#" class="link icon-only">
              <i class="icon icon-back"></i>
            </a>
          </div>
          <div class="title" v-if="!this.selectedGroup">
            {{ $t("tamtam.newGroup") }}
          </div>
          <div class="title" v-if="this.selectedGroup">
            {{ $t("tamtam.newSubgroup") }}<br />
            <span style="font-size: 75%"> {{ this.selectedGroup.name }}</span>
          </div>
          <div class="right">
            <f7-link panel-close></f7-link>
          </div>

          <f7-subnavbar :inner="false" no-shadow class="no-shadow">
            <f7-searchbar search-container=".search-list" search-in=".item-title, item-after, item-footer"
              :backdrop="false"></f7-searchbar>
          </f7-subnavbar>
        </f7-navbar>
        <f7-block strong v-show="this.chatUsers && this.chatUsers.length > 0">
          <f7-chip class="margin-left-half" outline v-for="(chatUser, index) in this.chatUsers" :key="index"
            :text="chatUser.name" media-bg-color="primary" deleteable
            :media="chatUser.logo ? '' : $filtersGetInitial.getInitial(chatUser.name)"
            @click="deleteChip(chatUser)"><template #media>
              <img v-if="!!chatUser.logo" :src="`${chatUser.logo}`" style="
                  width: var(--f7-chip-height);
                  height: var(--f7-chip-height);
                  border-radius: 25px;
                " />
            </template>
          </f7-chip>
        </f7-block>
        <f7-list dividers-ios strong-ios class="searchbar-not-found">
          <f7-list-item :title="$t('common.nothingFound')"></f7-list-item>
        </f7-list>

        <f7-list dividers-ios strong-ios v-if="!this.selectedGroup" media-list class="search-list searchbar-found"
          id="userRecipientlist">
          <li v-for="user in this.users" :key="user.id">
            <a class="item-checkbox item-content" :data-value="user.userId" @click="selectUser(user)">
              <div class="item-media">
                <img v-if="!!user.logo" :src="`${user.logo}`" width="40"
                  style="height: 48px; width: 48px; border-radius: 25px" />

                <f7-badge color="auto" v-if="user.logo === null && user.name !== null"
                  style="height: 48px; width: 48px; font-size: 16px; border-radius: 25px">{{
                    $filtersGetInitial.getInitial(user.name) }}
                </f7-badge>
              </div>
              <div class="item-inner">
                <div class="item-title">{{ user.name }}</div>
                <div class="item-after">
                  {{ user.phoneNumber }}
                </div>
                <div class="item-footer">
                  {{ user.tag }}
                </div>
              </div>
            </a>
          </li>
        </f7-list>
        <f7-list dividers-ios strong-ios v-if="this.selectedGroup" media-list class="search-list searchbar-found"
          id="userRecipientlist">
          <li v-for="user in this.selectedGroup.groupParticipantList" :key="user.id">
            <a class="item-checkbox item-content" :data-value="user.userId" @click="selectChatUser(user)">
              <div class="item-media">
                <img v-if="!!user.avatar" :src="`${user.avatar}`" width="40"
                  style="height: 48px; width: 48px; border-radius: 25px" />

                <f7-badge color="auto" v-if="!user.avatar && user.name !== null"
                  style="height: 48px; width: 48px; font-size: 16px; border-radius: 25px">{{
                    $filtersGetInitial.getInitial(user.name) }}
                </f7-badge>
              </div>
              <div class="item-inner">
                <div class="item-title">{{ user.name }}</div>
                <div class="item-after">
                  +
                  {{ user.phoneNumber }}
                </div>
                <div class="item-footer">
                  {{ user.tag }}
                </div>
              </div>
            </a>
          </li>
        </f7-list>

        <f7-toolbar tabbar bottom bg-color="red" v-if="this.chatUsers && this.chatUsers.length > 1">
          <f7-link :text="this.selectedGroup
              ? $t('tamtam.addGroupName')
              : $t('tamtam.addSubgroupName')
            " raised fill large class="link" text-color="white" strong @click="openSaveGroupeLeftPanel()"></f7-link>
          <!-- <f7-link v-if="this.selectedGroup" raised fill large class="link buttons" text-color="white" strong
          panel-open=".saveGroupeLeftPanel">{{ $t("tamtam.addSubgroupName") }}</f7-link> -->
        </f7-toolbar>
      </f7-page>
    </f7-view>
  </f7-panel>

  <f7-panel left resizable :backdrop="false" class="saveGroupeLeftPanel" :style="{
    width: this.f7.width >= 768 ? '418px' : '100%',
    'min-width': f7.width >= 768 ? '418px' : '100%',
    'border-right':
      'var(--f7-page-master-border-width) solid var(--f7-page-master-border-color)',
  }">
    <f7-view>
      <f7-page page-with-subnavbar>
        <f7-navbar no-shadow>
          <div class="left panel-close">
            <a href="#" class="link icon-only">
              <i class="icon icon-back"></i>
            </a>
          </div>
          <div class="title" v-if="!this.selectedGroup">
            {{ $t("tamtam.newGroup") }}
          </div>
          <div class="title" v-if="this.selectedGroup">
            {{ $t("tamtam.newSubgroup") }}<br />
            <span style="font-size: 75%"> {{ this.selectedGroup.name }}</span>
          </div>
          <div class="right">
            <f7-link panel-close></f7-link>
          </div>
        </f7-navbar>
        <f7-block strong v-if="this.chatUsers && this.chatUsers.length > 0">
          <f7-chip outline v-for="(chatUser, index) in this.chatUsers" :key="index"
            :text="chatUser.name || chatUser.username" deleteable @click="deleteChip(chatUser)" media-bg-color="primary"
            :media="chatUser.logo ? '' : $filtersGetInitial.getInitial(chatUser.name)"><template #media>
              <img v-if="!!chatUser.logo || !!chatUser.avatar" :src="`${chatUser.logo || chatUser.avatar}`" style="
                  width: var(--f7-chip-height);
                  height: var(--f7-chip-height);
                  border-radius: 50%;
                " />
            </template>
          </f7-chip>
        </f7-block>
        <form id="createGroupForm">
          <f7-list dividers-ios strong-ios media-list>
            <f7-list-input :label="$t('common.name')" floating-label type="text" name="groupName"
              :placeholder="$t('common.name')" :value="this.groupName" @input="groupName = $event.target.value"
              :error-message="$t('common.name')" required validate clear-button></f7-list-input>

            <f7-list-input :label="$t('common.description')" floating-label type="text" name="description"
              :placeholder="$t('common.description')" :value="this.description"
              @input="description = $event.target.value" :error-message="$t('common.description')"
              clear-button></f7-list-input>

            <f7-list-item v-if="
              (this.selectedGroup && this.selectedGroup.avatar !== '') ||
              !this.documentsUrl
            " link="#" :title="$t('tamtam.uploadNewProfilePicture')" @click="selectDocument('img')"><template #media>
                <img :src="`${this.selectedGroup.avatar}`" v-if="this.selectedGroup && !!this.selectedGroup.avatar"
                  style="
                    width: 48px;
                    height: 48px;
                    background-size: cover;
                    display: block;
                    border-radius: 50%;
                  " alt="avatar" /></template>
            </f7-list-item>
            <f7-link v-else class="margin-left margin-top" @click="selectDocument('img')" icon-size="50"
              icon-ios="f7:photo_on_rectangle" icon-aurora="f7:photo_on_rectangle" icon-md="material:add_to_photos"
              :text="$t('tamtam.addGroupAvatar')"></f7-link>
            <f7-list-input type="file" class="chatAvatarInput" input-id="selectPhoto" style="display: none"
              accept="image/*" capture="user"></f7-list-input>
            <f7-list-group>
              <span v-for="(docItem, index) in this.documentsUrl" :key="index">
                <f7-list-item class="attachmentName">{{ docItem.fileName }}
                </f7-list-item>
                <f7-link class="removeAttachement" @click="removeDoc(index)" raised color="red"
                  icon-f7="xmark_circle_fill"></f7-link>
              </span>
              <span v-if="this.documentsUrl && this.documentsUrl.length > 0" class="logoCropper">
                <imageCropper :imageSrc="this.documentsUrl[0]" :isToSave="false" />
              </span>
            </f7-list-group>
          </f7-list>
        </form>
        <f7-toolbar tabbar bottom bg-color="red" :style="{ bottom: 0, position: isTablet ? 'initial' : 'fixed' }"
          v-if="this.chatUsers && this.chatUsers.length > 1 && this.groupName !== ' '">
          <f7-link v-if="!this.selectedGroup" raised fill large ripple class="link" text-color="white"
            :disabled="this.groupName === ''" strong @click="createGroup()">{{ $t("tamtam.createGroup") }}</f7-link>
          <f7-link v-if="this.selectedGroup" raised fill large ripple :disabled="this.groupName === ''" class="link"
            text-color="white" strong @click="createSubGroup(selectedGroup)">{{ $t("tamtam.createSubgroup") }}</f7-link>
        </f7-toolbar>
      </f7-page>
    </f7-view>
  </f7-panel>
  <editDocs></editDocs>
</template>
<style scoped></style>
<script>
import { f7, theme } from "framework7-vue";
import { mapGetters, mapState } from "vuex";
import {
  messageOptionsMixin,
  messageGroupMixin,
  getusersMixin,
} from "../../js/mixins/messageMixins.js";
import { cordovaCamera } from "../../js/mixins/cordovaCamera.js";
import { attachDocs } from "../../js/mixins/attachDocs.js";
import _ from "lodash";
import editDocs from "../../components/editDocs.vue";
import imageCropper from "../imageCropper.vue";

export default {
  props: {
    f7route: Object,
    f7router: Object,
    selectedGroup: Object,
  },
  mixins: [
    messageOptionsMixin,
    messageGroupMixin,
    cordovaCamera,
    getusersMixin,
    attachDocs,
  ],
  components: {
    editDocs,
    imageCropper,
  },

  data() {
    return {
      theme,
      f7,
      chatUsers: [],
      chatUsersToPost: [],
      groupName: "",
      description: "",
      image: "",
      title: "",
      documents: [],
    };
  },
  mounted() {
    if (this.documentsUrl && this.documentsUrl.length > 0) {
      this.$store.dispatch("auth/clearDocumentsUrl");
    }
  },
  methods: {},
  computed: {
    ...mapState("message", [
      "chatUser",
      "message",
      "chatUserList",
      "messageList",
      "group",
      "groupList",
      "subGroup",
      "subGroupList",
    ]),
    ...mapState("account", ["searchedUser", "users"]),
    ...mapState("auth", [
      "video",
      "isDeskTop",
      "isTablet",
      "device",
      "profile",
      "documentsUrl",
    ]),
  },
};
</script>
