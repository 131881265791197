import i18n from "../../i18n.js";
import { selectedLocale } from "../../i18n.js";
import _ from "lodash";
import axios from "axios";
import f7 from "framework7";
const path = import.meta.env.VITE_APP_URL;
// const auth = {
export default (app) => {
  return {
    namespaced: true,
    state: {
      keycloak: "",
      token: "",
      refreshToken: "",
      profile: "",
      user: "",
      authenticated: false,
      locale: selectedLocale,
      topath: "",
      loginUrl: "",
      registerUrl: "",
      isDeskTop: "",
      isTablet: "",
      isCordova: "",
      documentsUrl: [],
      document: {},
      videoService: "",
      videosUrl: [],
      device: "",
      lastSwiperIsActive: false,
      onboardingFinished: app.config.globalProperties.$lcStorage.get("ql82uFo")
        ? app.config.globalProperties.$lcStorage.get("ql82uFo")
        : false,
      dynamicLink: "",
      adminToken: "",
      adminUser: "",
      csrfToken: "",
      selectedContact: "",
      contactList: [],
      landingPage: [],
      isAuthAvailable: false,
    },
    getters: {
      // isAuthenticated: state => state.token !== '',
      isAuthenticated: (state) => state.token,
      // authenticated: state => state.token !== "",

      getProfile: (state) => state.profile,
    },
    mutations: {
      GET_CSRF_TOKEN(state, csrfToken) {
        state.csrfToken = csrfToken;
      },
      GET_ADMIN_TOKEN(state, adminToken) {
        state.adminToken = adminToken;
      },
      GET_ADMIN_USER(state, adminUser) {
        state.adminUser = adminUser;
      },

      KEYCLOAK_SET_TOKEN(state, token) {
        state.token = token;
      },
      SET_KEYCLOAK(state, keycloak) {
        state.keycloak = keycloak;
      },

      KEYCLOAK_SET_REFRESHTOKEN(state, refreshToken) {
        state.refreshToken = refreshToken;
      },
      KEYCLOAK_SET_PROFILE(state, profile) {
        state.profile = profile;
        // state.profile = app.config.globalProperties.$keycloak.userInfo;
      },
      KEYCLOAK_SET_USER(state, user) {
        state.user = user;
        // state.user = app.config.globalProperties.$keycloak.profile;
      },
      IS_AUTHENTICATED(state, authenticated) {
        state.authenticated = authenticated;
        // state.authenticated =
        //     app.config.globalProperties.$keycloak.authenticated;
      },

      UPDATE_LOCALE(state, newLocale) {
        state.locale = newLocale;
      },
      GET_TOPATH(state, topath) {
        state.topath = topath;
      },
      QUERY_ERROR_EMAIL_CHECK(state, queryerrorEmailCheck) {
        state.queryerrorEmailCheck = queryerrorEmailCheck;
      },
      QUERY_ERROR_PHONE_CHECK(state, queryerrorPhoneCheck) {
        state.queryerrorPhoneCheck = queryerrorPhoneCheck;
      },

      ISDESKTOP(state, isDeskTop) {
        state.isDeskTop = isDeskTop;
      },
      ISTABLET(state, isTablet) {
        state.isTablet = isTablet;
      },
      ISCORDOVA(state, isCordova) {
        state.isCordova = isCordova;
      },
      SELECT_DOCUMENT(state, document) {
        state.document = document;
      },
      SET_DOCUMENTS_URL(state, document) {
        if (state.documentsUrl.find((item) => item.id === document.id)) {
          const doc = state.documentsUrl.find(
            (item) => item.id === document.id
          );
          state.documentsUrl.splice(state.documentsUrl.indexOf(doc), 1);
        }
        state.documentsUrl.push(document);
      },
      UNSET_DOCUMENTS_URL(state, document) {
        if (state.documentsUrl.find((item) => item.id === document.id)) {
          const doc = state.documentsUrl.find(
            (item) => item.id === document.id
          );
          state.documentsUrl.splice(state.documentsUrl.indexOf(doc), 1);
        }
      },
      CLEAR_DOCUMENTS_URL(state) {
        state.documentsUrl = [];
      },
      SET_VIDEO_SERVICE_TYPE(state, videoService) {
        state.videoService = videoService;
      },
      SET_VIDEOS_URL(state, videosUrl) {
        state.videosUrl = videosUrl;
      },
      SET_DEVICE(state, device) {
        state.device = device;
      },
      SET_LAST_SWIPER(state, lastSwiperIsActive) {
        state.lastSwiperIsActive = lastSwiperIsActive;
      },
      SET_ONBOARDING(state, onboardingFinished) {
        state.onboardingFinished = onboardingFinished;
        app.config.globalProperties.$lcStorage.set(
          "ql82uFo",
          onboardingFinished
        );
      },
      CREATE_DLINKS(state, dynamicLink) {
        state.dynamicLink = dynamicLink;
      },
      SET_SELECTED_CONTACT(state, selectedContact) {
        state.selectedContact = selectedContact;
      },
      SET_CONTACT_LIST(state, contactList) {
        state.contactList = contactList;
      },
      SET_LANDINGPAGE(state, landingPage) {
        state.landingPage = landingPage;
      },
      SET_AUTH_AVAILABLE(state, isAuthAvailable) {
        state.isAuthAvailable = isAuthAvailable;
      },
    },
    actions: {
      setToken({ commit }, token) {
        commit("KEYCLOAK_SET_TOKEN", token);
      },
      setRefreshToken({ commit }, refreshToken) {
        commit("KEYCLOAK_SET_REFRESHTOKEN", refreshToken);
      },

      setKeycloak({ commit }, keycloak) {
        commit("SET_KEYCLOAK", keycloak);
      },

      isAuthenticated({ commit }, authenticated) {
        commit("IS_AUTHENTICATED", authenticated);
      },
      setUser({ commit }, user) {
        commit("KEYCLOAK_SET_USER", user);
      },
      setProfile({ commit }, profile) {
        commit("KEYCLOAK_SET_PROFILE", profile);
      },
      changeLocale({ commit }, newLocale) {
        i18n.locale = newLocale;
        commit("UPDATE_LOCALE", newLocale);
      },
      isDeskTop({ commit }, payload) {
        console.log(payload);
        commit("ISDESKTOP", payload);
      },
      isTablet({ commit }, payload) {
        console.log(payload);
        commit("ISTABLET", payload);
      },
      isCordova({ commit }, payload) {
        console.log(payload);
        commit("ISCORDOVA", payload);
      },
      setDevice({ commit }, payload) {
        commit("SET_DEVICE", payload);
      },
      selectDocument({ commit }, payload) {
        commit("SELECT_DOCUMENT", payload);
      },
      setDocumentsUrl({ commit }, payload) {
        commit("SET_DOCUMENTS_URL", payload);
      },
      unSetDocumentsUrl({ commit }, payload) {
        commit("UNSET_DOCUMENTS_URL", payload);
      },
      clearDocumentsUrl({ commit }) {
        commit("CLEAR_DOCUMENTS_URL");
      },
      setVideoServiceType({ commit }, payload) {
        commit("SET_VIDEO_SERVICE_TYPE", payload);
      },
      setVideosUrl({ commit }, payload) {
        commit("SET_VIDEOS_URL", payload);
      },
      setLastSwiperIsActive({ commit }, payload) {
        commit("SET_LAST_SWIPER", payload);
      },
      setOnboardingFinished({ commit }, payload) {
        commit("SET_ONBOARDING", payload);
      },

      setLandingPage({ commit }, landingPage) {
        commit("SET_LANDINGPAGE", landingPage);
      },

      setAuthAvailable({ commit }, isAuthAvailable) {
        commit("SET_AUTH_AVAILABLE", isAuthAvailable);
      },
      createDynamicLink({ commit }, payload) {
        const dynamicLinkOptions = {
          dynamicLinkInfo: {
            domainUriPrefix:
              import.meta.env.VITE_AVOCASH_SITE_URL + "/" + payload.linkBase,
            link: import.meta.env.VITE_AVOCASH_SITE_URL + "" + payload.link,
            androidInfo: {
              androidPackageName: import.meta.env.VITE_ANDROID_PACKAGE_NAME,
              androidFallbackLink:
                import.meta.env.VITE_AVOCASH_SITE_URL + "" + payload.link,
            },
            iosInfo: {
              iosBundleId: import.meta.env.VITE_IOS_BUNDLE_ID_NAME,
              iosFallbackLink:
                import.meta.env.VITE_AVOCASH_SITE_URL + "" + payload.link,
            },
            navigationInfo: {
              enableForcedRedirect: true,
            },
            socialMetaTagInfo: {
              socialTitle: payload.subject,
              socialDescription: payload.message,
              socialImageLink: payload.imgUrl,
            },
          },
          suffix: {
            option: payload.short ? "SHORT" : "UNGUESSABLE",
          },
        };
        if (!window.cordova || !window.Cordova) {
          const instance = axios.create();
          instance({
            url:
              "https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=" +
              import.meta.env.VITE_FIREBASE_WEB_API_KEY,
            data: dynamicLinkOptions,
            method: "POST",
            dataType: "json",
          })
            .then((data) => {
              commit("CREATE_DLINKS", data.data);
              console.log(data);
            })
            .catch((err) => {
              console.log(err);
            });
        } else if (payload.short) {
          cordova.plugins.firebase.dynamiclinks
            .createShortDynamicLink(dynamicLinkOptions.dynamicLinkInfo)
            .then((deepLink) => {
              console.log("Generated short deep link", deepLink);
              const data = { shortLink: deepLink };
              commit("CREATE_DLINKS", data);
            });
        } else {
          cordova.plugins.firebase.dynamiclinks
            .createUnguessableDynamicLink(dynamicLinkOptions.dynamicLinkInfo)
            .then((deepLink) => {
              console.log("Generated unguessable deep link", deepLink);
              const data = { shortLink: deepLink };
              commit("CREATE_DLINKS", data);
            });
        }
      },

      getCsrfToken({ commit }) {
        const instance = axios.create();
        instance({
          url: `${path}csrf`,
          method: "GET",
          // headers: {
          //     Authorization: `Bearer ${rootState.auth.adminToken.access_token}`,
          // },
        })
          .then((r) => r.data)
          .then((csrfToken) => {
            commit("GET_CSRF_TOKEN", csrfToken);
            console.log("GET_CSRF_TOKEN", csrfToken);
          })
          .catch((err) => {
            console.log(err);
          });
      },
      getAdminToken({ commit }) {
        const instance = axios.create();
        const params = new URLSearchParams();
        params.append("client_id", "admin-cli");
        params.append("username", `${import.meta.env.VITE_ADMIN_CREDUSR}`);
        params.append("password", `${import.meta.env.VITE_ADMIN_CREDPWD}`);
        params.append("grant_type", "password");
        console.log(params);
        instance
          .post(`${import.meta.env.VITE_ADMIN_TOKEN_URL}`, params)
          .then((r) => r.data)
          .then((adminToken) => {
            console.log(import.meta.env.VITE_ADMIN_CRED);
            commit("GET_ADMIN_TOKEN", adminToken);
            console.log(adminToken);
          })
          .catch((err) => {
            console.log(err);
          });
      },
      getUserFromAdmin({ commit, rootState }) {
        const instance = axios.create();
        instance({
          url: `${import.meta.env.VITE_ADMIN_USER_URL}/${
            rootState.auth.user.id
          }`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${rootState.auth.adminToken.access_token}`,
          },
        })
          .then((r) => r.data)
          .then((adminUser) => {
            commit("GET_ADMIN_USER", adminUser);
          })
          .catch((err) => {
            console.log(err);
          });
      },
      UpdateUserFromAdmin({ commit, rootState }, payload) {
        const instance = axios.create();
        instance({
          url: `${import.meta.env.VITE_ADMIN_USER_URL}/${
            rootState.auth.user.id
          }`,
          method: "PUT",
          data: payload,
          headers: {
            Authorization: `Bearer ${rootState.auth.adminToken.access_token}`,
          },
        })
          .then((r) => r.data)
          .then((adminUser) => {
            commit("GET_ADMIN_USER", adminUser);
          })
          .catch((err) => {
            console.log(err);
          });
      },
      setSelectedContact({ commit }, payload) {
        commit("SET_SELECTED_CONTACT", payload);
      },
      setContactList({ commit }, payload) {
        commit("SET_CONTACT_LIST", payload);
      },
      checkEmailAvailability({ commit }, payload) {
        axios({
          url: `${path}account/new/register/checkEmailAvailability`,
          method: "GET",
          params: {
            email: payload.email,
          },
        })
          .then((r) => r.data)
          .then((availability) => {
            console.log(availability);
            commit("QUERY_ERROR_EMAIL_CHECK", availability);
          })
          .catch((err) => {
            commit("QUERY_ERROR_EMAIL_CHECK", err.response.data);
            console.log(err.response.data);
          });
      },

      checkPhoneAvailability({ commit }, payload) {
        axios({
          url: `${path}account/new/register/checkPhoneAvailability`,
          method: "GET",
          params: {
            phone: payload.phone,
            phoneCode: payload.phoneCode,
          },
        })
          .then((r) => r.data)
          .then((availability) => {
            console.log(availability);
            commit("QUERY_ERROR_PHONE_CHECK", availability);
          })
          .catch((err) => {
            commit("QUERY_ERROR_PHONE_CHECK", err.response.data);
            console.log(err.response.data);
            // reject(err)
          });
      },

      getTopath({ commit, state }, payload) {
        commit("GET_TOPATH", payload);
      },
    },
  };
};
// export default auth;
