import { f7 } from "framework7-vue";
import { mapState } from "vuex";
import _ from "lodash";
export const account = {
  props: {
    f7route: Object,
    f7router: Object,
  },
  data() {
    return {
      KYC_Detail: [],
      amount: "",
      trxType: "",
      referenceId: "",
      description: "",
      tag: "",
      maxNumberOfItem: 18,
      setWalletCurrency: "",
      userCtry: "",
    };
  },
  mounted() {
    if (this.userAccount === null && this.$keycloak.authenticated) {
      this.$store.dispatch("account/loadAccount", this.profile.sub);
    }
    this.userCtry = this.userAccount.countryCode || this.profile.countryCode;
    console.log("userCtry", this.userCtry);
    this.KYC_Detail = [
      {
        account_type: "STANDARD",
        name: "LEVEL_0",
        translated: this.$t("common.level0", { level0: 0 }),
        mandatory_data: [
          this.$t("common.emailOrPhoneNumber"),
          this.$t("common.lastName"),
          this.$t("common.firstName"),
        ],
        // ["Email or mobile phone number","Last name","First name"],
        document: [],
        wallet_creation: "Not possible",
        max_balance: 0,
        max_cash_in: "n/a",
        max_cash_out: "n/a",
        currency: "EUR",
        value: 0,
        apiCode: ["api_money"],
        other_details: this.$t("common.level0Details"),
      },
      {
        account_type: "STANDARD",
        name: "LEVEL_1",
        translated: this.$t("common.level0", { level0: 1 }),
        mandatory_data: [
          this.$t("common.level0Data", { level: 0 }),
          this.$t("common.dateOfBirth"),
          this.$t("common.cityOfBirth"),
          this.$t("common.countryOfBirth"),
        ],
        // ["Level_0 data","Birth date","Birth City","Birth Country"],
        document: [],
        optional_data: [],
        wallet_creation: "Possible",
        max_balance: 150,
        max_cash_in: 150,
        max_cash_out: 150,
        currency: "EUR",
        value: 1,
        apiCode: ["api_money"],
        other_details: this.$t("common.level1OtherDetails"),
      },
      {
        account_type: "STANDARD",
        name: "LEVEL_2",
        translated: this.$t("common.level0", { level0: 2 }),
        mandatory_data: [
          this.$t("common.level0Data", { level: 1 }),
          this.$t("common.nationality"),
          this.$t("common.residenceCountry"),
          this.$t("common.incomes"),
          this.$t("common.professionalCategory"),
          this.$t("common.aValidBankAccount"),
        ],

        // mandatory_data: [
        //   "Level_1 data",
        //   "Nationality",
        //   "Residence Country",
        //   "Incomes",
        //   "Professional Category",
        //   "A valid bank account",
        // ],
        document: [this.$t("common.proofOfId"), this.$t("common.proofOfInban")],
        // document: ["Proof of id", "Proof of iban"],
        wallet_creation: "Possible",
        max_balance: 10_000,
        max_cash_in: 100_000,
        max_cash_out: "unlimited",
        currency: "EUR",
        value: 2,
        apiCode: ["api_money"],
        other_details: this.$t("common.level2OtherDetails"),

        // "Une pièce d’'identité : cette pièce doit être conforme aux informations renseignées sur API-Money lors de la création du compte et doit être valide. La pièce d’identité peut être un passeport de l’EEE (Espace Economique Européen), une carte d’identité, un permis de conduire (nouvelle génération) ou un titre de séjour.
        // "Un RIB (relevé d’identité bancaire) :celui-ci doit être au nom du compte créé. Les informations doivent donc correspondre (nom, prénom et adresse).",
      },
      {
        account_type: "STANDARD",
        name: "LEVEL_3",
        translated: this.$t("common.level0", { level0: 3 }),
        mandatory_data: [
          this.$t("common.level0Data", { level: 2 }),
          `${this.$t("common.fiscaldata")} :  ${this.$t(
            "common.whetherOrNot"
          )} ${this.$t("common.citizenOfUS")}; ${this.$t(
            "common.whetherOrNot"
          )} ${this.$t("common.fiscalResidentOfUS")}; ${this.$t(
            "common.whetherOrNot"
          )} ${this.$t("common.fiscalResidentOutOfFrance")}`,
        ],
        document: [this.$t("common.secondProofOfIdOrTaxNotice")],
        optional_data: [],
        wallet_creation: "Possible",
        max_balance: 100_000,
        max_cash_in: 1_000_000,
        max_cash_out: "Unlimited",
        currency: "EUR",
        value: 2,
        apiCode: ["api_money"],
        other_details:
          "Une seconde pièce d’identité : de même que la 1er pièce d’identité, celle-ci doit être conforme aux informations renseignées sur API-Money lors de la création du compte et doit être valide. La seconde pièce d’identité peut être un passeport de l’EEE (Espace Economique Européen), une carte d’identité, un permis de conduire (nouvelle génération) ou un titre de séjour différent de la 1er pièce d’identité.",
      },
      {
        account_type: "BUSINESS",
        name: "LEVEL_1",
        translated: this.$t("common.level0", { level0: 1 }),
        mandatory_data: [
          this.$t("common.entityName"),
          `${this.$t("common.businessType")}: ${this.$t(
            "common.company"
          )}, ${this.$t("common.association")}, ${this.$t(
            "common.soleTrader"
          )}`,
          this.$t("common.registrationNumber"),
          this.$t("common.represenativeFirstName"),
          this.$t("common.represenativeLastname"),
          this.$t("common.represenativeBirthdate"),
          this.$t("common.entityEmail"),
        ],
        // mandatory_data: [
        //   "Entity Name",
        //   "Business Type:COMPANY,ASSOCIATION,SOLE_TRADER",
        //   "Registration Number",
        //   "Representative Firstname",
        //   "Representative Lastname",
        //   "Representative Birthdate",
        //   "Entity email",
        // ],
        optional_data: [
          this.$t("common.represenativeNationality"),
          this.$t("common.entityAddress"),
        ],
        // optional_data: ["Representative Nationality", "Entity Address"],
        document: [],
        wallet_creation: "Not possible(1)",
        max_balance: 0,
        max_cash_in: "n/a",
        max_cash_out: "n/a",
        currency: "EUR",
        value: 1,
        apiCode: ["api_money"],
        other_details:
          "(1) Depending of your use case, having a Wallet at KYC LEVEL_1 may be possible (crowdfunding, crowdlening, money pot...). It permits to start receiving cash-in but account is automatically set at 'KYC_REQUIRED' status after the first cash-in. Then, the e-money is blocked, neither cash-out nor transfer-out is allowed, until the account is successfully upgraded to the next LEVEL.In such case, cash-in or transfer-in are allowed but within the following maximal balance : 2 500 € for LEVEL_1 account",
      },
      {
        account_type: "BUSINESS",
        name: "LEVEL_2",
        translated: this.$t("common.level0", { level0: 2 }),
        mandatory_data: [
          this.$t("common.represenativeNationality"),
          this.$t("common.entityAddress"),
          this.$t("common.aValidBankAccount"),
        ],
        // mandatory_data: [
        //   "Representative Nationality",
        //   "Entity Address",
        //   "A valid bank account",
        // ],
        optional_data: [],
        document: [
          this.$t("common.proofOfRegistration"),
          this.$t("common.representativeProofOfId"),
          this.$t("common.proofOfAddress"),
          this.$t("common.proofOfInban"),
          this.$t("common.businessStatus"),
          this.$t("common.accountingReport"),
        ],
        // document: ["Proof of registration","Representative's Proof of id","Proof of address","Proof of iban","Business status","Accounting report"],
        wallet_creation: "Possible",
        max_balance: 10_000_000,
        max_cash_in: 100_000_000,
        max_cash_out: "Unlimited",
        currency: "EUR",
        value: 2,
        apiCode: ["api_money"],
        other_details:
          "(1) Depending of your use case, having a Wallet at KYC LEVEL_1 may be possible (crowdfunding, crowdlening, money pot...). It permits to start receiving cash-in but account is automatically set at 'KYC_REQUIRED' status after the first cash-in. Then, the e-money is blocked, neither cash-out nor transfer-out is allowed, until the account is successfully upgraded to the next LEVEL.In such case, cash-in or transfer-in are allowed but within the following maximal balance : 2 500 € for LEVEL_1 account",
      },
    ];
  },
  methods: {
    updateUser() {
      setTimeout(() => {
        this.$store.dispatch("account/loadAccount", this.profile.sub);
      }, 1000);
    },

    getAccountStatus(status) {
      const statusMapping = {
        ACTIVE: this.$t("common.active"),
        INACTIVE: this.$t("common.inActive"),
        KYC_REQUIRED: this.$t("common.kycRequired"),
        SUSPENDED: this.$t("bankAccount.suspended"),
        default: this.$t("bankAccount.inActive"),
      };

      return statusMapping[status] || statusMapping.default;
    },
    getAccountType(type) {
      return type === "STANDARD"
        ? this.$t("common.standard")
        : this.$t("common.business");
    },

    getAccountKYC_Level() {
      const { kyc_level, type } = this.activeAccount;
      const { apiCode } = this.userAccount;

      return this.KYC_Detail.find(
        (kYC) =>
          kYC.name === kyc_level &&
          kYC.account_type === type &&
          kYC.apiCode.includes(apiCode)
      );
    },
    getAccountKYC_Level_Up() {
      if (!this.activeAccount) {
        return;
      }

      const levelMapping = {
        LEVEL_0: "LEVEL_1",
        LEVEL_1: "LEVEL_2",
        LEVEL_2: "LEVEL_3",
        default: "LEVEL_0",
      };

      const upLevel =
        levelMapping[this.activeAccount.kyc_level] || levelMapping.default;

      return this.KYC_Detail.find(
        (kYC) =>
          kYC.name === upLevel &&
          kYC.account_type === this.activeAccount.type &&
          kYC.apiCode.includes(this.userAccount.apiCode)
      );
    },
    setStatus() {
      const statusToggle = f7.toggle.get(".toggle");
      this.accountStatus = statusToggle.checked ? "ACTIVE" : "INACTIVE";
      this.accountIsActive = statusToggle.checked ? true : false;
    },

    addNewBankAccount() {
      this.addNew = !this.addNew;
      console.log("this.addNew ", this.addNew);
    },
    addBankAccount() {
      this.$store.dispatch("apimoney/addBankAccount", {
        account_id: this.account_id,
        tag: this.tag,
        number: this.number,
        bic: this.bic,
        holder_name: this.holder_name,
        file_content: this.documentsUrl[0].url,
      });
      this.updateUser();
    },
    editBankAccount() {
      this.$store.dispatch("apimoney/editBankAccount", {
        bankAccount: {
          tag: this.tag,
          status: this.accountStatus,
        },
        bankAccountId: this.selectedAccount.id,
      });
      this.updateUser();
    },
    openItem(account) {
      const self = this;
      this.selectedAccount = account;
      const buttonsList = [
        {
          text: self.$t("common.cancel"),
          color: "red",
        },
      ];
      if (!account.main) {
        buttonsList.unshift({
          text: self.$t("common.delete"),
          color: "deeporange",
          bold: true,
          onClick() {
            self.$store.dispatch("apimoney/deleteBankAccount", account.id);
            self.updateUser();
          },
        });
      }
      buttonsList.unshift({
        text: self.$t("common.edit"),
        color: "yellow",
        bold: true,
        onClick() {
          self.isEditable = true;
          self.fillEditForm(account);
        },
      });
      if (!account.main) {
        buttonsList.unshift({
          text: self.$t("common.setAsMainBankAccount"),
          color: "primary",
          bold: true,
          onClick() {
            self.$store.dispatch("account/setAccounMaintBankAccount", {
              bankAccountId: account.id,
              accountId: account.account_id,
            });
          },
        });
      }

      // if (!self.actionsToPopover) {
      self.actionsToPopover = f7.actions.create({
        buttons: buttonsList,
        // Need to specify popover target
        targetEl: self.$el.querySelector(`#account${account.id}`),
      });
      // }

      // Open
      self.actionsToPopover.open();
    },
    fillEditForm(account) {
      console.log("account", account);
      this.number = account.number;
      this.bic = account.bic;
      this.tag = account.tag;
      this.account_id = account.account_id;
      this.accountIsActive = account.status === "ACTIVE";
      this.accountStatus = account.status;
      // }
    },
    deleteBankAccount(account) {
      f7.dialog.confirm(
        this.$t("common.areYouSureToDeleteCreditCard", {
          currentAccount: `${account.number} ${account.tag}`,
        }),
        () => {
          this.$store.dispatch("apimoney/deleteCreditCard", creditcard.id);
        }
      );
      this.updateUser();
    },
    //Credit cards

    openCreditCardItem(creditcard) {
      const self = this;
      this.selectedCard = creditcard;
      console.log("creditcard", this.selectedCard);
      // if (!self.actionsCCToPopover) {
      self.actionsCCToPopover = f7.actions.create({
        buttons: [
          {
            text: self.selectedCard.main
              ? ""
              : self.$t("common.setAsMainCreditCard"),
            color: self.selectedCard.main ? "white" : "primary",
            bold: self.selectedCard.main ? false : true,
            onClick() {
              if (self.selectedCard.main) {
                return;
              }
              self.$store.dispatch("account/setAccounMaintCreditCard", {
                creditcardId: self.selectedCard.id,
                accountId: self.selectedCard.account_id,
              });
            },
          },
          {
            text: self.$t("common.edit"),
            bold: true,
            color: "yellow",
            onClick() {
              self.editCreditCard(self.selectedCard);
            },
          },
          {
            text: self.selectedCard.main ? "" : self.$t("common.delete"),
            color: self.selectedCard.main ? "white" : "deeporange",
            bold: self.selectedCard.main ? false : true,
            onClick() {
              if (self.selectedCard.main) {
                return;
              }
              self.deleteCreditCard(self.selectedCard);
            },
          },
          {
            text: self.$t("common.cancel"),
            color: "red",
          },
        ],
        // Need to specify popover target
        targetEl: self.$el.querySelector(`#creditcard_${self.selectedCard.id}`),
      });
      // }

      // Open
      self.actionsCCToPopover.open();
    },

    editCreditCard(creditcard) {
      f7.dialog.prompt(
        this.$t("common.updateCreditCard"),
        (tag) => {
          console.log("tag", tag);
          this.$store.dispatch("apimoney/editCreditCard", {
            creditCard: {
              // status: "ACTIVE",
              tag,
            },
            creditCardId: creditcard.id,
          });
        },
        () => {
          return;
        },
        creditcard.tag
      );
      this.updateUser();
    },

    deleteCreditCard(creditcard) {
      f7.dialog.confirm(
        this.$t("common.areYouSureToDeleteCreditCard", {
          currentCard: `${creditcard.number} ${creditcard.tag}`,
        }),
        () => {
          this.$store.dispatch("apimoney/deleteCreditCard", creditcard.id);
        }
      );
      this.updateUser();
    },

    addApiWallet() {
      this.$store.dispatch("apimoney/createApiWallet", {
        account_id: this.activeAccount.id,
        tag: this.tag,
        status: "ACTIVE",
        type: "EMONEY",
        walletCategory: "MISC",
        shared: false,
        currency: this.walletctry.currencyCode,
        countryCode: this.walletctry.countryCode2,
      });
      this.updateUser();
      setTimeout(() => {
        f7.popup.close(".addNewApiWallet");
      }, 500);
    },
    openWalletMenu(wallet) {
      const self = this;
      const buttonsList = [
        {
          text: self.$t("common.cancel"),
          color: "red",
          icon: '<i class="icon f7-icons if-not-md color-red">multiply</i><i class="icon material-icons md-only color-red">highlight_off</i>',
        },
      ];
      if (
        !wallet.main &&
        !wallet.shared &&
        wallet.balance == 0 &&
        wallet.balance_available == 0
      ) {
        buttonsList.unshift({
          text: self.$t("common.delete"),
          color: "red",
          icon: '<i class="f7-icons icon if-not-md color-red">trash_circle</i><i class="icon material-icons md-only color-red">delete</i>',
          onClick() {
            self.deleteApiWallet(wallet);
          },
        });
      }
      if (!wallet.shared) {
        buttonsList.unshift({
          text: self.$t("common.edit"),
          bold: true,
          color: "blue",
          icon: '<i class="icon f7-icons if-not-md color-blue">pencil</i><i class="icon material-icons md-only color-blue">edit</i>',
          onClick() {
            self.editApiWallet(wallet);
          },
        });
      }
      if (!wallet.main && !wallet.shared) {
        buttonsList.unshift({
          text: self.$t("common.assignOrChangeMainWallet"),
          color: "primary",
          bold: true,
          icon: '<i class="icon f7-icons if-not-md">checkmark_alt_circle</i><i class="icon material-icons md-only">check_circle_outline</i>',
          onClick() {
            f7.dialog.confirm(
              self.$t("common.doYouWantToAssignOrChangeMainWallet", {
                walletTag: `${wallet.id} ${wallet.tag}`,
                acccountTag: self.activeAccount.tag,
              }),
              () => {
                self.$store.dispatch("account/setAccounMaintWallet", {
                  walletId: wallet.id,
                  accountId: self.activeAccount.id,
                });
              }
            );
            self.updateUser();
          },
        });
      }
      if (!wallet.shared && wallet.balance_available > 0) {
        buttonsList.unshift({
          text: self.$t("common.tranfer"),
          bold: true,
          color: "blue",
          icon: '<i class="icon f7-icons if-not-md">arrow_up_right_circle</i><i class="icon material-icons md-only">send</i>',
          onClick() {
            self.transferFrom(wallet);
          },
        });
      }
      buttonsList.unshift(
        {
          text: self.$t("common.select"),
          label: true,
        },

        {
          text: wallet.shared
            ? self.$t("common.contribute")
            : self.$t("common.topUp"),
          bold: true,
          color: "primary",
          icon: '<i class="icon f7-icons if-not-md color-primary">wallet</i><i class="icon material-icons md-only color-primary">wallet</i>',
          onClick() {
            self.selectProjectToContribute(wallet);
          },
        }
      );

      // if (!self.openWalletMenuToPopover) {
      self.openWalletMenuToPopover = f7.actions.create({
        convertToPopover: true,

        buttons: buttonsList,
        targetEl: self.$el.querySelector(`#wallet_${wallet.id}`),
      });
      // }
      self.openWalletMenuToPopover.open();
    },
    editApiWallet(wallet) {
      f7.dialog.prompt(
        this.$t("common.updateWallet"),
        (tag) => {
          console.log("tag", tag);
          this.$store.dispatch("apimoney/editApiWallet", {
            apiWallet: {
              id: wallet.id,
              tag,
            },
            walletId: wallet.id,
          });
        },
        () => {
          return;
        },
        wallet.tag
      );
      this.updateUser();
    },

    deleteApiWallet(wallet) {
      f7.dialog.confirm(
        this.$t("common.areYouSureToDeleteWallet", {
          currentWallet: `${wallet.id} ${wallet.tag}`,
        }),
        () => {
          this.$store.dispatch("apimoney/deleteApiWallet", wallet.id);
        }
      );
      this.updateUser();
    },
    setAccountMainWallet() {
      if (
        this.currentMainWallet !== "" &&
        this.currentMainWallet !== this.mainWallet.id
      ) {
        const cur_wallet = this.accountWallets.find(
          (w) => w.id === this.currentMainWallet
        );
        console.log("object", this.currentMainWallet);
        f7.dialog.confirm(
          this.$t("common.doYouWantToAssignOrChangeMainWallet", {
            walletTag: cur_wallet.tag,
            acccountTag: this.activeAccount.tag,
          }),
          () => {
            this.$store.dispatch("account/setAccounMaintWallet", {
              walletId: this.currentMainWallet.id,
              accountId: this.activeAccount.id,
            });
          }
        );
        this.updateUser();
        return;
      }
      return;
    },
    transferFrom(wallet) {
      f7.preloader.show("multi");
      const idempotency_key = f7.utils.id(
        "xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx"
      );
      const baseTransfer = {
        amount: "",
        trxType: "TRANSFER",
        referenceId: "",
        receiver_wallet_id: "",
        sender_wallet_id: wallet.id,
        payer_account_id: wallet.account_id,
        description: "",
        countryCode: "",
        currency: wallet.currency,
        tag: "",
        partner_ref: idempotency_key,
        idempotency_key,
        return_url: `${window.location.origin}/transaction/success`,
        return_url_fail: `${window.location.origin}/transaction/failure`,
      };
      this.$store.dispatch("account/loadUser", {
        walletId: wallet.id,
        type: "walletId",
      });
      this.$store.dispatch("apimoney/setBaseTransfer", baseTransfer);

      const url = this.f7router.generateUrl({
        name: "paymentPage",
        props: {
          payment_detail: {
            paymentCurrency: wallet.currency,
            entity: "",
            project: wallet,
            transaction_type: "cash_out",
            tag: "",
            setAmount: this.wallet.balance,
          },
        },
        query: {
          transfer_detail: this.$encodeData.encode([
            {
              paymentCurrency: wallet.currency,
              entity: "",
              project: wallet,
              transaction_type: "cash_out",
              tag: "",
              setAmount: this.wallet.balance,
            },
            baseTransfer,
          ]),
        },
      });
      console.log("url", url);
      this.f7router.navigate(url);

      f7.preloader.hide();
    },
    topUp(wallet) {
      f7.preloader.show("multi");
      const idempotency_key = f7.utils.id(
        "xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx"
      );
      const baseTransfer = {
        amount: this.amount,
        trxType: this.trxType,
        referenceId: this.referenceId,
        receiver_wallet_id: wallet.id,
        sender_wallet_id: this.$keycloak.authenticated
          ? this.userAccount.standardWallet.id
          : "",
        payer_account_id: wallet.account_id,
        description: this.description === "" ? wallet.tag : this.description,
        tag: this.tag === "" ? wallet.tag : this.tag,
        countryCode: "",
        currency: wallet.currency,
        partner_ref: idempotency_key,
        idempotency_key,
        return_url: `${window.location.origin}/transaction/success`,
        return_url_fail: `${window.location.origin}/transaction/failure`,
      };
      this.$store.dispatch("account/loadUser", {
        walletId: wallet.id,
        type: "walletId",
      });

      const url = this.f7router.generateUrl({
        name: "paymentPage",
        props: {
          payment_detail: {
            paymentCurrency: wallet.currency,
            entity: "",
            project: wallet,
            transaction_type: "cash_in",
            tag: "",
            setAmount: this.amount,
          },
        },
        query: {
          transfer_detail: this.$encodeData.encode([
            {
              paymentCurrency: wallet.currency,
              entity: "",
              project: wallet,
              transaction_type: "cash_in",
              tag: "",
              setAmount: this.amount,
            },
            baseTransfer,
          ]),
        },
      });
      console.log("url", url);
      this.f7router.navigate(url);
      f7.preloader.hide();
    },

    async selectProjectToContribute(wallet) {
      const self = this;
      let projectList = [];
      let entityName;
      // let project;
      if (wallet.shared) {
        const project = this.getSharedApiWalletRefType(wallet);
        // await this.getSharedApiWalletRefType(wallet).then((proj) => {
        //   project = proj;
        // });
        // setTimeout(() => {
        console.log("this.getSharedApiWalletRefType(wallet)", project);
        if (project) {
          projectList = project.projectList;
          this.trxType = project.trxType;
          this.entity = project.entity;
          entityName = this.entity.name;
          const buttonsList = [
            {
              text: self.$t("common.cancel"),
              color: "red",
            },
          ];

          for (const campaign of projectList.slice()) {
            let name;
            let number;
            if (this.trxType === "SAVB") {
              name = campaign.name;
              number = campaign.battleNumber;
              this.tag = `${name} - ${this.entity.name}`;
              this.description = name;
              this.amount = campaign.contribAmount;
            } else if (this.trxType === "TONT") {
              name = campaign.name;
              number = campaign.roundNumber;
              this.tag = `${name} - ${this.entity.name}`;
              this.description = name;
              this.amount = campaign.tontineRoundAmount;
            } else if (this.trxType === "GBUY") {
              name = campaign.name;
              number = campaign.projectNumber;
              this.tag = `${name} - ${this.entity.name}`;
              this.description = name;
              this.amount = campaign.contribAmount;
            } else {
              name = campaign.name;
              number = campaign.campaignNumber;
              this.tag = `${name} - ${this.entity.name}`;
              this.description = name;
              this.amount =
                this.trxType === "ASSO"
                  ? campaign.campaignAmount
                  : campaign.fundingTarget;
            }

            buttonsList.unshift({
              text:
                name +
                " " +
                ' <span class="badge color-auto">' +
                number +
                "</span>",
              onClick() {
                self.project = campaign;
                this.referenceId = campaign.id;
                setTimeout(() => {
                  self.topUp(wallet);
                }, 300);
              },
            });
          }
          if (projectList && projectList.length == 1) {
            this.referenceId = projectList[0].id;
            this.topUp(wallet);
          } else {
            buttonsList.unshift(
              {
                text: entityName,
                label: true,
              },
              {
                text: this.$t("common.select"),
                color: "auto",
                bold: true,
              }
            );
            // if (!self.contribToPopover) {
            self.contribToPopover = f7.actions.create({
              convertToPopover: true,

              buttons: buttonsList,
              targetEl: self.$el.querySelector(`#wallet_${wallet.id}`),
            });
            // }
            await self.contribToPopover.open();
          }
        } else {
          this.tag = wallet.tag;
          this.trxType === wallet.walletCategory;
          this.topUp(wallet);
        }
      } else {
        this.topUp(wallet);
      }

      // }, 3000);
      // });
    },

    getSharedApiWalletRefType(wallet) {
      const walletId = wallet.id;
      if (!wallet.shared) {
        this.topUp(wallet);
      }
      this.getUserGroups(wallet.walletCategory);
      setTimeout(() => {
        const walletLists = [
          {
            list: this.publicWalletList,
            type: wallet.walletCategory,
            key: "campaignList",
          },
          {
            list: this.privateWalletList,
            type: wallet.walletCategory,
            key: "campaignList",
          },
          {
            list: this.associationList,
            type: "ASSO",
            key: "associationCampaignList",
          },
          {
            list: this.tontineList,
            type: wallet.walletCategory,
            key: "tontineRoundList",
          },
          {
            list: this.groupBuyList,
            type: wallet.walletCategory,
            key: "projectList",
          },
        ];

        for (const walletList of walletLists) {
          const found = _.find(walletList.list, { walletId });
          if (found) {
            return {
              entity: found,
              projectList: found[walletList.key],
              trxType: walletList.type,
            };
          }
        }

        for (saving in this.savings) {
          for (batle in saving.battleList) {
            return {
              projectList: _.find(battle.participantList, { walletId }),
              trxType: wallet.walletCategory,
              entity: {},
            };
          }
        }
      }, 500);
    },

    async getUserGroups(category) {
      switch (category) {
        case "ASSO":
          if (!this.associationList) {
            await this.$store.dispatch("asso/getUserAssociationsByuserId", {
              maxNumberOfItem: this.maxNumberOfItem,
            });
          }

          break;
        case "WALL":
          if (!this.privateWalletList) {
            await this.$store.dispatch("wallet/getPrivateWalletList", {
              maxNumberOfItem: this.maxNumberOfItem,
              userId: this.profile.sub,
            });
          }
          if (!this.publicWalletList) {
            this.$store.dispatch(
              "wallet/getPublicWalletList",
              this.visibleItem
            );
          }
          break;
        case "TONT":
          if (!this.tontineList) {
            await this.$store.dispatch("tontine/getUserTontinesByuserId", {
              maxNumberOfItem: this.maxNumberOfItem,
              userId: this.profile.sub,
            });
          }
          break;

        case "GBUY":
          if (!this.groupBuyList) {
            await this.$store.dispatch("groupBuy/getUserGroupBuyList", {
              maxNumberOfItem: this.maxNumberOfItem,
              userId: this.profile.sub,
            });
          }
          break;
        case "SAVB":
          if (!this.savings) {
            await this.$store.dispatch("savings/getUserSavingsGroupList", {
              maxNumberOfItem: this.maxNumberOfItem,
              userId: this.profile.sub,
            });
          }

        default:
          break;
      }
    },
  },
  computed: {
    ...mapState("account", ["userAccount", "searchedUser", "userDetail"]),
    ...mapState("savings", ["savings"]),
    ...mapState("groupBuy", ["groupBuy", "groupBuyList"]),
    ...mapState("wallet", ["wallet", "privateWalletList", "publicWalletList"]),
    ...mapState("tontine", ["tontine", "tontineList"]),
    ...mapState("asso", ["association", "associationList"]),
    ...mapState("location", ["country"]),
    countries() {
      return _.groupBy(this.country, "continentName");
    },
    walletCurrency() {
      const ctry = this.country.find((x) => x.countryCode2 === this.userCtry);
      return ctry.currencyCode;
    },
    walletctry() {
      return this.country.find((x) => x.countryCode2 === this.userCtry);
    },
  },
};
