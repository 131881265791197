<template>
  <f7-popup class="editImage" :tablet-fullscreen="true" :backdrop="false" @popup:close="saveEditedImage()">
    <f7-view>
      <f7-page :page-content="false">
        <f7-tabs swipeable>
          <f7-tab v-for="doc in this.documentsUrl" :key="doc.fileId" :id="'tab-1' + doc.fileId" class="page-content"
            tab-active>
            <f7-navbar no-shadow>
              <f7-nav-title>{{ doc.fileName }}</f7-nav-title>
              <f7-nav-right>
                <f7-link @popup:close="saveEditedImage()" popup-close icon-f7="multiply"></f7-link>
              </f7-nav-right>
            </f7-navbar>
            <f7-link raised :style="{
              position: 'absolute',
              right: '8px',
              top: '8px',
              color: 'red',
            }" icon-f7="multiply" @popup:close="saveEditedImage()" popup-close></f7-link>
            <imageCropper :imageSrc="doc" :isToSave="false" ref="image_cropper" />
            <f7-toolbar tabbar bottom bg-color="red" :style="{ bottom: 0, position: isTablet ? '' : 'fixed' }">
              <f7-link raised fill class="link buttons" large ripple @click="saveEditedImage()" strong
                text-color="white">{{ $t("editdocs.saveDocument") }}</f7-link>
            </f7-toolbar>
          </f7-tab>
        </f7-tabs>
      </f7-page>
    </f7-view>
  </f7-popup>
</template>
<style scoped lang="scss"></style>
<script>
import {
  f7Popup,
  f7Page,
  f7Link,
  f7Button,
  f7Toolbar,
  f7Icon,
  f7,
  theme,
} from "framework7-vue";
import imageCropper from "./imageCropper.vue";
import { attachDocs } from "../js/mixins/attachDocs.js";
import { mapGetters, mapState } from "vuex";
import _ from "lodash";
export default {
  props: {
    f7route: Object,
    f7router: Object,
  },
  components: { f7Popup, f7Page, f7Link, f7Button, f7Toolbar, f7Icon, imageCropper },
  mixins: [attachDocs],

  data() {
    return {
      theme,
      f7,
      image: null,
    };
  },
  mounted() { },

  methods: {
    saveEditedImage() {
      this.$refs.image_cropper.saveCroppedImage();
      f7.popup.close(".editImage");
    },
  },

  computed: {
    ...mapState("account", ["primaryAccount", "businessAccount"]),
    ...mapGetters("auth", ["getProfile", "isAuthenticated", "isProfileLoaded"]),
    ...mapState("auth", ["profile", "documentsUrl", "document", "isTablet"]),
  },
};
</script>
