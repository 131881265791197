<template>
  <f7-page hide-bars-on-scroll :page-content="false" @page:init="onPageInit" @page:beforeremove="onPageBeforeRemove">
    <f7-navbar sliding no-shadow>
      <f7-nav-left :back-link="$t('common.backButton')" :back-link-show-text="false"></f7-nav-left>
      <f7-nav-title>{{
        $t("shopproductaddnew.listNewProduct", { shopname: this.shopTypeName })
      }}</f7-nav-title>
      <f7-nav-right>
        <navbarright></navbarright>
      </f7-nav-right>
    </f7-navbar>
    <f7-tabs animated>
      <f7-tab id="selectStoreType" class="page-content" tab-active v-if="this.f7route.params.shopId === 'select'">
        <div class="layout_content">
          <div class="left_layout"></div>
          <div class="main_layout">
            <f7-block-title large>{{
              $t("shopproductaddnew.listProductIn")
            }}</f7-block-title>
            <f7-list dividers-ios strong-ios media-list>
              <f7-list-group>
                <span class="cols50_50">
                  <f7-list-item v-if="this.shopList && this.shopList.length == 1 && this.shop" radio radio-icon="start"
                    name="myShop" @click="goToProductDetails()" :title="this.shop.name" :value="this.shop.id"
                    :checked="selectedShopId === this.selectedShop.id" @change="(e) => {
                        if (e.target.checked) selectedShopId = this.selectedShop.id;
                      }
                      ">
                    <img slot="media" v-if="!!this.shop.logo" :src="`data:image/png;base64,${this.shop.logo}`"
                      width="24" />
                  </f7-list-item>
                  <f7-list-item v-else-if="this.shopList && this.shopList.length > 1" smart-select :smart-select-params="{
                    openIn: this.isTablet == true ? 'popover' : 'sheet',
                    closeOnSelect: true,
                    sheetSwipeToClose: true,
                    searchbar:
                      this.shopList && this.shopList.length > 10 ? true : false,
                    searchbarPlaceholder: 'Search shop',
                  }" :smartselect:close="goToProductDetails()" :title="$t('shopproductaddnew.selectAShop')"
                    class="selectedShop item-label">
                    <select name="shop" v-model="selectedShopId">
                      <optgroup v-for="(shopGroup, ownership, index) in this.shopGroupList" :label="ownership"
                        :key="index">
                        <option v-for="(shopItem, index) in shopGroup" :value="shopItem.id" :key="index"
                          :data-option-image="`data:image/png;base64,${shopItem.logo}`" width="24">
                          {{ shopItem.name }}
                        </option>
                      </optgroup>
                      <optgroup :label="$t('shopproductaddnew.createMyShop')">
                        <option :value="'shopNew'">
                          {{ $t("shopproductaddnew.createMyShop") }}
                        </option>
                      </optgroup>
                    </select>
                  </f7-list-item>

                  <f7-list-item v-else link="/shop/new" :title="$t('shopproductaddnew.createMyShop')"></f7-list-item>

                  <f7-list-item radio radio-icon="start" @click="goToProductDetails()"
                    :title="$t('shopproductaddnew.marketPlace')" name="avocashMkt" value="avocashMkt"
                    :checked="selectedShopId === 'avocashMkt'" @change="(e) => {
                        if (e.target.checked) selectedShopId = 'avocashMkt';
                      }
                      "></f7-list-item>
                </span>
              </f7-list-group>
            </f7-list>
          </div>
          <div class="right_layout"></div>
        </div>
      </f7-tab>
      <f7-tab id="productDetails" class="page-content">
        <div class="layout_content">
          <div class="left_layout"></div>
          <div class="main_layout">
            <f7-block-title medium v-if="this.selectedShopId !== ''">{{
              $t("shopproductaddnew.addProductDetails")
            }}</f7-block-title>
            <f7-list dividers-ios strong-ios media-list form-store-data id="shopProductAdd"
              v-if="this.selectedShopId !== ''">
              <f7-list-group>
                <f7-list-item v-if="this.shopCategories && this.shopCategories.length >= 1"
                  class="productCategory item-label" smart-select :smart-select-params="{
                    openIn: 'popup',
                    popupSwipeToClose: true,
                    closeOnSelect: true,
                    searchbar: true,
                    searchbarPlaceholder: $t('shopproductaddnew.findProductCategory'),
                  }" :title="$t('shopproductaddnew.productCategory')">
                  <select name="category" v-model="productCategory">
                    <optgroup v-for="category in this.shopCategories" :label="category.name" :key="category.id">
                      <option smartselect v-for="(subCategory, index) in category.subCategoryList" :value="subCategory.id"
                        :key="index">
                        {{ subCategory.name }}
                      </option>
                    </optgroup>
                  </select>
                </f7-list-item>
                <f7-list-input :label="$t('common.name')" floating-label resizable name="name" :value="name"
                  :error-message="$t('common.nameInfo')" :info="$t('common.nameInfo', {
                    charLeft: 80 - this.name.length,
                  })
                    " required validate maxlength="80" minlength="10" type="textarea"
                  @input="name = $event.target.value">
                </f7-list-input>
                <f7-list-input :label="$t('shopproductaddnew.sku')" floating-label type="text" name="sku" required
                  validate :placeholder="$t('shopproductaddnew.sku')" :value="sku || this.sku"
                  @input="sku = $event.target.value" :error-message="$t('shopproductaddnew.skuInfo')"
                  :info="$t('shopproductaddnew.skuInfo')" clear-button></f7-list-input>
                <f7-block-title class="item-header">{{ $t("common.description") }}<br />{{
                  $t("shopproductaddnew.productDescriptionInfo")
                }}</f7-block-title>
                <f7-text-editor :placeholder="$t('common.description')" :value="description" name="description"
                  type="texteditor" @texteditor:change="(v) => (description = v)" resizable validate :style="f7.device.cordova || !this.isTablet
                      ? '--f7-text-editor-height: 150px'
                      : ''
                    " />
                <f7-block-title class="item-header">{{
                  $t("shopproductaddnew.productTagInfo")
                }}</f7-block-title>
                <f7-text-editor :placeholder="$t('shopproductaddnew.productTag')" :value="productTag" name="productTag"
                  @texteditor:change="(v) => (productTag = v)" resizable required validate type="texteditor" :style="f7.device.cordova || !this.isTablet
                      ? '--f7-text-editor-height: 150px'
                      : ''
                    " />
                <f7-list-item v-if="this.selectedShop && this.selectedShopId === this.selectedShop.id" checkbox
                  :title="$t('shopproductaddnew.myProductHasVariants')" name="hasVariant" :checked="hasVariant"
                  @change="hasVariant = $event.target.checked"
                  :footer="$t('shopproductaddnew.myProductHasVariantsInfo')"></f7-list-item>
              </f7-list-group>
            </f7-list>
            <!-- <f7-button
          v-if="
            this.selectedShop && this.selectedShopId === this.selectedShop.id
          "
          raised
          fill
          ripple
          large
          color="red"
          class="margin"
          @click="shopProductWithVariantGoToImages()"
          >{{ $t("shopproductaddnew.shopWithVariant") }}</f7-button
        > -->
            <!-- <f7-button
          v-if="
            this.selectedShop && this.selectedShopId === this.selectedShop.id
          "
          raised
          fill
          ripple
          large
          color="red"
          class="margin"
          @click="shopProductNoVariantQtyPrice()"
          >{{ $t("shopproductaddnew.shopNoVar") }}</f7-button
        > -->
            <f7-button raised fill ripple large color="red" class="margin" @click="goToQtyPrice()">{{
              $t("shopproductaddnew.goToQtyPrice") }}</f7-button>
          </div>
          <div class="right_layout"></div>
        </div>
      </f7-tab>

      <f7-tab id="productQtyPrice" class="page-content">
        <div class="layout_content">
          <div class="left_layout"></div>
          <div class="main_layout">
            <f7-block-title large>{{
              $t("shopproductaddnew.addProductQuantity")
            }}</f7-block-title>
            <span v-if="hasVariant === false">
              <f7-list dividers-ios strong-ios media-list form-store-data id="shopProductQtyPrice">
                <f7-list-group>
                  <f7-list-input :label="$t('shopproductaddnew.quantityInStock')" floating-label type="number" min="1"
                    pattern="[0-9]*" name="inventory" :placeholder="$t('shopproductaddnew.quantityInStock')"
                    :value="inventory" @input="inventory = $event.target.value"
                    :error-message="$t('shopproductaddnew.quantityInStockInfo')"
                    :info="$t('shopproductaddnew.quantityInStockInfo')" required validate clear-button></f7-list-input>
                  <li class="item-content item-input">
                    <div class="item-media">
                      {{ this.paymentCurrency }}
                    </div>
                    <div class="item-inner">
                      <div v-if="this.negotiable === true" class="item-title item-label">
                        {{ $t("shopproductaddnew.displayedPrice") }}
                      </div>
                      <div v-else class="item-title item-label">
                        {{ $t("shopproductaddnew.productPrice") }}
                      </div>
                      <div class="item-input-wrap">
                        <input type="number" name="productPrice" min="0" :value="productPrice"
                          @input="productPrice = $event.target.value" @change="updateProductPrice()" pattern="[0-9]*"
                          required validate data-error-message="$t('shopproductaddnew.productPriceInfo')" />
                        <div v-if="!this.productPrice && this.negotiable === false" class="item-input-info">
                          {{ $t("shopproductaddnew.pleaseEnterThe") }}
                        </div>
                        <div v-if="this.negotiable === true" class="item-input-info">
                          {{ $t("shopproductaddnew.pleaseEnterThe") }}
                        </div>
                        <span class="input-clear-button"></span>
                      </div>
                    </div>
                  </li>
                  <span v-if="this.selectedShop && this.selectedShopId === this.selectedShop.id
                    ">
                    <f7-list-item checkbox :title="$t('shopproductaddnew.myProductIs')" name="On sale" :checked="onSale"
                      @change="onSale = $event.target.checked"
                      :footer="$t('shopproductaddnew.myProductIsOnSale')"></f7-list-item>
                    <span v-if="this.onSale === true">
                      <f7-list-input :label="$t('shopproductaddnew.salePeriod')" type="datepicker"
                        :placeholder="$t('shopproductaddnew.salePeriod')" :value="dateOnSale"
                        @calendar:change="(value) => (dateOnSale = value)" :calendar-params="{
                          rangePicker: true,
                          rangePickerMaxDays: 30,
                          closeOnSelect: true,
                          openIn: 'auto',
                          minDate: new Date(),
                          header: true,
                          footer: true,
                        }" :required="this.dateOnSale === true" :validate="this.dateOnSale === true"
                        :error-message="$t('shopproductaddnew.selectSalePeriod')" :info="this.salePeriod"></f7-list-input>
                      <li class="item-content item-input">
                        <div class="item-media">
                          {{ this.paymentCurrency }}
                          <div class="item-inner">
                            <div class="item-title item-label">
                              {{ $t("shopproductaddnew.salePrice") }}
                            </div>
                          </div>
                          <div class="item-input-wrap">
                            <input type="number" name="salePrice" min="0" :value="salePrice"
                              @input="salePrice = $event.target.value" pattern="[0-9]*" required validate
                              :data-error-message="$t('shopproductaddnew.enterSalePrice')" />
                            <span class="input-clear-button"></span>
                          </div>
                        </div>
                      </li>
                    </span>
                    <f7-list-item checkbox :title="$t('shopproductaddnew.makeThePriceNegotiable')" name="negotiable"
                      :checked="negotiable" @change="negotiable = $event.target.checked"
                      :footer="$t('shopproductaddnew.selectPriceNegotiable')"></f7-list-item>
                    <li class="item-content item-input" v-if="this.negotiable === true">
                      <div class="item-media">
                        {{ this.paymentCurrency }}
                      </div>
                      <div class="item-inner">
                        <div class="item-title item-label">
                          {{ $t("shopproductaddnew.minimumPrice") }}
                        </div>
                        <div class="item-input-wrap">
                          <input type="number" name="minimumPrice" min="0" :max="this.productPrice" :value="minimumPrice"
                            @input="minimumPrice = $event.target.value" pattern="[0-9]*" required validate
                            :data-error-message="$t('shopproductaddnew.setMinimumPrice')" />
                          <div v-if="this.negotiable === true" class="item-input-info">
                            {{ $t("shopproductaddnew.pleaseEnterThe") }}
                          </div>
                          <span class="input-clear-button"></span>
                        </div>
                      </div>
                    </li>
                    <f7-list-item checkbox :title="$t('shopproductaddnew.groupAndFriendsBuy')" name="isGroupBuy"
                      :checked="isGroupBuy" @change="isGroupBuy = $event.target.checked"
                      :footer="$t('shopproductaddnew.setgroupAndFriendsBuy')"></f7-list-item>
                    <span v-if="this.isGroupBuy === true">
                      <div no-gap v-for="(item, index) in this.numberOfGroup" :key="index" class="cols35_65">
                        <f7-list-input :label="'Group' + (index + 1) + ' size'" type="number" pattern="[0-9]*" min="0"
                          :name="'groupSize' + (index + 1)" :placeholder="$t('shopproductaddnew.groupSize')"
                          :value="item.groupSize || groupSize" @input="item.groupSize = $event.target.value"
                          class="no-margin-right no-padding-right" error-message="Enter group size" required validate
                          clear-button></f7-list-input>
                        <li class="item-content item-input">
                          <div class="item-inner">
                            <div class="item-title item-label">
                              {{
                                $t("shopproductaddnew.groupPrice", {
                                  size: index + 1,
                                })
                              }}
                            </div>
                            <span class="input-currency">{{ paymentCurrency }}</span>
                            <f7-link v-if="index === 0 && numberOfGroup.length < 3" class="input-icon6 padding-right-half"
                              @click="addGroupBuy()" icon-color="auto" icon-aurora="f7:plus_circle"
                              icon-ios="f7:plus_circle" icon-md="material:add_circle"></f7-link>
                            <f7-link v-if="index === 1 || index === 2" class="input-icon6 padding-right-half"
                              @click="removeGroupBuy(index)" icon-color="red" icon-ios="f7:minus_circle"
                              icon-aurora="f7:minus_circle" icon-md="material:remove_circle_outline"></f7-link>
                            <div class="item-input-wrap">
                              <input type="number" :name="'groupBuyPrice' + index + 1" class="input-currency-input"
                                :placeholder="$t('shopproductaddnew.groupPrice')" min="0"
                                :value="item.groupBuyPrice || groupBuyPrice"
                                @input="item.groupBuyPrice = $event.target.value" pattern="[0-9]*" required validate
                                :data-error-message="$t('shopproductaddnew.setGroupPrice')
                                  " />
                            </div>
                          </div>
                        </li>
                      </div>
                    </span>
                  </span>
                  <span v-if="this.selectedShopId === 'avocashMkt'">
                    <attachDoc :currentDocuments="null" :type="this.getType" :entity="null"></attachDoc>
                  </span>
                </f7-list-group>
              </f7-list>
              <!-- </f7-col>
              <f7-col width="40" medium="25">

                <f7-list-input
                  :label="$t('shopproductaddnew.perUnit')"
                  type="text"
                  min="0"
                  name="unit"
                  :placeholder="$t('shopproductaddnew.kglunitm')"
                  :value="unit"
                  @input="unit = $event.target.value"
                  error-message="Enter unit"
                  required
                  validate
                  clear-button
                ></f7-list-input>
              </f7-col>
                  </f7-row>-->

              <!-- </f7-list-group>
            </f7-list>
          </f7-col>
          <f7-col width="0" medium="25" class="if-not-aurora"></f7-col>
        </f7-row>
      </f7-tab>
      <f7-tab id="productDescripPhotos" class="page-content">
        <f7-row no-gap class="margin-bottom">
          <f7-col width="0" medium="25"></f7-col>
          <f7-col width="100" medium="50">
            <f7-list dividers-ios strong-ios media-list>
                  <f7-list-group>-->
            </span>
            <f7-button v-if="this.selectedShopId !== 'avocashMkt'" raised fill ripple large color="red"
              class="margin buttons" @click="addProductImages()">{{ $t("shopproductaddnew.addProductImages")
              }}</f7-button>

            <f7-button v-else raised fill ripple large color="red" class="margin buttons" @click="saveProduct()">{{
              $t("shopproductaddnew.noVarListProduct") }}</f7-button>
          </div>
          <div class="right_layout"></div>
        </div>
      </f7-tab>
      <f7-tab id="addProductImages" class="page-content" v-if="this.f7route.params.shopId !== 'select'">
        <div class="layout_content">
          <div class="left_layout"></div>
          <div class="main_layout">
            <f7-block-title large>{{
              $t("shopproductaddnew.addProductImages")
            }}</f7-block-title>

            <myDocs :type="this.getType"></myDocs>
            <f7-button v-if="this.hasVariant == true" raised fill ripple large color="red" class="margin buttons"
              @click="saveProduct()">{{ $t("shopproductaddnew.saveImgContinue") }}</f7-button>
            <f7-button v-else raised fill ripple large color="red" class="margin buttons" @click="saveProduct()">{{
              $t("shopproductaddnew.noVarListProduct") }}</f7-button>
          </div>
          <div class="right_layout"></div>
        </div>
      </f7-tab>
    </f7-tabs>
  </f7-page>
</template>
<style scoped>
.ios .input-currency {
  top: 50%;
  margin-bottom: -24px;
  padding-top: 10px;
}

.ios .input-currency-input {
  padding-left: 35px !important;
  margin-top: 24px;
}

.md .input-currency-input {
  padding-left: 40px !important;
  /* margin-top: 24px; */
}

.md .input-currency {
  margin-bottom: -24px;
  display: block;
  transform: translate(0, -50%);
  pointer-events: none;
  width: 25px;
  text-align: center;
  font-style: normal;
  position: absolute;
  padding-top: 58px;
  left: 0;
  top: calc(var(--f7-input-height) / 2);
}

/* .productImages {
  opacity: 0;
  height: 200px;
  position: absolute;
  cursor: pointer;
} */
</style>

<script>
import { theme, f7 } from "framework7-vue";
import { mapGetters, mapState } from "vuex";
import Navbarright from "../components/navbarright.vue";
import attachDoc from "../components/attachDocs.vue";
import myDocs from "../components/myDocs.vue";
import { shop } from "../js/mixins/shop.js";
import { attachDocs } from "../js/mixins/attachDocs.js";

import _ from "lodash";
export default {
  props: {
    f7route: Object,
    f7router: Object,
  },
  components: {
    navbarright: Navbarright,
    attachDoc,
  },
  mixins: [shop, attachDocs],
  data() {
    return {
      theme,
      f7,
      myShopName: "",
      selectedShopId: "",
      visibleItem: 6,
      productCategory: "",
      product: "",
      productParent: [],
      productTag: "",
      negotiable: true,
      isGroupBuy: true,
      numberOfGroup: [
        {
          groupSize: "",
          groupBuyPrice: "",
        },
      ],
      groupSize: "",
      groupBuyPrice: "",
      groupSize2: "",
      groupBuyPrice2: "",
      groupSize3: "",
      groupBuyPrice3: "",
      hasVariant: false,

      name: "",
      productSKU: "",
      productPrice: "",
      onSale: true,
      dateOnSale: [],
      dateOnSaleFrom: "",
      dateOnSaleTo: "",
      salePrice: "",
      minimumPrice: "",
      unit: "",
      productImages: [],
      inventory: "",
      description: "",
      userrecipient: "",
      recipientCountry: "",
      deliveryCities: [],
      cityId: "",
      paymentCurrency: "",
      // country:'',
      state: "",
      city: "",
      url: "",
      getType: "product",
      attachDoc,
      myDocs,
      imageURLJson: JSON.parse(window.localStorage.getItem("productImages")),
      f7formproductAdd: JSON.parse(window.localStorage.getItem("f7form-productAdd")),
    };
  },
  mounted() {
    const self = this;

    if (this.userAccount === "" && this.$keycloak.authenticated) {
      self.$store.dispatch("account/loadAccount");
    }
    self.$store.dispatch("product/getOptionNames");
    this.paymentCurrency = this.profile.currencyCode;
    f7.$(".productCategory .smart-select .item-after").html("Select my product category");
    f7.$(".deleveryCities .smart-select .item-after").html(
      "Select my product delivery areas"
    );
    if (this.isTablet == false) {
      f7.$("div.page").addClass("no-toolbar");
    }
    self.$store.dispatch("shop/getUserShopList", this.visibleItem);
    if (this.f7route.params.shopId === "noShop") {
      this.selectedShopId = "avocashMkt";
    }

    if (this.shop && this.shopList.length == 1) {
      this.selectedShopId = this.shop.id;
    } else if (this.shop && this.shopList.length > 1) {
      this.selectedShopId = this.shop.id;
      f7.$(".selectedShop .smart-select .item-after").html(this.shop.shopName);
    }
  },
  methods: {
    goToProductDetails() {
      const self = this;

      if (this.selectedShopId === "shopNew") {
        this.f7router.navigate("/shop/new");
      } else {
        f7.tab.show("#productDetails", true);
      }
    },
    updateProductPrice() {
      const self = this;
    },
    addGroupBuy() {
      const self = this;

      if (this.numberOfGroup.length < 3) {
        this.numberOfGroup.push({
          groupSize: "",
          groupBuyPrice: "",
        });
      }
      return false;
    },
    removeGroupBuy(index) {
      const self = this;

      if (index !== 0 && index < 3) {
        this.numberOfGroup.splice(index, 1);
      }
    },

    onPageBeforeRemove() {
      const self = this;
    },

    onPageInit() {
      const self = this;
    },
    goToQtyPrice() {
      const self = this;

      if (f7.input.validateInputs("#productDetails")) {
        f7.tab.show("#productQtyPrice", true);
      }
    },
    addProductImages() {
      const self = this;

      f7.tab.show("#addProductImages", true);
    },

    async saveProduct() {
      const self = this;

      newProduct = {
        id: f7.utils.id("xxxx-xxxx-xxxx-xxxx"),
        images: this.imageUrlList,
        description: this.description,
        category: this.productCategory,
        deliveryCities: this.deliveryCities,
        inventory: this.inventory,
        minimumPrice: this.minimumPrice,
        productPrice: this.productPrice,
        name: this.name,
        negotiable: this.negotiable,
        currencyCode: this.paymentCurrency,
      };
      await self.$store.dispatch("location/getCityList");
      await this.f7router.navigate("/product/" + this.product.name);
    },
  },

  computed: {
    ...mapState("shop", ["categorylist", "categories", "shop", "userShop", "shopList"]),
    ...mapState("product", [
      // "product",
      "images",
      "optionNames",
      "shopImages",
      "allProduct",
      // "productParent",
      "productByListOwner",
      "productByShopId",
      "productByShopNumber",
      "productByCategory",
      "productBySubCategory",
      "allProductParent",
      "productParentByList",
      "productParentByListOwner",
      "productParentByShopId",
      "productParentByShopNumber",
      "productParentByCategory",
      "productParentBySubCategory",
      "productParentGroupBuyList",
      "negoPrice",
      "groupBuy",
      "userGroupBuyList",
    ]),

    ...mapState("location", [
      "destinationCity",
      "country",
      "countryStates",
      "countryCities",
      "currentUserCountry",
    ]),
    ...mapState("account", ["userAccount", "userDetail"]),
    ...mapState("auth", [
      "phoneNumberDetail",
      "mobilePhoneError",
      "profile",
      "isDeskTop",
      "isTablet",
    ]),
    ...mapState("transaction", ["destinationCountry", "originCountry"]),

    ...mapGetters("auth", [
      "getProfile",
      "isAuthenticated",
      "isProfileLoaded",
      "getusererror",
    ]),
    ...mapGetters("transaction", [
      "getusererror",
      "fromCurrencyRate",
      "toCurrencyRate",
      "grossexchangerate2",
    ]),

    ...mapGetters("remit", ["recipients"]),
    ...mapGetters("account", ["userByAccountNumber"]),

    destinationCtry() {
      return _.groupBy(this.destinationCountry, "toContinentName");
    },
    category() {
      return _.groupBy(this.categories, "categoryName");
    },
    sku() {
      return this.name.split(" ").slice(0, 3).join("-");
    },

    salePeriod() {
      if (this.dateOnSale.length > 1) {
        return (
          (new Date(this.dateOnSale[1]) - this.dateOnSale[0].getTime()) /
          (1000 * 3600 * 24) +
          " days"
        );
      }
    },
    selectedShop() {
      if (this.shop) {
        return this.shop;
      } else if (this.shopList && this.shopList.length >= 1 && !this.shop) {
        return this.shopList.find((s) => s.id === this.selectedShopId);
      }
    },
    shopCategories() {
      if (this.selectedShopId !== "avocashMkt" && this.selectedShop) {
        console.log(this.selectedShop);
        return _.filter(this.categories, (o) => o.id === this.selectedShop.categoryId);
      } else {
        return this.categories;
      }
    },
    shopTypeName() {
      const self = this;

      if (this.selectedShopId === "avocashMkt") {
        return self.$t("shopproductaddnew.marketPlace");
      } else if (this.shop && this.selectedShop) {
        return this.shop.shopName;
      }
    },
  },
};
</script>
