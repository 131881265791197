import { f7, theme } from "framework7-vue";
import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { onBackgroundMessage } from "firebase/messaging/sw";
import { mapState } from "vuex";
// import { getAnalytics } from "firebase/analytics";
import FingerprintJS from "@fingerprintjs/fingerprintjs";
import _ from "lodash";
export const firebaseFn = {
  data() {
    return {
      deviceId: "",
      appId: "",
      myMessaging: "",
      traceName: "avocash-app_trace",
      firebaseConfig: {
        apiKey: "AIzaSyB3LpwOFmWCEPfwoFuRmmdPuHBYDYH1mp0",
        authDomain: "avocash-app.firebaseapp.com",
        projectId: "avocash-app",
        storageBucket: "avocash-app.appspot.com",
        messagingSenderId: "692788665353",
        appId: "1:692788665353:web:0fa04008c780da55902cd8",
        measurementId: "G-MFY1LVWT4Y",
      },
      FirebasePlugin: "",
      serviceWorkerRegistration: "",
    };
  },
  computed: {
    ...mapState("auth", [
      "video",
      "isDeskTop",
      "isTablet",
      "device",
      "authenticated",
      "profile",
    ]),
    ...mapState("location", ["ipAddress"]),
    ...mapState("account", ["userAccount"]),
  },

  mounted() {
    if ("serviceWorker" in window.navigator && !f7.device.cordova) {
      window.addEventListener("load", () => {
        this.serviceWorkerRegistration = navigator.serviceWorker.register(
          "/firebase-messaging-sw.js"
        );
        // navigator.serviceWorker.getRegistration().then((reg) => {
        //   console.log("current registered service worker", reg);
        //   self.serviceWorkerRegistration = reg;
        // });
        navigator.serviceWorker.ready;
      });
      this.getDeviceToken();
    }
    document.addEventListener("deviceready", this.onDeviceReady, false);

    const firebaseApp = initializeApp(this.firebaseConfig);
    const messaging = getMessaging(firebaseApp);
    // const analytics = getAnalytics(app);
    this.myMessaging = messaging;
    console.log(this.myMessaging);
    console.log(navigator.userAgentData);
    console.log(navigator.userAgent);

    if (window.Notification && !f7.device.cordova) {
      this.requestPermission();
    }

    const fpPromise = FingerprintJS.load();

    (async () => {
      // Get the visitor identifier when you need it.
      const fp = await fpPromise;
      const result = await fp.get();
      console.log(result.visitorId);
      this.deviceId = result.visitorId;
    })();
    self.addEventListener("notificationclick", (event) => {
      event.notification.close(); // CLosing the notification when clicked
      const urlToOpen =
        event?.notification?.data?.link || window.location.origin;
      // Open the URL in the default browser.
      event.waitUntil(
        clients
          .matchAll({
            type: "window",
          })
          .then((windowClients) => {
            // Check if there is already a window/tab open with the target URL
            for (const client of windowClients) {
              if (client.url === urlToOpen && "focus" in client) {
                return client.focus();
              }
            }
            // If not, open a new window/tab with the target URL
            if (clients.openWindow) {
              return clients.openWindow(urlToOpen);
            }
          })
      );
    });
    window.addEventListener("notificationclose", (event) => {
      const closedNotification = event.notification;
      const notificationData = closedNotification.data; // If you stored data when creating the notification

      // You can perform additional actions or logging here
      console.log("Notification was closed:", notificationData);
    });
  },
  methods: {
    requestPermission() {
      console.log("Requesting permission...");
      Notification.requestPermission().then((permission) => {
        if (permission === "granted") {
          console.log("Notification permission granted.");
          // TODO(developer): Retrieve a registration token for use with FCM.
          // In many cases once an app has been granted notification permission,
          // it should update its UI reflecting this.
          this.receiveMessage();
        } else {
          console.log("Unable to get permission to notify.");
        }
      });
    },

    getDeviceToken() {
      const firebaseApp = initializeApp(this.firebaseConfig);
      const messaging = getMessaging(firebaseApp);
      console.log("messaging", messaging);
      if (f7.device.cordova) {
        this.getCordovaDeviceToken();
      } else {
        getToken(messaging, {
          vapidKey: import.meta.env.VITE_FIREBASE_FCM_VAPIDKEY,
          serviceWorkerRegistration: this.serviceWorkerRegistration,
        })
          .then((currentToken) => {
            console.log("currentToken", currentToken);
            if (currentToken) {
              this.registerToken(currentToken);
            } else {
              // Show permission request.
              console.log(
                "No registration token available. Request permission to generate one."
              );
              // Show permission UI.
              this.requestPermission();
            }
          })
          .catch((err) => {
            console.log("An error occurred while retrieving token. ", err);
          });
      }
    },
    registerToken(token) {
      setTimeout(() => {
        console.log("token", token);
        if (!this.$keycloak.authenticated && !this.userAccount) {
          return;
        }
        const deviceToken = {
          userId:
            this.userAccount.userId ||
            this.isPerformanceCollectionEnabled.sub ||
            this.$keycloak.subject,
          userAgent: navigator.userAgent,
          // appId: this.appId,
          token: token,
          deviceName: f7.device.cordova
            ? `${device.manufacturer} ${device.model}`
            : `${navigator.userAgentData.platform} ${navigator.userAgentData.brands[0].brand}`,
          devicedId: f7.device.cordova ? device.uuid : this.deviceId,
          deviceType: f7.device.cordova
            ? `${device.manufacturer} ${device.platform}`
            : navigator.userAgentData.platform,
          ipAddress: this.ipAddress,
          registerTime: Date.now(),
        };
        console.log("deviceToken", deviceToken);
        if (this.$keycloak.authenticated) {
          this.$store.dispatch("notification/registerDeviceToken", deviceToken);
        }
      }, 5000);
    },
    // Notification support

    receiveMessage() {
      if ("serviceWorker" in window.navigator && !f7.device.cordova) {
        const firebaseApp = initializeApp(this.firebaseConfig);
        const messaging = getMessaging(firebaseApp);
        onMessage(messaging, (payload) => {
          console.log("Message received. ", payload);

          const webpushData = payload.data.webpush ? payload.data.webpush : {};
          const notificationTitle = payload.notification.title;
          const notificationOptions = {
            body: payload.notification.body,
            icon: payload.notification.icon,
            image: payload.notification.image,
          };
          navigator.serviceWorker.getRegistration().then((registration) => {
            registration.showNotification(
              notificationTitle,
              notificationOptions
            );
          });

          // const sentTime = payload.data.sent_time;
          // const d = new Date();
          // console.log("sentTime", d, this.$dayjs(d), this.$dayjs(d).fromNow());
          // const link =
          //   webpushData &&
          //   webpushData.fcm_options &&
          //   webpushData.fcm_options.link
          //     ? webpushData.fcm_options.link
          //     : "";
          // const FCMnotification = f7.notification.create({
          //   title:
          //     "<span class='avocash primary' style='text-transform:lowercase;'>avo¢ash</span>",
          //   subtitle: payload.notification.title,
          //   icon: payload.notification.icon,
          //   titleRightText: this.$dayjs(d).fromNow(),
          //   text: payload.notification.body,
          //   // closeButton: true,
          //   closeTimeout: 3000,
          //   closeOnClick: true,
          //   on: {
          //     close() {
          //       if (link === "") {
          //         return;
          //       }
          //       f7.views.main.router.navigate(link);
          //     },
          //   },
          // });
          // FCMnotification.open();
          // Update the UI to include the received message.
          // appendMessage(payload);
        });
        // onBackgroundMessage(messaging, (payload) => {
        //   console.log("Message received. ", payload);

        //   const webpushData = payload.data.webpush ? payload.data.webpush : {};
        //   const notificationTitle = payload.notification.title;
        //   const notificationOptions = {
        //     body: payload.notification.body,
        //     icon: payload.notification.icon,
        //     image: payload.notification.image,
        //   };
        //   navigator.serviceWorker.getRegistration().then((registration) => {
        //     registration.showNotification(
        //       notificationTitle,
        //       notificationOptions
        //     );
        //   });
        // });
      }
    },

    onDeviceReady() {
      //  window.FirebasePlugin = window.FirebasePlugin;
      console.log("window.FirebasePlugin", window.FirebasePlugin);
      // console.log("device", device);
      console.log("cordova this.device", this.device);

      window.FirebasePlugin.hasPermission((hasPermission) => {
        if (hasPermission) {
          this.getCordovaDeviceToken();
          console.log("Permission already granted");
        } else {
          window.FirebasePlugin.grantPermission((permissionGranted) => {
            if (permissionGranted) {
              console.log("Permission granted");
              this.getCordovaDeviceToken();
            } else {
              console.warn("Permission denied");
            }
          });
        }
      });
      // Set global error handler to catch uncaught JS exceptions
      // const appRootURL = window.location.href.replace("index.html", "");
      // window.onerror = (errorMsg, url, line, col, error) => {
      //   let logMessage = errorMsg;
      //   let stackTrace = null;

      //   const sendError = () => {
      //     FirebasePlugin.logError(
      //       logMessage,
      //       stackTrace,
      //       function () {
      //         log("Sent JS exception trace");
      //       },
      //       (error) => {
      //         logError("Failed to send JS exception trace", error);
      //       }
      //     );
      //   };

      //   logMessage += `: url=${url.replace(
      //     appRootURL,
      //     ""
      //   )}; line=${line}; col=${col}`;

      //   if (error && typeof error === "object") {
      //     StackTrace.fromError(error).then((trace) => {
      //       stackTrace = trace;
      //       sendError();
      //     });
      //   } else {
      //     sendError();
      //   }
      // };

      //Register handlers
      window.FirebasePlugin.onMessageReceived(
        (message) => {
          try {
            console.log("onMessageReceived", message);
            console.dir(message);
            if (message.messageType === "notification") {
              this.handleNotificationMessage(message);
            } else {
              this.handleDataMessage(message);
            }
          } catch (e) {
            console.error(
              `Exception in onMessageReceived callback: ${e.message}`
            );
          }
        },
        (error) => {
          console.error("Failed receiving FirebasePlugin message", error);
        }
      );

      window.FirebasePlugin.onTokenRefresh(
        (token) => {
          console.log(`Token refreshed: ${token}`);
          this.registerToken(token);
        },
        (error) => {
          console.error("Failed to refresh token", error);
        }
      );

      // cordova.plugin.customfcmreceiver.registerReceiver((message) => {
      //   console.log(`Received custom message: ${message}`);
      // });

      this.checkNotificationPermission(false); // Check permission then get token

      // this.checkAutoInit(false); // Check if autoInit is enabled then get token
      this.isAnalyticsCollectionEnabled(false);
      this.isPerformanceCollectionEnabled(false);
      this.isCrashlyticsCollectionEnabled(false);
      //   isUserSignedIn();

      // Platform-specific

      if (cordova.platformId === "android") {
        this.initAndroid();
      } else if (cordova.platformId === "ios") {
        this.initIos();
      }
    },

    initIos() {
      window.FirebasePlugin.onApnsTokenReceived(
        (token) => {
          console.log(`APNS token received: ${token}`);
          this.registerToken(token);
        },
        (error) => {
          console.error("Failed to receive APNS token", error);
        }
      );

      window.FirebasePlugin.registerInstallationIdChangeListener(
        (installationId) => {
          console.log(`Installation ID changed - new ID: ${installationId}`);
        }
      );
      window.FirebasePlugin.registerApplicationDidBecomeActiveListener(() => {
        log("Application did become active");
      });

      window.FirebasePlugin.registerApplicationDidEnterBackgroundListener(
        () => {
          log("Application did enter background");
        }
      );
    },
    initAndroid() {
      // Define custom  channel - all keys are except 'id' are optional.
      const customChannel = {
        // channel ID - must be unique per app package
        id: "my_channel_id",

        // Channel name. Default: empty string
        name: "My channel name",

        //The sound to play once a push comes. Default value: 'default'
        //Values allowed:
        //'default' - plays the default notification sound
        //'ringtone' - plays the currently set ringtone
        //filename - the filename of the sound file located in '/res/raw' without file extension (mysound.mp3 -> mysound)
        sound: "blackberry",

        //Vibrate on new notification. Default value: true
        //Possible values:
        //Boolean - vibrate or not
        //Array - vibration pattern - e.g. [500, 200, 500] - milliseconds vibrate, milliseconds pause, vibrate, pause, etc.
        vibration: [300, 200, 300],

        // Whether to blink the LED
        light: true,

        //LED color in ARGB format - this example BLUE color. If set to -1, light color will be default. Default value: -1.
        lightColor: "0xFF0000FF",

        //Importance - integer from 0 to 4. Default value: 3
        //0 - none - no sound, does not show in the shade
        //1 - min - no sound, only shows in the shade, below the fold
        //2 - low - no sound, shows in the shade, and potentially in the status bar
        //3 - default - shows everywhere, makes noise, but does not visually intrude
        //4 - high - shows everywhere, makes noise and peeks
        importance: 4,

        //Show badge over app icon when non handled pushes are present. Default value: true
        badge: true,

        //Show message on locked screen. Default value: 1
        //Possible values (default 1):
        //-1 - secret - Do not reveal any part of the notification on a secure lockscreen.
        //0 - private - Show the notification on all lockscreens, but conceal sensitive or private information on secure lockscreens.
        //1 - public - Show the notification in its entirety on all lockscreens.
        visibility: 1,
      };

      window.FirebasePlugin.createChannel(
        customChannel,
        () => {
          console.log(`Created custom channel: ${customChannel.id}`);
          window.FirebasePlugin.listChannels(
            (channels) => {
              if (typeof channels == "undefined") return;
              for (const channel of channels) {
                console.log(`Channel id=${channel.id}; name=${channel.name}`);
              }
            },
            (error) => {
              console.error(`List channels error: ${error}`);
            }
          );
        },
        (error) => {
          console.error("Create channel error", error);
        }
      );
      window.FirebasePlugin.getToken(
        (token) => {
          console.log(`Got FCM token: ${token}`);
          this.registerToken(token);
        },
        (error) => {
          console.error("Failed to get FCM token", error);
        }
      );
    },

    // Notifications
    checkNotificationPermission(requested) {
      window.FirebasePlugin.hasPermission((hasPermission) => {
        if (hasPermission) {
          console.log("Remote notifications permission granted");
          // Granted
          this.getCordovaDeviceToken();
        } else if (requested) {
          // Denied
          console.error("Notifications won't be shown as permission is denied");
        } else {
          // Request permission
          console.log("Requesting remote notifications permission");
          window.FirebasePlugin.grantPermission(
            this.checkNotificationPermission(true)
          );
        }
      });
    },
    getID() {
      window.FirebasePlugin.getId(
        (id) => {
          console.log(`Got FCM ID: ${id}`);
          this.appId = id;
        },
        (error) => {
          console.error("Failed to get FCM ID", error);
        }
      );
    },

    getCordovaDeviceToken() {
      if (cordova.platformId === "android") {
        this.getAndroidToken();
      } else if (cordova.platformId === "ios") {
        this.getAPNSToken();
      }
    },
    getAndroidToken() {
      window.FirebasePlugin.getToken(
        (token) => {
          console.log(`Got FCM token: ${token}`);
          this.registerToken(token);
        },
        (error) => {
          console.error("Failed to get FCM token", error);
        }
      );
    },
    getAPNSToken() {
      window.FirebasePlugin.getAPNSToken(
        (token) => {
          console.log(`Got APNS token: ${token}`);
          this.registerToken(token);
        },
        (error) => {
          console.error("Failed to get APNS token", error);
        }
      );
    },

    handleNotificationMessage(message) {
      console.log("Notification message received", message);
      let title;
      if (message.title) {
        title = message.title;
      } else if (message.notification && message.notification.title) {
        title = message.notification.title;
      } else if (message.aps && message.aps.alert && message.aps.alert.title) {
        title = message.aps.alert.title;
      }

      let body;
      if (message.body) {
        body = message.body;
      } else if (message.notification && message.notification.body) {
        body = message.notification.body;
      } else if (message.aps && message.aps.alert && message.aps.alert.body) {
        body = message.aps.alert.body;
      }
      let image;
      if (message.image) {
        image = message.image;
      } else if (message.notification && message.notification.image) {
        image = message.notification.image;
      } else if (message.aps && message.aps.alert && message.aps.alert.image) {
        image = message.aps.alert.image;
      }

      let msg = "Notification message received";
      if (message.tap) {
        msg += ` (tapped in ${message.tap})`;
      }
      if (title) {
        msg += `; title=${title}`;
      }
      if (body) {
        msg += `; body=${body}`;
      }
      msg += `: ${JSON.stringify(message)}`;
      console.log(msg);
      cordova.plugins.notification.local.schedule({
        title: title,
        text: body,
        attachments: [image],
        foreground: true,
      });
    },

    handleDataMessage(message) {
      let image;
      if (message.image) {
        image = message.image;
      }
      cordova.plugins.notification.local.schedule({
        title: message.title,
        text: message.body,
        attachments: [image],
        foreground: true,
      });
      console.log(`Data message received: ${JSON.stringify(message)}`);
    },

    clearNotifications() {
      window.FirebasePlugin.clearAllNotifications(
        () => {
          console.log("Cleared all notifications");
        },
        (error) => {
          console.error("Failed to clear notifications", error);
        }
      );
    },

    subscribe() {
      window.FirebasePlugin.subscribe(
        "my_topic",
        () => {
          console.log("Subscribed to topic");
        },
        (error) => {
          console.error("Failed to subscribe to topic", error);
        }
      );
    },

    unsubscribe() {
      window.FirebasePlugin.unsubscribe(
        "my_topic",
        () => {
          console.log("Unsubscribed from topic");
        },
        (error) => {
          console.error("Failed to unsubscribe from topic", error);
        }
      );
    },

    getBadgeNumber() {
      window.FirebasePlugin.getBadgeNumber(
        (number) => {
          console.log(`Current badge number: ${number}`);
        },
        (error) => {
          console.error("Failed to get badge number", error);
        }
      );
    },

    incrementBadgeNumber() {
      window.FirebasePlugin.getBadgeNumber(
        (current) => {
          const number = current + 1;
          window.FirebasePlugin.setBadgeNumber(
            number,
            () => {
              console.log(`Set badge number to: ${number}`);
            },
            (error) => {
              console.error("Failed to set badge number", error);
            }
          );
        },
        (error) => {
          console.error("Failed to get badge number", error);
        }
      );
    },

    clearBadgeNumber() {
      window.FirebasePlugin.setBadgeNumber(
        0,
        () => {
          console.log("Cleared badge number");
        },
        (error) => {
          console.error("Failed to clear badge number", error);
        }
      );
    },

    unregister() {
      window.FirebasePlugin.unregister(
        () => {
          console.log("Unregistered from Firebase");
        },
        (error) => {
          console.error("Failed to unregister from Firebase", error);
        }
      );
    },

    // Crashlytics
    setCrashlyticsCollectionEnabled(enabled) {
      window.FirebasePlugin.setCrashlyticsCollectionEnabled(
        enabled,
        () => {
          console.log(
            "Crashlytics data collection has been " +
              (enabled ? "enabled" : "disabled")
          );
        },
        (error) => {
          console.error("Failed to enable crashlytics data collection", error);
        }
      );
    },
    isCrashlyticsCollectionEnabled() {
      window.FirebasePlugin.isCrashlyticsCollectionEnabled(
        (enabled) => {
          console.log(
            "Crashlytics data collection setting is " +
              (enabled ? "enabled" : "disabled")
          );
        },
        (error) => {
          console.error(
            "Failed to fetch crashlytics data collection setting",
            error
          );
        }
      );
    },

    setCrashlyticsUserId() {
      window.FirebasePlugin.setCrashlyticsUserId(
        this.$keycloak.subject,
        () => {
          console.log("Set crashlytics user ID");
        },
        (error) => {
          console.error("Failed to set crashlytics user ID", error);
        }
      );
    },

    setCrashlyticsCustomKey() {
      window.FirebasePlugin.setCrashlyticsCustomKey(
        "my_key",
        "foo",
        () => {
          console.log("Set crashlytics custom key");
        },
        (error) => {
          console.error("Failed to set crashlytics custom key", error);
        }
      );
    },

    sendNonFatal() {
      window.FirebasePlugin.logError(
        "This is a non-fatal error",
        () => {
          console.log("Sent non-fatal error");
        },
        (error) => {
          console.error("Failed to send non-fatal error", error);
        }
      );
    },

    causeJsException() {
      // Cause an uncaught JS exception
      const foo = someUndefinedVariable.bar;
    },

    logCrashMessage() {
      window.FirebasePlugin.logMessage(
        "A custom message about this crash",
        () => {
          console.log(
            "Logged crash message - it will be sent with the next crash"
          );
        },
        (error) => {
          console.error("Failed to log crash message", error);
        }
      );
    },

    sendCrash() {
      window.FirebasePlugin.sendCrash();
    },

    sendNdkCrash() {
      helloc.causeCrash();
    },

    didCrashOnPreviousExecution() {
      window.FirebasePlugin.didCrashOnPreviousExecution(
        (didCrashOnPreviousExecution) => {
          console.log(
            `Did crash on previous execution: ${didCrashOnPreviousExecution}`
          );
        },
        (error) => {
          console.error("Failed to check crash on previous execution:" + error);
        }
      );
    },

    // Analytics
    setAnalyticsCollectionEnabled() {
      window.FirebasePlugin.setAnalyticsCollectionEnabled(
        true,
        () => {
          console.log("Enabled analytics data collection");
        },
        (error) => {
          console.error("Failed to enable analytics data collection", error);
        }
      );
    },

    isAnalyticsCollectionEnabled(showUser) {
      window.FirebasePlugin.isAnalyticsCollectionEnabled(
        (enabled) => {
          console.log(
            "Analytics data collection setting is " +
              (enabled ? "enabled" : "disabled"),
            showUser
          );
        },
        (error) => {
          console.error(
            "Failed to fetch Analytics data collection setting",
            error
          );
        }
      );
    },
    setAnalyticsConsentEnabled() {
      const consents = {};
      consents[window.FirebasePlugin.AnalyticsConsentMode.ANALYTICS_STORAGE] =
        window.FirebasePlugin.AnalyticsConsentStatus.GRANTED;
      consents[window.FirebasePlugin.AnalyticsConsentMode.AD_STORAGE] =
        window.FirebasePlugin.AnalyticsConsentStatus.GRANTED;
      consents[window.FirebasePlugin.AnalyticsConsentMode.AD_USER_DATA] =
        window.FirebasePlugin.AnalyticsConsentStatus.GRANTED;
      consents[window.FirebasePlugin.AnalyticsConsentMode.AD_PERSONALIZATION] =
        window.FirebasePlugin.AnalyticsConsentStatus.GRANTED;

      window.FirebasePlugin.setAnalyticsConsentMode(consents);

      window.FirebasePlugin.setAnalyticsConsentMode(
        consents,
        () => {
          log("Set analytics consent", true);
        },
        (error) => {
          logError("Failed to set analytics consent", error, true);
        }
      );
    },

    logEvent() {
      window.FirebasePlugin.logEvent(
        "my_event",
        {
          string: "bar",
          integer: 10,
          float: 1.234,
        },
        () => {
          console.log("Logged event");
        },
        (error) => {
          console.error("Failed to log event", error);
        }
      );
    },

    setScreenName() {
      window.FirebasePlugin.setScreenName(
        this.f7router.name,
        () => {
          console.log("Sent screen name", this.f7router.name);
        },
        (error) => {
          console.error("Failed to send screen name", error);
        }
      );
    },

    setUserID() {
      window.FirebasePlugin.setUserId(
        this.$keycloak.subject,
        () => {
          console.log("Set user ID");
        },
        (error) => {
          console.error("Failed to set user ID", error);
        }
      );
    },

    setUserProperty() {
      window.FirebasePlugin.setUserProperty(
        "some_key",
        "some_value",
        () => {
          console.log("Set user property");
        },
        (error) => {
          console.error("Failed to set user property", error);
        }
      );
    },

    // Performance
    setPerformanceCollectionEnabled() {
      window.FirebasePlugin.setPerformanceCollectionEnabled(
        true,
        () => {
          console.log("Enabled performance data collection");
        },
        (error) => {
          console.error("Failed to enable performance data collection", error);
        }
      );
    },

    isPerformanceCollectionEnabled(showUser) {
      window.FirebasePlugin.isPerformanceCollectionEnabled(
        (enabled) => {
          console.log(
            "Performance data collection setting is " +
              (enabled ? "enabled" : "disabled"),
            showUser
          );
        },
        (error) => {
          console.error(
            "Failed to fetch Performance data collection setting",
            error,
            true
          );
        }
      );
    },

    startTrace() {
      window.FirebasePlugin.startTrace(
        this.traceName,
        () => {
          console.log("Trace started");
        },
        (error) => {
          console.error("Failed to start trace", error);
        }
      );
    },

    incrementCounter() {
      window.FirebasePlugin.incrementCounter(
        this.traceName,
        "my_counter",
        () => {
          console.log("Incremented trace counter");
        },
        (error) => {
          console.error("Failed to increment trace counter", error);
        }
      );
    },

    stopTrace() {
      window.FirebasePlugin.stopTrace(
        this.traceName,
        () => {
          console.log("Trace stopped");
        },
        (error) => {
          console.error("Failed to stop trace", error);
        }
      );
    },
    getInstallationId() {
      window.FirebasePlugin.getInstallationId(
        (id) => {
          console.log("Got installation ID: " + id, true);
        },
        (error) => {
          console.error("Failed to get installation ID", error, true);
        }
      );
    },

    getInstallationToken() {
      window.FirebasePlugin.getInstallationToken(
        (token) => {
          console.log(`Got installation token: ${token}`);

          // Decode JWT
          try {
            const payload = this.parseJwt(token);
            console.log(`Token payload: ${JSON.stringify(payload)}`, true);
          } catch (e) {
            console.error(
              `Exception in decoded installation JWT: ${e.message}`,
              true
            );
          }
        },
        (error) => {
          console.error("Failed to get installation token", error, true);
        }
      );
    },
    parseJwt(token) {
      const base64Url = token.split(".")[1];
      const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
      const jsonPayload = decodeURIComponent(
        atob(base64)
          .split("")
          .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
          .join("")
      );

      return JSON.parse(jsonPayload);
    },

    deleteInstallationId() {
      window.FirebasePlugin.deleteInstallationId(
        () => {
          console.log("Deleted installation ID", true);
        },
        (error) => {
          console.error("Failed to delete installation ID", error, true);
        }
      );
    },
  },
};
