import FileSaver from "file-saver";
import { f7, theme } from "framework7-vue";
import { utils } from "framework7";
import { mapState } from "vuex";
import _ from "lodash";
// import { filter } from "core-js/core/array";
export const cordovaPlugin = {
  methods: {
    onDeviceReady() {
      this.checkConnection();
      console.log("cordova device", device);
      this.$store.dispatch("auth/setDevice", device);
      networkinterface.getWiFiIPAddress(onSuccessNetWork, onErrorNetWork);
      networkinterface.getCarrierIPAddress(onSuccessNetWork, onErrorNetWork);
      function onSuccessNetWork(ipInformation) {
        console.log(`IP: ${ipInformation.ip} subnet:${ipInformation.subnet}`);
        console.log(JSON.stringify(ipInformation));
      }

      function onErrorNetWork(error) {
        // Note: onError() will be called when an IP address can't be
        // found, e.g. WiFi is disabled, no SIM card, Airplane mode
        console.log(error);
      }
    },

    checkAppPermissions() {
      return new Promise(function (resolve) {
        const permissions = cordova.plugins.permissions;
        const permissionList = [
          permissions.CAMERA,
          permissions.GET_ACCOUNTS,
          permissions.RECORD_AUDIO,
          permissions.RECORD_VIDEO,
          permissions.MODIFY_AUDIO_SETTINGS,
          permissions.READ_CONTACTS,
          permissions.WRITE_CONTACTS,
          permissions.FLASHLIGHT,
          permissions.USE_BIOMETRIC,
          permissions.ACCESS_COARSE_LOCATION,
          permissions.ACCESS_FINE_LOCATION,
          permissions.READ_PHONE_STATE,
        ];
        let acceptedCount = 0;
        permissionList.forEach((perm) => {
          permissions.checkPermission(
            perm,
            (status) => {
              if (status.hasPermission) {
                acceptedCount++;
                console.log(
                  "checkAppPermission: checkPermission: Current acceptedCount: ",
                  acceptedCount
                );
                if (acceptedCount === permissionList.length) {
                  console.log(
                    "checkAppPermission: checkPermission: All permissions accepted."
                  );
                  resolve();
                }
                return;
              }
              permissions.requestPermission(
                perm,
                () => {
                  console.log(
                    "checkAppPermission: requestPermission: All permissions accepted."
                  );
                  resolve();
                },
                null
              );
            },
            null
          );
        });
      });
    },

    onDeviceReadySim() {
      window.plugins.sim.getSimInfo(deviceSuccessCallback, deviceErrorCallback);
      function deviceSuccessCallback(result) {
        console.log(JSON.stringify(result));
      }

      function deviceErrorCallback(error) {
        console.log(error);
      }
    },

    // Android only: check permission
    hasReadPermission() {
      window.plugins.sim.hasReadPermission(
        this.successCallback,
        this.errorCallback
      );
    },

    // Android only: request permission
    requestReadPermission() {
      window.plugins.sim.requestReadPermission(
        this.successCallback,
        this.errorCallback
      );
    },

    onDeviceReadyContacts() {
      const permissions = cordova.plugins.permissions;
      permissions.checkPermission(permissions.READ_CONTACTS, (status) => {
        if (status.hasPermission) {
          const options = new ContactFindOptions();
          options.filter = "";
          options.multiple = true;
          options.hasPhoneNumber = true;
          const fields = [
            "displayName",
            "addresses",
            "phoneNumbers",
            "name",
            "addresses",
            "emails",
            "givenName",
            "familyName",
            "birthday",
          ];
          // navigator.contacts.find(filter, onSuccess, onError, options);
          navigator.contacts.find(fields, onSuccess, onError, options);
          console.log(JSON.stringify(navigator.contacts));

          function onSuccess(contacts) {
            console.log(contacts);
          }

          function onError(contactError) {
            console.log("onError!");
            console.log(contactError);
          }
          return;
        }
        console.warn("No :( ");
      });
    },

    success(result) {
      const firstResult = result[0];
      console.log(`First Result: ${JSON.stringify(firstResult)}`);
    },

    failure(err) {
      console.log(err);
    },

    checkConnection() {
      const networkState = navigator.connection.type;
      const states = {};
      states[Connection.UNKNOWN] = "Unknown connection";
      states[Connection.ETHERNET] = "Ethernet connection";
      states[Connection.WIFI] = "WiFi connection";
      states[Connection.CELL_2G] = "Cell 2G connection";
      states[Connection.CELL_3G] = "Cell 3G connection";
      states[Connection.CELL_4G] = "Cell 4G connection";
      states[Connection.CELL] = "Cell generic connection";
      states[Connection.NONE] = "No network connection";

      console.log(`Connection type: ${states[networkState]}`);
    },

    onOnline() {
      console.log(`You are Online on :${navigator.connection.type}`);
    },
    onOffline() {
      console.log("You are Offline!");
    },
    // redirectOnOpen(eventData) {
    //   const self = this;
    //   console.log("App  started from a in cordova keycloak");
    //   console.log(eventData);
    //       const link = eventData.url.split(".com/login").pop();
    //   if (link && link.search(`/login`) >= 0) {
    //     const link2 = eventData.url.split(".com/login").pop();
    //     f7.views.main.router.navigate(link2);
    //   } else {
    //     f7.views.main.router.navigate(eventData.path);
    //   }
    // },

    async selectContact() {
      const self = this;
      const supported = "contacts" in navigator && "ContactsManager" in window;
      if (f7.device.cordova) {
        const permissions = cordova.plugins.permissions;
        permissions.checkPermission(permissions.READ_CONTACTS, (status) => {
          if (status.hasPermission) {
            const options = new ContactFindOptions();
            options.filter = "";
            options.multiple = true;
            options.hasPhoneNumber = true;
            const fields = [
              "displayName",
              "addresses",
              "phoneNumbers",
              "name",
              "addresses",
              "emails",
              "givenName",
              "familyName",
              "birthday",
              "photos",
            ];
            // navigator.contacts.find(filter, onSuccess, onError, options);
            navigator.contacts.find(fields, onSuccess, onError, options);
            // console.log(JSON.stringify(navigator.contacts));

            function onSuccess(contacts) {
              console.log("My contacts", contacts);
              const sortedContact = contacts.sort((a, b) => {
                if (a.displayName < b.displayName) {
                  return -1;
                }
                if (a.displayName > b.displayName) {
                  return 1;
                }
                return 0;
              });
              const contactsList = sortedContact.filter(
                (user) =>
                  user.displayName !== null && user.phoneNumbers.length > 0
              );
              self.$store.dispatch("auth/setContactList", contactsList);
              f7.popup.open(".selectContact");

              setTimeout(() => {
                //Open popup to select contact
              }, 500);
            }
            function onError(contactError) {
              console.log("onError!");
              console.log(contactError);
            }
            return;
          }
          console.warn("No :( ");
        });
      } else if (!f7.device.cordova && supported) {
        // const props = ["name", "email", "tel", "address", "icon"];
        const supportedProperties = await navigator.contacts.getProperties();
        const opts = { multiple: false };
        try {
          const contact = await navigator.contacts.select(
            supportedProperties,
            opts
          );
          console.log("Web navigator contacts", contact[0]);
          self.$store.dispatch("auth/setSelectedContact", contact[0]);
          f7.popup.open(".selectContact");
          // self.handleResult(contact[0]);
        } catch (ex) {
          console.log("onError!", ex);
        }
      }
    },
    handleResult(contact) {
      const selectedContact = {
        name: contact.name,
        tel: contact.tel,
        email: contact.email,
        address: contact.address,
        icon: contact.icon,
      };

      console.log("Selected contact", selectedContact);
      this.$store.dispatch("auth/setSelectedContact", contact);
      f7.popup.open(".selectContact");
    },
    universalLinksReady() {
      universalLinks.subscribe(
        "launchedAppFromLink",
        this.onAppLauchedFromLink
      );
      universalLinks.subscribe(
        "lauchedFromAllLink",
        this.onAppLauchedFromAllLink
      );
      // universalLinks.subscribe("keycloak", this.redirectOnOpen);
    },
    // redirectOnOpen(eventData) {
    //   console.log("App  started from a in cordova keycloak");
    //   const link = eventData.url;
    //   console.log("keycloak universal links", eventData, link, eventData.path);
    //   if (link.includes(`/login`)) {
    //     const link2 = eventData.url.split("/login").pop();
    //     f7.views.main.router.navigate(link2);
    //     universalLinks.unsubscribe("keycloak");
    //   }
    // },
    onAppLauchedFromLink(eventData) {
      console.log(eventData);
      // f7.dialog.alert(eventData);
      const link = eventData.url;

      if (link.includes(`/links`) && !link.includes(`/login`)) {
        // f7.dialog.alert(eventData.path);
        const path = eventData.url.split("/links").pop();
        f7.views.main.router.navigate(path);
      }
      universalLinks.unsubscribe("launchedAppFromLink");
      // }
    },

    onAppLauchedFromAllLink(eventData) {
      console.log("lauchedFromAllLink ", eventData);
      const link = eventData.url;
      console.log("App started from lauchedFromAllLink", link);
      console.log("link ", link, eventData.path);

      let path;
      if (link.includes(`/links`) && !link.includes(`/login`)) {
        // path = eventData.url.split("/login").pop();
        path = eventData.url.split("/links").pop();
      } else if (!link.includes(`/links`) && !link.includes(`/login`)) {
        path = eventData.url.split(".com").pop();
      }
      // else {
      // }

      f7.preloader.show("multi");
      setTimeout(() => {
        f7.views.main.router.navigate(path);
        f7.preloader.hide();
      }, 500);
      universalLinks.unsubscribe("lauchedFromAllLink");
    },
  },
  mounted() {
    document.addEventListener("deviceready", this.onDeviceReady, false);
    document.addEventListener("deviceready", this.onDeviceReadySim, false);
    document.addEventListener(
      "deviceready",
      this.onDeviceReadyContacts,
      this.onSuccessContacts,
      this.onErrorContacts,
      false
    );
    document.addEventListener("online", this.onOnline, false);
    document.addEventListener("offline", this.onOffline, false);
    document.addEventListener("deviceready", this.checkAppPermissions, false);
    document.addEventListener("deviceready", this.universalLinksReady, false);
    document.addEventListener(
      "deviceready",
      () => {
        cordova.plugins.firebase.dynamiclinks.getDynamicLink().then((data) => {
          if (data) {
            console.log("get dynamiclinks ", data);
            openLink(data);
          } else {
            console.log("App wasn't started from a dynamic link surely");
          }
        });

        function openLink(data) {
          console.log("open dynamiclinks ", data);
          if (data) {
            const link = data.deepLink.split(".com").pop();
            if (link) {
              // f7.dialog.alert(link);
              f7.views.main.router.navigate(link);
            }
          }
        }

        cordova.plugins.browsertab.isAvailable(
          (result) => {
            if (result) {
              // cordova.plugins.browsertab.openUrl(
              //   url,
              //   (successResp) => {},
              //   (failureResp) => {
              console.log("browsertab Is present", result);
              // }
              // );
            } else {
              console.log("browsertab Not present");
              // const ref = cordova.InAppBrowser.open(url, "_self", "location = no");
            }
          },
          (isAvailableError) => {
            console.log(
              "failed to query availability of in-app browser tab",
              isAvailableError
            );
          }
        );
      },
      false
    );
  },
};

export const socialShare = {
  methods: {
    onClickShare(share_content) {
      const self = this;
      // let content=this.shareContent;
      console.log("share content", this.shareContent);
      if (!self.actionsToPopover) {
        self.actionsToPopover = f7.actions.create({
          convertToPopover: true,
          buttons: [
            [
              {
                text: self.$t("common.share"),
                color: "auto",
                bold: true,
              },
              {
                text: self.$t("common.shareInAvochat"),
                // color: "auto",
                icon: '<i class="icon elevation-3"><img src="/32x32.png" alt="avocash icon" height="32" width="32"></i>',
                onClick() {
                  self.avochatShare(self.shareContent);
                },
              },
              {
                text: self.$t("common.shareInFairia"),
                // color: "red",
                icon: '<i class="icon elevation-3"><img src="/32x32.png" alt="avocash icon" height="32" width="32"></i>',
                onClick() {
                  self.fairiaShare(self.shareContent);
                },
              },
              {
                text: self.$t("common.shareMoreOptions"),
                // color: "red",
                icon: '<i class="f7-icons color-auto">arrow_branch</i>',
                onClick() {
                  self.socialShare(self.shareContent);
                },
              },
            ],
            [
              {
                text: self.$t("common.cancel"),
                color: "red",
              },
            ],
          ],
          targetEl: ".shareButton",
        });
      }
      self.actionsToPopover.open();
      self.socialShare(self.shareContent);
    },

    avochatShare(options) {},

    fairiaShare(options) {},

    socialShare(options) {
      if (options === "" || options.linkBase === "") {
        this.share();
      }
      let shareLink;
      if (options !== "" && options.linkBase !== "" && options.url === "") {
        this.$store.dispatch("auth/createDynamicLink", options);
        setTimeout(() => {
          shareLink =
            this.dynamicLink && this.dynamicLink.shortLink !== null
              ? this.dynamicLink.shortLink
              : "";
        }, 500);
      } else {
        shareLink = options.url;
      }
      console.log("share options", options);
      setTimeout(() => {
        console.log(this.dynamicLink);
        if (f7.device.cordova) {
          const optionsMsg = {
            message: options.message, //'share this', // not supported on some apps (Facebook, Instagram)
            subject: options.subject, // 'the subject', // fi. for email
            files: options.files, //['', ''], // an array of filenames either locally or remotely
            url: shareLink == "undefined" ? "" : shareLink, // 'https://www.website.com/foo/#bar?a=b',
            chooserTitle: this.$t("common.pickanAppToShare"), // Android only, you can override the default share sheet title
            appPackageName: `${import.meta.env.VITE_APP_PACKAGE_NAME}`, //'com.apple.social.facebook', // Android only, you can provide id of the App you want to share with
            iPadCoordinates: "0,0,0,0",
          };
          console.log("Option", optionsMsg);
          const onSuccess = (result) => {
            console.log("Share completed", result.completed); // On Android apps mostly return false even while it's true
            console.log("Shared to app", result.app); // On Android result.app since plugin version 5.4.0 this is no longer empty. On iOS it's empty when sharing is cancelled (result.completed=false)
          };

          const onError = (msg) => {
            console.log("Sharing failed with message", msg);
          };
          window.plugins.socialsharing.share(optionsMsg, onSuccess, onError);
          return;
        }
        const fileArray = options.files;
        console.log(fileArray);
        console.log("shareLink", shareLink);
        if (fileArray && fileArray.length > 0) {
          if (navigator.canShare && navigator.canShare({ fileArray })) {
            navigator
              .share({
                files: fileArray,
                title: options.subject,
                text: options.message,
                url: shareLink == "undefined" ? "" : shareLink,
              })
              .then(() => console.log("Share was successful."))
              .catch(
                (error) => console.log("Sharing failed", error)
                // , f7.dialog.alert('Sharing failed: ' + error)
              );
          } else {
            // f7.dialog.alert(`Your system doesn't support sharing files.`);
            console.log(`Your system doesn't support sharing files.`);
            navigator
              .share({
                title: options.subject,
                text: options.message,
                url: shareLink == "undefined" ? "" : shareLink,
              })
              .then(() => console.log("Share was successful."))
              .catch((error) => console.log("Sharing failed", error));
          }
        } else {
          navigator
            .share({
              title: options.subject,
              text: options.message,
              url: shareLink == "undefined" ? "" : shareLink,
            })
            .then(() => console.log("Share was successful."))
            .catch((error) => console.log("Sharing failed", error));
        }
      }, 1000);
    },

    saveFile(fileToSave) {
      console.log(fileToSave);
      for (const file of fileToSave) {
        FileSaver.saveAs(file);
      }
    },
  },
  mounted() {
    // document.addEventListener(
    //   "deviceready",
    //   () => {
    //     cordova.plugins.firebase.dynamiclinks.getDynamicLink().then((data) => {
    //       if (data) {
    //         console.log("get dynamiclinks ", data);
    //         openLink(data);
    //       } else {
    //         console.log("App wasn't started from a dynamic link");
    //       }
    //     });
    //     cordova.plugins.firebase.dynamiclinks.onDynamicLink(
    //       (res) => {
    //         console.log("on dynamiclinks ", res);
    //         openLink(res);
    //       },
    //       (error) => console.log(error)
    //     );
    //     function openLink(data) {
    //       console.log("dynamiclinks ", data);
    //       if (data) {
    //         const link = data.deepLink.split(".com").pop();
    //         if (link) {
    //           f7.views.main.router.navigate(link);
    //         }
    //       }
    //     }
    //   },
    //   false
    // );
  },
  computed: {
    ...mapState("auth", [
      "phoneNumberDetail",
      "mobilePhoneError",
      "profile",
      "isDeskTop",
      "isTablet",
      "dynamicLink",
      "keycloak",
      "authenticated",
    ]),
  },
};

const openUrl = {
  openLink(url) {
    const options = {
      zoom: "no",
      location: "no",
      clearsessioncache: "yes",
      clearcache: "yes",
    };
    if (window.cordova || window.Cordova) {
      cordova.plugins.browsertab.isAvailable(
        (result) => {
          if (result) {
            // if (window.cordova && window.cordova.browsertab) {
            // Use browsertab for IOS and Android if available
            console.log("opened in browsertab");
            window.cordova.plugins.browsertab.openUrl(url);
          } else if (window.cordova && window.cordova.InAppBrowser) {
            const target = "_blank";
            console.log("opened in inappbrowser");
            // Use inappbrowser for IOS and Android if available
            window.cordova.InAppBrowser.open(url, target, options);
          } else {
            const target = "_self";
            console.log("opened in browser");
            window.open(url, target, options);
          }
        },

        (isAvailableError) => {
          console.log(
            "failed to query availability of in-app browser tab",
            isAvailableError
          );
          const target = "_self";
          console.log("opened in browser");
          window.open(url, target, options);
        }
      );
    } else {
      const target = "_self";
      window.open(url, target, options);
    }
  },
};
export default openUrl;

// cordova.plugins.browsertab.isAvailable(
//   (result) => {
//     if (result) {
//       // cordova.plugins.browsertab.openUrl(
//       //   url,
//       //   (successResp) => {},
//       //   (failureResp) => {
//       console.log("browsertab Is present", result);
//       // }
//       // );
//     } else {
//       console.log("browsertab Not present");
//       // const ref = cordova.InAppBrowser.open(url, "_self", "location = no");
//     }
//   },
//   (isAvailableError) => {
//     console.log(
//       "failed to query availability of in-app browser tab",
//       isAvailableError
//     );
//   }
// );
