import { f7, theme } from "framework7-vue";
import { utils } from "framework7";
import _ from "lodash";
export const cordovaCamera = {
  methods: {
    getFiles() {
      const self = this;

      if (f7.device.cordova) {
        f7.actions
          .create({
            // grid: true,
            buttons: [
              [
                {
                  text: self.$t("attachdocs.takeAPhoto"),
                  color: "auto",
                  icon: theme.ios
                    ? '<i class="f7-icons">camera</i>'
                    : '<i class="material-icons ">photo_camera</i>',
                  bold: true,
                  close: true,
                  onClick() {
                    const src = "CAMERA";
                    self.takePicture(src);
                  },
                },
                {
                  text: self.$t("attachdocs.selectAPhoto"),
                  color: "teal",
                  bold: true,
                  close: true,
                  icon: theme.ios
                    ? '<i class="f7-icons ">photo_on_rectangle</i>'
                    : '<i class="material-icons ">photo_library</i>',
                  onClick() {
                    const src = "PHOTOLIBRARY";
                    self.takePicture(src);
                  },
                },
              ],
              [
                {
                  text: self.$t("attachdocs.phonefolder"),
                  color: "orange",
                  bold: true,
                  close: true,
                  icon: theme.ios
                    ? '<i class="f7-icons ">folder</i>'
                    : '<i class="material-icons ">folder_open</i>',
                  onClick() {
                    self.uploadedFile();
                  },
                },
              ],
              [
                {
                  text: self.$t("common.cancel"),
                  color: "red",
                  icon: theme.ios
                    ? '<i class="f7-icons ">multiply</i>'
                    : '<i class="material-icons ">close</i>',
                },
              ],
            ],
          })
          .open();
      } else {
        this.uploadedFile();
      }
    },

    takePicture(source) {
      const self = this;

      console.log(source);
      if (source === "CAMERA") {
        navigator.camera.getPicture(onSuccess, onFail, {
          // Some common settings are 20, 50, and 100
          quality: 50,
          destinationType: Camera.DestinationType.FILE_URL,
          // In this app, dynamically set the picture source, Camera or photo gallery
          sourceType: Camera.PictureSourceType.CAMERA, //this.srcType, //Camera.PictureSourceType.CAMERA,Camera.PictureSourceType.PHOTOLIBRARY, Camera.PictureSourceType.SAVEDPHOTOALBUM
          encodingType: Camera.EncodingType.JPEG,
          mediaType: Camera.MediaType.PICTURE,
          // allowEdit: true,
          correctOrientation: true,
          popoverOptions: new CameraPopoverOptions(
            300,
            300,
            100,
            100,
            Camera.PopoverArrowDirection.ARROW_ANY,
            300,
            600
          ),
        });

        function onSuccess(imageData) {
          console.log(imageData);
          // processImageFile(imageData);
          window.resolveLocalFileSystemURL(imageData, gotFile, fail);

          function fail(e) {
            console.log("Cannot found requested file");
          }

          function gotFile(fileEntry) {
            fileEntry.file((file) => {
              self.files = file;
              const reader = new FileReader();

              if (
                f7.views.main.router.currentRoute.url.search("/message/") >= 0
              ) {
                reader.onloadend = function () {
                  const blob = new Blob([new Uint8Array(this.result)], {
                    type: file.type,
                  });
                  console.log(blob);
                  self.setAttachment({ blob });
                };

                reader.readAsArrayBuffer(file);
              } else {
                reader.onload = (event) => {
                  self.documentsUrl.push({
                    fileName: file.name,
                    contentType: file.type,
                    alt: file.name,
                    fileId: utils.id("xxxx-xxxx-xxxx-xxxx") + "/" + file.name,
                    size: file.size,
                    fileType: file.type.split("/", 1)[0],
                    url: window.URL.createObjectURL(file),
                    file,
                  });
                };
                reader.readAsDataURL(file);
              }
            });
          }
        }

        function onFail(message) {
          f7.dialog.alert(message);
        }
        return;
      }
      navigator.camera.getPicture(onSuccessL, onFailL, {
        // Some common settings are 20, 50, and 100
        quality: 50,
        destinationType: Camera.DestinationType.FILE_URI,
        // In this app, dynamically set the picture source, Camera or photo gallery
        sourceType: Camera.PictureSourceType.PHOTOLIBRARY, //this.srcType, //Camera.PictureSourceType.CAMERA,Camera.PictureSourceType.PHOTOLIBRARY, Camera.PictureSourceType.SAVEDPHOTOALBUM
        encodingType: Camera.EncodingType.JPEG,
        mediaType: Camera.MediaType.PICTURE,
        allowEdit: true,
        correctOrientation: true,
        popoverOptions: new CameraPopoverOptions(
          300,
          300,
          100,
          100,
          Camera.PopoverArrowDirection.ARROW_ANY,
          300,
          600
        ),
      });

      function onSuccessL(imageData) {
        // if (this.f7router.currentRoute.url.search("/tamtam") >= 0) {
        //     var blob = new Blob([new Uint8Array(`data:image/png;base64,${imageData}`)], { type: "image/png" });
        //     self.setAttachment({ blob });
        // } else {
        window.resolveLocalFileSystemURL(imageData, gotFile, fail);

        function fail(e) {
          console.log("Cannot found requested file");
        }

        function gotFile(fileEntry) {
          fileEntry.file((file) => {
            self.files = file;
            const reader = new FileReader();
            if (
              f7.views.main.router.currentRoute.url.search("/message/") >= 0
            ) {
              reader.onloadend = function () {
                const blob = new Blob([new Uint8Array(this.result)], {
                  type: file.type,
                });
                console.log(blob);
                self.setAttachment({ blob });
              };

              reader.readAsArrayBuffer(file);
            } else {
              reader.onload = (event) => {
                self.documentsUrl.push({
                  fileName: file.name,
                  contentType: file.type,
                  alt: file.name,
                  fileId: utils.id("xxxx-xxxx-xxxx-xxxx") + "/" + file.name,
                  size: file.size,
                  fileType: file.type.split("/", 1)[0],
                  url: window.URL.createObjectURL(file),
                  file,
                });
                reader.readAsDataURL(file);
              };
            }
          });
        }
      }

      function onFailL(message) {
        f7.dialog.alert(message);
      }
    },

    processImageFile(imageData) {
      const self = this;

      window.resolveLocalFileSystemURL(imageData, gotFile, fail);

      function fail(e) {
        console.log("Cannot found requested file");
      }

      function gotFile(fileEntry) {
        fileEntry.file((file) => {
          self.files = file;
          // self.setAttachment({ file });
          const reader = new FileReader();
          reader.onload = (event) => {
            // self.readFileBlob(file).then(blob => self.setAttachment({ blob, file }));
            self.documentsUrl.push({
              fileName: file.name,
              contentType: file.type,
              alt: file.name,
              fileId: utils.id("xxxx-xxxx-xxxx-xxxx") + "/" + file.name,
              size: file.size,
              fileType: file.type.split("/", 1)[0],
              url: window.URL.createObjectURL(file),
              file,
            });
          };
          reader.readAsDataURL(file);
        });
      }
    },

    onDeviceReady() {
      // deviceIsReady = true;
      const self = this;

      const idStorageKey = "idStorageKey";
      document.addEventListener(
        "pendingcaptureresult",
        this.mediaCapture_Success
      );
      document.addEventListener(
        "pendingcaptureerror",
        this.mediaCapture_Failure
      );
    },
    captureMedia(mediaSource, captureOptions) {
      const self = this;

      switch (mediaSource) {
        case "img":
          navigator.device.capture.captureImage(
            self.mediaCapture_Success,
            self.mediaCapture_Failure,
            captureOptions
          );
          break;
        case "audio":
          navigator.device.capture.captureAudio(
            self.mediaCapture_Success,
            self.mediaCapture_Failure,
            captureOptions
          );
          break;
        case "video":
          navigator.device.capture.captureVideo(
            self.mediaCapture_Success,
            self.mediaCapture_Failure,
            captureOptions
          );
          break;
      }
    },

    mediaCapture_Success(mediaFiles) {
      const self = this;

      let i;
      let path;
      let len;
      for (i = 0, len = mediaFiles.length; i < len; i += 1) {
        path = mediaFiles[i].fullPath;
        console.log(mediaFiles[i]);
        window.resolveLocalFileSystemURL(path, gotFile, fail);
      }

      function fail(e) {
        console.log("Cannot found requested file");
      }

      function gotFile(fileEntry) {
        fileEntry.file((file) => {
          self.files = file;
          console.log(file);
          const reader = new FileReader();
          if (f7.views.main.router.currentRoute.url.search("/message/") >= 0) {
            reader.onloadend = function () {
              const blob = new Blob([new Uint8Array(this.result)], {
                type: file.type,
              });
              console.log(blob);
              self.setAttachment({ blob });
            };

            reader.readAsArrayBuffer(file);
          } else {
            reader.onload = (event) => {
              self.documentsUrl.push({
                fileName: file.name,
                contentType: file.type,
                alt: file.name,
                fileId: utils.id("xxxx-xxxx-xxxx-xxxx") + "/" + file.name,
                size: file.size,
                fileType: file.type.split("/", 1)[0],
                url: window.URL.createObjectURL(file),
                file,
              });
            };
            reader.readAsDataURL(file);
          }
        });
      }
    },

    //Caputre media failure
    mediaCapture_Failure(captureError) {
      console.log(captureError.code);
    },

    //Applicaton on pause handler
    onPause() {
      if ("mediaElement" in window) {
        const mediaElementParentId = mediaElement.parentElement.id;
        window.localStorage.setItem("idStorageKey", mediaElementParentId);
      } else window.localStorage.removeItem("idStorageKey");
    },

    //Application on Resume handler
    onResume() {
      const mediaElementParentId = window.localStorage.getItem("idStorageKey");
      if (mediaElementParentId !== null)
        mediaElement =
          document.getElementById(mediaElementParentId).firstElementChild;
    },

    uploadImage(event) {
      const self = this;

      // Reference to the DOM input element
      const input = event.target;
      // Ensure that you have a file before attempting to read it
      if (!(input.files && input.files[0])) {
        return;
      }
      // create a new FileReader to read this image and convert to base64 format
      const reader = new FileReader();
      // Define a callback function to run, when FileReader finishes its job
      reader.onload = (e) => {
        // Note: arrow function used here, so that "this.imageData" refers to the imageData of Vue component
        // Read image as base64 and set to imageData
        self.image = e.target.result;
      };
      // Start the reader job - read file as a data url (base64 format)
      reader.readAsDataURL(input.files[0]);
    },
  },
  mounted() {
    const self = this;
    document.addEventListener("deviceready", this.onDeviceReady, false);
    document.addEventListener("pause", this.onPause, false);
    document.addEventListener("resume", this.onResume, false);
  },
};
