<template>
  <f7-page hide-bars-on-scroll :no-toolbar="this.isTablet == true ? false : true" :page-content="false">
    <!-- @page:afterout="afterOut()" -->

    <f7-navbar sliding no-shadow>
      <f7-nav-left :back-link="$t('common.backButton')" :back-link-show-text="false">
      </f7-nav-left>
      <f7-nav-title>
        {{ this.savings.name }}
      </f7-nav-title>
      <f7-nav-right v-if="this.$keycloak.authenticated && this.currentParticipantIsAdmin">
        <f7-link icon-aurora="f7:person_crop_circle_fill_badge_plus" icon-ios="f7:person_crop_circle_fill_badge_plus"
          icon-md="material:person_add" color="red" href="#" popup-open=".addNewMember"></f7-link>
        <f7-link icon-aurora="f7:pencil" icon-ios="f7:pencil" icon-md="material:edit"
          :href="'/saving/edit/' + this.savings.id"></f7-link>
      </f7-nav-right>
    </f7-navbar>

    <f7-toolbar tabbar labels top :scrollable="this.savings && this.savings.battleList && this.savings.battleList.length > 6
        ? true
        : false
      " no-shadow class="detailToolbar">
      <f7-link tab-link="#mainPage" tab-link-active :text="isTablet ? this.savings.name : ''">
        <img v-if="this.savings.logo" :src="`${this.savings.logo}`" width="40" height="40" slot="media"
          style="border-radius: 50%" class="margin-left-half" />
        <f7-badge v-else-if="this.savings && this.savings.name !== null" color="auto" slot="media"
          class="margin-left-half" :style="{
            height: theme.md ? '27px' : '27px',
            width: theme.md ? '27px' : '27px',
            'min-width': theme.md ? '27px' : '27px',
            'border-radius': '50%',
          }">{{ $filtersGetInitial.getInitial(savings.name) }}</f7-badge>
      </f7-link>
      <f7-link v-for="(battle, index) in this.savings.battleList" :key="index + 1" :text="isTablet ? battle.name : ''"
        :tab-link="`#tab-${battle.battleNumber}`" @tab:show="selectBattle(battle)">
        <f7-badge slot="media" color="auto" :style="{
          height: theme.md ? '27px' : '27px',
          width: theme.md ? '27px' : '27px',
          'min-width': theme.md ? '27px' : '27px',
          'border-radius': '50%',
        }">{{ battle.battleNumber }}</f7-badge>
      </f7-link>
    </f7-toolbar>

    <f7-tabs swipeable class="detailTabs">
      <f7-tab id="mainPage" class="page-content margin-bottom padding-bottom" tab-active
        style="padding-bottom: 120px !important">
        <div class="layout_content">
          <div class="left_layout">
            <div class="sticky with_content">
              <f7-card outline header-divider v-if="
                this.savings &&
                this.$keycloak.authenticated &&
                this.currentParticipant &&
                this.savings.savingsGroupAdminList !== null
              ">
                <f7-card-header>
                  {{ $t("savingsdetail.participants") }}

                  <f7-badge v-if="currentParticipantIsAdmin" bg-color="red" :style="{
                    position: 'absolute',
                    right: '16px',
                    top: '8px',
                    'border-radius': '50px',
                    height: '32px',
                    width: '32px',
                    'min-width': '32px',
                  }" class="elevation-3">
                    <f7-link icon-aurora="f7:person_crop_circle_fill_badge_plus"
                      icon-ios="f7:person_crop_circle_fill_badge_plus" icon-md="material:person_add" color="white"
                      href="#" popup-open=".addNewMember"></f7-link>
                  </f7-badge>
                </f7-card-header>
                <membersComponent :entityId="this.savings.id" :memberList="this.savings.savingsGroupUserList"
                  :adminList="savings.savingsGroupAdminList" :entityType="'savings'"
                  :isAdmin="currentParticipantIsAdmin" :mainUserId="savings.groupOwnerUserId"></membersComponent>
              </f7-card>
            </div>
          </div>
          <div class="main_layout">
            <f7-card v-if="!this.savings" class="skeleton-text skeleton-effect-blink demo-facebook-card">
              <f7-card-header class="no-border">
                <div class="demo-facebook-avatar padding-right">
                  <f7-badge slot="media" color="gray"
                    style="height: 40px; width: 40px; font-size: 16px; border-radius: 50%">GT</f7-badge>
                </div>
                <div class="tontineTitle demo-facebook-name">Savings name</div>
                <div class="demo-facebook-date tontineTitle">Savings tag</div>
              </f7-card-header>
              <f7-card-content style="width: auto; height: 400px">
                <div class="timeline">
                  <div class="timeline-item" v-for="x in 6" :key="x">
                    <div class="timeline-item-date">2021-11>2022-12</div>
                    <div class="timeline-item-divider"></div>
                    <div class="timeline-item-content">
                      <div class="timeline-item-title">Savings name</div>
                      <div class="timeline-item-inner">
                        Collected
                        <span style="font-weight: bold; color: green"> 250 EUR</span><br />Balance
                        <span style="font-weight: bold"> 200 EUR </span>
                      </div>
                    </div>
                  </div>
                </div>
              </f7-card-content>
              <f7-list dividers-ios strong-ios accordion-list media-list>
                <f7-list-item accordion-item :title="$t('common.description')">
                  <f7-accordion-content>
                    <f7-block> </f7-block>
                  </f7-accordion-content>
                </f7-list-item>
              </f7-list>
              <f7-button fill large style="border-radius: 0px" class="buttons" color="gray">{{ $t("common.seeDetail")
                }}</f7-button>
            </f7-card>
            <f7-card class="elevation-3" v-else>
              <f7-card-content :padding="false" v-if="this.savings">
                <swiper-container slidesPerView="1" :spaceBetween="0" :disableOnInteraction="true">
                  <swiper-slide v-if="this.savings.battleList && this.savings.battleList.length > 0"
                    class="align-content-center" :style="{
                      background:
                        'linear-gradient(90deg, rgba(131,58,180,1) 0%, rgba(29,253,36,1) 50%, rgba(252,176,69,1) 100%)',

                      height: '400px',
                      'overflow-y': 'auto',
                    }">
                    <div class="timeline timeline-horizontal grid grid-cols-1 medium-grid-cols-2">
                      <div v-for="battle in this.savings.battleList" href="#" :key="battle.id" class="timeline-item">
                        <div class="timeline-item-date" @click="selectBattle(battle)">
                          <f7-badge slot="media" color="auto" width="24" height="24px"
                            style="height: 20px; width: 20px; font-size: 12px">{{ battle.battleNumber }}</f7-badge>
                          {{ battle.name }}
                        </div>
                        <div class="timeline-item-content" style="height: max-content">
                          <div class="timeline-item-inner">
                            <div class="timeline-item-title" style="font-weight: bold; color: auto">
                              {{ $t("common.contribution") }}:
                              {{
                                $filtersToCurrency.toCurrency(
                                  battleContribution(battle),
                                  null,
                                  battle.currencyCode
                                )
                              }}
                            </div>
                            <span class="timeline-item-subtitle" v-if="battleContribution(battle) > 0">{{
                              $t("common.savingsTarget") }}:
                              <f7-badge color="green">{{
                                (parseFloat(battle.savingsTarget) *
                                  battle.participantList.length *
                                  100) /
                                battleContribution(battle)
                              }}%</f7-badge> </span><br v-if="battleContribution(battle) > 0" />

                            <small class="timeline-item-text" v-if="!!battle.startDate">{{ battle.startDate.substr(0, 7)
                              }} >
                              {{ battle.endDate.substr(0, 7) }}</small>
                          </div>

                          <div v-if="
                            battle.participantList && battle.participantList.length > 0
                          " class="timeline-item-inner" v-for="(participant, index) in battle.participantList">
                            <div class="timeline-item-title">
                              <f7-badge slot="media" color="auto" width="24" height="24px"
                                style="height: 20px; width: 20px; font-size: 12px">{{ battle.battleNumber }}</f7-badge>
                              {{ participant.name }}
                            </div>
                            <div class="timeline-item-subtitle">
                              {{ $t("common.contribution") }}:
                              <span style="font-weight: bold; color: auto" v-html="$filtersToCurrency.toCurrency(
                                participant.contribAmount,
                                null,
                                participant.currencyCode
                              )
                                "></span>
                              <div class="timeline-item-text">
                                {{ $t("common.savingsTarget") }}:
                                <span style="font-weight: bold" v-html="$filtersToCurrency.toCurrency(
                                  battle.savingsTarget,
                                  null,
                                  battle.currencyCode
                                )
                                  ">
                                </span>
                                <span class="padding-left-half"><f7-badge color="green">{{
                                  (
                                    (parseFloat(participant.contribAmount) * 100) /
                                    parseFloat(battle.savingsTarget)
                                  ).toFixed(0)
                                }}%</f7-badge>
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </swiper-slide>
                  <swiper-slide v-if="this.savings && this.savings.qr !== null" style="height: 400px; width: 100%">
                    <a link="#" @click="$refs.popup.open()"
                      style="display: flex; align-items: center; justify-content: center">
                      <img :src="`${this.savings.qr}`" style="
                          display: block;
                          margin: auto;
                          position: absolute;
                          top: 0;
                          bottom: 0;
                        " />
                    </a>
                  </swiper-slide>
                </swiper-container>
              </f7-card-content>
              <f7-button v-if="this.savings.battleList && this.savings.battleList.length > 0" raised fill ripple large
                @click="goToContributeNoBattle()" style="border-radius: 0px"
                class="no-margin-horiztontal buttons contributeButton" color="red">{{ $t("common.contribute") }}
              </f7-button>
            </f7-card>

            <f7-list dividers-ios strong-ios accordion-list media-list class="no-margin-top">
              <f7-list-item accordion-item :title="$t('common.description')">
                <f7-accordion-content>
                  <f7-block>
                    <p v-html="this.savings.description"></p>
                  </f7-block>
                </f7-accordion-content>
              </f7-list-item>
            </f7-list>
            <div class="right not_sticky_with_content">
              <campaignRounds :entity="this.savings" :entityType="'savings'" :isAdmin="this.currentParticipantIsAdmin">
              </campaignRounds>
            </div>

            <f7-card outline class="left not_sticky_with_content" v-if="
              this.savings &&
              this.$keycloak.authenticated &&
              this.currentParticipant &&
              this.savings.savingsGroupAdminList !== null
            ">
              <f7-card-header>
                <div>{{ $t("savingsdetail.participants") }}</div>
                <div></div>
                <f7-badge v-if="currentParticipantIsAdmin" bg-color="red" :style="{
                  position: 'absolute',
                  right: '16px',
                  top: '8px',
                  'border-radius': '50px',
                  height: '32px',
                  width: '32px',
                  'min-width': '32px',
                }" class="elevation-3">
                  <f7-link icon-aurora="f7:person_crop_circle_fill_badge_plus"
                    icon-ios="f7:person_crop_circle_fill_badge_plus" icon-md="material:person_add" color="white"
                    href="#" popup-open=".addNewMember"></f7-link>
                </f7-badge>
              </f7-card-header>
              <membersComponent :entityId="this.savings.id" :memberList="this.savings.savingsGroupUserList"
                :adminList="savings.savingsGroupAdminList" :entityType="'savings'" :isAdmin="currentParticipantIsAdmin"
                :mainUserId="savings.groupOwnerUserId"></membersComponent>
            </f7-card>
          </div>
          <div class="right_layout">
            <div class="sticky with_content">
              <campaignRounds :entity="this.savings" :entityType="'savings'" :isAdmin="this.currentParticipantIsAdmin">
              </campaignRounds>
            </div>
          </div>
        </div>
      </f7-tab>

      <f7-tab v-if="
        this.savings && this.savings.battleList && this.savings.battleList.length > 0
      " class="page-content margin-bottom padding-bottom" v-for="(battle, index) in this.savings.battleList"
        :key="index" :id="`tab-${battle.battleNumber}`" @tab:show="selectedTab(battle)">
        <div class="layout_content">
          <div class="left_layout"></div>
          <div class="main_layout">
            <f7-card class="demo-facebook-card" :id="`campaingView-${battle.battleNumber}`" header-divider>
              <f7-card-header text-color="auto">
                <div class="demo-facebook-avatar padding-right">
                  <f7-badge slot="media" color="auto"
                    style="height: 40px; width: 40px; font-size: 16px; border-radius: 50%">{{
                      $filtersGetInitial.getInitial(battle.name) }}</f7-badge>
                </div>
                <div style="white-space: normal; word-wrap: break-word; font-weight: 90%">
                  {{ battle.name }}
                </div>

                <div class="demo-facebook-date item-label" style="font-size: small">
                  {{
                    $t("associationdetail.fromto", {
                      startDate: battle.startDate.substr(0, 10),
                      endDate: battle.endDate.substr(0, 10),
                    })
                  }}
                </div>
              </f7-card-header>

              <f7-card-content>
                <div class="grid xsmall-grid-cols-1 small-grid-cols-2">
                  <small :style="{ opacity: 0.7 }">{{ $t("common.amount") }}</small>
                  <br /><b>
                    <span v-html="$filtersToCurrency.toCurrency(
                      battleContribution(battle),
                      null,
                      battle.currencyCode
                    )
                      "></span>
                  </b>
                  <br />

                  <!-- <small :style="{ opacity: 0.7 }">{{ $t("common.balance") }}</small>
              <br /> -->
                  <f7-gauge v-if="
                    battle.participantList !== null &&
                    battle.participantList.length !== null
                  " type="circle" :value="(parseFloat(battleContribution(battle)) /
                        parseFloat(battle.savingsTarget)) *
                      battle.participantList.length
                      " :valueText="$filtersToCurrency.toCurrency(
                      battleContribution(battle),
                      null,
                      battle.currencyCode
                    )
                      " valueTextColor="auto" border-color="green" :labelText="$t('common.collected')"
                    labelTextColor="#4caf50" :labelFontWeight="600" :labelFontSize="12" :borderWidth="30"
                    bg-color="white" />

                  <f7-list dividers-ios strong-ios media-list>
                    <f7-list-item v-for="(participant, index) in battle.participantList" :title="participant.name"
                      :subtitle="$filtersToCurrency.toCurrency(
                        participant.contribAmount,
                        null,
                        participant.currencyCode
                      )
                        ">
                      <template #media>
                        <f7-badge color="auto" style="
                            height: 32px;
                            width: 32px;
                            font-size: 16px;
                            border-radius: 25px;
                          ">{{ index + 1 }}</f7-badge>
                        <img v-if="participant.avatar" :src="`${participant.avatar}`" width="40"
                          style="height: 28px; width: 28px; border-radius: 25px" />

                        <f7-badge color="auto" v-else style="
                            height: 28px;
                            width: 28px;
                            font-size: 12px;
                            border-radius: 25px;
                          ">{{ $filtersGetInitial.getInitial(participant.name) }}</f7-badge>
                      </template></f7-list-item>
                  </f7-list>
                </div>
                <f7-list dividers-ios strong-ios media-list v-if="savings && isAuthenticated && currentParticipant"
                  class="margin-top">
                  <f7-list-item :title="$t('common.selectManager')" smart-select :smart-select-params="{
                    openIn: isTablet ? 'popover' : 'sheet',
                    sheetSwipeToClose: true,
                    closeOnSelect: true,
                  }" @smartselect:close="changeBattleManager()" v-model="battleManager">
                    <select name="battleManager">
                      <option v-for="participant in battle.participantList" :key="participant.id"
                        :value="participant.userId">
                        {{ participant.name }}
                      </option>
                    </select>
                  </f7-list-item>
                </f7-list>
              </f7-card-content>
              <transactionCharts v-if="battle.transactionList.length > 0"></transactionCharts>
              <f7-button raised fill ripple large style="border-radius: 0px"
                class="no-margin-horiztontal card_button buttons" @click="goToContribute(battle)" color="red">{{
                  $t("common.contribute") }}</f7-button>
            </f7-card>

            <div class="left not_sticky_with_content">
              <getTransactions :transactionList="battle.transactionList" v-if="battle.transactionList.length > 0">
              </getTransactions>
            </div>
          </div>
          <div class="right_layout">
            <div class="sticky with_content">
              <getTransactions :transactionList="battle.transactionList" v-if="battle.transactionList.length > 0">
              </getTransactions>
            </div>
          </div>
        </div>
      </f7-tab>
    </f7-tabs>

    <addNewBattle :savings="this.savings" />
    <template #fixed>
      <f7-fab v-if="this.savings && this.currentParticipantIsAdmin && !this.currentBattle" position="center-bottom"
        :text="$t('savingsdetail.addNewBattle')" color="red" @click="addNewBattlePopup()">
        <f7-icon ios="f7:plus" aurora="f7:plus" md="material:add"></f7-icon>
      </f7-fab>
      <f7-fab v-if="this.savings && this.currentBattle && !this.currentParticipantIsInBattle" position="center-bottom"
        :text="$t('savingsdetail.joinBattle')" color="red" @click="joinBattle()">
        <f7-icon ios="f7:plus" aurora="f7:plus" md="material:add"></f7-icon>
      </f7-fab>
      <f7-fab position="left-bottom" color="auto" @click="goToMessageGroup()">
        <f7-icon ios="f7:ellipses_bubble_fill" aurora="f7:ellipses_bubble_fill" md="material:chat"></f7-icon>
      </f7-fab>
      <f7-fab position="right-bottom" color="auto" class="shareButton" @click="socialShare(sharedContent)">
        <f7-icon aurora="f7:square_arrow_up" ios="f7:square_arrow_up" md="material:share"></f7-icon>
      </f7-fab>
    </template>
    <sendMessage :entityId="this.savings.id" :memberList="this.savings.savingsGroupUserList" :entityType="'savings'"
      :entity="this.savings" :currentUser="this.currentParticipant"></sendMessage>
    <groupDocuments :entityId="this.savings.id" :projectList="this.savings.battleList" :entityType="'savings'"
      :entity="this.savings" :isAdmin="this.currentParticipantIsAdmin" :currentUser="this.currentParticipant">
    </groupDocuments>
  </f7-page>
</template>

<style scoped></style>

<script>
import {
  f7Page,
  f7Navbar,
  f7NavLeft,
  f7NavTitle,
  f7List,
  f7Link,
  f7Tabs,
  f7Tab,
  f7Card,
  f7CardHeader,
  f7Badge,
  f7CardContent,
  f7PhotoBrowser,
  f7Button,
  f7AccordionContent,
  f7AccordionItem,
  f7Block,
  f7Gauge,
  f7Input,
  f7ListInput,
  f7Popup,
  f7ListGroup,
  f7BlockTitle,
  f7PageContent,
  f7Toolbar,
  f7Chip,
  f7TextEditor,
  f7Fab,
  f7Icon,
  f7ListItem,
  f7,
  theme,
} from "framework7-vue";
import { mapGetters, mapState } from "vuex";
import navbarright from "../components/navbarright.vue";
import addNewBattle from "../components/savingsAddBattle.vue";
import sendMessage from "../components/modules/sendMessageToMembers.vue";
import groupDocuments from "../components/modules/groupDocumentsView.vue";
//import { calculateTransferPayment } from "../js/mixins/transaction.js";
import userslist from "./users.vue";
import { socialShare } from "../js/mixins/cordovaPlugins.js";
import { saving } from "../js/mixins/savings.js";
import { contributeRedeem } from "../js/mixins/contributeRedeem.js";
import membersComponent from "../components/modules/members.vue";
import campaignRounds from "../components/modules/campaignRounds.vue";
import getTransactions from "../components/modules/transactions.vue";
import transactionCharts from "../components/modules/charts.vue";

import _ from "lodash";
export default {
  props: {
    f7route: Object,
    f7router: Object,
  },
  components: {
    navbarright,
    userslist,
    sendMessage,
    groupDocuments,
    addNewBattle,
    getTransactions,
    transactionCharts,
    membersComponent,
    campaignRounds,
    f7Page,
    f7Navbar,
    f7NavLeft,
    f7NavTitle,
    f7List,
    f7Link,
    f7Tabs,
    f7Tab,
    f7Card,
    f7CardHeader,
    f7Badge,
    f7CardContent,

    f7PhotoBrowser,

    f7Button,
    f7AccordionContent,
    f7AccordionItem,
    f7Block,

    f7Gauge,
    f7Input,
    f7ListInput,
    f7Popup,
    f7ListGroup,
    f7BlockTitle,
    f7PageContent,
    f7Toolbar,
    f7Chip,
    f7TextEditor,
    f7Fab,
    f7Icon,
    f7ListItem,
  },
  mixins: [saving, socialShare, contributeRedeem],
  data() {
    return {
      theme,
      f7,
      title: "",
      description: "",
      fundingTarget: "",
      campaignAmount: "",
      campaignPeriod: [],
      battleManager: "",
      cardNumber: "",
      transferCode: "",
      transferPIN: "",
      nocardNumberOrPINError: "",
      currentBattle: "",
      hideOrShowcampaign: "",
      isVisible: false,
      campaignBeneficiary: "",
      hiddenFrom: [],
      sharedContent: "",
      fileToSave: [],
      newParticipants: [],
      type: "savings",
      amount: "",
      setAmount: null,
      paymentCurrency: "",
      project_type: "",
      isAdmin: "",
      isMember: "",
      trxType: "",
      referenceId: "",
      receiver_wallet_id: "",
      sender_wallet_id: "",
      tag: "",
      return_url: "",
      return_url_fail: "",
      countryCode: "",
      project: "",
      entity: "",
    };
  },

  mounted() {
    this.hideOrShowcampaign = this.$t("associationdetail.hideCampaignFromBeneficiary");
    if (!this.userAccount && this.$keycloak.authenticated) {
      this.$store.dispatch("account/loadAccount", this.profile.sub);
    }
    if (this.country === null) {
      this.$store.dispatch("location/getCountry");
    }
    if (!this.savings) {
      this.$store.dispatch("savings/getSavings", this.f7route.params.entityId);
    }
  },
  methods: {
    goToContributeNoBattle() {
      const self = this;

      if (
        this.savings &&
        this.savings.battleList &&
        this.savings.battleList.length == 1
      ) {
        this.goToContribute(this.savings.battleList[0]);
        return;
      }
      const butt = [];
      const label = {
        text: self.$t("savingsdetail.selectBattleToContribute"),
        color: "auto",
        bold: true,
      };

      butt.push(label);
      for (const battle of this.savings.battleList) {
        butt.push({
          text:
            '<span class="badge color-auto">' +
            battle.battleNumber +
            "</span>" +
            " " +
            battle.name,
          onClick() {
            self.goToContribute(battle);
          },
        });
      }
      if (!self.contribToPopover) {
        self.contribToPopover = f7.actions.create({
          convertToPopover: true,
          buttons: [
            butt,
            [
              {
                text: self.$t("common.cancel"),
                color: "red",
              },
            ],
          ],
          targetEl: self.$el.querySelector(".contributeButton"),
        });
      }
      self.contribToPopover.open();
    },
    goToContribute(battle) {
      this.project_type = "contribute";
      this.preparePayment(battle);
      setTimeout(() => {
        this.contribute();
      }, 500);
    },
    redeemCampaign(battle) {
      this.project_type = "redeem";
      this.preparePayment(battle);
      setTimeout(() => {
        this.redeem();
      }, 500);
    },

    async goToMessageGroup() {
      f7.preloader.show("multi");
      setTimeout(() => {
        const chatId =
          this.savings.chatGroupId === "" ? this.savings.id : this.savings.chatGroupId;
        this.f7router.navigate(`/message/${chatId}/GROUP`, {
          reloadAll: true,
        });
        f7.preloader.hide();
      }, 2000);
    },

    updateSavings() {
      this.$store.dispatch("savings/getSavings", this.savings.id);
    },

    addNewBattlePopup() {
      f7.sheet.open(".addNewCampaign");
      this.battleNumber = parseFloat(this.savings.battleList.length) + 1;
      this.savingsTarget = "";
    },

    selectedTab(battle) {
      this.currentBattle = battle;
      console.log(" this.currentBattle", this.currentBattle);
    },
    joinBattle() {
      const currentUser = this.savings.savingsGroupUserList.find(
        (user) => user.userId === this.profile.sub
      );
      this.newParticipants.push(currentUser);
      this.$store.dispatch("savings/joinBattle", {
        newParticipants: this.newParticipants,
        battleId: this.currentBattle.id,
      });
    },

    openMemberList(battle) {
      const self = this;
      this.currentBattle = battle;
      const members = [];
      const label = {
        text: self.$t("savingsdetail.selectBattleManager"),
        color: "auto",
        bold: true,
      };

      participants.push(label);
      for (const participant of battle.participantList) {
        addParticipantsVue.push({
          text:
            participant.name +
            "<br>" +
            "" +
            participant.phoneNumber +
            "<br>" +
            participant.email,
          bold: true,
          onClick() {
            self.changeBattleManager(member);
          },
        });
      }
      if (!self.changeCampaignManagerToPopover) {
        self.changeCampaignManagerToPopover = f7.actions.create({
          convertToPopover: true,
          buttons: [
            members,
            [
              {
                text: self.$t("common.cancel"),
                color: "red",
              },
            ],
          ],
          targetEl: self.$el.querySelector(`.selectChangeBattleManager${battle.id}`),
        });
      }
      self.changeCampaignManagerToPopover.open();
    },

    async changeBattleManager() {
      if (this.battleManager !== this.currentBattle.battleManager) {
        await this.$store.dispatch("savings/addCampaignManager", {
          id: this.currentBattle.id,
          battleManager: this.battleManager,
        });
      }
    },

    getBattleManager(value) {
      const participant = _.find(this.battle.participantList, ["userId", value]);
      if (participant) {
        return participant.name;
      } else {
        return;
      }
    },
  },

  computed: {
    ...mapState("savings", [
      "savings",
      "savingsList",
      "battle",
      "participant",
      "participantList",
      "admin",
      "adminList",
      "savingsTransaction",
    ]),
    ...mapState("transaction", [
      "destinationCountry",
      "destinationCity",
      "grossexchangerate",
      "transferDetail",
      "fromCurrencyRate",
      "toCurrencyRate",
      "transferDetail",
      "paymentDetail",
      "paymentReverseDetail",
    ]),

    ...mapState("location", [
      "destinationCity",
      "country",
      "countryStates",
      "countryCities",
      "currentUserCountry",
    ]),
    ...mapState("account", ["userAccount", "userDetail", "searchedUser"]),
    ...mapState("auth", [
      "phoneNumberDetail",
      "mobilePhoneError",
      "profile",
      "isDeskTop",
      "isTablet",
      "dynamicLink",
    ]),
    ...mapState("transaction", ["destinationCountry", "originCountry"]),

    ...mapGetters("auth", [
      "getProfile",
      "isAuthenticated",
      "isProfileLoaded",
      "getusererror",
    ]),
    ...mapGetters("transaction", [
      "getusererror",
      "fromCurrencyRate",
      "toCurrencyRate",
      "grossexchangerate2",
    ]),
    ...mapState("message", [
      "chatUser",
      "message",
      "chatUserList",
      "messageList",
      "group",
      "groupList",
      "subGroup",
      "subGroupList",
    ]),

    ...mapGetters("account", ["userByAccountNumber"]),

    recipientCountry() {
      if (this.savings && !this.battle) {
        return _.find(this.destinationCountry, [
          "toCountryCode2",
          this.savings.countryCode,
        ]);
      }
      // else if (this.M) {
      //   return _.find(this.destinationCountry, [
      //     "toCountryCode2",
      //     this.M.countryCode,
      //   ]);
      // }
    },
    campaignDays() {
      if (this.campaignPeriod && this.campaignPeriod.length > 1) {
        return (
          (this.campaignPeriod[1].getTime() - this.campaignPeriod[0].getTime()) /
          (1000 * 3600 * 24) +
          " days"
        );
      }
    },

    destinationCtry() {
      return _.groupBy(this.country, "continentName");
    },

    currentParticipant() {
      if (this.profile.sub) {
        return _.find(this.savings.savingsGroupUserList, ["userId", this.profile.sub]);
      }
    },
    currentParticipantIsInBattle() {
      if (this.profile.sub) {
        return _.find(this.currentBattle.participantList, ["userId", this.profile.sub]);
      }
    },
    currentParticipantIsAdmin() {
      if (this.profile.sub) {
        return _.find(this.savings.savingsGroupAdminList, ["userId", this.profile.sub]);
      }
    },

    daysLeft() {
      return (
        (new Date(this.associationEndDate).getTime() - new Date().getTime()) /
        (1000 * 3600 * 24)
      ).toFixed(0);
    },

    totalCardbasket() {
      return _.reduce(
        this.carditemlist,
        (amount, carditem) => {
          return amount + parseFloat(carditem.cardAmount || 0);
        },
        0
      );
    },

    sortedBattleList() {
      return _.orderBy(this.savings.battleList, ["battleNumber"], ["desc"]);
    },
  },
};
</script>
