<template>
  <f7-page @page:beforeout="beforeOut">
    <f7-navbar sliding no-shadow>
      <f7-nav-left
        :back-link="$t('common.backButton')"
        :back-link-show-text="false"
      ></f7-nav-left>
      <f7-nav-title>{{
        $t("associationcreatenew.createaccount", { assocType: this.getType })
      }}</f7-nav-title>
      <f7-nav-right>
        <navbarright></navbarright>
      </f7-nav-right>
    </f7-navbar>
    <div class="layout_content">
      <div class="left_layout"></div>
      <div class="main_layout">
        <f7-card outline>
          <f7-card-content strong v-if="this.accountType === 'group'">
            {{ $t("associationcreatenew.manageGroups") }}
          </f7-card-content>
          <f7-card-content strong v-else>
            {{ $t("associationcreatenew.manageAssociations") }}
          </f7-card-content>
        </f7-card>
        <f7-list dividers-ios strong-ios id="createNewAssociation" form>
          <f7-list-group>
            <f7-list-input
              :label="
                $t('associationcreatenew.anSsociationName', {
                  assocType: this.capGetType,
                })
              "
              floating-label
              type="text"
              name="associationTitle"
              :placeholder="
                $t('associationcreatenew.anSsociationName', {
                  assocType: this.capGetType,
                })
              "
              :value="associationTitle"
              @input="associationTitle = $event.target.value"
              :error-message="$t('associationcreatenew.anSsociationInfoErr')"
              :info="
                $t('associationcreatenew.anSsociationInfo', {
                  leftChar: 80 - associationTitle.length,
                })
              "
              required
              validate
              maxlength="80"
              minlength="5"
              clear-button
            ></f7-list-input>
            <f7-list-input
              v-if="this.accountType === 'association'"
              :label="$t('associationcreatenew.registrationNumber')"
              floating-label
              :placeholder="$t('associationcreatenew.registrationNumber')"
              type="text"
              :value="registrationNumber"
              @input="registrationNumber = $event.target.value"
              :info="$t('associationcreatenew.registrationNumberInfo')"
              :error-message="$t('associationcreatenew.registrationNumberInfo')"
              required
              validate
              clear-button
            >
            </f7-list-input>
            <f7-list-input
              :label="$t('common.tag')"
              floating-label
              :info="$t('common.tagInfo')"
              :error-message="$t('common.tagInfo')"
              :value="tag"
              name="tag"
              @input="tag = $event.target.value"
              resizable
              required
              validate
              maxlength="80"
              type="text"
            />
            <f7-block-title class="item-header">{{
              $t("associationcreatenew.pleaseProvideDescription")
            }}</f7-block-title>
            <f7-text-editor
              :value="description"
              name="description"
              :placeholder="$t('associationcreatenew.pleaseProvideDescription')"
              @texteditor:change="(v) => (description = v)"
              :style="
                f7.device.cordova || !this.isTablet
                  ? '--f7-text-editor-height: 150px'
                  : ''
              "
              required
              validate
              resizable
            />

            <myDocs type:this.getType />
          </f7-list-group>
        </f7-list>
        <f7-toolbar
          tabbar
          bottom
          no-hairline
          bg-color="red"
          :style="{ bottom: 0, position: isTablet ? 'initial' : 'fixed' }"
        >
          <f7-link
            raised
            fill
            class="link"
            large
            ripple
            @click="createNewAssociation()"
            strong
            text-color="white"
            >{{
              $t("associationcreatenew.createaccount", { assocType: this.getType })
            }}</f7-link
          >
        </f7-toolbar>
      </div>
      <div class="right_layout"></div>
    </div>
  </f7-page>
</template>

<style scoped>
.calendar-day.calendar-day-prev {
  pointer-events: none;
}
</style>

<script>
import {
  f7Page,
  f7Navbar,
  f7NavLeft,
  f7NavRight,
  f7NavTitle,
  f7Card,
  f7CardContent,
  f7List,
  f7ListGroup,
  f7ListInput,
  f7BlockTitle,
  f7TextEditor,
  f7Link,
  f7Toolbar,
  f7,
  theme,
} from "framework7-vue";
import { mapGetters, mapState } from "vuex";
import Navbarright from "../components/navbarright.vue";
import { association } from "../js/mixins/association.js";
import attachDoc from "../components/attachDocs.vue";
import myDocs from "../components/myDocs.vue";
import { attachDocs } from "../js/mixins/attachDocs.js";
import _ from "lodash";
export default {
  props: {
    f7route: Object,
    f7router: Object,
  },
  components: {
    navbarright: Navbarright,
    attachDoc,
    myDocs,
    f7Page,
    f7Navbar,
    f7NavLeft,
    f7NavRight,
    f7NavTitle,
    f7Card,
    f7CardContent,
    f7List,
    f7ListGroup,
    f7ListInput,
    f7BlockTitle,
    f7TextEditor,
    f7Link,
    f7Toolbar,
  },
  mixins: [association, attachDocs],
  data() {
    return {
      theme,
      f7,
      registrationNumber: "",
      projectCategoryId: "",
      associationTitle: "",
      tag: "",
      description: "",
      files: "",
      countryCode: "",
      accountType: "",
      forms: "",
    };
  },
  mounted() {
    this.accountType = this.f7route.params.accountType;
    if (!this.userAccount && this.$keycloak.authenticated) {
      this.$store.dispatch("account/loadAccount", this.profile.sub);
      this.$store.dispatch("location/loadDestinationCity", this.profile.countryCode);
      this.paymentCurrency = this.profile.currencyCode;
    }
  },
  methods: {
    async createNewAssociation() {
      f7.preloader.show("multi");
      if (!f7.input.validateInputs("#createNewAssociation")) {
        return;
      }
      const newAssociation = {
        name: this.associationTitle,
        registrationNumber: this.registrationNumber,
        mainAdminUserId: this.profile.sub,
        description: this.description,
        tag: this.tag,
        type: this.accountType,
        isActive: true,
        countryCode: this.profile.countryCode,
      };
      this.forms = new FormData();
      this.forms.append("association", JSON.stringify(newAssociation));
      this.forms.append("userId", JSON.stringify(this.profile.sub));

      for (const element of this.documentsUrl) {
        this.forms.append("files", element.file);
      }
      await this.$store.dispatch("asso/createNewAssociation", this.forms);

      setTimeout(() => {
        this.$store.dispatch("auth/clearDocumentsUrl");
        // this.f7router.navigate(`/association/${this.association.id}`);
        f7.preloader.hide();
      }, 300);
    },
    attachFileOpen(dbtn) {
      console.log("dbtn", dbtn);
      this.$refs.attachDocuments.attachFileOpenActions();
    },
    beforeOut() {
      f7.$(".mainToolbar").css("display", "block");
    },
  },

  computed: {
    ...mapState("asso", [
      "association",
      "campaign",
      "member",
      "campaignTransaction",
      "admin",
    ]),

    ...mapState("location", [
      "destinationCity",
      "country",
      "countryStates",
      "countryCities",
      "currentUserCountry",
    ]),
    ...mapState("account", ["userAccount", "userDetail"]),
    ...mapState("auth", [
      "phoneNumberDetail",
      "mobilePhoneError",
      "profile",
      "isDeskTop",
      "isTablet",
      "documentsUrl",
      "document",
    ]),
    ...mapState("transaction", ["destinationCountry", "originCountry"]),

    ...mapGetters("auth", [
      "getProfile",
      "isAuthenticated",
      "isProfileLoaded",
      "getusererror",
    ]),
    ...mapGetters("transaction", [
      "getusererror",
      "fromCurrencyRate",
      "toCurrencyRate",
      "grossexchangerate2",
    ]),

    ...mapGetters("remit", ["recipients"]),
    ...mapGetters("account", ["userByAccountNumber"]),
  },
};
</script>
