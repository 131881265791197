import axios from "axios";
const topic = import.meta.env.VITE_APP_URL + "topic";
const notification = import.meta.env.VITE_APP_URL + "notification";
import _ from "lodash";

export default (app) => {
  return {
    namespaced: true,
    state: {
      msgCltConnected: false,
      messageSocketClient: "",
      deviceToken: "",
      topic: "",
      topicList: "",
      notificationList: [],
      newNotifList: [],
    },
    getters: {},
    mutations: {
      MSG_CONNECT_SOCKET(state, msgCltConnected) {
        state.msgCltConnected = msgCltConnected;
      },

      MSG_CONNECT_SOCKET_CLIENT(state, messageSocketClient) {
        state.messageSocketClient = messageSocketClient;
      },

      REGISTER_TOKEN(state, deviceToken) {
        state.deviceToken = deviceToken;
      },
      CREATE_TOPIC(state, topic) {
        state.topic = topic;
      },
      GET_TOPICLIST(state, topicList) {
        state.topicList = topicList;
      },
      GET_NOTIFICATION_LIST(state, notificationList) {
        state.newNotifList = notificationList;
        state.notificationList = state.notificationList.push(
          ...notificationList
        );
      },
      DELETE_NOTIFICATION(state, criteria) {
        const index = state.notificationList.findIndex(
          (notification) => notification.id === criteria.notificationId
        );

        if (index > -1) {
          // Create a new notification object with updated properties
          const updatedNotification = {
            ...state.notificationList[index],
            has_deleted: has_deleted.push(criteria.userId),
          };
          // Replace the old notification with the updated one
          state.notificationList.splice(index, 1, updatedNotification);
        }
      },
      READ_NOTIFICATION(state, criteria) {
        const index = state.notificationList.findIndex(
          (notification) => notification.id === criteria.notificationId
        );

        if (index > -1) {
          // Create a new notification object with updated properties
          const updatedNotification = {
            ...state.notificationList[index],
            has_read: has_read.push(criteria.userId),
          };
          // Replace the old notification with the updated one
          state.notificationList.splice(index, 1, updatedNotification);
        }
      },

      UPDATE_NOTIFICATION_LIST(state, payload) {
        state.notificationList.push(payload);
      },
    },

    actions: {
      msgConnectSocketed({ commit }, msgCltConnected) {
        commit("MSG_CONNECT_SOCKET", msgCltConnected);
        console.log("msgConnectSocketed", msgCltConnected);
      },

      msgConnectSocketClient({ commit }, messageSocketClient) {
        commit("MSG_CONNECT_SOCKET_CLIENT", messageSocketClient);
        console.log("msgConnectSocketedclient", messageSocketClient);
      },

      registerDeviceToken({ commit }, payload) {
        console.log("device token to register", payload);
        // if (app.config.globalProperties.$keycloak.authenticated) {
        setTimeout(() => {
          axios({
            url: `${topic}/register/token`,
            method: "POST",
            data: payload,
            headers: {
              Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
            },
          })
            .then((r) => r.data)
            .then((deviceToken) => {
              commit("REGISTER_TOKEN", deviceToken);
            })
            .catch((err) => {
              console.log(err.response.data);
            });
        }, 5000);
      },

      unregisterDeviceToken(payload) {
        axios({
          url: `${topic}/unregister/token`,
          method: "DELETE",
          params: {
            token: payload,
          },
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((deviceToken) => {})
          .catch((err) => {
            console.log(err.response.data);
          });
      },
      createTopic({ commit, dispatch }, payload) {
        axios({
          url: `${topic}/create`,
          method: "POST",
          data: payload,
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((deviceToken) => {
            commit("CREATE_TOPIC", topic);
            dispatch("getMyTopicList");
          })
          .catch((err) => {
            console.log(err.response.data);
          });
      },
      deleteTopic(payload) {
        axios({
          url: `${topic}/delete`,
          method: "DELETE",
          params: {
            topicId: payload,
          },
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((topic) => {
            dispatch("getMyTopicList");
          })
          .catch((err) => {
            console.log(err.response.data);
          });
      },
      subscribeToTopic({ commit, dispatch }, payload) {
        axios({
          url: `${topic}/subscribe`,
          method: "POST",
          data: payload,
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((topic) => {
            commit("CREATE_TOPIC", topic);
            dispatch("getMyTopicList");
          })
          .catch((err) => {
            console.log(err.response.data);
          });
      },
      subscribeToTopic({ dispatch }, payload) {
        axios({
          url: `${topic}/subscribe`,
          method: "PUT",
          params: {
            topicId: payload,
          },
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((topic) => {
            dispatch("getMyTopicList");
          })
          .catch((err) => {
            console.log(err.response.data);
          });
      },
      getMyTopicList({ commit }) {
        axios({
          url: `${topic}/getAll`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((topicList) => {
            commit("GET_TOPICLIST", topicList);
          })
          .catch((err) => {
            console.log(err.response.data);
          });
      },
    },

    getNotificationList({ commit }, payload, state) {
      axios({
        url: `${notification}/getAll`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
        },
        params: {
          limit: payload.limit,
          skip: payload.skip,
        },
      })
        .then((r) => r.data)
        .then((notificationList) => {
          commit("GET_NOTIFICATION_LIST", notificationList);
        })
        .catch((err) => {
          console.log(err.response.data);
        });
    },
    deleteNotification({ commit }, payload) {
      axios({
        url: `${notification}/delete`,
        method: "PUT",
        params: {
          notificationId: payload,
        },
        headers: {
          Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
        },
      })
        .then((r) => r.data)
        .then((response) => {
          console.log("notification delete", response);
          const criteria = {
            notificationId: payload,
            userId: app.config.globalProperties.$keycloak.subject,
          };
          commit("DELETE_NOTIFICATION", criteria);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    readNotification({ commit }, payload) {
      axios({
        url: `${notification}/read`,
        method: "PUT",
        params: {
          notificationId: payload,
        },
        headers: {
          Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
        },
      })
        .then((r) => r.data)
        .then((response) => {
          console.log("notification read", response);
          const criteria = {
            notificationId: payload,
            userId: app.config.globalProperties.$keycloak.subject,
          };
          commit("READ_NOTIFICATION", criteria);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    updateNotificationList({ commit }, payload) {
      commit("UPDATE_NOTIFICATION_LIST", payload);
    },
  };
};
// export default message;
