<template>
  <f7-popup style="min-height: 100%; --f7-popup-bg-color: #fff" class="addNewParticipant" :tablet-fullscreen="true"
    :backdrop="false" :close-by-backdrop-click="false" :close-on-escape="false">
    <f7-page>
      <f7-navbar no-shadow>
        <div class="left"></div>
        <div class="title">
          {{ $t("tontinedetail.addNewParticipant") }}
        </div>
        <div class="right">
          <f7-link popup-close icon-f7="multiply"></f7-link>
        </div>
      </f7-navbar>
      <div class="layout_content">
        <div class="left_layout"></div>
        <div class="main_layout">
          <f7-block-title large>{{
            $t("tontinedetail.addNewParticipant")
            }}</f7-block-title>
          <userslist> </userslist>

          <f7-card outline header-divider>
            <f7-card-header>
              {{ $t("tontinedetail.participants") }}
            </f7-card-header>
            <f7-card-content>
              <membersComponent :entityId="this.tontine.id" :memberList="this.tontine.tontineParticipantList"
                :adminList="this.tontine.tontineAdminList" :entityType="'tontine'" :isAdmin="currentParticipantIsAdmin"
                :mainUserId="tontine.mainAdminUserId"></membersComponent>
            </f7-card-content>
          </f7-card>

          <f7-toolbar tabbar bottom :style="{ bottom: 0, position: isTablet ? 'initial' : 'fixed' }" bg-color="red"
            v-if="this.searchedUser &&
              this.searchedUser.userId !== this.profile.sub &&
              !tontine.tontineParticipantList.find(
                (x) => x.userId === this.searchedUser.userId
              )
            ">
            <f7-link raised fill large ripple class="link buttons" text-color="white" strong
              @click="addParticipant(searchedUser)">
              {{
                $t("tontinedetail.addAsParticipant", {
                  asParticipant: this.searchedUser.name,
                })
              }}</f7-link>
          </f7-toolbar>
          <f7-chip class="margin" outline style="display: flex; justify-content: center" v-if="this.searchedUser &&
            tontine.tontineParticipantList.find(
              (x) => x.userId === this.searchedUser.userId
            )
          " color="red">{{
                $t("tontinedetail.isAlreadyAParticipant", {
                  isParticipant: this.searchedUser.name,
            })
            }}</f7-chip>
        </div>
        <div class="right_layout"></div>
      </div>
    </f7-page>
  </f7-popup>

  <f7-popup v-if="this.tontine.tontineRoundList !== null" class="addNewRound"
    style="min-height: 100%; --f7-sheet-bg-color: #fff" swipe-to-close :tablet-fullscreen="true" :params="{
      backdrop: false,
      animate: true,
      swipeToClose: true,
      closeByBackdropClick: true,
      closeOnEscape: true,
    }">
    <f7-page>
      <f7-navbar>
        <div class="left"></div>
        <div class="title">
          {{
            $t("tontinedetail.addRoundN", {
              newRound: parseFloat(this.tontine.tontineRoundList.length + 1),
            })
          }}
        </div>
        <div class="right">
          <f7-link popup-close icon-f7="multiply"></f7-link>
        </div>
      </f7-navbar>
      <div class="layout_content">
        <div class="left_layout"></div>
        <div class="main_layout">
          <f7-block-title large v-if="this.tontine && this.tontine.tontineRoundList.length > 0">{{
            $t("tontinedetail.addRoundN", {
              newRound: parseFloat(this.tontine.tontineRoundList.length + 1),
            })
          }}</f7-block-title>
          <form id="addRound">
            <f7-list dividers-ios strong-ios media-list>
              <f7-list-group>
                <f7-list-input v-if="this.tontine && this.tontine.tontineRoundList.length > 0" type="number"
                  name="round" inline-label :label="$t('tontinedetail.round')"
                  :value="parseFloat(this.tontine.tontineRoundList.length + 1)" @input="newRound = $event.target.value"
                  disabled></f7-list-input>

                <f7-list-input :label="$t('tontinedetail.roundAmount')" floating-label type="number"
                  name="tontineRoundAmount" min="0" :value="this.tontine.tontineRoundAmount"
                  @input="tontineRoundAmount = $event.target.value" pattern="[0-9]*" required validate
                  :error-message="$t('tontinecreatenew.tontineRoundAmountInfo')"
                  :info="$t('tontinedetail.pleaseChangeRound')" clear-button>
                  <template #media> {{ this.tontine.currencyCode }} </template>
                </f7-list-input>

                <f7-list-input :label="$t('tontinedetail.selectRoundTaker')" type="select"
                  :placeholder="$t('tontinedetail.selectRoundTaker')" @input="roundTaker = $event.target.value">
                  <option v-for="participant in this.tontine.tontineParticipantList" :key="participant.id"
                    :value="participant.id">
                    {{ participant.name }}
                  </option>
                </f7-list-input>
              </f7-list-group>
            </f7-list>
          </form>
          <f7-toolbar tabbar bottom bg-color="red" :style="{ bottom: 0, position: isTablet ? 'initial' : 'fixed' }">
            <f7-link v-if="this.tontine && this.tontine.tontineRoundList.length >= 0" raised fill large ripple
              class="link buttons" text-color="white" strong @click="addRound()">{{
                $t("tontinedetail.addRoundN", {
                  newRound: parseFloat(this.tontine.tontineRoundList.length + 1),
                })
              }}</f7-link>
          </f7-toolbar>
        </div>
        <div class="right_layout"></div>
      </div>
    </f7-page>
  </f7-popup>
</template>
<style scoped></style>
<script>
import {
  f7Popover,
  f7Block,
  f7Button,
  f7Link,
  f7Sheet,
  f7PageContent,
  f7,
  theme,
} from "framework7-vue";
import { mapGetters, mapState } from "vuex";
import { tontine } from "../js/mixins/tontine.js";
import membersComponent from "../components/modules/members.vue";

import UsersPage from "../pages/users.vue";
import _ from "lodash";
export default {
  props: {
    f7route: Object,
    f7router: Object,
    tontine: Object,
  },
  mixins: [tontine],
  components: {
    userslist: UsersPage,
    membersComponent,
    f7Popover,
    f7Block,

    f7Button,
    f7Link,
    f7Sheet,
    f7PageContent,
  },
  data() {
    return {
      theme,
      f7,
      newRound: "",
      tontineRound: "",
      tontineRoundAmount: "",
      roundTaker: "",
      beneficiaryParticipant: "",
      participantCountry: "",
      roundTakerParticipant: "",
      currentRound: "",
      sharedContent: "",
      contributeButton: "",
    };
  },

  // methods: {

  // },

  computed: {
    ...mapState("tontine", [
      "tontine",
      "projectCategories",
      "tontineOwner",
      "tontineList",
      "participantList",
      "tontineTransaction",
      "thirdPartyAllowedWalletList",
      "privateWalletTransactionList",
      "publicWalletTransactionList",
    ]),
    ...mapState("account", [
      "primaryAccount",
      "businessAccount",
      "userDetail",
      "searchedUser",
    ]),
    ...mapState("auth", ["video", "isDeskTop", "isTablet", "device", "profile"]),
  },
};
</script>
