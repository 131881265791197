<template>
  <f7-panel right cover resizable close-by-backdrop-click :backdrop="false" class="loginPanel"
    v-if="$keycloak.authenticated || this.authenticated" :style="{
      'border-left':
        'var(--f7-page-master-border-width) solid var(--f7-page-master-border-color)',
      width: '260px',
    }">
    <f7-page>
      <f7-navbar no-shadow>
        <f7-nav-left>
          <f7-link href="/user/profile" panel-close v-if="$keycloak.authenticated || this.authenticated">
            <img :src="`${this.userAccount.avatar}`" v-if="this.userAccount && this.userAccount.avatar !== null" style="
                width: 28px;
                height: 28px;
                background-size: cover;
                display: block;
                border-radius: 50%;
              " alt="user avatar or logo" />
            <f7-badge v-else-if="this.profile && this.profile.name !== ''" color="primary" class="elevation-1"
              slot="media" :style="{
                height: theme.md ? '32px' : '28px',
                width: theme.md ? '32px' : '28px',
                'min-width': theme.md ? '32px' : '28px',
                'border-radius': '50%',
              }">{{ $filtersGetInitial.getInitial(profile.name) }}</f7-badge>
          </f7-link>
        </f7-nav-left>
        <f7-nav-title href="/user/profile" v-if="this.profile">{{
          this.profile.given_name
        }}</f7-nav-title>
        <f7-nav-right>
          <f7-link panel-close>
            <f7-icon ios="f7:multiply" aurora="f7:multiply" md="material:close"></f7-icon></f7-link>
        </f7-nav-right>
      </f7-navbar>

      <f7-list dividers-ios strong-ios inset>
        <f7-list-item link="/user/profile" panel-close v-if="$keycloak.authenticated || this.authenticated"
          :title="$t('dashboard.goToMyProfile')">
          <template #media>
            <img :src="`${this.userAccount.avatar}`" v-if="this.userAccount && this.userAccount.avatar !== null" style="
                width: 28px;
                height: 28px;
                background-size: cover;
                display: block;
                border-radius: 50%;
              " alt="user avatar or logo" />
            <f7-badge v-else-if="this.profile && this.profile.name !== ''" color="primary" class="elevation-1"
              slot="media" :style="{
                height: theme.md ? '32px' : '28px',
                width: theme.md ? '32px' : '28px',
                'min-width': theme.md ? '32px' : '28px',
                'border-radius': '50%',
              }">{{ $filtersGetInitial.getInitial(profile.name) }}</f7-badge></template>
        </f7-list-item>
        <f7-list-item small outline raised link="" :title="$t('common.logout')" @click="logout()" panel-close
          style="color: red"><template #media>
            <f7-icon style="color: red" ios="f7:square_arrow_left" aurora="f7:square_arrow_left"
              md="material:exit_to_app"></f7-icon>
          </template>
        </f7-list-item>
        <f7-list-item link="/recipients" panel-close :title="$t('panel-right.myRecipients')"></f7-list-item>
        <f7-list-item link="/dashboard/" panel-close :title="$t('panel-right.myDashboard')"></f7-list-item>
        <f7-list-item panel-close link="/topup/my-account" :title="$t('panel-right.topMeUp')"></f7-list-item>
        <f7-list-item divider></f7-list-item>
        <f7-list-item panel-close link="/tontines" :title="$t('panel-right.myTontines')"></f7-list-item>
        <f7-list-item panel-close link="/wallets/private" :title="$t('panel-right.myWallets')"></f7-list-item>
        <f7-list-item panel-close link="/card/sold" :title="$t('panel-right.soldCards')"></f7-list-item>

        <br />
      </f7-list>
    </f7-page>
  </f7-panel>
</template>
<script>
import {
  f7View,
  f7Page,
  f7Link,
  f7Icon,
  f7List,
  f7ListItem,
  f7,
  theme,
} from "framework7-vue";
import { mapGetters, mapState } from "vuex";
import _ from "lodash";
export default {
  props: {
    f7route: Object,
    f7router: Object,
  },
  components: { f7View, f7Page, f7Link, f7Icon, f7List, f7ListItem },

  data() {
    return { theme, f7, theme, redirect: "" };
  },
  mounted() { },

  methods: {
    login() {
      this.$keycloak.login();
    },
    logout() {
      const logoutURL =
        this.$keycloak.endpoints.logout() +
        "?id_token_hint=" +
        this.$keycloak.idToken +
        "&post_logout_redirect_uri=" +
        this.$keycloak.redirectUri;
      // encodeURIComponent(`${window.location.origin}`)
      const redirect = f7.device.cordova
        ? `${import.meta.env.VITE_AVOCASH_MOBILE_URL_SCHEME}`
        : `${window.location.origin}/`;
      f7.dialog.confirm(this.$t("common.doYouWantToLogout"), () => {
        console.log(logoutURL);
        f7.preloader.show("multi");
        if (f7.device.cordova) {
          this.$keycloak.logout();
        } else {
          this.$keycloak.logout({
            redirectUri: redirect,
          });
        }
        // if (f7.views.main.router.currentRoute.path !== "/") {
        //   setTimeout(() => {
        //     f7.views.main.router.navigate("/");
        //   }, 1500);
        // }

        f7.preloader.hide();
        // } else {
        //   window.location.href = logoutURL;
        // }
      });
    },
  },

  computed: {
    ...mapState("account", ["userAccount"]),
    ...mapGetters("auth", ["getProfile", "isAuthenticated", "isProfileLoaded"]),
    ...mapState("auth", ["profile", "authenticated", "onboardingFinished"]),
  },
};
</script>
