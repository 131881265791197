<template>
  <f7-page @page:beforeout="beforeOut">
    <f7-navbar sliding no-shadow>
      <f7-nav-left
        :back-link="$t('common.backButton')"
        :back-link-show-text="false"
      ></f7-nav-left>
      <f7-nav-title>{{ $t("savingscreatenew.createAGroup") }}</f7-nav-title>
      <f7-nav-right>
        <navbarright></navbarright>
      </f7-nav-right>
    </f7-navbar>
    <div class="layout_content">
      <div class="left_layout"></div>
      <div class="main_layout">
        <f7-card outline>
          <f7-card-content strong>
            {{ $t("savingscreatenew.manageGroups") }}
          </f7-card-content>
        </f7-card>
        <f7-list dividers-ios strong-ios id="createNewSavingsGroup" form>
          <f7-list-group>
            <f7-list-input
              :label="$t('common.name')"
              floating-label
              type="text"
              name="associationTitle"
              :placeholder="$t('common.name')"
              :value="name"
              @input="name = $event.target.value"
              :error-message="$t('common.nameInfo')"
              :info="
                $t('savingscreatenew.anSsociationInfo', {
                  leftChar: 80 - name.length,
                })
              "
              required
              validate
              maxlength="80"
              minlength="5"
              clear-button
            ></f7-list-input>
            <f7-list-input
              :label="$t('common.registrationNumber')"
              floating-label
              :placeholder="$t('common.registrationNumber')"
              type="text"
              :value="registrationNumber"
              @input="registrationNumber = $event.target.value"
              :info="$t('common.registrationNumberInfo')"
              :error-message="$t('common.registrationNumberInfo')"
              required
              validate
              clear-button
            >
            </f7-list-input>
            <f7-list-input
              :label="$t('common.tag')"
              floating-label
              :info="$t('common.tagInfo')"
              :error-message="$t('common.tagInfo')"
              :value="tag"
              name="tag"
              @input="tag = $event.target.value"
              resizable
              clear-button
              required
              validate
              maxlength="80"
              type="text"
            />
            <f7-block-title class="item-header">{{
              $t("common.description")
            }}</f7-block-title>
            <f7-text-editor
              :value="description"
              name="description"
              :placeholder="$t('common.description')"
              @texteditor:change="(v) => (description = v)"
              :style="
                f7.device.cordova || !this.isTablet
                  ? '--f7-text-editor-height: 150px'
                  : ''
              "
              :mode="f7.device.cordova || !this.isTablet ? 'keyboard-toolbar' : 'toolbar'"
              required
              validate
              resizable
            />
            <!-- <myDocs :type="this.getType"></myDocs> -->
          </f7-list-group>
        </f7-list>

        <f7-toolbar
          tabbar
          bottom
          no-hairline
          bg-color="red"
          :style="{ bottom: 0, position: isTablet ? 'initial' : 'fixed' }"
        >
          <f7-link
            raised
            fill
            class="link buttons"
            large
            ripple
            @click="createNewSavingsGroup()"
            strong
            text-color="white"
            >{{ $t("savingscreatenew.savechange") }}</f7-link
          >
        </f7-toolbar>
      </div>
      <div class="right_layout"></div>
    </div>
  </f7-page>
</template>
<style scoped></style>

<script>
import {
  f7Page,
  f7Navbar,
  f7NavLeft,
  f7NavRight,
  f7NavTitle,
  f7Card,
  f7CardContent,
  f7List,
  f7ListGroup,
  f7ListInput,
  f7BlockTitle,
  f7TextEditor,
  f7Link,
  f7Toolbar,
  f7,
  theme,
} from "framework7-vue";
import { mapGetters, mapState } from "vuex";
import Navbarright from "../components/navbarright.vue";
import { saving } from "../js/mixins/savings.js";
import { attachDocs } from "../js/mixins/attachDocs.js";
import attachDoc from "../components/attachDocs.vue";
import myDocs from "../components/myDocs.vue";
import _ from "lodash";
export default {
  props: {
    f7route: Object,
    f7router: Object,
  },
  components: {
    navbarright: Navbarright,
    attachDoc,
    myDocs,
    f7Page,
    f7Navbar,
    f7NavLeft,
    f7NavRight,
    f7NavTitle,
    f7Card,
    f7CardContent,
    f7List,
    f7ListGroup,
    f7ListInput,
    f7BlockTitle,
    f7TextEditor,
    f7Link,
    f7Toolbar,
  },
  mixins: [saving, attachDocs],
  data() {
    return {
      theme,
      f7,
      registrationNumber: "",
      name: "",
      tag: "",
      description: "",
      files: "",
      countryCode: "",
      accountType: "",
      forms: "",
      type: "Savings",
    };
  },
  mounted() {
    if (!this.userAccount && this.$keycloak.authenticated) {
      this.$store.dispatch("account/loadAccount", this.profile.sub);
      this.paymentCurrency = this.profile.currencyCode;

      this.$store.dispatch("location/loadDestinationCity", this.profile.countryCode);
    }
  },
  methods: {
    async createNewSavingsGroup() {
      f7.preloader.show("multi");
      if (!f7.input.validateInputs("#createNewSavingsGroup")) {
        return;
      }
      const savingsGroup = {
        name: this.name,
        registrationNumber: this.registrationNumber,
        groupOwnerUserId: this.profile.sub,
        description: this.description,
        tag: this.tag,
        countryCode: this.profile.countryCode,
        currencyCode: this.paymentCurrency,
      };
      // this.forms = new FormData();
      // this.forms.append("savingsGroup", JSON.stringify(newAssociation));
      // this.forms.append("user", JSON.stringify(this.profile));

      // for (const element of this.documentsUrl) {
      //   this.forms.append("files", element.file);
      // }
      await this.$store.dispatch("savings/createSavings", savingsGroup);

      setTimeout(() => {
        // this.f7router.navigate(`/saving/${this.savings.id}`);
        f7.preloader.hide();
      }, 300);
    },
    beforeOut() {
      f7.$(".mainToolbar").css("display", "block");
    },
  },

  computed: {
    ...mapState("savings", [
      "savings",
      "savingsList",
      "battle",
      "participant",
      "participantList",
      "admin",
      "adminList",
      "savingsTransaction",
    ]),

    ...mapState("location", [
      "destinationCity",
      "country",
      "countryStates",
      "countryCities",
      "currentUserCountry",
    ]),
    ...mapState("account", ["userAccount", "userDetail"]),
    ...mapState("auth", [
      "phoneNumberDetail",
      "mobilePhoneError",
      "profile",
      "isDeskTop",
      "isTablet",
      "documentsUrl",
      "document",
    ]),
    ...mapState("transaction", ["destinationCountry", "originCountry"]),

    ...mapGetters("auth", [
      "getProfile",
      "isAuthenticated",
      "isProfileLoaded",
      "getusererror",
    ]),
    ...mapGetters("transaction", [
      "getusererror",
      "fromCurrencyRate",
      "toCurrencyRate",
      "grossexchangerate2",
    ]),

    ...mapGetters("remit", ["recipients"]),
    ...mapGetters("account", ["userByAccountNumber"]),
  },
};
</script>
