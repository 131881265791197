<template>
  <f7-popup class="addNewCampaign" style="--f7-sheet-bg-color: #fff" swipe-to-close :tablet-fullscreen="true" :params="{
    backdrop: true,
    animate: true,
    swipeToClose: true,
    closeByBackdropClick: true,
    closeOnEscape: true,
  }">
    <f7-page>
      <f7-navbar>
        <div class="left"></div>
        <div class="title">
          {{ $t("groupbuydetail.addNewProject") }}
        </div>
        <div class="right">
          <f7-link popup-close icon-f7="multiply"></f7-link>
        </div>
      </f7-navbar>
      <div class="layout_content">
        <div class="left_layout"></div>
        <div class="main_layout">
          <f7-list dividers-ios strong-ios media-list form id="addNewCampaign">
            <f7-list-group>
              <f7-list-input required validate :placeholder="$t('common.name')" type="text" name="campaign"
                floating-label :label="$t('common.name')" :error-message="$t('common.nameInfo')"
                :info="$t('common.nameInfo')" :value="name" @input="name = $event.target.value"></f7-list-input>
              <f7-list-input :title="$t('common.selectProjectType')" smart-select
                :smart-select-params="{ openIn: this.isTablet ? 'popover' : 'sheet' }" v-model="this.groupBuyType">
                <select name="groupBuyType">
                  <option value="GROUP_BUY" selected>
                    {{ $t("common.groupBuyProject") }}
                  </option>
                  <option value="SNBL">{{ $t("common.saveToBuyProject") }}</option>
                  <option value="BNPL" disabled>
                    {{ $t("common.BuyNowPayLaterProject") }}
                  </option>
                </select>
              </f7-list-input>
              <f7-list-input required validate :placeholder="$t('common.tag')" type="text" name="tag" floating-label
                :label="$t('common.tag')" :error-message="$t('common.tagInfo')" :info="$t('common.tagInfo')"
                :value="tag" @input="tag = $event.target.value"></f7-list-input>
              <f7-block-title class="item-header">{{
                $t("common.description")
                }}</f7-block-title>
              <f7-text-editor :value="description" name="description" :placeholder="$t('common.description')"
                @texteditor:change="(v) => (description = v)" resizable required validate type="texteditor" :mode="f7.device.cordova || !this.isTablet ? 'keyboard-toolbar' : 'toolbar'
                  " :style="f7.device.cordova || !this.isTablet
                    ? '--f7-text-editor-height: 150px'
                    : ''
                    " />

              <f7-list-input :label="$t('associationdetail.selectMyCampaignDateRange')" type="datepicker" floating-label
                :placeholder="$t('associationdetail.selectMyCampaignDateRange')" readonly :value="campaignPeriod"
                @calendar:change="(value) => (campaignPeriod = value)" :calendar-params="{
                  rangePicker: true,
                  closeOnSelect: true,
                  openIn: 'auto',
                  minDate: new Date(),
                  header: true,
                  footer: true,
                }" :error-message="$t('associationdetail.selectMyCampaignDateRange')" :info="this.campaignDays"
                clear-button></f7-list-input>

              <f7-list-input :label="$t('groupbuydetail.fundingTarget')" floating-label type="number"
                name="fundingTarget" :placeholder="$t('groupbuydetail.fundingTarget')" min="0" :value="fundingTarget"
                @input="fundingTarget = $event.target.value" pattern="[0-9]*" clear-button>
                <template #media>
                  {{ this.groupBuy.currencyCode }}
                </template>
              </f7-list-input>

              <f7-list-item>
                <span style="text-align: left">{{
                  $t("groupbuydetail.addFrequency")
                  }}</span>
                <f7-toggle class="topupType" v-model:checked="this.hasFrequency" checked color="auto"></f7-toggle>
              </f7-list-item>
              <span v-if="this.hasFrequency">
                <f7-list-input :label="$t('groupbuydetail.fundingTarget')" floating-label type="number"
                  name="frequencyAmount" :placeholder="$t('common.frequencyAmount')" min="0" :value="frequencyAmount"
                  @input="frequencyAmount = $event.target.value" required validate clear-button>
                  <template #media>
                    {{ this.paymentCurrency }}
                  </template>
                </f7-list-input>
                <div class="grid grid-cols-2">
                  <f7-list-input :title="$t('common.selectFrequencyType')" smart-select
                    :smart-select-params="{ openIn: this.isTablet ? 'popover' : 'sheet' }" v-model="this.frequencyType">
                    <select name="frequencyType">
                      <option value="days" selected>
                        {{ $t("common.inDay") }}
                      </option>
                      <option value="weeks">{{ $t("common.inWeek") }}</option>
                      <option value="months">
                        {{ $t("common.inMonth") }}
                      </option>
                    </select>
                  </f7-list-input>
                  <!-- <f7-list-input :label="$t('common.frequencyType')" type="select" required validate defaultValue="days"
                :placeholder="$t('common.selectFrequencyType')" @input="frequencyType = $event.target.value">
                <option value="days" selected>
                  {{ $t("common.inDay") }}
                </option>
                <option value="weeks">{{ $t("common.inWeek") }}</option>
                <option value="months">
                  {{ $t("common.inMonth") }}
                </option>
              </f7-list-input> -->

                  <f7-list-input :label="$t('common.frequency')" type="number" floating-label
                    :disabled="this.frequencyType === ''" :placeholder="$t('common.frequency', {
                      frequency: this.frequencyType,
                    })
                      " :value="nbrOfFrequency" @input="nbrOfFrequency = $event.target.value" required validate
                    clear-button></f7-list-input>
                </div>
              </span>
              <f7-list-input v-if="this.groupBuy.customerList && this.groupBuy.customerList.length > 2"
                :label="$t('groupbuydetail.selectProjectManager')" type="select"
                :placeholder="$t('groupbuydetail.selectProjectManager')" @input="battleManager = $event.target.value">
                <option v-for="participant in this.groupBuy.customerList" :key="participant.id"
                  :value="participant.userId">
                  {{ participant.name }}
                </option>
              </f7-list-input>
            </f7-list-group>
          </f7-list>
          <f7-toolbar tabbar bottom no-hairline bg-color="red"
            :style="{ bottom: 0, position: isTablet ? 'initial' : 'fixed' }">
            <f7-link raised fill large class="link buttons" text-color="white" strong @click="addCampaign()">{{
              $t("groupbuydetail.addNewProject") }}</f7-link>
          </f7-toolbar>
        </div>
        <div class="right_layout"></div>
      </div>
    </f7-page>
  </f7-popup>
  <f7-popup class="addNewMember" style="min-height: 50%; --f7-popup-bg-color: #fff" :tablet-fullscreen="true"
    :backdrop="false" :close-by-backdrop-click="false" :close-on-escape="false">
    <f7-page>
      <f7-navbar no-shadow>
        <div class="left"></div>
        <div class="title">
          {{ $t("groupbuydetail.addNewParticipant") }}
        </div>
        <div class="right">
          <f7-link popup-close icon-f7="multiply"></f7-link>
        </div>
      </f7-navbar>
      <div class="layout_content">
        <div class="left_layout"></div>
        <div class="main_layout">
          <f7-block-title large class="padding-left">{{
            $t("groupbuydetail.addNewParticipant")
            }}</f7-block-title>
          <userslist> </userslist>
          <f7-block-title large class="padding-left">
            {{ $t("groupbuydetail.participants") }}
          </f7-block-title>
          <f7-list dividers-ios strong-ios media-list accordion-list class="no-margin-vertical"
            :no-chevron="!currentParticipantIsAdmin">
            <f7-list-item v-for="participant in this.groupBuy.customerList" :key="participant.id" accordion-item
              :title="participant.name" :subtitle="participant.phoneNumber" :text="participant.email" :badge="groupBuy.adminList.find((x) => x.userId === participant.userId)
                ? $t('common.admin')
                : ''
                " badge-color="primary">
              <f7-accordion-content v-if="
                currentParticipantIsAdmin &&
                pariticipant.userId !== groupBuy.ownerUserId
              ">
                <f7-button raised fill small large ripple color="red" class="margin-horizontal margin-vertical-half"
                  :text="$t('common.removeAsParticipant')" @click="removeMember(pariticipant)">
                </f7-button>

                <f7-button raised fill small large ripple color="green" class="margin-horizontal margin-vertical-half"
                  @click="grantAdminRole(pariticipant)" :text="$t('common.grantAdminRights')" v-if="
                    !groupBuy.customerList.find((x) => x.userId === participant.userId)
                  ">
                </f7-button>
                <f7-button v-if="groupBuy.adminList.find((x) => x.userId === participant.userId)" raised fill small
                  large ripple color="orange" class="margin-horizontal margin-vertical-half"
                  @click="withdrawAdminRole(participant)" :text="$t('common.withdrawAdminRights')">
                </f7-button>
              </f7-accordion-content>
              <template #media>
                <img v-if="participant.avatar" :src="`${participant.avatar}`" width="40" alt="user avatar"
                  style="height: 48px; width: 48px; border-radius: 25px" />

                <f7-badge color="auto" v-else style="height: 48px; width: 48px; font-size: 16px; border-radius: 25px">
                  {{ $filtersGetInitial.getInitial(participant.name) }}</f7-badge>
              </template>
            </f7-list-item>
          </f7-list>
          <f7-chip class="margin" outline style="display: flex; justify-content: center" v-if="
            this.searchedUser &&
            this.groupBuy.customerList.find(
              (x) => x.userId === this.searchedUser.userId
            )
          " color="red">{{
            $t("groubuydetail.isAlreadyAParticipant", {
              isParticipant: this.searchedUser.name,
            })
          }}</f7-chip>

          <f7-toolbar tabbar bottom :style="{ bottom: 0, position: isTablet ? 'initial' : 'fixed' }" bg-color="red"
            ripple class="link" v-if="
              this.searchedUser &&
              this.searchedUser.userId !== this.profile.sub &&
              !this.groupBuy.customerList.find(
                (x) => x.userId === this.searchedUser.userId
              )
            ">
            <f7-link raised fill large ripple text-color="white" strong class="link buttons"
              @click="addMember(searchedUser)">{{
                $t("groupbuydetail.addAsParticipant", {
                  addParticipant: this.searchedUser.name,
                })
              }}</f7-link>
          </f7-toolbar>
        </div>
        <div class="right_layout"></div>
      </div>
    </f7-page>
  </f7-popup>
</template>
<script>
import {
  f7Popover,
  f7Block,
  f7Button,
  f7Link,
  f7Sheet,
  f7PageContent,
  f7,
  theme,
} from "framework7-vue";
import { mapState } from "vuex";
import { groupbuy } from "../js/mixins/groupBuy.js";
import UsersPage from "../pages/users.vue";
import _ from "lodash";
export default {
  props: {
    f7route: Object,
    f7router: Object,
    groupBuy: Object,
  },
  mixins: [groupbuy],
  components: {
    userslist: UsersPage,
    f7Popover,
    f7Block,

    f7Button,
    f7Link,
    f7Sheet,
    f7PageContent,
  },
  data() {
    return {
      theme,
      f7,
      description: "",
      fundingTarget: "",
      campaignAmount: "",
      campaignPeriod: [],
      projectManager: "",
      hiddenFrom: [],
      campaignBeneficiary: "",
      name: "",
      tag: "",
      hasFrequency: false,
      groupBuyType: "",
      frequencyType: "",
      nbrOfFrequency: "",
      frequencyAmount: "",
    };
  },

  methods: {
    async addCampaign() {
      const self = this;

      if (f7.input.validateInputs("#addNewCampaign")) {
        await self.$store.dispatch("groupBuy/createProject", {
          groupBuyId: this.groupBuy.id,
          project: {
            projectNumber: parseFloat(this.groupBuy.projectList.length) + 1,
            frequencyAmount: this.frequencyAmount,
            startDate: this.campaignPeriod[0],
            endDate: this.campaignPeriod[1],
            fundingTarget: this.fundingTarget,
            description: this.description,
            tag: this.tag,
            name: this.name,
            projectManager: this.profile.sub,
            groupBuyType: this.groupBuyType,
            frequencyType: this.frequencyType,
            nbrOfFrequency: this.nbrOfFrequency,
          },
        });
        f7.sheet.close(".addNewCampaign");
      }
    },
    async addMember(searchedUser) {
      const self = this;
      await self.$store.dispatch("groupBuy/addParticipant", {
        groupBuyId: this.groupBuy.id,
        userId: searchedUser.userId,
      });
      f7.popup.close(".addNewMember");
    },
  },

  computed: {
    ...mapState("savings", [
      "savings",
      "savingsList",
      "battle",
      "participant",
      "participantList",
      "admin",
      "adminList",
      "savingsTransaction",
    ]),
    ...mapState("account", ["userAccount", "userDetail", "searchedUser"]),
    ...mapState("auth", ["video", "isDeskTop", "isTablet", "device", "profile"]),
  },
};
</script>
