<template>
  <f7-page @page:beforeout="beforeOut">
    <f7-navbar sliding no-shadow>
      <f7-nav-left :back-link="$t('common.backButton')" :back-link-show-text="false"></f7-nav-left>
      <f7-nav-title>{{ $t("deliveryAccount.createNewAccount") }}</f7-nav-title>
      <f7-nav-right>
        <navbarright></navbarright>
      </f7-nav-right>
    </f7-navbar>
    <div class="layout_content">
      <div class="left_layout"></div>
      <div class="main_layout">
        <f7-card outline>
          <f7-card-content>{{ $t("deliveryAccount.createNewAccountDetail") }}
          </f7-card-content>
        </f7-card>

        <f7-list dividers-ios strong-ios media-list form id="createDeliveryAccount">
          <f7-list-group>
            <f7-list-input :label="$t('deliveryAccount.companyName')" floating-label type="text" name="companyName"
              :placeholder="$t('deliveryAccount.companyName')" :value="companyName"
              @input="companyName = $event.target.value" :error-message="$t('deliveryAccount.entercompanyNameErr')" :info="$t('deliveryAccount.entercompanyNameInfo', {
                1: 80 - companyName.length,
              })
                " required validate maxlength="80" minlength="5" clear-button></f7-list-input>
            <f7-list-item class="deleveryCities item-label" smart-select :smart-select-params="{
              openIn: 'popup',
              popupSwipeToClose: true,
              popupPush: true,
              searchbar: true,
              searchbarPlaceholder: $t('deliveryAccount.accountDeliveryCities'),
            }" :title="$t('deliveryAccount.accountDeliveryCities')">
              <select id="stateid" name="city" v-model="deliveryCities" multiple>
                <optgroup v-for="(states, stateName, index) in destinationCity.stateList" :data-stateid="states.stateId"
                  :label="states.stateName" :key="index">
                  <option smartselect v-for="(citylist, index) in states.cityList" :value="citylist.cityId"
                    :data-cityname="citylist.cityName" :key="index">
                    {{ citylist.cityName }}
                  </option>
                </optgroup>
              </select>
            </f7-list-item>
            <f7-list-input :label="$t('deliveryAccount.ownerName')" floating-label type="text" name="ownerName"
              :placeholder="$t('deliveryAccount.ownerName')" :value="this.ownerName"
              @input="ownerName = $event.target.value" :error-message="$t('deliveryAccount.enterownerName')" required
              validate clear-button></f7-list-input>
            <f7-list-item :title="$t('shopcreatenew.selectCountry')" class="selectCountry item-label" smart-select
              :smart-select-params="{
                openIn: 'page',
                searchbar: true,
                searchbarPlaceholder: $t('shopcreatenew.findCountry'),
                closeOnSelect: true,
              }" style="display: none">
              <select name="country" v-model="selectedCountry">
                <optgroup v-for="(countryList, continentName, index) in this.countries" :label="continentName"
                  :key="index">
                  <option v-for="country in countryList" :key="country.id" :value="country.countryCode2"
                    :data-option-image="'https://flagcdn.com/' + country.countryCode2.toLowerCase() + '.svg'
                      " width="24">
                    {{ country.name }} {{ country.countryCode2 }} +{{ country.dialCode }}
                  </option>
                </optgroup>
              </select>
            </f7-list-item>
            <f7-list-input :label="$t('deliveryAccount.phoneNumber')" type="tel" name="telephone" input-id="phoneNumber"
              :value="this.phoneNumber" @input="phoneNumber = $event.target.value"
              :error-message="$t('deliveryAccount.providePhoneNumber')" required validate></f7-list-input>
            <f7-list-input :label="$t('deliveryAccount.email')" floating-label type="email" name="email"
              :placeholder="$t('deliveryAccount.email')" :value="this.email" @input="email = $event.target.value"
              :error-message="$t('deliveryAccount.enterEmail')" :info="$t('deliveryAccount.enterEmail')" required validate
              clear-button></f7-list-input>
            <f7-list-input :label="$t('deliveryAccount.address')" floating-label type="textarea" name="address"
              :value="this.accountAddress" :placeholder="$t('deliveryAccount.address')"
              @input="accountAddress = $event.target.value" :error-message="$t('deliveryAccount.enteraddress')" required
              resizable validate clear-button></f7-list-input>

            <f7-block-title class="item-header">{{
              $t("deliveryAccount.accountDescription")
            }}</f7-block-title>
            <f7-text-editor :value="deliveryAccountInfo" name="deliveryAccountInfo"
              :placeholder="$t('deliveryAccount.accountDescriptionPlaceholder')" floating-label
              @texteditor:change="(v) => (deliveryAccountInfo = v)" resizable required validate :style="f7.device.cordova || !this.isTablet
                ? '--f7-text-editor-height: 150px'
                : ''
                " />
            <myDocs :type="this.type"></myDocs>
          </f7-list-group>
        </f7-list>

        <f7-toolbar tabbar bottom no-hairline bg-color="red"
          :style="{ bottom: 0, position: isTablet ? 'absolute' : 'fixed' }">
          <f7-link raised fill class="link buttons" large ripple @click="createDeliveryAccount()" strong
            text-color="white">{{ $t("deliveryAccount.createDeliveryAccount") }}</f7-link>
        </f7-toolbar>
      </div>
      <div class="right_layout"></div>
    </div>
  </f7-page>
</template>
<style scoped>
.calendar-day.calendar-day-prev {
  pointer-events: none;
}
</style>

<script>
import {
  f7Page,
  f7Navbar,
  f7NavLeft,
  f7NavRight,
  f7NavTitle,
  f7Card,
  f7CardContent,
  f7BlockTitle,
  f7TextEditor,
  f7List,
  f7ListGroup,
  f7ListItem,
  f7ListInput,
  f7Toolbar,
  f7Input,
  theme,
  f7,
} from "framework7-vue";
import { mapGetters, mapState } from "vuex";
import Navbarright from "../components/navbarright.vue";
import attachDoc from "../components/attachDocs.vue";
import myDocs from "../components/myDocs.vue";
import { telephoneUtils } from "../js/mixins/telephone.js";
import "intl-tel-input/build/css/intlTelInput.min.css";
import "intl-tel-input/build/js/intlTelInput.js";
import intlTelInput from "intl-tel-input";
import { attachDocs } from "../js/mixins/attachDocs.js";

import _ from "lodash";
export default {
  props: {
    f7route: Object,
    f7router: Object,
  },
  components: {
    navbarright: Navbarright,
    attachDoc,
    myDocs,
    f7Page,
    f7Navbar,
    f7NavLeft,
    f7NavRight,
    f7NavTitle,
    f7Card,
    f7CardContent,
    f7BlockTitle,
    f7TextEditor,
    f7List,
    f7ListGroup,
    f7ListItem,
    f7ListInput,
    f7Toolbar,
    f7Input,
  },
  mixins: [telephoneUtils, attachDocs],
  data() {
    return {
      theme,
      f7,
      companyName: "",
      ownerName: "",
      email: "",
      phoneNumber: "",
      deliveryAccountInfo: "",
      forms: "",
      deliveryCities: [],
      type: "delivery",
      countryCode: "",
      countryPhoneCode: "",
      accountAddress: "",
      phoneCode: "",
      itiPhoneNumber: "",
      selectedCountry: "",
    };
  },
  mounted: function () {
    const self = this;

    if (
      (this.primaryAccount === "" || this.businessAccount === "") &&
      this.$keycloak.authenticated
    ) {
      self.$store.dispatch("account/loadPrimaryAccount");
      self.$store.dispatch("account/loadBusinessAccount");
    }

    if (this.profile) {
      self.$store.dispatch("location/loadDestinationCity", this.profile.countryCode);
      this.email = this.profile.email;
      this.phoneNumber = this.profile.phoneNumber;
      this.ownerName = this.profile.name;
      this.accountAddress = `${this.profile.address.street_address} ${this.profile.address.postal_code} ${this.profile.address.locality}, ${this.profile.address.region} ${this.profile.address.country}`;
      this.countryCode = this.profile.countryCode;
      this.countryPhoneCode = this.profile.phoneCode;
      this.paymentCurrency = this.profile.currencyCode;
      this.selectedCountry = this.profile.countryCode;
    }
    this.changePhoneNumber();
    const phoneNumberInput = document.querySelector("#phoneNumber");
    this.itiPhoneNumber = intlTelInput(phoneNumberInput, {
      separateDialCode: true,
      initialCountry: this.countryCode,
      localizedCountries: this.countryCode,
      dropdownContainer: document.body,
      preferredCountries: [],
    });

    window.intlTelInputGlobals.loadUtils(
      "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.13/js/utils.js"
    );

    phoneNumberInput.addEventListener("blur", function () {
      if (phoneNumberInput.value.trim()) {
        if (this.itiPhoneNumber.isValidNumber()) {
          this.phoneNumber = this.itiPhoneNumber
            .getNumber(intlTelInputUtils.numberFormat.INTERNATIONAL)
            .replace(/\s+/g, "");
          console.log(this.phoneNumber);
        }
      } else {
        const errorCode = this.itiPhoneNumber.getValidationError();
      }
    });
    if (this.selectedCountry) {
      this.itiPhoneNumber.setCountry(this.selectedCountry);
    }
    const smartSelect = f7.smartSelect.get(".selectCountry .smart-select");
    f7.$(".iti__flag-container").click(function () {
      smartSelect.open();
      if (self.telephone !== null) {
        self.telephone = null;
      }
      smartSelect.setValue(this.selectedCountry);
    });
    f7.$(".selectCountry .smart-select").on("smartselect:open", () => {
      self.phoneNumber = null;
      self.selectedCountry = null;
    });
    f7.$(".selectCountry .smart-select").on("smartselect:close", function () {
      this.selectedCountry = smartSelect.getValue();
      console.log(this.selectedCountry);
      self.itiPhoneNumber.setCountry(this.selectedCountry);
      console.log(self.itiPhoneNumber.getSelectedCountryData());
      self.changePhoneNumber();
    });
  },
  methods: {
    async createDeliveryAccount() {
      const self = this;

      f7.preloader.show("multi");
      if (f7.input.validateInputs("#createDeliveryAccount")) {
        const deliveryAccount = {
          companyName: this.companyName,
          ownerName: this.ownerName,
          accountAddress: this.accountAddress,
          deliverAccountInfo: this.deliverAccountInfo,
          email: this.email,
          phoneNumber: this.phoneNumber,
          phoneCode: this.profile.phoneCode,
          primaryAccount: this.primaryAccount.accountNumber,
          businessAccount: this.businessAccount.accountNumber,
          deliveryCities: this.deliveryCities,
          currencyCode: this.profile.currencyCode,
          countryCode: this.profile.countryCode,
          countryName: this.profile.countryName,
        };
        this.forms = new FormData();
        await this.forms.append("deliveryAccount", JSON.stringify(deliveryAccount));

        for (const element of this.documentsUrl) {
          await this.forms.append("file", element.file);
        }
        await self.$store.dispatch("delivery/createDeliveryAccount", this.forms);

        setTimeout(() => {
          this.f7router.navigate("/detail/delivery/account/" + this.deliveryAccount.id);
          self.$store.dispatch("auth/setDocumentsUrl", "");
          f7.preloader.hide();
        }, 500);
      }
    },
    beforeOut() {
      const self = this;
      f7.$(".mainToolbar").css("display", "block");
    },
  },

  computed: {
    ...mapState("delivery", [
      "deliveryAccount",
      "delivery",
      "deliverer",
      "pickuPointAccount",
      "pickuPoint",
    ]),

    ...mapState("location", [
      "destinationCity",
      "country",
      "countryStates",
      "countryCities",
      "currentUserCountry",
    ]),
    ...mapState("account", ["primaryAccount", "businessAccount", "userDetail"]),
    ...mapState("auth", [
      "phoneNumberDetail",
      "mobilePhoneError",
      "profile",
      "isDeskTop",
      "isTablet",
      "documentsUrl",
    ]),
    ...mapState("transaction", ["destinationCountry", "originCountry"]),

    ...mapGetters("auth", [
      "getProfile",
      "isAuthenticated",
      "isProfileLoaded",
      "getusererror",
    ]),
    ...mapGetters("transaction", [
      "getusererror",
      "fromCurrencyRate",
      "toCurrencyRate",
      "grossexchangerate2",
    ]),

    destinationCtry() {
      return _.groupBy(this.country, "continentName");
    },
    countries() {
      return _.groupBy(this.country, "continentName");
    },
  },
};
</script>
