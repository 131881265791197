import axios from "axios";
// import base_url from "../base_url.js";
import _ from "lodash";
const path = import.meta.env.VITE_APP_URL + "tontine";
import { f7 } from "framework7-vue";

// const tontine={
export default (app) => {
  return {
    namespaced: true,
    state: {
      tontine: "",
      round: "",
      participant: "",
      participantList: "",
      admin: "",
      payment: "",
      tontineList: "",
      activeTontineList: "",
      adminList: "",
      tontineImages: "",
      id: "",
      tontineQRImage: "",
      tontineTransaction: "",
      tontineTransactionList: "",
    },
    getters: {
      // recipients: state => {
      //     return state.recipients;
      // },
      // allrecipients: state => {
      //     return state.allrecipients;
      // },
      // recipientsList: state => {
      //     return _.groupBy(state.allrecipients, "countryName");
      // },
      // recipient: state => {
      //     return state.recipient;
      // },
      // userrecipientList: state => {
      //     return state.userrecipientList;
      // }
    },
    mutations: {
      CREATE_TONTINE(state, tontine) {
        state.tontine = tontine;
      },
      GET_TONTINE(state, tontine) {
        state.tontine = tontine;
      },
      GET_TONTINEIMAGES(state, tontineImages) {
        state.tontineImages = tontineImages;
      },

      EDIT_TONTINE(state, tontine) {
        state.tontine = tontine;
      },
      GET_TONTINELIST(state, tontineList) {
        state.tontineList = tontineList;
      },
      GET_ACTIVETONTINELIST(state, activeTontineList) {
        state.activeTontineList = activeTontineList;
      },

      GET_TONTINEIMAGE(state, tontineImages) {
        state.tontineImages = tontineImages;
      },
      DELETE_ACCOUNTIMAGE(state, payload) {
        state.id = payload;
      },
      GET_PARTICIPANT(state, participant) {
        state.participant = participant;
      },
      GET_PARTICIPANTLIST(state, participantList) {
        state.participantList = participantList;
      },
      REMOVE_PARTICIPANT(state, payload) {
        state.id = payload;
      },
      GET_TONTINETRANSACTION(state, tontineTransaction) {
        state.tontineTransaction = tontineTransaction;
      },

      GET_TONTINE_TRANSACTIONLIST(state, tontineTransactionList) {
        state.tontineTransactionList = tontineTransactionList;
      },

      CREATE_QRCODE(state, payload) {
        state.tontineQRImage = payload;
      },
      READ_QRCODE(state, payload) {
        state.tontine = payload;
      },
    },

    actions: {
      createTontine({ commit }, payload) {
        axios({
          url: `${path}/create`,
          method: "POST",
          data: payload,
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((tontine) => {
            commit("GET_TONTINE", tontine);
            f7.views.main.router.navigate(`/tontine/${tontine.id}`);
          })
          .catch((err) => {
            console.log(err.errorMessage);
          });
      },
      getCreatedTontine({ commit }, payload) {
        axios({
          url: `${path}/getCreatedTontine/${payload}`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((tontine) => {
            commit("GET_TONTINE", tontine);
          })
          .catch((err) => {
            console.log(err);
          });
      },
      editTontine({ commit }, payload) {
        axios({
          url: `${path}/edit`,
          method: "PUT",
          data: payload,
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((tontine) => {
            commit("GET_TONTINE", tontine);
          })
          .catch((err) => {
            console.log(err);
          });
      },

      createRound({ commit }, payload) {
        console.log(payload);
        axios({
          url: `${path}/round/create`,
          method: "POST",
          data: payload.round,
          params: {
            userId: app.config.globalProperties.$keycloak.subject,
            participantId: payload.participantId,
            tontineId: payload.tontineId,
          },
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((tontine) => {
            commit("GET_TONTINE", tontine);
          })
          .catch((err) => {
            console.log(err.errorMessage);
          });
      },
      changeRoundTaker({ commit }, payload) {
        axios({
          url: `${path}/round/change/roundTaker`,
          method: "PUT",
          data: payload,
          params: {
            userId: app.config.globalProperties.$keycloak.subject,
          },
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((tontine) => {
            commit("GET_TONTINE", tontine);
          })
          .catch((err) => {
            console.log(err.errorMessage);
          });
      },
      editRound({ dispatch }, payload) {
        console.log(payload);
        axios({
          url: `${path}/round/edit`,
          method: "PUT",
          data: payload.round,
          params: {
            userId: app.config.globalProperties.$keycloak.subject,
            participantId: payload.participantId,
            tontineId: payload.tontineId,
          },
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((tontine) => {
            commit("GET_TONTINE", tontine);
          })
          .catch((err) => {
            console.log(err.errorMessage);
          });
      },

      getTontineById({ commit }, payload) {
        axios({
          url: `${path}/get/${payload}`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((tontine) => {
            commit("GET_TONTINE", tontine);
            console.log(tontine);
          })
          .catch((err) => {
            console.log(err);
          });
      },
      getTontineImagesById({ commit }, payload) {
        axios({
          url: `${path}/images/${payload}`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((tontine) => {
            commit("GET_TONTINE", tontine);
          })
          .catch((err) => {
            console.log(err);
          });
      },
      getUserTontinesByuserId({ commit }, payload) {
        axios({
          url: `${path}/participant/${app.config.globalProperties.$keycloak.subject}`,
          method: "GET",
          params: {
            maxNumberOfItem: payload.maxNumberOfItem,
          },
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((tontineList) => {
            commit("GET_TONTINELIST", tontineList);
            console.log(tontineList);
          })
          .catch((err) => {
            console.log(err);
          });
      },
      getUserCoParticipantList({ commit }) {
        axios({
          url: `${path}/participantList/${app.config.globalProperties.$keycloak.subject}`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((participantList) => {
            commit("GET_PARTICIPANTLIST", participantList);
            console.log(participantList);
          })
          .catch((err) => {
            console.log(err);
          });
      },
      getUserActiveTontineList({ commit }, payload) {
        console.log(payload);

        axios({
          url: `${path}/participant/active/${payload.userId}`,
          method: "GET",
          params: {
            maxNumberOfItem: payload.maxNumberOfItem,
          },
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((activeTontineList) => {
            commit("GET_ACTIVETONTINELIST", activeTontineList);
          })
          .catch((err) => {
            console.log(err);
          });
      },

      editTontineImages({ commit }, payload) {
        axios({
          url: `${path}/image/update`,
          method: "PUT",
          data: payload,
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((tontine) => {
            commit("GET_TONTINE", tontine);
          })
          .catch((err) => {
            console.log(err);
          });
      },

      deleteTontineImages({ commit }, payload) {
        axios({
          url: `${path}/image/delete`,
          method: "DELETE",
          params: {
            imageId: payload.imageId,
            tontineId: payload.entityId,
          },
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((tontine) => {
            commit("GET_TONTINE", tontine);
          })
          .catch((err) => {
            console.log(err);
          });
      },

      addParticipant({ commit }, payload) {
        axios({
          url: `${path}/add/participant`,
          method: "POST",
          params: {
            userId: payload.userId,
            tontineId: payload.tontineId,
          },
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((tontine) => {
            commit("GET_TONTINE", tontine);
          })
          .catch((err) => {
            console.log(err);
          });
      },
      removeParticipant({ commit }, payload) {
        axios({
          url: `${path}/remove/participant`,
          method: "PUT",
          params: {
            userId: payload.userId,
            tontineId: payload.tontineId,
          },
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((tontine) => {
            commit("GET_TONTINE", tontine);
          })
          .catch((err) => {
            console.log(err);
          });
      },
      topupTontineFromNewCards({ commit }, payload) {
        console.log(payload);
        axios({
          url: `${path}/card/top-up-round`,
          method: "POST",
          params: {
            userId: app.config.globalProperties.$keycloak.subject,
            roundId: payload.roundId,
            participantId: payload.participantId,
          },
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((tontine) => {
            commit("GET_TONTINE", tontine);
          })
          .catch((err) => {
            console.log(err.response.data);
          });
      },

      topupTontineFromBalance({ commit }, payload) {
        console.log(payload);
        axios({
          url: `${path}/account/top-up-round`,
          method: "POST",
          params: {
            userId: app.config.globalProperties.$keycloak.subject,
            amount: payload.amount,
            paymentCurrency: payload.paymentCurrency,
            roundId: payload.roundId,
            participantId: payload.participantId,
            participantAccountNumber: payload.participantAccountNumber,
            participantAccountType: payload.participantAccountType,
          },
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((tontine) => {
            commit("GET_TONTINE", tontine);
          })
          .catch((err) => {
            console.log(err);
            console.log(err.response.data);
          });
      },
      //         topUpWallet({
      //     commit,
      //     dispatch,
      //     rootState
      // }, payload) {
      //     axios({
      //             url: path + "transaction/transactions/top-up-wallet",
      //             method: "POST",
      //             params: {
      //                 userId: rootState.auth.profile.sub,
      //                 amount: payload.amount,
      //                 walletId: payload.walletId,
      //                 senderAccountNumber: payload.senderAccountNumber,
      //                 senderAccountType: payload.senderAccountType,
      //                 round: payload.round,
      //                 payerComment: payload.payerComment,
      //                 id: payload.id,
      //             },
      //             headers: {
      //                 Authorization: `Bearer ${ app.config.globalProperties.$keycloak.token}`,
      //             },
      //         })
      //         .then((r) => r.data)
      //         .then((walletTransaction) => {
      //             commit("GET_WALLETTRANSACTION", walletTransaction);
      //         })
      //         .catch((err) => {
      //             console.log(err);
      //         });
      // },
      redeemRound({ commit }, payload) {
        axios({
          url: `${path}/redeem/tontine/round`,
          method: "POST",
          params: {
            userId: app.config.globalProperties.$keycloak.subject,
            amount: payload.amount,
            roundId: payload.roundId,
            participantId: payload.participantId,
            beneficiaryAccountNumber: payload.beneficiaryAccountNumber,
            beneficiaryAccountType: payload.beneficiaryAccountType,
          },
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((tontineTransaction) => {
            commit("GET_TONTINETRANSACTION", tontineTransaction);
          })
          .catch((err) => {
            console.log(err);
          });
      },
      validateRoundRedeem({ commit }, payload) {
        axios({
          url: `${path}/redeem/round/validate`,
          method: "POST",
          params: {
            userId: app.config.globalProperties.$keycloak.subject,
            transferCode: payload.transferCode,
            transferPIN: payload.transferPIN,
          },
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((tontineTransaction) => {
            commit("GET_TONTINETRANSACTION", tontineTransaction);
          })
          .catch((err) => {
            console.log(err);
          });
      },

      getTontineTransactionList({ commit }, payload) {
        axios({
          url: `${path}/transactions/tontineTransaction`,
          method: "GET",
          params: {
            userId: app.config.globalProperties.$keycloak.subject,
            tontineId: payload.tontineId,
            maxNumberOfItem: payload.maxNumberOfItem,
          },
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((tontineTransactionList) => {
            commit("GET_TONTINETRANSACTION_LIST", tontineTransactionList);
          })
          .catch((err) => {
            console.log(err);
          });
      },

      grantAdminRole({ commit }, payload) {
        console.log(payload);
        axios({
          url: `${path}/participant/grantAdminRole`,
          method: "PUT",
          params: {
            userId: payload.userId,
            tontineId: payload.tontineId,
          },
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((tontine) => {
            commit("GET_TONTINE", tontine);
            console.log(tontine);
          })
          .catch((err) => {
            console.log(err.response.data);
          });
      },

      withdrawAdminRole({ commit }, payload) {
        axios({
          url: `${path}/participant/withdrawAdminRole`,
          method: "PUT",
          params: {
            userId: payload.userId,
            tontineId: payload.tontineId,
          },
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((tontine) => {
            commit("GET_TONTINE", tontine);
          })
          .catch((err) => {
            console.log(err.response.data);
          });
      },

      createTontineQRCode({ commit }, payload) {
        axios({
          url: `${path}/createQrCode`,
          data: {
            id: payload,
          },
          method: "POST",
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
          responseType: "arraybuffer",
        })
          .then((res) => {
            `data:${res.headers["content-type"]};base64,${Buffer.from(
              String.fromCharCode(...new Uint8Array(res.data)),
              "binary"
            ).toString("base64")}`;
            commit(
              "CREATE_QRCODE",
              `data:${res.headers["content-type"]};base64,${Buffer.from(
                String.fromCharCode(...new Uint8Array(res.data)),
                "binary"
              ).toString("base64")}`
            );
          })
          .catch((err) => {
            console.log(err);
            console.log(err.response.data);
          });
      },

      readQRcode({ commit, dispatch }, payload) {
        axios({
          url: `${path}/decodeQRCode`,
          method: "GET",
          params: {
            qrCodeText: payload,
          },
        })
          .then((r) => r.data)
          .then((tontine) => {
            commit("READ_QRCODE", tontine);
            console.log(tontine);
          })
          .catch((err) => {
            console.log(err);
            console.log(err.response.data);
          });
      },
    },
  };
};
// export default tontine;
