<template>
  <f7-panel
    class="transferMessageContactSelection"
    right
    cover
    resizable
    @panel:opened="reducePageWidth"
    @panel:close="resetPageWidth"
    :backdrop="false"
    :style="{
      'min-width': f7.width >= 768 ? '418px' : '100%',
      width: f7.width >= 768 ? '418px' : '100%',
      'border-left':
        'var(--f7-page-master-border-width) solid var(--f7-page-master-border-color)',
    }"
    ><f7-view>
      <f7-page>
        <f7-navbar no-shadow>
          <f7-nav-left>
            <f7-link panel-close>
              <f7-icon
                ios="f7:multiply"
                aurora="f7:multiply"
                md="material:close"
              ></f7-icon>
            </f7-link>
          </f7-nav-left>
          <f7-nav-title>{{ $t("tamtammessage.transferMessageTo") }} </f7-nav-title>
          <f7-nav-right>
            <f7-link
              class="searchbar-enable"
              data-searchbar=".searchbar-groups"
              icon-ios="f7:search"
              icon-aurora="f7:search"
              icon-md="material:search"
            ></f7-link>
          </f7-nav-right>
          <f7-searchbar
            expandable
            class="searchbar-groups"
            search-container=".search-UserList"
            search-in=".item-title, item-after, item-footer"
            :disable-button="!theme.aurora"
            :backdrop="false"
          >
          </f7-searchbar>
        </f7-navbar>
        <f7-block-title medium class="margin-left">{{
          $t("tamtammessage.recentlyUsed")
        }}</f7-block-title>
        <f7-block
          strong
          ignore
          v-show="this.transferMsgRecipients && this.transferMsgRecipients.length > 0"
        >
          <f7-chip
            outline
            class="margin-right-half"
            v-for="(recipient, index) in this.transferMsgRecipients"
            :key="index"
            :text="recipient.name"
            media-bg-color="primary"
            deleteable
            :media="
              recipient.logo || recipient.avatar
                ? ''
                : $filtersGetInitial.getInitial(recipient.name)
            "
            @click="deleteRecipientChip(recipient)"
          >
            <template #media>
              <img
                v-if="!!recipient.logo"
                :src="`${recipient.logo}`"
                style="
                  width: var(--f7-chip-height);
                  height: var(--f7-chip-height);
                  border-radius: 50%;
                "
              />
              <img
                v-else-if="!!recipient.avatar"
                :src="`${recipient.avatar}`"
                style="
                  width: var(--f7-chip-height);
                  height: var(--f7-chip-height);
                  border-radius: 50%;
                "
              />
            </template>
          </f7-chip>
        </f7-block>
        <f7-list dividers-ios strong-ios class="searchbar-not-found">
          <f7-list-item :title="$t('common.nothingFound')"></f7-list-item>
        </f7-list>
        <f7-list
          dividers-ios
          strong-ios
          media-list
          no-chevron
          class="search-UserList searchbar-found no-margin"
          v-for="(chatGroup, index) in this.groupList.slice(0, 10)"
          :key="index + '' + index"
        >
          <li v-if="chatGroup.groupType === 'GROUP'">
            <label
              :class="'transferMsgRecipient' + chatGroup.id"
              class="item-checkbox item-content"
            >
              <input
                type="checkbox"
                :name="'transferMsg' + chatGroup.id"
                :checked="
                  transferMsgRecipients.findIndex((x) => x.id === chatGroup.id) >= 0
                "
                @change="selectRecipient(chatGroup)"
                :value="chatGroup.id"
              />
              <i class="icon icon-checkbox" v-if="f7.support.touch == false"></i>
              <div class="item-media">
                <img
                  v-if="!!chatGroup.avatar"
                  :src="`${chatGroup.avatar}`"
                  width="40"
                  style="height: 48px; width: 48px; border-radius: 25px"
                />
                <f7-badge
                  v-else
                  color="auto"
                  style="height: 48px; width: 48px; font-size: 16px; border-radius: 25px"
                  >{{ $filtersGetInitial.getInitial(chatGroup.name) }}</f7-badge
                >
              </div>
              <div class="item-inner">
                <div class="item-title-row">
                  <div class="item-title">{{ chatGroup.name }}</div>
                </div>
                <div class="item-title-row">
                  <div class="item-footer text-container">
                    {{ chatGroup.description }}
                  </div>
                </div>
              </div>
            </label>

            <ul v-if="chatGroup.subGroupList && chatGroup.subGroupList.length > 0">
              <li
                v-for="(chatSubGroup, index) in chatGroup.subGroupList"
                :key="index + '' + chatSubGroup.id"
              >
                <label
                  :class="'transferMsgRecipient' + chatSubGroup.id"
                  class="item-checkbox item-content"
                >
                  <input
                    type="checkbox"
                    :name="'transferMsg' + chatSubGroup.id"
                    :value="chatSubGroup.id"
                    :checked="
                      transferMsgRecipients.findIndex((x) => x.id === chatSubGroup.id) >=
                      0
                    "
                    @change="selectRecipient(chatSubGroup)"
                  />
                  <i class="icon icon-checkbox" v-if="f7.support.touch == false"></i>
                  <div class="item-media">
                    <img
                      v-if="!!chatSubGroup.avatar"
                      :src="`${chatSubGroup.avatar}`"
                      style="height: 36px; width: 36px; border-radius: 25px"
                    />
                    <f7-badge
                      v-else
                      color="auto"
                      style="
                        height: 36px;
                        width: 36px;
                        font-size: 12px;
                        border-radius: 25px;
                      "
                      >{{ $filtersGetInitial.getInitial(chatSubGroup.name) }}</f7-badge
                    >
                  </div>
                  <div class="item-inner">
                    <div class="item-title-row">
                      <div class="item-title">
                        {{ chatSubGroup.name }}
                      </div>
                    </div>
                    <div class="item-title-row">
                      <div class="item-footer text-container">
                        {{ chatSubGroup.description }}
                      </div>
                    </div>
                  </div>
                </label>
              </li>
            </ul>
          </li>
          <li
            v-if="
              chatGroup.groupType === 'PERSONAL' &&
              chatGroup.groupParticipantList.find((x) => x.userId !== profile.sub) !==
                null
            "
          >
            <label
              class="item-checkbox item-content"
              :class="
                'transferMsgRecipient' +
                chatGroup.groupParticipantList.find((x) => x.userId !== profile.sub).id
              "
            >
              <input
                type="checkbox"
                :name="
                  'transferMsg' +
                  chatGroup.groupParticipantList.find((x) => x.userId !== profile.sub).id
                "
                :value="
                  chatGroup.groupParticipantList.find((x) => x.userId !== profile.sub).id
                "
                :checked="
                  transferMsgRecipients.findIndex(
                    (x) =>
                      x.id ===
                      chatGroup.groupParticipantList.find((x) => x.userId !== profile.sub)
                        .id
                  ) >= 0
                "
                @change="
                  selectRecipient(
                    chatGroup.groupParticipantList.find((x) => x.userId !== profile.sub)
                  )
                "
              />
              <i class="icon icon-checkbox" v-if="!f7.support.touch"></i>
              <div class="item-media">
                <img
                  v-if="
                    chatGroup.groupParticipantList.find(
                      (x) => x.userId !== profile.sub
                    ) !== null &&
                    !!chatGroup.groupParticipantList.find((x) => x.userId !== profile.sub)
                      .avatar
                  "
                  :src="`${
                    chatGroup.groupParticipantList.find((x) => x.userId !== profile.sub)
                      .avatar
                  }`"
                  width="40"
                  style="height: 48px; width: 48px; border-radius: 25px"
                />
                <f7-badge
                  v-if="
                    !chatGroup.groupParticipantList.find((x) => x.userId !== profile.sub)
                      .avatar &&
                    chatGroup.groupParticipantList.find((x) => x.userId !== profile.sub)
                      .name !== null
                  "
                  color="auto"
                  style="height: 48px; width: 48px; font-size: 16px; border-radius: 25px"
                  >{{
                    $filtersGetInitial.getInitial(
                      chatGroup.groupParticipantList.find((x) => x.userId !== profile.sub)
                        .name
                    )
                  }}</f7-badge
                >
              </div>
              <div class="item-inner">
                <div class="item-title-row">
                  <div class="item-title">
                    {{
                      chatGroup.groupParticipantList.find((x) => x.userId !== profile.sub)
                        .username
                    }}
                  </div>
                </div>
              </div>
            </label>
          </li>
        </f7-list>

        <f7-list
          dividers-ios
          strong-ios
          v-for="(user, index) in this.counterPartUserList"
          :key="index"
          media-list
          class="no-padding-left"
        >
          <f7-list-item
            checkbox
            :title="user.name"
            :after="'+' + user.phoneNumber"
            :footer="user.tag"
            :name="'transferMsg' + user.id"
            :checked="transferMsgRecipients.findIndex((x) => x.id === user.id) >= 0"
            :value="user.userId"
            @change="selectRecipient(user)"
            :class="'transferMsgRecipient' + user.id"
          >
            <template #media>
              <img
                v-if="!!user.avatar"
                :src="`${user.avatar}`"
                width="40"
                style="height: 48px; width: 48px; border-radius: 25px"
                badge="+"
                badge-color="auto"
              />

              <f7-badge
                color="auto"
                badge="+"
                badge-color="auto"
                v-if="!user.avatar && user.name !== null"
                style="height: 48px; width: 48px; font-size: 16px; border-radius: 25px"
                >{{ $filtersGetInitial.getInitial(user.name) }}</f7-badge
              >
            </template>
          </f7-list-item>
        </f7-list>

        <f7-toolbar
          tabbar
          bottom
          bg-color="red"
          :style="{ bottom: 0, position: isTablet ? 'initial' : 'fixed' }"
          v-if="this.transferMsgRecipients && this.transferMsgRecipients.length >= 1"
        >
          <f7-link
            raised
            fill
            large
            ripple
            class="link"
            text-color="white"
            icon-ios="f7:arrow_up_circle_fill"
            icon-md="material:send"
            strong
            @click="transferMessages()"
            >{{ $t("tamtammessage.sendNow") }}
          </f7-link>
        </f7-toolbar>
      </f7-page>
    </f7-view>
  </f7-panel>
</template>
<style scoped></style>
<script>
import { f7, theme } from "framework7-vue";
import { mapGetters, mapState } from "vuex";
import { messageSocket } from "../../js/websocket/websocketConnect.js";
import {
  messageOptionsMixin,
  messageGroupMixin,
  getusersMixin,
} from "../../js/mixins/messageMixins.js";

import _ from "lodash";
export default {
  props: {
    f7route: Object,
    f7router: Object,
  },
  mixins: [messageOptionsMixin, messageGroupMixin, getusersMixin, messageSocket],

  data() {
    return {
      theme,
      f7,
      transferMsgRecipients: [],
    };
  },

  computed: {
    ...mapState("message", [
      "chatUser",
      "message",
      "chatUserList",
      "messageList",
      "group",
      "groupList",
      "subGroup",
      "subGroupList",
      "blockedUser",
      "blockedUserList",
      "blockedUserMsg",
      "groupAdmin",
      "groupAdminList",
      "counterPartUser",
      "counterPartUserList",
      "groupParticipantList",
      "messageIsSelected",
      "selectedMessages",
      "messageSocketClient",
    ]),
    ...mapState("auth", [
      "phoneNumberDetail",
      "mobilePhoneError",
      "profile",
      "isDeskTop",
      "isTablet",
      "isCordova",
      "locale",
      "documentsUrl",
    ]),
    ...mapState("account", [
      "primaryAccount",
      "businessAccount",
      "userDetail",
      "searchedUser",
    ]),
  },
};
</script>
