<template>
  <f7-popup :close-by-backdrop-click="false" :close-by-outside-click="false" tablet-fullscreen
    @popup:close="selectContactClose()" class="selectContact" :backdrop="false">
    <f7-page hide-bars-on-scroll>
      <f7-navbar no-shadow no-border no-outline v-if="this.selectedContact.name && this.selectedContact.name.length > 0">
        <f7-nav-left> </f7-nav-left><f7-nav-title> {{ this.selectedContact.name[0] }}</f7-nav-title>
        <f7-nav-right>
          <f7-link popup-close>{{ this.$t("common.buttonOk") }}
            <!-- <f7-icon ios="f7:multiply" md="material:close" color="primary"></f7-icon> -->
          </f7-link>
        </f7-nav-right>
      </f7-navbar>
      <f7-navbar no-shadow :title="$t('common.contacts')" :back-link-show-text="false">
        <f7-nav-right>
          <f7-link v-if="!isTablet" class="searchbar-enable" data-searchbar=".searchbar-contacts"
            icon-aurora="f7:search_strong" icon-ios="f7:search_strong" icon-md="material:search"></f7-link>
          <f7-nav-right>
            <f7-link popup-close>{{ this.$t("common.buttonOk") }}
              <!-- <f7-icon ios="f7:multiply" md="material:close" color="primary"></f7-icon> -->
            </f7-link>
          </f7-nav-right>
        </f7-nav-right>
        <f7-subnavbar :inner="false" v-if="isTablet">
          <f7-searchbar :placeholder="$t('common.search')" search-container=".contacts-list" search-group=".list-group"
            search-group-title=".list-group-title" search-in=".item-title, .item-text"></f7-searchbar>
        </f7-subnavbar>
        <f7-searchbar v-else class="searchbar-contacts" expandable :backdrop="false" search-container=".contacts-list"
          search-group=".list-group" search-group-title=".list-group-title" search-in=".item-title, .item-text"
          :placeholder="$t('common.search')"></f7-searchbar>
      </f7-navbar>
      <div class="layout_content">
        <div class="left_layout"></div>
        <div class="main_layout margin">
          <div v-if="this.selectedContact !== null">
            <f7-block-title medium v-if="this.selectedContact.name && this.selectedContact.name.length > 0">{{
              $t("common.name") }}</f7-block-title>
            <f7-list media-list strong-ios outline-ios dividers-ios
              v-if="this.selectedContact.name && this.selectedContact.name.length > 0">
              <f7-list-item :title="this.selectedContact.name[0]" :badge="isAvocashUser(selectedContact) ? '¢' : ''"
                badge-color="primary">
                <template #media>
                  <img v-if="this.selectedContact.icon.length > 0" style="border-radius: 8px" alt='user avatar'
                    :src="URL.createObjectURL(this.selectedContact.icon[0])" width="80" />
                </template>
              </f7-list-item>
            </f7-list>
            <f7-block-title medium v-if="this.selectedContact.tel && this.selectedContact.tel.length > 0">{{
              $t("common.phoneNumber") }}</f7-block-title>
            <f7-list media-list strong-ios outline-ios dividers-ios
              v-if="this.selectedContact.tel && this.selectedContact.tel.length > 0">
              <f7-list-item v-for="phone in this.selectedContact.tel" radio :value="phone" :title="phone" @change="(e) => {
                if (e.target.checked) this.telephone = phone;
              }
                " name="telephone" link="#" :popup-close="this.type === 'telephone' ? true : false"
                :badge="hasTel(phone) ? '¢' : ''" badge-color="primary"></f7-list-item>
            </f7-list>
            <span v-if="this.type !== 'telephone'">
              <f7-block-title medium v-if="this.selectedContact.email && this.selectedContact.email.length > 0">{{
                $t("common.email") }}</f7-block-title>
              <f7-list media-list strong-ios outline-ios dividers-ios
                v-if="this.selectedContact.email && this.selectedContact.email.length > 0">
                <f7-list-item v-for="mail in this.selectedContact.email" radio :value="mail" :title="mail" @change="(e) => {
                  if (e.target.checked) this.email = mail;
                }
                  " name="email" :badge="hasEmail(mail) ? '¢' : ''" badge-color="primary"></f7-list-item>
              </f7-list>
              <f7-block-title medium v-if="this.selectedContact.address && this.selectedContact.address.length > 0
                ">{{ $t("common.address") }}</f7-block-title>
              <f7-list media-list strong-ios outline-ios dividers-ios v-if="this.selectedContact.address && this.selectedContact.address.length > 0
                ">
                <f7-list-item v-for="addr in this.selectedContact.address" radio :value="addr.addressLine[0]"
                  :title="arr.addressLine[0]" :footer="addr" @change="(e) => {
                    if (e.target.checked) this.address = addr.addressLine[0];
                  }
                    " name="address">
                </f7-list-item>
              </f7-list>
              <f7-button raised fill large ripple popup-close color="primary" class="buttons"
                @click="selectContactClose()">{{ this.$t("common.buttonOk") }}</f7-button>
            </span>
          </div>
          <!-- <div > -->
          <f7-list dividers-ios strong-ios class="searchbar-not-found" media-list
            v-if="this.contactList !== '' && this.contactList.length > 0">
            <f7-list-item :title="$t('common.nothingFound')" style="font-size: 18px; color: red"></f7-list-item></f7-list>
          <f7-list media-list outline-ios dividers-ios v-if="this.contactList !== '' && this.contactList.length > 0"
            class="contacts-list searchbar-found" v-for="contact in this.contactList">
            <f7-list-group>
              <f7-list-item :title="contact.displayName" group-title>
                <!-- :badge="isAvocashUser(contact) ? '¢' : ''" badge-color="primary" -->
                <template #media>
                  <img v-if="contact.photos && contact.photos.length > 0" style="border-radius: 8px" alt='user avatar'
                    :src="contact.photos[0]" width="80" /> </template></f7-list-item>
              <!-- <f7-accordion-content>
                <f7-list media-list> -->
              <span v-if="contact.phoneNumbers && contact.phoneNumbers.length > 0">
                <f7-list-item :title="$t('common.phoneNumber')" />
                <f7-list-item v-for="phone in contact.phoneNumbers" radio :value="phone.value" :title="phone.value"
                  @change="(e) => {
                    if (e.target.checked) this.telephone = phone.value;
                  }
                    " name="telephone" link="#" :popup-close="type === 'telephone' ? true : false"
                  @click="getCurrentUser(contact)" />
              </span>
              <!-- :badge="hasTel(phone.value) ? '¢' : ''" badge-color="primary" -->
              <span v-if="contact.emails && contact.emails.length > 0">
                <f7-list-item :title="$t('common.email')" />
                <f7-list-item v-for="mail in contact.emails" radio :value="mail.value" :title="mail.value" @change="(e) => {
                  if (e.target.checked) this.email = mail.value;
                }
                  " name="email" @click="getCurrentUser(contact)" /></span>
              <!-- :badge="hasEmail(mail.value) ? '¢' : ''" badge-color="primary" -->
              <span v-if="contact.addresses && contact.addresses.length > 0">
                <f7-list-item :title="$t('common.address')" />
                <f7-list-item v-for="addr in contact.addresses" radio :value="addr.formatted" :title="addr.formatted"
                  @change="(e) => {
                    if (e.target.checked) this.address = addr.formatted;
                  }
                    " name="address" @click="getCurrentUser(contact)" /></span>
              <!-- </f7-list>
              </f7-accordion-content> -->
            </f7-list-group>
            <!-- <f7-button raised fill large ripple popup-close color="primary" class="buttons"
              @click="selectContactClose()">{{ this.$t("common.buttonOk") }}</f7-button> -->
          </f7-list>
          <!-- </div> -->
        </div>
        <div class="right_layout">
          <div class="sticky with_content" v-if="this.isTablet"></div>
        </div>
      </div>
    </f7-page>
  </f7-popup>
</template>
<style scoped></style>
<script>
import {
  f7,
  theme,
  f7Popup,
  f7Block,
  f7Button,
  f7Link,
  f7PageContent,
} from "framework7-vue";
import { mapState } from "vuex";
import { telephoneUtils } from "../js/mixins/telephone.js";
import { cordovaPlugin } from "../js/mixins/cordovaPlugins.js";
import _ from "lodash";

export default {
  props: {
    f7route: Object,
    f7router: Object,
    type: String,
  },
  components: {
    f7Popup,
    f7Block,
    f7Button,
    f7Link,
    f7PageContent,
  },
  mixins: [telephoneUtils, cordovaPlugin],
  data() {
    return {
      theme,
      f7,
      name: "",
      telephone: "",
      email: "",
      address: "",
      firstName: "",
      lastName: "",
      currContact: "",
    };
  },
  mounted() {
    const self = this;
  },

  methods: {
    hasTel(tel) {
      const self = this;
      let curtel;
      if (tel != undefined) {
        if (tel.replace(" ", "").substring(0, 2) === "00") {
          curtel = `+${tel.replace(" ", "").slice(2)}`;
        } else if (tel.substring(0, 2) !== "00" && tel.substring(0, 1) !== "+") {
          curtel = `+${tel.replace(" ", "")}`;
        } else {
          curtel = tel.replace(" ", "");
        }
        return _.find(this.users, ["username", curtel]) !== "";
      } else {
        return false;
      }
    },
    hasEmail(email) {
      return email != undefined
        ? _.find(this.users, ["email", email.replace(" ", "")]) !== ""
        : false;
    },

    isAvocashUser(contact) {
      let has_tel;
      let has_email;
      const phoneList = contact.phoneNumbers ? contact.phoneNumbers : contact.tel;
      for (const tel of phoneList) {
        const phone = tel.value ? tel.vaue : tel;
        has_tel = this.hasTel(phone);
      }
      const mailList = contact.emails ? contact.emails : contact.email;

      for (const email of mailList) {
        const mail = email.value ? email.vaue : email;
        has_tel = this.hasEmail(mail);
      }
      // const has_tel = contact.tel.forEach((tel) => {
      //   this.hasTel(tel);
      // });

      // const has_email = contact.email.forEach((element) => {
      //   this.hasEmail(element);
      // });
      return has_tel || has_email ? true : false;
    },
    getCurrentUser(contact) {
      const self = this;
      this.currContact = contact;
    },
    selectContactClose() {
      const self = this;
      let selectedUser;
      // if (this.selectedContact !== null && this.type === "telephone") {
      // }
      if (this.selectedContact !== "" && this.contactList === "") {
        this.name = this.selectedContact.name[0];
        selectedUser = {
          name: this.name,
          firstName:
            this.name != undefined ? this.name.substring(0, this.name.indexOf(" ")) : "",
          lastName:
            this.name != undefined ? this.name.substring(this.name.indexOf(" ") + 1) : "",
          email: this.email,
          telephone: this.telephone,
          address: this.address,
        };
      } else {
        this.name = this.currContact.displayName;
        selectedUser = {
          name: this.name,
          firstName: this.currContact.familyName,
          lastName: this.currContact.familyName,
          email: this.email,
          telephone: this.telephone,
          address: this.address,
        };
      }

      console.log("Selected user", selectedUser);
      self.$store.dispatch("auth/setSelectedContact", selectedUser);
      console.log("Selected user phone 000", this.telephone);
      console.log("Selected user phone", this.selectedContact.telephone);
      // }
      f7.popup.close(".selectContact");
    },
  },

  computed: {
    ...mapState("auth", [
      "video",
      "isDeskTop",
      "isTablet",
      "device",
      "lastSwiperIsActive",
      "landingPage",
      "selectedContact",
      "contactList",
    ]),
    ...mapState("account", ["users"]),
  },
};
</script>
