<template>
  <!-- <h1>Payment</h1>

    <p>
      Enable more payment method types
      <a href="https://dashboard.stripe.com/settings/payment_methods" target="_blank"
        >in your dashboard</a
      >.
    </p> -->

  <form id="payment-form" @submit.prevent="handleSubmit" style="margin-bottom: 24px"
    v-if="this.paymentIntent && this.paymentIntent.client_secret !== null">
    <div id="link-authentication-element" />
    <div id="payment-element" />
    <div id="messages" role="alert">{{ this.message }}</div>
    <f7-toolbar tabbar bottom bg-color="red" class="margin-top payButton"
      v-if="this.payElement && this.payElement !== null"
      :style="{ bottom: 0, position: isTablet ? 'initial' : 'fixed' }">
      <f7-link @click="handleSubmit()" raised fill large class="link buttons button" ripple id="submit"
        :disabled="isLoading" strong text-color="white">
        <div>
          <f7-preloader color="multi" v-if="isLoading" />
          <span>{{ $t("checkout.payNow") }}</span>
        </div>
      </f7-link>
    </f7-toolbar>
  </form>
  <f7-toolbar tabbar bottom bg-color="red" class="margin-top"
    :style="{ bottom: 0, position: isTablet ? 'initial' : 'fixed', display: 'none' }"><f7-link
      v-if="this.detail_confirmed == false && this.payElement === ''" raised fill large class="link buttons" ripple
      @click="triggerStripePayment()" strong text-color="white">{{ $t("common.confirmDetail") }}</f7-link></f7-toolbar>
  <f7-button @click="stripeValidate()" class="display-none"></f7-button>
</template>

<style scoped>
/* .payButton {
  display: none;
} */
</style>

<script>
import {
  f7Popup,
  f7Page,
  f7Link,
  f7Button,
  f7Toolbar,
  f7Icon,
  f7,
  theme,
} from "framework7-vue";
import { loadStripe } from "@stripe/stripe-js";
import { mapGetters, mapState } from "vuex";
import _ from "lodash";
import { transactAuth } from "../js/mixins/transactAuth.js";

export default {
  props: {
    f7route: Object,
    f7router: Object,
    stripeAccountId: String,
    payment_options: Object,
    detail_confirmed: Boolean,
  },
  components: { f7Popup, f7Page, f7Link, f7Button, f7Toolbar, f7Icon },
  mixins: [transactAuth],

  data() {
    return {
      theme,
      f7,
      stripe: "",
      elements: "",
      isLoading: false,
      message: "",
      payElement: "",
    };
  },
  mounted() {
    // document.querySelector("#payment-form").addEventListener("submit", this.handleSubmit);
    // let elements;
    this.$store.dispatch("stripe/getStripePublicKey");
    console.log("this.publishableKey", this.publishableKey);
    // setTimeout(() => {

    // }, 1000);
    // f7.$("#submit").on("click",  () => {
    // async function handleSubmit(e) {
    //   e.preventDefault();

    //   if (this.isLoading) {
    //     return;
    //   }

    //   this.isLoading = true;

    //   const { error } = await stripe.confirmPayment({
    //     elements:this.elements,
    //     confirmParams: {
    //       return_url: `${window.location.origin}/transaction/success`,
    //     },
    //   });
    //   if (error) {
    //     this.message =
    //       error.type === "card_error" || error.type === "validation_error"
    //         ? error.message
    //         : "An unexpected error occured.";
    //   } else {
    //   }
    //   this.isLoading = false;
    // }

    // });
    // if (f7.$("#payment-form") && f7.$("#payment-form").length > 0) {
    //   f7.$(".payButton").css("display", "block");
    // }
  },

  methods: {
    async triggerStripePayment() {
      if (this.publishableKey) {
        this.stripe = this.stripeAccountId
          ? await loadStripe(this.publishableKey, { stripeAccount: this.stripeAccountId })
          : await loadStripe(this.publishableKey);

        if (this.paymentIntent && this.paymentIntent.client_secret !== null) {
          console.log("this.paymentIntent", this.paymentIntent, this.stripe);
          const clientSecret = this.paymentIntent.client_secret;
          this.elements = this.stripe.elements({ clientSecret });
          const paymentElement = this.elements.create("payment", {
            layout: {
              type: "tabs",
              defaultCollapsed: false,
            },
            defaultValues: {
              billingDetails: {
                name: this.profile.name,
                email: this.profile.email,
                phone: this.profile.upn,
                address: {
                  line1: this.profile.address.street_address,
                  postal_code: this.profile.address.postal_code,
                  city: this.profile.address.locality,
                  state: this.profile.address.region,
                  country: this.profile.countryCode,
                },
              },
            },
          });
          paymentElement.mount("#payment-element");
          console.log("paymentElement", paymentElement);
          this.payElement = paymentElement;
          const linkAuthenticationElement = this.elements.create("linkAuthentication");
          linkAuthenticationElement.mount("#link-authentication-element");
        }
      }
    },
    async handleSubmit() {
      console.log("paymentElement", this.payElement);
      this.checkAuth();
    },
    async stripeValidate() {
      if (this.isLoading) {
        return;
      }
      console.log("this.stripe", this.stripe);
      this.isLoading = true;
      const { error } = await this.stripe.confirmPayment({
        elements: this.elements,
        confirmParams: {
          return_url: f7.device.cordova
            ? `${import.meta.env.VITE_AVOCASH_SITE_URL}/transaction/success`
            : `${window.location.origin}/transaction/success`,
        },
      });
      if (error) {
        this.message =
          error.type === "card_error" || error.type === "validation_error"
            ? error.message
            : "An unexpected error occured.";
      } else {
      }
      this.isLoading = false;
    },
  },

  computed: {
    ...mapState("apimoney", ["paymentIntent"]),
    ...mapState("stripe", ["publishableKey"]),
    ...mapState("auth", ["profile", "documentsUrl", "document", "isTablet"]),
    ...mapState("account", ["userAccount", "userDetail", "searchedUser"]),
  },
};
</script>
