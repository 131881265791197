<template>
  <f7-page hide-bars-on-scroll>
    <f7-navbar sliding no-shadow>
      <f7-nav-left :back-link="$t('common.backButton')" :back-link-show-text="false"></f7-nav-left>
      <f7-nav-title>{{
        $t("businessaccountactivate.activateBusinessAccount")
        }}</f7-nav-title>
      <f7-nav-right>
        <navbarright></navbarright>
      </f7-nav-right>
    </f7-navbar>
    <div class="layout_content">
      <div class="left_layout"></div>
      <div class="main_layout">
        <f7-block-title>{{
          $t("businessaccountactivate.fillInCompanyDetails")
          }}</f7-block-title>
        <f7-list dividers-ios strong-ios form-store-data media-list>
          <f7-list-group>
            <f7-list-input :label="$t('businessaccountactivate.companyLegalName')" floating-label type="text"
              name="name" :placeholder="$t('businessaccountactivate.myCompanyName')" :value="name"
              @input="name = $event.target.value" :error-message="$t('businessaccountactivate.enterMyCompanyName')"
              required validate clear-button>
            </f7-list-input>
            <f7-list-item :title="$t('businessaccountactivate.companyType')" smart-select
              :smart-select-params="{ openIn: this.isTablet ? 'popover' : 'sheet' }">
              <select name="business_type" v-model="business_type">
                <option value="COMPANY" selected>
                  {{ $t("common.businesstype_Company") }}
                </option>
                <option value="ASSOCIATION">
                  {{ $t("common.businesstype_Association") }}
                </option>
                <option value="SOLE_TRADER">
                  {{ $t("common.businesstype_SoleTrader") }}
                </option>
              </select>
            </f7-list-item>
            <f7-list-input :label="$t('businessaccountactivate.myCompanyNumber')" floating-label type="text"
              name="registration_number" :placeholder="$t('businessaccountactivate.myCompanyNumber')"
              :value="registration_number" @input="registration_number = $event.target.value"
              :error-message="$t('businessaccountactivate.enterMyCompanyNumber')" required validate
              clear-button></f7-list-input>
            <f7-list-input :label="$t('businessaccountactivate.companyPhoneNumber')" type="tel" name="telephone"
              input-id="phoneNumber" :placeholder="$t('businessaccountactivate.companyPhoneNumber')" :value="telephone"
              @input="telephone = $event.target.value"
              :error-message="$t('businessaccountactivate.enterCompanyPhoneNumber')" required validate clear-button>
            </f7-list-input>
            <f7-list-input :label="$t('businessaccountactivate.companyEmail')" floating-label type="text" name="email"
              :placeholder="$t('businessaccountactivate.myCompanyEmail')" :value="email"
              @input="email = $event.target.value" :error-message="$t('businessaccountactivate.enterMyCompanyEmail')"
              required validate clear-button>
            </f7-list-input>
            <f7-list-input :label="$t('businessaccountactivate.companyWebsite')" floating-label type="url"
              name="website" :placeholder="$t('businessaccountactivate.companyWebsite')" :value="website"
              @input="website = $event.target.value" :error-message="$t('businessaccountactivate.enterCompanyWebsite')"
              required validate clear-button>
            </f7-list-input>
            <f7-list-input :label="$t('businessaccountactivate.companyActivitydescription')" floating-label
              type="textarea" resizable name="description" :placeholder="$t('businessaccountactivate.companyActivity')"
              :value="description" @input="description = $event.target.value"
              :error-message="$t('businessaccountactivate.enterCompanyActivity')" required validate>
            </f7-list-input>
          </f7-list-group>

          <f7-list-group>
            <f7-list-item>
              <span>{{ this.addressType }}</span>
              <f7-toggle checked class="addressType margin-left-half" @change="addressTypeChange()"></f7-toggle>
            </f7-list-item>

            <f7-list-input :label="$t('common.street')" floating-label type="text" name="street_address"
              :placeholder="$t('common.street')" :value="label1" @input="label1 = $event.target.value"
              error-message="$t('common.enterStreet')" required validate clear-button>
            </f7-list-input>
            <div class="grid grid-cols-2">
              <f7-list-input :label="$t('common.postCode')" floating-label type="text" name="zip_code"
                :placeholder="$t('common.postCode')" :value="zip_code" @input="zip_code = $event.target.value"
                :error-message="$t('common.enterPostCode')" required validate clear-button>
              </f7-list-input>

              <f7-list-input :label="$t('common.citylocality')" floating-label type="text" name="city"
                :placeholder="$t('common.citylocality')" :value="city" @input="city = $event.target.value"
                :error-message="$t('common.enterCityOrLocality')" required validate clear-button>
              </f7-list-input>
            </div>
          </f7-list-group>

          <f7-list-group>
            <f7-list-item>
              <span>{{ this.iamTheRep }}</span>
              <f7-toggle checked class="representative margin-left-half" @change="representativeChange()"></f7-toggle>
            </f7-list-item>
            <span v-if="this.userIsRep">
              <f7-list-input :label="$t('common.firstName')" floating-label type="text" name="firstName"
                :placeholder="$t('common.firstName')" :value="this.firstname"
                @input="this.firstname = $event.target.value" error-message="$t('common.provideFirstName')" required
                validate clear-button>
              </f7-list-input>
              <f7-list-input :label="$t('common.lastName')" floating-label type="text" name="lastName"
                :placeholder="$t('common.lastName')" :value="this.lastname" @input="this.lastname = $event.target.value"
                error-message="$t('common.provideLastName')" required validate clear-button>
              </f7-list-input>
              <f7-list-input :label="$t('common.birthDate')" type="date" name="birthdate"
                :placeholder="$t('common.birthDate')" :value="this.birthdate"
                @input="this.birthdate = $event.target.value" :error-message="$t('common.enterBirthDate')" required
                validate>
              </f7-list-input>
            </span>
            <!-- <f7-list-input :label="$t('common.nationality')" floating-label type="text" name="nationality"
                  :readonly="!this.userIsRe" :placeholder="$t('common.nationality')" :value="nationality"
                  @input="nationality = $event.target.value" :error-message="$t('common.enterNationality')" required
                  validate clear-button>
                </f7-list-input> -->

            <f7-list-item :title="$t('common.nationality')" class="selectCountry" smart-select :smart-select-params="{
              openIn: 'popup',
              searchbar: true,
              searchbarPlaceholder: $t('common.findCountry'),
              closeOnSelect: true,
            }" style="display: none">
              <select name="country" v-model="nationality">
                <optgroup v-for="(countryList, continentName, index) in this.countries" :label="continentName"
                  :key="index">
                  <option v-for="country in countryList" :key="country.id" :value="country.countryCode3"
                    :data-option-image="'https://flagcdn.com/' + country.countryCode2.toLowerCase() + '.svg'
                      " width="24">
                    {{ country.countryCode2 }}+' '+
                    {{ country.name }}
                  </option>
                </optgroup>
              </select>
            </f7-list-item>
          </f7-list-group>
        </f7-list>

        <f7-block-title medium class="margin-left">{{
          $t("businessaccountactivate.uploadCompanyDocuments")
          }}</f7-block-title>
        <f7-list>
          <f7-list-group>
            <myDocs :type="null"></myDocs>
          </f7-list-group>
        </f7-list>

        <f7-toolbar tabbar bottom no-hairline bg-color="red">
          <f7-link raised fill large class="link buttons" text-color="white" strong @click="saveCompanyInfo()">{{
            $t("businessaccountactivate.submitCompanyDetails") }}</f7-link>
        </f7-toolbar>
      </div>
      <div class="right_layout"></div>
    </div>
    <f7-popup class="viewDocs" swipe-to-close :tablet-fullscreen="true" :params="{
      backdrop: true,
      animate: true,
      swipeToClose: true,
      closeByBackdropClick: true,
      closeOnEscape: true,
    }">
      <f7-page>
        <f7-navbar class="no-shadow">
          <f7-nav-right>
            <f7-link popup-close>{{ $t("common.close") }}</f7-link>
          </f7-nav-right>
        </f7-navbar>
        <div class="layout_content">
          <div class="left_layout"></div>
          <div class="main_layout">
            <f7-block v-if="this.documentsUrl && this.documentsUrl.length > 0" style="height: 100%">
              <iframe v-for="(docItem, index) in this.documentsUrl" :src="docItem.url" :key="index"
                :title="docItem.fileName" style="width: 100%; height: 500px" frameborder="0"></iframe>
            </f7-block>
          </div>
          <div class="right_layout"></div>
        </div>
      </f7-page>
    </f7-popup>
  </f7-page>
</template>
<style scoped>
.input-dropdown-wrap:before,
.input-dropdown:before {
  content: "";
}
</style>

<script>
import {
  f7Page,
  f7Navbar,
  f7NavLeft,
  f7NavRight,
  f7NavTitle,
  f7Tabs,
  f7Tab,
  f7BlockTitle,
  f7List,
  f7ListGroup,
  f7ListInput,
  f7Button,
  f7Link,
  f7Toggle,
  f7Popup,
  f7PageContent,
  f7,
  theme,
} from "framework7-vue";
import { mapGetters, mapState } from "vuex";
import Navbarright from "../components/navbarright.vue";
import attachDoc from "../components/attachDocs.vue";
import { attachDocs } from "../js/mixins/attachDocs.js";
import myDocs from "../components/myDocs.vue";
import { telephoneUtils } from "../js/mixins/telephone.js";
import _ from "lodash";
export default {
  props: {
    f7route: Object,
    f7router: Object,
  },
  components: {
    navbarright: Navbarright,
    attachDoc,
    myDocs,
    f7Page,
    f7Navbar,
    f7NavLeft,
    f7NavRight,
    f7NavTitle,
    f7Tabs,
    f7Tab,
    f7BlockTitle,
    f7List,
    f7ListGroup,
    f7ListInput,
    f7Button,

    f7Link,
    f7Toggle,
    f7Popup,
    f7PageContent,
  },
  mixins: [telephoneUtils, attachDocs],
  data() {
    return {
      theme,
      f7,
      name: "",
      registration_number: "",
      email: "",
      telephone: "",
      description: "",
      website: "",
      countryCode: "",
      label1: "",
      city: "",
      zip_code: "",
      firstname: "",
      lastname: "",
      birthdate: "",
      nationality: "",
      documents: "",
      selectedCountry: "",
      docIndex: "",
      files: "",
      forms: "",
      destinationCountryCode: "",
      countryCode: "",
      addressType: this.$t("businessaccountactivate.newAddress"),
      newAddress: false,
      userIsRep: true,
      iamTheRep: this.$t("businessaccountactivate.iamNotTherep"),
    };
  },

  mounted() {
    this.selectedCountry = this.profile.countryCode;
    if (!this.userAccount && this.$keycloak.authenticated) {
      this.$store.dispatch("account/loadAccount", this.profile.sub);
    }
    this.paymentCurrency = this.profile.currencyCode;

    this.$store.dispatch("location/loadDestinationCity", this.profile.countryCode);
    this.paymentCurrency = this.profile.currencyCode;

    this.nationality = this.userCountry.countryCode3;
    this.iamTheRep = this.$t("businessaccountactivate.iamNotTherep");
    if (this.isTablet == false) {
      f7.$("div.page").addClass("no-toolbar");
    }
    this.firstname = this.profile.given_name;
    this.lastname = this.profile.family_name;
    this.birthdate = this.profile.dateOfBirth;
    this.nationality = this.profile.nationality;
  },
  methods: {
    addressTypeChange() {
      const toggle = f7.toggle.get(".addressType");
      if (toggle.checked) {
        this.addressType = this.$t("businessaccountactivate.newAddress");
        this.newAddress = true;
        this.label1 = "";
        this.zip_code = "";
        this.city = "";
        return;
      }
      this.addressType = this.$t("businessaccountactivate.personalAddress");
      this.newAddress = false;
      this.label1 = this.profile.address.street_address;
      this.zip_code = this.profile.address.postal_code;
      this.city = this.profile.address.locality;
    },

    representativeChange() {
      const toggle = f7.toggle.get(".representative");
      if (!toggle.checked) {
        this.iamTheRep = this.$t("businessaccountactivate.iamNotTherep");
        this.userIsRep = false;
        this.firstname = "";
        this.birthdate = "";
        this.nationality = this.nationality;
        return;
      }
      this.iamTheRep = this.$t("businessaccountactivate.iamTherep");
      this.userIsRep = true;
      this.firstname = this.profile.given_name;
      this.lastname = this.profile.family_name;
      this.birthdate = this.profile.dateOfBirth;
      this.nationality = this.profile.nationality;
    },
    saveCompanyDetails() {
      if (f7.input.validateInputs("#addCompanyDetails")) {
        f7.tab.show("#addContact", true);
      } else {
        const valid = f7.$("#addCompanyDetails .input-invalid").length === 0;
      }
    },

    saveContactDetails() {
      if (f7.input.validateInputs("#addContact")) {
        f7.tab.show("#uploadDocs", true);
      } else {
        const valid = f7.$("#addContact .input-invalid").length === 0;
      }
    },

    async saveCompanyInfo() {
      if (!f7.input.validateInputs("#validateBusinnessAcct")) {
        return;
      }
      this.newBusinessAccount = {
        name: this.name,
        business_type: this.business_type,
        registration_number: this.registration_number,
        phone_number: this.telephone,
        description: this.description,
        email: this.email,
        website: this.website,
        address: {
          zip_code: this.zip_code,
          city: this.city,
          zip_code: this.zip_code,
          country: this.profile.country,
        },
        representative: {
          firstname: this.firstname,
          lastname: this.lastname,
          birthdate: this.birthdate,
          nationality: this.nationality,
        },
      };
      console.log(this.newBusinessAccount);
      this.forms = new FormData();
      await this.forms.append("businessAccount", JSON.stringify(this.newBusinessAccount));

      for (const element of this.documentsUrl) {
        await this.forms.append("files", element.file);
      }
      console.log(this.forms);
      await this.$store
        .dispatch("account/activateBusinessAccount", this.forms)
        .then((result) => {
          this.$store.dispatch("auth/clearDocumentsUrl");
          this.f7router.navigate("/user/profile");
        })
        .catch((err) => { });
    },
    async selectUserCountryCode() {
      if (this.country === "" || !this.country) {
        this.$store.dispatch("location/getCountry");
      }
      f7.popup.open(".selectUserCountryCode");
      console.log(this.countryCode);
    },
    async selectDestinationCountry(destination) {
      this.countryCode = await destination.countryCode2;
      console.log(this.countryCode);
      f7.popup.close(".selectUserCountryCode");
    },
  },

  computed: {
    ...mapState("location", [
      "destinationCity",
      "country",
      "countryStates",
      "countryCities",
      "currentUserCountry",
    ]),
    ...mapState("account", ["userAccount", "userDetail"]),
    ...mapState("auth", [
      "phoneNumberDetail",
      "mobilePhoneError",
      "profile",
      "isDeskTop",
      "isTablet",
      "documentsUrl",
    ]),
    ...mapState("transaction", ["destinationCountry", "originCountry"]),

    ...mapGetters("auth", [
      "getProfile",
      "isAuthenticated",
      "isProfileLoaded",
      "getusererror",
    ]),
    ...mapGetters("transaction", [
      "getusererror",
      "fromCurrencyRate",
      "toCurrencyRate",
      "grossexchangerate2",
    ]),

    ...mapGetters("remit", ["recipients"]),
    ...mapGetters("account", ["userByAccountNumber"]),

    countries() {
      return _.groupBy(this.country, "continentName");
    },
    userCountry() {
      return _.find(this.country, ["countryCode2", this.profile.countryCode]);
    },
  },
};
</script>
