import dayjs from "dayjs";
import { selectedLocale } from "./i18n.js";

// Add any plugins you want to use with Day.js
import advancedFormat from "dayjs/plugin/advancedFormat";
import weekday from "dayjs/plugin/weekday";
import localeData from "dayjs/plugin/localeData";
import weekOfYear from "dayjs/plugin/weekOfYear";
import customParseFormat from "dayjs/plugin/customParseFormat";
import quarterOfYear from "dayjs/plugin/quarterOfYear";
import localizedFormat from "dayjs/plugin/localizedFormat";
import calendar from "dayjs/plugin/calendar";
import updateLocale from "dayjs/plugin/updateLocale";
import isoWeek from "dayjs/plugin/isoWeek";
import relativeTime from "dayjs/plugin/relativeTime";
// import de from "dayjs/locale/de";
// import fr from "dayjs/locale/fr";
// import en from "dayjs/locale/en";
// import pt from "dayjs/locale/pt";
// import nl from "dayjs/locale/nl";
// import es from "dayjs/locale/es";
dayjs.locale(selectedLocale);
dayjs.extend(updateLocale);
dayjs.updateLocale(selectedLocale);
dayjs.extend(advancedFormat);
dayjs.extend(calendar);
dayjs.extend(localeData);
dayjs.extend(weekday);
dayjs.extend(weekOfYear);
dayjs.extend(relativeTime);
dayjs.extend(customParseFormat);
dayjs.extend(quarterOfYear);
dayjs.extend(localizedFormat);
dayjs.extend(isoWeek);

export default dayjs;
