<template>
  <f7-page hide-bars-on-scroll no-toolbar v-if="this.shop">
    <f7-navbar sliding no-shadow>
      <f7-nav-left :back-link="$t('common.backButton')" :back-link-show-text="false">
        <span class="padding-left-half"><img v-if="this.shop.logo !== null" :src="`${this.shop.logo}`" width="40"
            height="40" />
          <f7-badge v-else slot="media" color="auto"
            style="height: 40px; width: 40px; font-size: 16px; border-radius: 50%">{{
              $filtersGetInitial.getInitial(shop.name) }}</f7-badge>
        </span>
      </f7-nav-left>
      <f7-nav-title> {{ this.shop.name }}</f7-nav-title>
      <f7-nav-right v-if="this.profile.sub === this.shop.ownerId || this.currentUserIsAdmin">
        <f7-link icon-color="red" icon-aurora="f7:text_badge_plus" icon-ios="f7:text_badge_plus"
          icon-md="material:playlist_add" href="#" @click="listNewProduct()"></f7-link>
        <f7-link icon-aurora="f7:person_crop_circle_fill_badge_plus" icon-ios="f7:person_crop_circle_fill_badge_plus"
          icon-md="material:person_add" color="red" href="#" popup-open=".addNewMember"></f7-link>
        <f7-link icon-aurora="f7:pencil" icon-ios="f7:pencil" icon-md="material:edit"
          :href="'/shop/edit/' + this.shop.id"></f7-link>
      </f7-nav-right>
    </f7-navbar>

    <f7-toolbar tabbar labels top :scrollable="this.shop.privateShopList && this.shop.privateShopList.length > 6 ? true : false
      " no-shadow class="detailToolbar">
      <f7-link tab-link="#mainPage" tab-link-active :text="isTablet ? this.shop.name : ''">
        <img v-if="this.shop.logo !== null" :src="`${this.shop.logo}`" width="40" height="40" slot="media"
          style="border-radius: 50%" class="margin-left-half" />
        <f7-badge v-else color="auto" slot="media" class="margin-left-half" :style="{
          height: theme.md ? '27px' : '27px',
          width: theme.md ? '27px' : '27px',
          'min-width': theme.md ? '27px' : '27px',
          'border-radius': '50%',
        }">{{ $filtersGetInitial.getInitial(shop.name) }}</f7-badge>
      </f7-link>
      <f7-link v-for="(privateShop, index) in this.shop.privateShopList" :key="index + 1"
        :text="isTablet ? privateShop.name : ''" :tab-link="`#tab-${index + 1}`"
        @tab:show="selectCampaign(privateShop)">
        <f7-badge slot="media" color="auto" :style="{
          height: theme.md ? '27px' : '27px',
          width: theme.md ? '27px' : '27px',
          'min-width': theme.md ? '27px' : '27px',
          'border-radius': '50%',
        }">{{ $filtersGetInitial.getInitial(privateShop.name) }}</f7-badge>
      </f7-link>
    </f7-toolbar>

    <f7-tabs swipeable class="detailTabs">
      <f7-tab id="mainPage" tab-active class="page-content">
        <div class="layout_content">
          <div class="left_layout">
            <div class="sticky with_content">
              <f7-card outline header-divider v-if="
                this.shop &&
                this.$keycloak.authenticated &&
                /* this.currentMember &&*/
                this.shop.shopAdminList !== null
              ">
                <f7-card-header>
                  {{ $t("common.members") }}

                  <f7-badge v-if="this.currentUserIsAdmin" bg-color="red" class="addParticipantToolTip">
                    <f7-link icon-ios="f7:person_crop_circle_fill_badge_plus" icon-md="material:person_add"
                      color="white" href="#" popup-open=".addNewMember"></f7-link>
                  </f7-badge>
                </f7-card-header>
                <membersComponent :entityId="this.shop.id" :memberList="this.shop.shopMemberList"
                  :adminList="this.shop.shopAdminList" :entityType="'shop'" :isAdmin="this.currentUserIsAdmin"
                  :mainUserId="this.shop.ownerId"></membersComponent>
              </f7-card>
            </div>
          </div>
          <div class="main_layout">
            <f7-card v-if="!this.shop" class="skeleton-text skeleton-effect-blink">
              <f7-card-header class="no-border">
                <div class="demo-facebook-avatar padding-right">
                  <f7-badge slot="media" color="gray"
                    style="height: 40px; width: 40px; font-size: 16px; border-radius: 50%">GT</f7-badge>
                </div>
                <div class="tontineTitle demo-facebook-name">Shop name</div>
                <div class="demo-facebook-date tontineTitle">Shop tag</div>
              </f7-card-header>
              <f7-card-content style="width: auto; height: 400px">
                <div class="timeline">
                  <div class="timeline-item" v-for="x in 6" :key="x">
                    <div class="timeline-item-date">2021-11>2022-12</div>
                    <div class="timeline-item-divider"></div>
                    <div class="timeline-item-content">
                      <div class="timeline-item-title">Shop name</div>
                      <div class="timeline-item-inner">
                        Collected
                        <span style="font-weight: bold; color: green"> 250 EUR</span><br />Balance
                        <span style="font-weight: bold"> 200 EUR </span>
                      </div>
                    </div>
                  </div>
                </div>
              </f7-card-content>
              <f7-list dividers-ios strong-ios accordion-list media-list>
                <f7-list-item accordion-item :title="$t('common.description')">
                  <f7-accordion-content>
                    <f7-block> </f7-block>
                  </f7-accordion-content>
                </f7-list-item>
              </f7-list>
              <f7-button fill large style="border-radius: 0px" color="gray" class="buttons">{{ $t("common.seeDetail")
                }}</f7-button>
            </f7-card>

            <f7-card expandable swipe-to-close v-else hide-navbar-on-open class="demo-facebook-card">
              <f7-card-content :padding="false">
                <f7-card-header :padding="false" class="no-margin">
                  <div class="demo-facebook-avatar padding-right">
                    <img v-if="this.shop.logo !== null" :src="`${this.shop.logo}`" width="40" height="40" />
                    <f7-badge v-else slot="media" color="auto" style="height: 40px; width: 40px; font-size: 16px">{{
                      $filtersGetInitial.getInitial(shop.name) }}</f7-badge>
                  </div>
                  <div class="demo-facebook-name">{{ this.shop.name }}</div>
                  <div class="demo-facebook-date" v-html="this.shop.tag"></div>
                </f7-card-header>
                <div v-if="this.shop.qr !== null">
                  <img :src="`${this.shop.qr}`" style="display: block; margin: auto; height: auto" />
                </div>
                <f7-link card-close color="inherit" class="card-opened-fade-in"
                  :style="{ position: 'absolute', right: '15px', top: '15px' }" icon-f7="multiply"></f7-link>
                <div class="card-content-padding">
                  <p v-html="this.shop.tag"></p>
                  <p v-html="this.shop.about"></p>
                  <p v-html="this.shop.policies"></p>
                </div>
              </f7-card-content>
            </f7-card>
            <div class="right not_sticky_with_content">
              <campaignRounds :entity="this.shop" :entityType="'shop'"></campaignRounds>
            </div>
            <f7-card outline header-divider class="left not_sticky_with_content" v-if="
              this.shop &&
              this.$keycloak.authenticated &&
              this.currentMember &&
              shop.shopAdminList !== null
            ">
              <f7-card-header>
                {{ $t("common.members") }}

                <f7-badge v-if="currentUserIsAdmin" bg-color="red" class="addParticipantToolTip">
                  <f7-link icon-ios="f7:person_crop_circle_fill_badge_plus" icon-md="material:person_add" color="white"
                    href="#" popup-open=".addNewMember"></f7-link>
                </f7-badge>
              </f7-card-header>
              <membersComponent :entityId="this.shop.id" :memberList="this.shop.shopMemberList"
                :adminList="this.shop.shopAdminList" :entityType="'shop'" :isAdmin="this.currentUserIsAdmin"
                :mainUserId="this.shop.ownerId"></membersComponent>
            </f7-card>

            <div v-if="this.shop && this.currentUserIsAdmin">
              <f7-card-header>
                <div v-if="this.shop.shopAdminList && this.shop.shopAdminList.length > 1">
                  {{ $t("shopdetail.shopAdmins") }}
                </div>
                <div v-else>{{ $t("shopdetail.addShopAdmin") }}</div>
                <div></div>
                <f7-badge v-if="currentUserIsAdmin" bg-color="red" :style="{
                  position: 'absolute',
                  right: '16px',
                  top: '8px',
                  'border-radius': '50px',
                  height: '32px',
                  width: '32px',
                }" class="elevation-3">
                  <f7-link icon-aurora="f7:person_crop_circle_fill_badge_plus"
                    icon-ios="f7:person_crop_circle_fill_badge_plus" icon-md="material:person_add" color="white"
                    href="#" popup-open=".addNewParticipant"></f7-link>
                </f7-badge>
              </f7-card-header>
              <f7-list dividers-ios strong-ios media-list v-if="
                this.shop.shopAdminList &&
                this.shop.shopAdminList.length > 1 &&
                this.currentUserIsAdmin
              ">
                <f7-list-item swipeout v-for="(shopAdmin, index) in this.shop.shopAdminList" :key="index"
                  :title="shopAdmin.name">
                  <f7-swipeout-actions right v-if="shopAdmin.userId !== profile.sub">
                    <f7-swipeout-button @click="withdrawAdminRole(shopAdmin)" color="red" overswipe>{{
                      $t("common.withdrawAdminRights") }}</f7-swipeout-button>
                  </f7-swipeout-actions>
                  <template #after>
                    <f7-badge color="auto" v-if="shopAdmin.userId !== profile.sub">{{
                      $t("common.admin")
                      }}</f7-badge>
                    <f7-badge color="auto" v-else>{{
                      $t("shopdetail.shopOwner")
                      }}</f7-badge>
                  </template>
                  <template #media>
                    <f7-badge color="auto" style="
                        height: 48px;
                        width: 48px;
                        font-size: 16px;
                        border-radius: 25px;
                      ">
                      {{ $filtersGetInitial.getInitial(shopAdmin.name) }}</f7-badge>
                  </template>
                </f7-list-item>
              </f7-list>
            </div>
            <f7-list dividers-ios strong-ios media-list v-if="currentUserIsAdmin">
              <f7-list-item :title="$t('shopdetail.addVideoOr')" link="#"
                @click="addVideoUrls(shop, type)"></f7-list-item>
            </f7-list>
            <swiper-container v-if="this.shop.shopImagesList && this.shop.shopImagesList.length > 0" :params="{
              slidesPerView: this.isTablet ? 1 : 1,
              spaceBetween: 0,
              disableOnInteraction: true,
              pagination: this.isTablet ? false : true,
            }">
              <f7-photo-browser :photos="this.shopPhotos" ref="popup"></f7-photo-browser>
              <swiper-slide v-for="(image, index) in this.shopPhotos" :key="index" style="height: 400px">
                <a link="#" @click="$refs.popup.open()">
                  <img :src="image.url" width="auto" max-height="100%" class="margin-horizontal-half" />
                </a>
                <f7-link v-if="currentUserIsAdmin" raised :style="{
                  position: 'absolute',
                  right: '-8px',
                  top: '-2px',
                  color: 'red',
                }" icon-f7="xmark_circle_fill" @click="deleteImage(image.id, shop, 'shop')"></f7-link>
              </swiper-slide>
            </swiper-container>

            <f7-card v-if="this.shop.shopVideoList && this.shop.shopVideoList.length > 0"
              v-for="(video, index) in this.shop.shopVideoList" :key="index"
              class="videoContainer grid grid-cols-1 medium-grid-cols-2 large-grid-cols-3" :style="{
                position: 'relative',
                overflow: 'hidden',
                'padding-top': '56.25%',
              }">
              <span :style="{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                border: 0,
              }" v-if="video.url !== null && video.url.includes('iframe')" v-html="video.url"></span>
              <iframe class="videoIframe" :style="{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                border: 0,
              }" :src="video.url" :title="video.name" frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen></iframe>
              <f7-link v-if="currentUserIsAdmin" raised :style="{
                position: 'absolute',
                right: '0px',
                top: '0px',
                color: 'red',
              }" icon-f7="xmark_circle_fill" @click="deleteVideo(video.id, shop, 'shop')"></f7-link>
              <f7-card-footer>{{ video.name }}</f7-card-footer>
            </f7-card>

            <f7-block-title medium class="margin-top-half" v-if="this.productParentByShopId">{{
              $t("shopdetail.shopProducts") }}</f7-block-title>
            <f7-list dividers-ios strong-ios media-list v-if="this.productParentByShopId"
              style="padding-bottom: 50px !important">
              <div width="50" medium="20" v-for="(productParent, index) in this.productParentByShopId" :key="index"
                class="itemImageContainer grid grid-cols-2 medium-grid-cols-4">
                <a class="text-color-black" :href="itemUrl(productParent.id)">
                  <swiper-container>
                    <f7-photo-browser :photos="this.shopPhotos" type="popup" ref="popup"></f7-photo-browser>
                    <swiper-slide v-for="(imageUrl, index) in this.shopPhotos" :key="index"
                      style="width: 150px; height: 155px">
                      <a link="#" @click="$refs.popup.open()">
                        <img v-if="imageUrl.fileType === 'image'" :src="imageUrl.url" width="150" height="150"
                          max-height="100%" class="margin-horizontal-half" />
                        <video v-if="imageUrl.fileType === 'video'" :src="imageUrl.url" controls />
                      </a>
                    </swiper-slide>
                  </swiper-container>
                  <div class="right not_sticky_with_content">
                    <campaignRounds :entity="this.shop" :entityType="'shop'"></campaignRounds>
                  </div>
                  <f7-card outline header-divider class="left not_sticky_with_content" v-if="
                    this.association &&
                    this.$keycloak.authenticated &&
                    this.currentMember &&
                    association.associationAdminList !== null
                  ">
                    <f7-card-header>
                      {{ $t("common.members") }}

                      <f7-badge v-if="currentMemberIsAdmin" bg-color="red" class="addParticipantToolTip">
                        <f7-link icon-ios="f7:person_crop_circle_fill_badge_plus" icon-md="material:person_add"
                          color="white" href="#" popup-open=".addNewMember"></f7-link>
                      </f7-badge>
                    </f7-card-header>
                    <membersComponent :entityId="this.shop.id" :memberList="this.shop.shopMemberList"
                      :adminList="this.shop.shopAdminList" :entityType="'shop'" :isAdmin="this.currentUserIsAdmin"
                      :mainUserId="this.shop.ownerId"></membersComponent>
                  </f7-card>
                  <!-- <img
              :src="`data:image/png;base64,${productParent.productImagesList[0].image}`"
              class="itemIamge no-margin-vertical"
            />-->

                  <div class="no-margin-vertical padding-horizontal-half" style="font-size: 90%">
                    <p class="no-margin-vertical itemTags">
                      <b>{{ productParent.name }}</b>
                    </p>
                    <p class="no-margin-vertical" v-if="productParent">
                      {{
                        $t("shopdetail.productVariant", {
                          myproduct: productParent.productList.length,
                        })
                      }}
                    </p>
                  </div>
                </a>
              </div>
            </f7-list>
          </div>
          <div class="right_layout">
            <div class="sticky with_content">
              <campaignRounds :entity="this.shop" :entityType="'shop'"></campaignRounds>
            </div>
          </div>
        </div>
      </f7-tab>
      <f7-tab v-for="(privateShop, index) in this.shop.privateShopList" :key="index + 1" :id="`tab-${index + 1}`"
        @tab:show="selectCampaign(privateShop)" class="page-content">
        <div class="layout_content">
          <div class="left_layout">
            <div class="main_layout">
              <f7-card class="demo-facebook-card" header-divider :id="`campaingView-${index + 1}`">
                <f7-card-header text-color="auto">
                  <div class="demo-facebook-avatar padding-right"
                    style="white-space: normal; word-wrap: break-word; font-weight: 90%">
                    <img v-if="privateShop.logo !== null" :src="privateShop.logo" width="40" height="40" />
                    <f7-badge v-else slot="media" color="auto" style="height: 40px; width: 40px; font-size: 16px">{{
                      $filtersGetInitial.getInitial(privateShop.name) }}</f7-badge>
                  </div>
                  <div class="demo-facebook-name">{{ privateShop.name }}</div>
                  <div class="demo-facebook-date" v-html="privateShop.tag"></div>

                  <div class="demo-facebook-date item-label" style="font-size: small">
                    {{
                      $t("common.fromto", {
                        startDate: privateShop.startDate.substr(0, 10),
                        endDate: privateShop.endDate.substr(0, 10),
                      })
                    }}
                  </div>
                </f7-card-header>

                <f7-card-content>
                  <div class="grid grid-cols-1 medium-grid-cols-2">
                    <span>
                      <!-- <br /> -->
                      <small :style="{ opacity: 0.7 }">{{ $t("common.amount") }}: </small>
                      <b>
                        <span v-html="$filtersToCurrency.toCurrency(
                          (privateShop.collectedAmount || 0).toFixed(2),
                          null,
                          privateShop.currencyCode
                        )
                          "></span>
                      </b>
                      <br />

                      <small :style="{ opacity: 0.7 }">{{ $t("common.balance") }}</small>
                      <b>
                        <span v-html="$filtersToCurrency.toCurrency(
                          (privateShop.accountBalance || 0).toFixed(2),
                          null,
                          privateShop.currencyCode
                        )
                          "></span>
                      </b>
                    </span>
                    <span>
                      <f7-gauge type="circle" :value="parseFloat(privateShop.collectedAmount) /
                        parseFloat(privateShop.collectedAmount)
                        " :valueText="$filtersToCurrency.toCurrency(
                          (privateShop.collectedAmount || 0).toFixed(2),
                          null,
                          privateShop.currencyCode
                        )
                          " valueTextColor="auto" border-color="green" labelText="collected"
                        labelTextColor="#4caf50" :labelFontWeight="600" :labelFontSize="12" :borderWidth="30"
                        bg-color="white" />
                    </span>
                  </div>
                </f7-card-content>
                <transactionCharts v-if="privateShop.transactionList.length > 0"></transactionCharts>
              </f7-card>
              <f7-list accordion-list>
                <f7-list-item accordion-item :title="$t('common.description')">
                  <f7-accordion-content>
                    <f7-block>
                      <p v-html="privateShop.description"></p>
                    </f7-block>
                  </f7-accordion-content>
                </f7-list-item>
                <f7-list-item accordion-item :title="$t('common.tag')">
                  <f7-accordion-content>
                    <f7-block>
                      <p v-html="privateShop.tag"></p>
                    </f7-block>
                  </f7-accordion-content>
                </f7-list-item>
              </f7-list>

              <div class="left not_sticky_with_content">
                <getTransactions :transactionList="privateShop.transactionList"
                  v-if="privateShop.transactionList.length > 0">
                </getTransactions>
              </div>
            </div>
            <div class="right_layout">
              <div class="sticky with_content">
                <getTransactions :transactionList="privateShop.transactionList"
                  v-if="privateShop.transactionList.length > 0">
                </getTransactions>
              </div>
            </div>
          </div>
        </div>
      </f7-tab>
    </f7-tabs>
    <addNewCampaign></addNewCampaign>
    <f7-popup class="addNewParticipant" style="min-height: 100%; --f7-sheet-bg-color: #fff" :tablet-fullscreen="true"
      :backdrop="false" :close-by-backdrop-click="false" :close-on-escape="false">
      <f7-page>
        <f7-navbar no-shadow>
          <div class="left"></div>
          <div class="title">{{ $t("shopdetail.addNewShopAdmin") }}</div>
          <div class="right">
            <f7-link popup-close icon-f7="multiply"></f7-link>
          </div>
        </f7-navbar>
        <div class="layout_content">
          <div class="left_layout"></div>
          <div class="main_layout">
            <f7-block-title large>{{ $t("shopdetail.addNewShopAdmin") }}</f7-block-title>
            <userslist> </userslist>
            <f7-toolbar tabbar bottom style="position: fixed; bottom: 0" bg-color="red" v-if="
              this.searchedUser &&
              this.searchedUser.userId !== this.profile.sub &&
              !shop.shopAdminList.find((x) => x.userId === this.searchedUser.userId)
            ">
              <f7-link raised fill large class="link buttons" text-color="white" strong
                :text="'Add ' + this.searchedUser.name + ' as admin'" @click="grantAdminRole(searchedUser)"></f7-link>
            </f7-toolbar>
            <f7-chip class="margin" outline style="display: flex; justify-content: center" v-if="
              this.searchedUser &&
              shop.shopAdminList.find((x) => x.userId === this.searchedUser.userId)
            " color="red">{{
              $t("shopdetail.isAlreadyAnAdmin", {
                isAdmin: this.searchedUser.name,
              })
            }}</f7-chip>
          </div>
          <div class="right_layout"></div>
        </div>
      </f7-page>
    </f7-popup>
    <template #fixed>
      <f7-fab position="center-bottom" :text="$t('shopdetail.listAProduct')" color="red" popup-close
        @click="listNewProduct()">
        <f7-icon ios="f7:text_badge_plus" aurora="f7:text_badge_plus" md="material:playlist_add"></f7-icon>
      </f7-fab>
      <f7-fab position="left-bottom" color="auto" @click="goToMessageGroup()">
        <f7-icon ios="f7:ellipses_bubble_fill" aurora="f7:ellipses_bubble_fill" md="material:chat"></f7-icon>
      </f7-fab>
      <f7-fab position="right-bottom" class="shareButton" color="auto" @click="socialShare(sharedContent)">
        <f7-icon aurora="f7:square_arrow_up" ios="f7:square_arrow_up" md="material:share"></f7-icon>
      </f7-fab>
    </template>
  </f7-page>
</template>
<style scoped>
.itemImageContainer {
  border-left: 1px solid #f4f4f4;
  border-bottom: 1px solid #f4f4f4;
}

.itemImageContainer img {
  height: "auto";
  width: 100%;
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
}

.demo-facebook-card .card-header {
  display: block;
  padding: 10px;
}

.demo-facebook-card .demo-facebook-avatar {
  float: left;
}

.demo-facebook-card .demo-facebook-name {
  margin-left: 44px;
  font-size: 14px;
  font-weight: 500;
}

.demo-facebook-card .demo-facebook-date {
  margin-top: 8px;
  font-size: 12px;
  color: #8e8e93;
}
</style>

<script>
import {
  f7Page,
  f7Navbar,
  f7NavLeft,
  f7Badge,
  f7NavRight,
  f7NavTitle,
  f7Link,
  f7Card,
  f7CardContent,
  f7CardHeader,
  f7List,
  f7ListItem,
  f7SwipeoutActions,
  f7SwipeoutButton,
  f7PhotoBrowser,
  f7BlockTitle,
  f7Popup,
  f7PageContent,
  f7Toolbar,
  f7Chip,
  f7Fab,
  f7Icon,
  theme,
  f7,
} from "framework7-vue";
import { mapGetters, mapState } from "vuex";
import Navbarright from "../components/navbarright.vue";
import addNewCampaign from "../components/associationAddCampaign.vue";
import sendMessage from "../components/modules/sendMessageToMembers.vue";
import membersComponent from "../components/modules/members.vue";
import campaignRounds from "../components/modules/campaignRounds.vue";
import getTransactions from "../components/modules/transactions.vue";
import transactionCharts from "../components/modules/charts.vue";
import { attachDocs } from "../js/mixins/attachDocs.js";
import { socialShare } from "../js/mixins/cordovaPlugins.js";
import { shop } from "../js/mixins/shop.js";
import UsersPage from "./users.vue";
import _ from "lodash";
export default {
  props: {
    f7route: Object,
    f7router: Object,
  },
  components: {
    navbarright: Navbarright,
    userslist: UsersPage,
    userslist: UsersPage,
    addNewCampaign,
    getTransactions,
    transactionCharts,
    membersComponent,
    campaignRounds,
    sendMessage,
    f7Page,
    f7Navbar,
    f7NavLeft,
    f7Badge,
    f7NavRight,
    f7NavTitle,
    f7Link,
    f7Card,
    f7CardContent,
    f7CardHeader,
    f7List,
    f7ListItem,
    f7SwipeoutActions,
    f7SwipeoutButton,
    f7PhotoBrowser,
    f7BlockTitle,
    f7Popup,
    f7PageContent,
    f7Toolbar,
    f7Chip,
    f7Fab,
    f7Icon,
  },
  mixins: [attachDocs, socialShare, shop],
  data() {
    return {
      theme,
      f7,
      productCategory: "",
      paymentCurrency: "",
      type: "shop",
      sharedContent: "",
      selectedMessages: "",
      isRecording: false,
      camapaignNumber: 0,
    };
  },
  mounted() {
    f7.toolbar.hide(".mainToolbar", true);
    if (!this.shop) {
      this.$store.dispatch("shop/getActiveShopById", this.f7route.params.shopId);
    }
    if (!this.userAccount && this.$keycloak.authenticated) {
      this.$store.dispatch("account/loadAccount", this.profile.sub);
    }
    if (this.shop) {
      this.$store.dispatch("product/getProductParentByShopId", this.shop.id);
    }
    console.log(this.shop);
    this.$store.dispatch("location/loadDestinationCity", this.profile.countryCode);
  },

  methods: {
    async grantAdminRole(searchedUser) {
      await this.$store.dispatch("shop/grantAdminRole", {
        shopId: this.shop.id,
        userId: searchedUser.userId,
      });
    },
    async withdrawAdminRole(shopAdmin) {
      await this.$store.dispatch("shop/withdrawAdminRole", {
        shopId: this.shop.id,
        userId: shopAdmin.userId,
      });
    },

    listNewProduct() {
      f7.preloader.show("multi");
      setTimeout(() => {
        this.f7router.navigate(`/product/new/${this.shop.id}`);
        f7.preloader.hide();
      }, 300);
    },

    async goToMessageGroup() {
      const chatId = this.shop.chatGroupId === "" ? this.shop.id : this.shop.chatGroupId;
      f7.preloader.show("multi");

      if (this.profile.sub === this.shop.shopOwnerId || this.currentUserIsAdmin) {
        setTimeout(() => {
          this.f7router.navigate(`/message/${chatId}/GROUP`, {
            reloadAll: true,
          });
          f7.preloader.hide();
        }, 300);
      } else {
        setTimeout(() => {
          this.f7router.navigate(`/message/${this.shop.chatUserId}/PERSONAL`, {
            reloadAll: true,
          });
          f7.preloader.hide();
        }, 300);
      }
    },

    itemUrl(id) {
      return `/shopProductDetail/${id}`;
    },
    addNewCampaignPopup() {
      f7.sheet.open(".addNewCampaign");
      this.camapaignNumber = parseFloat(this.shop.privateShopList.length) + 1;
    },
  },

  computed: {
    ...mapState("auth", [
      "profile",
      "documentsUrl",
      "document",
      "videosUrl",
      "videoService",
      "isDeskTop",
    ]),
    ...mapState("shop", ["categorylist", "categories", "shop", "shopList"]),
    ...mapState("product", [
      "product",
      "images",
      "optionNames",
      "shopImages",
      "allProduct",
      "productParent",
      "productByListOwner",
      "productByShopId",
      "productByShopNumber",
      "productByCategory",
      "productBySubCategory",
      "allProductParent",
      "productParentByList",
      "productParentByListOwner",
      "productParentByShopId",
      "productParentByShopNumber",
      "productParentByCategory",
      "productParentBySubCategory",
      "productParentGroupBuyList",
      "negoPrice",
      "groupBuy",
      "userGroupBuyList",
    ]),

    ...mapState("location", [
      "destinationCity",
      "country",
      "countryStates",
      "countryCities",
      "currentUserCountry",
    ]),
    ...mapState("account", [
      "primaryAccount",
      "businessAccount",
      "userDetail",
      "searchedUser",
    ]),
    ...mapState("auth", [
      "profile",
      "documentsUrl",
      "document",
      "videoService",
      "videosUrl",
      "dynamicLink",
    ]),

    ...mapGetters("remit", ["recipients"]),
    ...mapGetters("account", ["userByAccountNumber"]),

    destinationCtry() {
      return _.groupBy(this.destinationCountry, "toContinentName");
    },
    category() {
      return _.groupBy(this.categories, "categoryName");
    },

    shopPhotos() {
      this.shop.shopImagesList.map((item) => {
        return item.fileType === "video"
          ? {
            html: `<video src="${item.url}" controls ></video>`,
            caption: item.fileName,
          }
          : {
            url: item.url,
            caption: item.fileName,
          };
      });
    },
    currentUserIsAdmin() {
      if (this.profile.sub) {
        return _.find(this.shop.shopAdminList, ["userId", this.profile.sub]);
      }
    },
  },
};
</script>
