<template>
  <f7-page>
    <f7-navbar no-shadow :title="$t('404.notFound')" :back-link="$t('common.backButton')"
      :back-link-show-text="false"></f7-navbar>
    <div class="layout_content">
      <div class="left_layout"></div>
      <div class="main_layout">
        <f7-block strong>
          <p>{{ $t("404.sorry") }}</p>
          <p>{{ $t("404.requestedContentNotFound") }}</p>
        </f7-block>
      </div>
      <div class="right_layout"></div>
    </div>
  </f7-page>
</template>
<script>
import _ from "lodash";
export default {
  props: {
    f7route: Object,
    f7router: Object,
  },
};
</script>
