import { f7, theme } from "framework7-vue";
import _ from "lodash";
export const keyPadMixin = {
  data() {
    return {
      cardNumberKeypad: "",
      cardPINKeypad: "",
      cardCVVKeypad: "",
      inputVal: "",
      debitCardNumberKeypad: "",
    };
  },

  mounted() {
    const self = this;

    /*         f7.$('.keypad-button .keypad-button-dark').click(function() {
                                f7.$('.keypad-button-number').html('');
                                f7.dialog.alert("button");
                            }); */

    this.cardNumberKeypad = f7.keypad.create({
      inputEl: "#cardNumber, #transferCode",
      openIn: "auto",
      toolbarCloseText: "OK",
      valueMaxLength: 16,
      dotButton: false,
      // dotCharacter: '<i readonly disabled class="icon" style="height: var(--f7-tabbar-icon-size); width: var(--f7-tabbar-icon-size)"><img src="/static/icons/favicon.png" width="85%" height="85%"/></i>',
      formatValue(value) {
        value = value.toString();
        return (value =
          value.substr(0, 4) +
          " " +
          value.substr(4, 4) +
          " " +
          value.substr(8, 4) +
          " " +
          value.substr(12, 4) +
          "0000 0000 0000 0000".substring(0, 16 - value.length));
      },
      on: {
        change(keypad, value) {
          console.log(keypad, value);
          value = value.toString();
          this.inputVal = (
            value.substr(0, 4) +
            " " +
            value.substr(4, 4) +
            " " +
            value.substr(8, 4) +
            " " +
            value.substr(12, 4)
          ).toString();
          if (this.inputVal.length === 19) {
            if (keypad.inputEl.id === "cardNumber") {
              f7.$("#cardNumber").value = this.inputVal;
              self.cardNumber = this.inputVal;
              console.log(self.cardNumber);
            } else if (keypad.inputEl.id === "transferCode") {
              f7.$("#transferCode").value = this.inputVal;
              self.transferCode = this.inputVal;
            } else if (keypad.inputEl.id === "debitCardNumber") {
              f7.$("#debitCardNumber").value = this.inputVal;
              self.debitCardNumber = this.inputVal;
            }
            console.log(`keypad value ${this.inputVal}`);
          } else if (this.inputVal.length === 0) {
            self.cardNumber = "";
            self.transferCode = "";
          }
        },
      },
    });

    this.cardPINKeypad = f7.keypad.create({
      inputEl: "#cardPIN, #transferPIN",
      openIn: "auto",
      toolbarCloseText: "OK",
      valueMaxLength: 4,
      dotButton: false,
      // dotCharacter: '<i class="icon" style="height: var(--f7-tabbar-icon-size); width: var(--f7-tabbar-icon-size)"><img src="/static/icons/favicon.png" width="85%" height="85%"/></i>',
      formatValue(value) {
        value = value.toString();
        return (
          "****".substring(0, value.length) +
          "°°°°".substring(0, 4 - value.length)
        );
      },
      on: {
        change(keypad, value) {
          console.log(keypad, value);
          value = value.toString();
          console.log(keypad.inputEl.id);
          if (keypad.inputEl.id === "cardPIN") {
            f7.$("#cardPIN").value = value;
            self.cardPIN = value;
            console.log(`cardPIN: ${self.cardPIN}`);
            return;
          }
          if (keypad.inputEl.id !== "transferPIN") {
            return;
          }
          f7.$("#transferPIN").value = value;
          self.transferPIN = value;
          console.log(`transferPIN: ${self.transferPIN}`);
        },
      },
    });

    this.cardCVVKeypad = f7.keypad.create({
      inputEl: "#CVV",
      openIn: "auto",
      toolbarCloseText: "OK",
      valueMaxLength: 3,
      dotButton: false,
      // dotCharacter: '<i class="icon" style="height: var(--f7-tabbar-icon-size); width: var(--f7-tabbar-icon-size)"><img src="/static/icons/favicon.png" width="85%" height="85%"/></i>',
      on: {
        change(keypad, value) {
          value = value.toString();
          if (keypad.inputEl.id !== "CVV") {
            return;
          }
          f7.$("#CVV").value = value;
          self.CVV = value;
          console.log(`CVV: ${self.CVV}`);
        },
      },
    });

    /*    this.cardPINKeypad = f7.keypad.create({
                           // inputEl: "#cardPIN, #transferPIN",
                           openIn: "auto",
                           toolbarCloseText: "OK",
                           valueMaxLength: 4,
                           dotButton: false,
                           formatValue: function(value) {
                               value = value.toString();
                               return (
                                   "****".substring(0, value.length) +
                                   "°°°°".substring(0, 4 - value.length)
                               );
                           },
                           on: {
                               change(keypad, value) {
                                   console.log(keypad, value);
                                   value = value.toString();
                                   f7.$("#cardPIN").value = value;
                                   f7.$("#transferPIN").value = value;
                                   self.cardPIN = value;
                                   self.transferPIN = value;
                                   if (value.length === 4) {
                                       self.checkCard();
                                       self.payTransferNow();
                                   } else if (value.length === 0) {
                                       self.cardPIN = '';
                                   }
                               },
                           },
                       }); */
    this.debitCardNumberKeypad = f7.keypad.create({
      inputEl: "#debitCardNumber",
      openIn: "auto",
      toolbarCloseText: "OK",
      valueMaxLength: 16,
      dotButton: false,
      // dotCharacter: '<i class="icon" style="height: var(--f7-tabbar-icon-size); width: var(--f7-tabbar-icon-size)"><img src="/static/icons/favicon.png" width="85%" height="85%"/></i>',
      formatValue(value) {
        value = value.toString();
        return (value =
          value.substr(0, 4) +
          " " +
          value.substr(4, 4) +
          " " +
          value.substr(8, 4) +
          " " +
          value.substr(12, 4) +
          "0000 0000 0000 0000".substring(0, 16 - value.length));
      },
      on: {
        change(keypad, value) {
          value = value.toString();
          console.log(keypad, value);
          this.inputVal = (
            value.substr(0, 4) +
            " " +
            value.substr(4, 4) +
            " " +
            value.substr(8, 4) +
            " " +
            value.substr(12, 4)
          ).toString();
          if (this.inputVal.length === 19) {
            if (keypad.inputEl.id === "debitCardNumber") {
              f7.$("#debitCardNumber").value = this.inputVal;
              self.debitCardNumber = this.inputVal;
              console.log(self.debitCardNumber);
            }
            self.debitCardNumber = this.inputVal;
            console.log(`debit card keypad value ${self.debitCardNumber}`);
            return;
          }
          if (this.inputVal.length === 0) {
            self.debitCardNumber = "";
          }
        },
      },
    });
  },
  methods: {
    clearCardButton() {
      const self = this;

      if (!this.cardNumberKeypad) {
        return;
      }
      this.cardNumber = "";
      this.transferCode = "";
      this.cardNumberKeypad.setValue("");
      this.cardNumberKeypad.inputVal = "";
    },
    clearPINButton() {
      const self = this;

      if (!this.cardPINKeypad) {
        return;
      }
      this.cardPIN = "";
      this.transferPIN = "";
      this.cardPINKeypad.setValue("");
      this.cardPINKeypad.inputVal = "";
    },
    /*    getCardNumber() {
            const self = this;


            if (this.cardNumberKeypad.initialized == true && this.cardNumberKeypad.value.length === 16) {
                this.cardNumber = this.cardNumberKeypad.inputVal;
                this.transferCode = this.cardNumberKeypad.inputVal;
                console.log("card number value " + this.cardNumber);
            } else if (!this.cardNumberKeypad || (this.cardNumberKeypad && this.cardNumberKeypad.value === "")) {
                this.cardNumber = "";
                this.transferCode = "";
            }
        },
        getDebitCardInfo() {
            const self = this;

            if (this.debitCardNumberKeypad.initialized == true && this.debitCardNumberKeypad.value.length === 16) {
                this.debitCardNumber = this.debitCardNumberKeypad.inputVal;
                console.log("debit card number value " + this.debitCardNumber);
            } else if (!this.debitCardNumberKeypad || (this.debitCardNumberKeypad && this.debitCardNumberKeypad.value === "")) {
                this.debitCardNumber = "";
            }
        },
 */
  },
};
