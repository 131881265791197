import { f7, theme } from "framework7-vue";
// import { getStorage, ref, uploadBytes } from "firebase/storage";
import { mapGetters, mapState } from "vuex";
// import { initializeApp } from "firebase/app";
import {
  S3Client,
  GetObjectCommand,
  PutObjectCommand,
  DeleteObjectCommand,
  CopyObjectCommand,
  CreateMultipartUploadCommand,
} from "@aws-sdk/client-s3";
import { getSignedUrl } from "@aws-sdk/s3-request-presigner";
// import { Storage } from "@google-cloud/storage";
// const avocash_service_account = import.meta.env.VITE_AVOCASH_SERVICE_ACCOUNT;
export const fileUpload = {
  data() {
    return {
      // bucket_name: "avocash-storage",
      // projectId: "avocash",
      // storage: null,
      // firebaseConfig: {
      //   apiKey: "AIzaSyB3LpwOFmWCEPfwoFuRmmdPuHBYDYH1mp0",
      //   authDomain: "avocash-app.firebaseapp.com",
      //   projectId: "avocash-app",
      //   storageBucket: "avocash-app.appspot.com",
      //   messagingSenderId: "692788665353",
      //   appId: "1:692788665353:web:0fa04008c780da55902cd8",
      //   measurementId: "G-MFY1LVWT4Y",
      // },
      s3Storage: null,
    };
  },
  mounted() {
    // this.storage = new Storage({
    //   projectId: this.projectId,
    //   keyFilename: avocash_service_account,
    // });
    // const firebaseApp = initializeApp(this.firebaseConfig);
    // this.storage = getStorage(firebaseApp, "gs://avocash-storage");

    const ACCOUNT_ID = import.meta.env.VITE_R2_ACCOUNT_ID;
    this.s3Storage = new S3Client({
      region: "auto",
      endpoint: `https://${ACCOUNT_ID}.r2.cloudflarestorage.com`,
      requestChecksumCalculation: "WHEN_REQUIRED",
      credentials: {
        accessKeyId: import.meta.env.VITE_R2_ACCESS_KEY_ID,
        secretAccessKey: import.meta.env.VITE_R2_SECRET_ACCESS_KEY,
      },
    });
  },
  methods: {
    async getR2SignedUrl(bucketName, fileId) {
      return await getSignedUrl(
        this.s3Storage,
        new GetObjectCommand({ Bucket: bucketName, Key: fileId }),
        { expiresIn: Date.now() + 15 * 60 * 1000 } //7 days
      );
    },
    async uploadR2File(document, fileId, bucketName) {
      console.log("document", document, fileId, bucketName);
      if (document.file == null) {
        throw new Error("No file provided");
      }
      if (document.file.size < 1) {
        throw new Error("File is empty");
      }
      const bucket =
        bucketName === null || bucketName === ""
          ? import.meta.env.VITE_R2_PUBLIC_BUCKET_NAME
          : bucketName;
      console.log("fileId", fileId);
      const command =
        document.fileId.size <= 5242880
          ? new PutObjectCommand({
              Bucket: bucket,
              Key: fileId,
              Body: document.file,
              ContentType: document.file.type,
              ContentLength: document.file.size,
              // ACL:
              //   bucket === import.meta.env.VITE_R2_PUBLIC_BUCKET_NAME
              //     ? "public-read"
              //     : "private",
            })
          : new CreateMultipartUploadCommand({
              Bucket: bucket,
              Key: fileId,
              Body: document.file,
              ContentType: document.file.type,
              ContentLength: document.file.size,
              // ACL:
              //   bucket === import.meta.env.VITE_R2_PUBLIC_BUCKET_NAME
              //     ? "public-read"
              //     : "private",
            });
      console.log("command", command);
      const response = await this.s3Storage.send(command);
      console.log("response", response);
      document.url =
        bucket == import.meta.env.VITE_R2_PUBLIC_BUCKET_NAME
          ? `${import.meta.env.VITE_R2_CDN_PUBLIC_ENDPOINT}/${fileId}`
          : `${import.meta.env.VITE_R2_CDN_PRIVATE_ENDPOINT}/${fileId}`;
      document.fileId = fileId;
      return document;
    },
    async updateR2File(file, fileId, bucketName, url) {
      let command;
      if (fileId != null && bucketName != "" && url === "") {
        command = new PutObjectCommand({
          Bucket: bucketName,
          Key: fileId,
          Body: file,
        });
      } else {
        const fileId = await this.getFileKeyFromUrl(url);
        command = new CopyObjectCommand({
          Bucket: bucketName,
          CopySource: `${bucketName}/${fileId}`,
          Key: fileId,
        });
      }
      await this.s3Storage.send(command);
    },
    async deleteR2File(bucketName, fileId) {
      const command = new DeleteObjectCommand({
        Bucket: bucketName,
        Key: fileId,
      });
      await this.s3Storage.send(command);
    },
    async getDataKeyFromUrl(url) {
      const fileData = {};
      const cleanUrl = new URL(url);
      fileData.fileKey = cleanUrl.pathname.slice(1);
      if (
        cleanUrl.pathname.includes(import.meta.env.VITE_R2_CDN_PUBLIC_ENDPOINT)
      ) {
        fileData.bucketName = import.meta.env.VITE_R2_PUBLIC_BUCKET_NAME;
      } else {
        fileData.bucketName = url.includes(
          import.meta.env.VITE_R2_CDN_PRIVATE_ENDPOINT
        )
          ? import.meta.env.VITE_R2_PRIVATE_BUCKET_NAME
          : import.meta.env.VITE_R2_PUBLIC_BUCKET_NAME;
      }
      return fileData;
    },
    async getFileKeyFromUrl(url) {
      const cleanUrl = new URL(url);
      return cleanUrl.pathname.slice(1);
    },

    //
    // Example of how to upload a file to GCS
    // async uploadToGcsNodeJs(file, fileId, outputFileName, isPublic = true) {
    //   if (!file) throw new Error("No file provided");
    //   if (file.size < 1) throw new Error("File is empty");
    //   const file_id = fileId
    //     ? fileId.replace("-", "")
    //     : f7.utils.id("xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx");
    //   const bucket = this.storage.bucket(this.bucketName);
    //   const filename = outputFileName ?? file.name;
    //   const buffer = await file.arrayBuffer();
    //   let response;
    //   response = isPublic
    //     ? this.storage
    //         .bucket(this.bucketName)
    //         .file(`${file_id}&&${filename}`)
    //         .save(Buffer.from(buffer))
    //         .makePublic()
    //     : this.storage
    //         .bucket(this.bucketName)
    //         .file(`${file_id}&&${filename}`)
    //         .save(Buffer.from(buffer));

    //   response = bucket.upload(file, {
    //     destination: `${file_id}&&${filename}`,
    //     public: isPublic,
    //   });
    //   console.log("upload response ", response);
    //   const gcpDocUrl = {
    //     url: `https://storage.googleapis.com/${this.bucketName}/${file_id}&&${filename}`,
    //     fileId: file_id,
    //     fileName: filename,
    //     mediaLink: response.mediaLink
    //       ? response.mediaLink
    //       : `https://storage.googleapis.com/${this.bucketName}/${file_id}&&${filename}`,
    //     selfLink: response.selfLink
    //       ? response.selfLink
    //       : `https://storage.googleapis.com/${this.bucketName}/${file_id}&&${filename}`,
    //     contentType: response.contentType ? response.contentType : file.type,
    //   };
    //   console.log("gcpDocUrl", gcpDocUrl);
    //   return gcpDocUrl;
    // },

    // async uploadSignedUrl() {
    //   for (const content of this.documentsUrl) {
    //     const url = this.getSignedUrl(content.name);
    //     console.log(url);
    //     const response = await fetch(url, {
    //       method: "PUT",
    //       body: file,
    //       headers: {
    //         "Content-Type": "application/octet-stream",
    //       },
    //     });
    //     console.log(response.ok);
    //   }
    // },

    // async getSignedUrl(fileName) {
    //   const [url] = await this.storage
    //     .bucket(this.bucketName)
    //     .file(fileName)
    //     .getSignedUrl({
    //       action: "write",
    //       version: "v4",
    //       expires: Date.now() + 15 * 60 * 1000,
    //       contentType: "application/octet-stream",
    //     });

    //   return url;
    // },

    // // Example of how to run CORS from code - if needed
    // async SetCors() {
    //   await this.storage.bucket(this.bucketName).setCorsConfiguration([
    //     {
    //       maxAgeSeconds: 3600,
    //       method: ["GET", "PUT"],
    //       origin: ["*"],
    //       responseHeader: ["Content-Type"],
    //     },
    //   ]);
    // },

    // async uploadToGcs(file, fileId, outputFileName, isPublic = true) {
    //   if (!file) throw new Error("No file provided");
    //   if (file.size < 1) throw new Error("File is empty");
    //   const file_id = fileId
    //     ? fileId.replace("-", "")
    //     : f7.utils.id("xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx");
    //   const filename = outputFileName ?? file.name;
    //   const storageRef = ref(this.storage, `${file_id}&&${filename}`);
    //   let gcpDocUrl;
    //   uploadBytes(storageRef, file).then((snapshot) => {
    //     console.log("Uploaded a blob or file!", snapshot);
    //     const response = snapshot;
    //     gcpDocUrl = {
    //       url: `https://storage.googleapis.com/${this.bucketName}/${file_id}&&${filename}`,
    //       fileId: file_id,
    //       fileName: filename,
    //       mediaLink: response.mediaLink
    //         ? response.mediaLink
    //         : `https://storage.googleapis.com/${this.bucketName}/${file_id}&&${filename}`,
    //       selfLink: response.selfLink
    //         ? response.selfLink
    //         : `https://storage.googleapis.com/${this.bucketName}/${file_id}&&${filename}`,
    //       contentType: response.contentType ? response.contentType : file.type,
    //     };
    //     console.log("gcpDocUrl", gcpDocUrl);
    //   });
    //   return gcpDocUrl;
    // },
    // async downloadFromGcs(fileName, fileId) {
    //   const filename = fileId == null ? fileName : `${fileId}&&${fileName}`;
    //   const contents = await this.storage
    //     .bucket(this.bucketName)
    //     .file(filename)
    //     .download();
    //   console.log(contents);
    //   return contents;
    // },

    // async deleteFromGcs(fileName, fileId) {
    //   const deleteOptions = {
    //     ifGenerationMatch: generationMatchPrecondition,
    //   };
    //   let filename;
    //   if (fileName.contains("&&")) {
    //     filename = fileName;
    //   } else {
    //     filename = fileId == null ? fileName : `${fileId}&&${fileName}`;
    //   }

    //   await this.storage
    //     .bucket(this.bucketName)
    //     .file(filename)
    //     .delete(deleteOptions);

    //   console.log(`gs://${bucketName}/${filename} deleted`);

    //   deleteFile().catch(console.error);
    // },
    // deleteFileFromUrl(url) {
    //   const fileName = this.getFileNameFromUrl(url);
    //   this.deleteFromGcs(fileName, null);
    // },
  },

  computed: {
    ...mapState("auth", [
      "profile",
      "documentsUrl",
      "document",
      "isDeskTop",
      "isTablet",
      "isCordova",
      "locale",
    ]),
  },
};
