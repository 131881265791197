<template>
  <f7-popup class="addNewWalletCampaign" style="--f7-sheet-bg-color: #fff" :tablet-fullscreen="true" :params="{
    backdrop: true,
    animate: true,
    swipeToClose: false,
    closeByBackdropClick: false,
    closeOnEscape: false,
  }">
    <f7-page>
      <f7-navbar>
        <div class="left"></div>
        <div class="title">
          {{ $t("walletdetail.addNewCampaign") }}
        </div>
        <div class="right">
          <f7-link popup-close icon-f7="multiply"></f7-link>
        </div>
      </f7-navbar>
      <div class="layout_content">
        <div class="left_layout"></div>
        <div class="main_layout">
          <f7-list dividers-ios strong-ios media-list form id="addNewCampaign">
            <f7-list-group>
              <f7-list-input required validate :placeholder="$t('common.name')" type="text" name="name" floating-label
                :label="$t('common.name')" :error-message="$t('common.nameInfo')" :info="$t('common.nameInfo')"
                :value="name" @input="name = $event.target.value" clear-button></f7-list-input>
              <f7-list-input required validate :placeholder="$t('common.tag')" type="text" name="tag" floating-label
                :label="$t('common.tag')" :error-message="$t('common.tagInfo')" :info="$t('common.tagInfo')"
                :value="this.tag" @input="tag = $event.target.value" clear-button></f7-list-input>

              <f7-list-input :label="$t('common.selectMyCampaignDateRange')" type="datepicker" floating-label
                :placeholder="$t('common.selectMyCampaignDateRange')" readonly :value="this.campaignPeriod"
                @calendar:change="(value) => (campaignPeriod = value)" :calendar-params="{
                  rangePicker: true,
                  closeOnSelect: true,
                  openIn: 'auto',
                  minDate: new Date(),
                  header: true,
                  footer: true,
                }" :error-message="$t('common.selectMyCampaignDateRange')" :info="this.campaignDay"
                clear-button></f7-list-input>
              <f7-list-input type="number" name="fundingTarget" :placeholder="$t('walletdetail.fundingTarget')" min="0"
                :value="this.fundingTarget" @input="fundingTarget = $event.target.value" pattern="[0-9]*" clear-button>
                <template #media> {{ this.wallet.currencyCode }} </template>
              </f7-list-input>
              <f7-block-title class="item-header">{{
                $t("common.description")
                }}</f7-block-title>
              <f7-text-editor :value="this.description" name="description" :placeholder="$t('common.description')"
                @texteditor:change="(v) => (description = v)" resizable required validate type="texteditor" :mode="f7.device.cordova || !this.isTablet ? 'keyboard-toolbar' : 'toolbar'
                  " :style="f7.device.cordova || !this.isTablet
                    ? '--f7-text-editor-height: 150px'
                    : ''
                    " />
              <f7-list-input v-if="
                this.wallet.walletOwnerList && this.wallet.walletOwnerList.length > 2
              " :label="$t('walletdetail.selectCampaignManager')" type="select"
                :placeholder="$t('walletdetail.selectCampaignManager')" @input="campaignManager = $event.target.value">
                <option v-for="owner in this.wallet.walletOwnerList" :key="owner.id" :value="owner.userId">
                  {{ owner.name }}
                </option>
              </f7-list-input>
              <span v-if="this.wallet.thirdPartyCampaign">
                <f7-list-item :title="$t('shopcreatenew.selectCountry')" class="selectCountry item-label" smart-select
                  :smart-select-params="{
                    openIn: 'page',
                    searchbar: true,
                    searchbarPlaceholder: $t('shopcreatenew.findCountry'),
                    closeOnSelect: true,
                  }">
                  <f7-link class="input-icon4">
                    <img v-if="this.selectedCountry" class="coutryMap no-margin-bottom no-padding-bottom" :src="'https://flagcdn.com/' +
                      this.selectedCountry.countryCode2.toLowerCase() +
                      '.svg'
                      " width="20" :alt="selectedCountry.name" />
                  </f7-link>
                  <select name="country" v-model="currentCountryCode" @change="get3rdPartyWallet()">
                    <optgroup v-for="(countryList, continentName, index) in this.country" :label="continentName"
                      :key="index">
                      <option v-for="country in countryList" :key="country.id" :value="country.countryCode2"
                        :data-option-image="'https://flagcdn.com/' +
                          country.countryCode2.toLowerCase() +
                          '.svg'
                          " width="20">
                        {{ country.name }}
                      </option>
                    </optgroup>
                  </select>
                </f7-list-item>
                <f7-list-item v-if="this.currentCountryCode !== ''" class="beneficiaryCampaigns item-label" smart-select
                  :smart-select-params="{
                    openIn: 'popup',
                    popupSwipeToClose: true,
                    popupPush: true,
                    closeOnSelect: true,
                    searchbar: true,
                    searchbarPlaceholder: $t('walletcreatenew.selectBeneficiaryCampaign'),
                  }" :title="$t('walletcreatenew.beneficiaryCampaign')">
                  <select name="campaigns" v-model="beneficiaryCampaign">
                    <optgroup v-for="(wallet, index) in this.thirdPartyAllowedWalletList" :label="wallet.name"
                      :key="wallet.id">
                      <option smartselect v-for="(campaign, index) in wallet" :value="campaign.id" :key="index">
                        {{ campaign.name }}
                      </option>
                    </optgroup>
                  </select>
                </f7-list-item>
              </span>
              <!-- <myDocs :currentDocuments="null" :type="null" :entity="null" /> -->
            </f7-list-group>
          </f7-list>
          <f7-toolbar tabbar bottom bg-color="red" :style="{ bottom: 0, position: isTablet ? 'initial' : 'fixed' }">
            <f7-link raised fill large text-color="white" class="link buttons" strong @click="addCampaign()">{{
              $t("walletdetail.addNewCampaign") }}</f7-link>
          </f7-toolbar>
        </div>
        <div class="right_layout"></div>
      </div>
    </f7-page>
  </f7-popup>

  <f7-popup class="addNewMember" style="min-height: 100%; --f7-sheet-bg-color: #fff; height: auto"
    :tablet-fullscreen="true" :backdrop="false" :close-by-backdrop-click="false" :close-on-escape="false"
    swipe-to-close>
    <f7-page>
      <f7-navbar>
        <div class="left"></div>
        <div class="title">
          {{ $t("walletdetail.addNewWalletOwner") }}
        </div>
        <div class="right">
          <f7-link popup-close icon-f7="multiply"></f7-link>
        </div>
      </f7-navbar>
      <div class="layout_content">
        <div class="left_layout"></div>
        <div class="main_layout">
          <f7-block-title large class="padding-left">{{
            $t("walletdetail.addNewWalletOwner")
            }}</f7-block-title>

          <userslist> </userslist>
          <f7-block-title large class="padding-left">{{
            $t("walletdetail.members")
            }}</f7-block-title>

          <f7-list dividers-ios strong-ios accordion-list class="no-margin-top" :no-chevron="!currentUserIsAdmin"
            v-if="this.$keycloak.authenticated">
            <f7-list-item v-for="(owner, index) in this.wallet.walletOwnerList" :key="index" accordion-item
              :title="owner.name" :subtitle="owner.phoneNumber" :text="owner.email" :badge="wallet.walletAdminList.find((x) => x.userId === owner.userId)
                ? $t('common.admin')
                : ''
                " badge-color="primary">
              <f7-accordion-content v-if="currentUserIsAdmin && owner.userId !== wallet.ownerUserId">
                <f7-button raised fill small large ripple color="red" class="margin-horizontal margin-vertical-half"
                  :text="$t('common.removeUser')" @click="removeWalletOwner(owner)">
                </f7-button>

                <f7-button @click="grantAdminRole(owner)" raised fill small large ripple color="green"
                  class="margin-horizontal margin-vertical-half" :text="$t('common.grantAdminRights')"
                  v-if="!wallet.walletAdminList.find((x) => x.userId === owner.userId)">
                </f7-button>
                <f7-button v-if="wallet.walletAdminList.find((x) => x.userId === owner.userId)"
                  @click="withdrawAdminRole(owner)" raised fill small large ripple
                  class="margin-horizontal margin-vertical-half" :text="$t('common.withdrawAdminRights')"
                  color="orange">
                </f7-button>
              </f7-accordion-content>
              <template #media>
                <img v-if="owner.avatar" :src="`data:image/png;base64,${owner.avatar}`" width="40"
                  style="height: 48px; width: 48px; border-radius: 25px" />

                <f7-badge color="auto" v-else style="height: 48px; width: 48px; font-size: 16px; border-radius: 25px">
                  {{ $filtersGetInitial.getInitial(owner.name) }}</f7-badge>
              </template>
            </f7-list-item>
          </f7-list>

          <f7-toolbar tabbar bottom :style="{ bottom: 0, position: isTablet ? 'initial' : 'fixed' }" bg-color="red"
            v-if="
              this.searchedUser &&
              this.searchedUser.userId !== this.profile.sub &&
              !this.wallet.walletOwnerList.find(
                (x) => x.userId === this.searchedUser.userId
              )
            ">
            <f7-link raised fill large class="link buttons" text-color="white" strong rippe
              @click="addWalletOwner(searchedUser)">
              {{
                $t("walletdetail.addAsMember", {
                  asMember: this.searchedUser.name,
                })
              }}</f7-link>
          </f7-toolbar>
          <f7-chip class="margin" outline style="display: flex; justify-content: center" v-if="
            this.searchedUser &&
            this.wallet.walletOwnerList.find(
              (x) => x.userId === this.searchedUser.userId
            )
          " color="red">{{
            $t("walletdetail.isAlreadyAMember", {
              isMember: this.searchedUser.name,
            })
          }}</f7-chip>
        </div>
        <div class="right_layout"></div>
      </div>
    </f7-page>
  </f7-popup>
</template>
<script>
import {
  f7Popover,
  f7Block,
  f7Button,
  f7Link,
  f7Sheet,
  f7PageContent,
  f7,
  theme,
} from "framework7-vue";
import { mapGetters, mapState } from "vuex";
import { walletMixin } from "../js/mixins/walletMixins.js";
import UsersPage from "../pages/users.vue";
import _ from "lodash";
import attachDocs from "../components/attachDocs.vue";
// import myDocs from "../components/myDocs.vue";

import { cordovaCamera } from "../js/mixins/cordovaCamera.js";

export default {
  props: {
    f7route: Object,
    f7router: Object,
  },
  mixins: [walletMixin, cordovaCamera],
  components: {
    userslist: UsersPage,
    attachDocs,
    // myDocs,
    f7Popover,
    f7Block,
    f7Button,
    f7Link,
    f7Sheet,
    f7PageContent,
  },
  data() {
    return {
      theme,
      f7,
      beneficiaryCampaign: "",
      tag: "",
      name: "",
      description: "",
      campaignManager: "",
      campaignPeriod: [],
      fundingTarget: "",
      campaignPeriod: [],
      forms: "",
      currentCountryCode: "",
      type: "wallet",
    };
  },
  mounted() {
    this.currentCountryCode == this.profile.countryCode;
  },
  methods: {
    async addCampaign() {
      if (!f7.input.validateInputs("#addNewCampaign")) {
        return;
      }
      const campaign = {
        campaignNumber:
          this.wallet.campaignList !== undefined
            ? parseFloat(this.wallet.campaignList.length) + 1
            : 1,
        fundingTarget: this.fundingTarget,
        startDate: this.campaignPeriod[0],
        endDate: this.campaignPeriod[1],
        description: this.description,
        name: this.name,
        beneficiaryCampaign: this.beneficiaryCampaign,
        campaignManager: this.campaignManager,
      };
      this.forms = new FormData();
      await this.forms.append("campaign", JSON.stringify(campaign));
      await this.forms.append("projectWalletId", this.wallet.id);
      for (const element of this.documentsUrl) {
        await this.forms.append("files", element.file);
      }

      await this.$store.dispatch("wallet/createCampaign", this.forms);
      f7.sheet.close(".addNewCampaign");
    },
  },

  computed: {
    ...mapState("wallet", [
      "wallet",
      "projectCategories",
      "walletOwner",
      "walletList",
      "publicWalletList",
      "walletTransaction",
      "thirdPartyAllowedWalletList",
      "privateWalletTransactionList",
      "publicWalletTransactionList",
    ]),
    ...mapState("account", [
      "primaryAccount",
      "businessAccount",
      "userDetail",
      "searchedUser",
    ]),
    ...mapState("location", [
      "destinationCity",
      "country",
      "countryStates",
      "countryCities",
      "currentUserCountry",
    ]),
    ...mapState("auth", [
      "video",
      "isDeskTop",
      "isTablet",
      "device",
      "profile",
      "documentsUrl",
      "document",
    ]),
    selectedCountry() {
      return _.find(this.country, ["countryCode2", this.currentCountryCode]);
    },

    currentWalletOwner() {
      if (this.$keycloak.authenticated) {
        return _.find(this.wallet.walletOwnerList, ["userId", this.profile.sub]);
      }
    },
    campaignDay() {
      if (this.campaignPeriod.length > 1) {
        return (
          (this.campaignPeriod[1].getTime() - this.campaignPeriod[0].getTime()) /
          (1000 * 3600 * 24) +
          " days"
        );
      }
    },
  },
};
</script>
