<template>
  <f7-card-content no-padding>
    <f7-list
      dividers-ios
      strong-ios
      outline
      media-list
      accordion-list
      :no-chevron="!this.isAdmin"
    >
      <f7-list-item
        v-for="(participant, index) in this.memberList"
        :key="index"
        accordion-item
        :title="participant.name"
        :subtitle="getUserCountry(participant)"
        :text="participant.email ? participant.email : ''"
        :badge="
          adminList.find((x) => x.userId === participant.userId) ? $t('common.admin') : ''
        "
        badge-color="primary"
      >
        <f7-accordion-content
          v-if="isAdmin && participant.userId !== mainUserId"
          class="grid medium-grid-cols-2"
        >
          <f7-button
            raised
            fill
            small
            large
            ripple
            color="red"
            class="margin-horizontal margin-bottom-half"
            :text="$t('common.removeAsParticipant')"
            @click="removeParticipant(participant)"
          >
          </f7-button>
          <f7-button
            raised
            fill
            small
            large
            ripple
            color="green"
            class="margin-horizontal margin-bottom-half"
            @click="grantAdminRole(participant)"
            :text="$t('common.grantAdminRights')"
            v-if="!adminList.find((x) => x.userId === participant.userId)"
          >
          </f7-button>
          <f7-button
            v-if="adminList.find((x) => x.userId === participant.userId)"
            raised
            fill
            small
            large
            ripple
            color="orange"
            class="margin-horizontal margin-bottom-half"
            @click="withdrawAdminRole(participant)"
            :text="$t('common.withdrawAdminRights')"
          >
          </f7-button>
        </f7-accordion-content>
        <template #media>
          <img
            v-if="participant.avatar"
            :src="`${participant.avatar}`"
            width="40"
            style="height: 48px; width: 48px; border-radius: 25px"
          />
          <f7-badge
            color="auto"
            v-else
            style="height: 48px; width: 48px; font-size: 16px; border-radius: 25px"
            >{{ $filtersGetInitial.getInitial(participant.name) }}</f7-badge
          >
        </template>
      </f7-list-item>
    </f7-list>
  </f7-card-content>
</template>
<style scoped></style>
<script>
import { f7, theme } from "framework7-vue";
import { mapGetters, mapState } from "vuex";
import _ from "lodash";
export default {
  props: {
    f7route: Object,
    f7router: Object,
    memberList: Object,
    adminList: Object,
    isAdmin: Boolean,
    mainUserId: String,
    entityType: String,
    entityId: String,
  },
  data() {
    return {
      theme,
      f7,
    };
  },
  mounted() {},

  methods: {
    async removeParticipant(participant) {
      await this.$store.dispatch(`${this.entityType}/removeParticipant`, {
        userId: participant.userId,
        entityId: this.entityId,
      });
      this.closeAccordionItem();
    },
    async grantAdminRole(participant) {
      console.log(participant);
      await this.$store.dispatch(`${this.entityType}/grantAdminRole`, {
        entityId: this.entityId,
        userId: participant.userId,
      });
      this.closeAccordionItem();
    },
    async withdrawAdminRole(participant) {
      await this.$store.dispatch(`${this.entityType}/withdrawAdminRole`, {
        entityId: this.entityId,
        userId: participant.userId,
      });
      this.closeAccordionItem();
    },

    closeAccordionItem() {
      f7.accordion.close(".accordion-item-opened");
    },
    getUserCountry(user) {
      if (user.phoneNumber == undefined) {
        return "";
      } else {
        const curCtry = user.countryCode
          ? this.country.find((x) => x.countryCode2 === user.countryCode.toUpperCase())
          : "";
        const phone =
          user.phoneNumber.startsWith("+" || "00") && curCtry.length > 0
            ? user.phoneNumber
            : `+${curCtry.phoneCode}${user.phoneNumber}`;
        return user.phoneNumber === "" ? "" : phone;

        // curCtry ? `+${curCtry.phoneCode}${user.phoneNumber}` : "";
      }
    },
  },

  computed: {
    ...mapState("account", ["userAccount", "users"]),
    ...mapState("auth", [
      "profile",
      "documentsUrl",
      "document",
      "isTablet",
      "authenticated",
    ]),
    ...mapState("location", ["destinationCity", "country"]),
    countries() {
      return _.groupBy(this.country, "continentName");
    },
  },
};
</script>
