import axios from "axios";
// import base_url from "../base_url.js";

const path = import.meta.env.VITE_APP_URL + "account/transfer";
// const transaction={
export default (app) => {
  return {
    namespaced: true,
    state: {
      ipAddress: "",
      country: "",
      userLocation: "",
      destinationCountry: "",
      originCountry: "",
      payerDestinationCountry: "",

      grossexchangerate: "",
      grossexchangerate2: "",
      toCurrencyRate: "",
      fromCurrencyRate: "",
      //Transfer detail
      transferDetail: "",
      transferReverseDetail: "",
      //Payment detail
      paymentDetail: "",
      paymentReverseDetail: "",
      //QR Payment detail
      issueQRPaymentDetail: "",
      issueQRPaymentReverseDetail: "",
      //Claim detail
      claimDetail: "",
      claimReverseDetail: "",
      //QR Claim detail
      issueQRClaimDetail: "",
      issueQRClaimReverseDetail: "",
      //cash-out detail
      cashOutDetail: "",
      cashOutReverseDetail: "",
      //QR cash-out detail
      issueQRCashOutDetail: "",
      issueQRCashOutReverseDetail: "",
      //pay-out detail
      payOutDetail: "",
      payOutReverseDetail: "",
      extraTransferDetail: "",
      QRCode: "",
      QRCodeToClaim: "",
      QRCodeToCashOut: "",
      QRtext: "",
      decodedQRCode: "",
      primaryTransactionList: "",
      businessTransactionList: "",

      recipientTopupTransactionList: "",
    },
    getters: {
      extraTransferDetail: (state) => state.extraTransferDetail,
      ipAddress: (state) => state.ipAddress,
      // recipientTransactionListMerged: state => {
      //     return _.assign(
      //         state.recipientPickupTransactionList,
      //         state.recipientTopupTransactionList
      //     );
      // },
      grossexchangerate2: (state) =>
        state.toCurrencyRate / state.fromCurrencyRate,
      // grossexchangerate2: state => {
      //   return state.toCurrencyRate / state.fromCurrencyRate || parseFloat(
      //     state.grossexchangerate[Object.keys(state.grossexchangerate)[0]]
      //   );
      // },
    },
    mutations: {
      GET_CURRENTUSERIPADDRESS(state, ipAddress) {
        state.ipAddress = ipAddress;
      },
      GET_CURRENTUSERLOCATION(state, userLocation) {
        state.userLocation = userLocation;
      },
      SET_DESTINATIONCTRY(state, destinationCountry) {
        state.destinationCountry = destinationCountry;
      },
      SET_ORIGINCTRY(state, originCountry) {
        state.originCountry = originCountry;
      },
      GET_PAYERDESTINATIONCTRY(state, payerDestinationCountry) {
        state.payerDestinationCountry = payerDestinationCountry;
      },

      GET_EXTRATRANSFERDETAIL(state, extraTransferDetail) {
        state.extraTransferDetail = extraTransferDetail;
      },

      GET_PRIMARYACCOUNTTRANSACTIONS(state, primaryTransactionList) {
        state.primaryTransactionList = primaryTransactionList;
      },
      GET_BUSINSSACCOUNTTRANSACTIONS(state, businessTransactionList) {
        state.businessTransactionList = businessTransactionList;
      },

      // GET_RECIPIENTTOPUPTRANSACTIONLIST(state, recipientTopupTransactionList) {
      //     state.recipientTopupTransactionList = recipientTopupTransactionList;
      // },
      TOPUPBETWEENACCOUNTS(state, payload) {
        state.payload = payload;
      },
      TOPUPMYACCOUNT(state, topuptransaction) {
        state.topuptransaction = topuptransaction;
      },

      GET_GROSSXRATE(state, grossexchangerate) {
        state.grossexchangerate = grossexchangerate;
      },
      GET_CURRENCYRATE1(state, fromCurrencyRate) {
        state.fromCurrencyRate = fromCurrencyRate;
      },
      GET_CURRENCYRATE2(state, toCurrencyRate) {
        state.toCurrencyRate = toCurrencyRate;
      },
      GET_TRANSFERDETAIL(state, transferDetail) {
        state.transferDetail = transferDetail;
      },
      GET_TRANSFERREVERSEDETAIL(state, transferReverseDetail) {
        state.transferReverseDetail = transferReverseDetail;
      },
      //get pay detail
      GET_PAYMENTDETAIL(state, paymentDetail) {
        state.paymentDetail = paymentDetail;
      },
      GET_PAYMENTREVERSEDETAIL(state, paymentReverseDetail) {
        state.paymentReverseDetail = paymentReverseDetail;
      },
      GET_QRPAYMENTDETAIL(state, issueQRPaymentDetail) {
        state.issueQRPaymentDetail = issueQRPaymentDetail;
      },
      GET_QRPAYMENTREVERSEDETAIL(state, issueQRPaymentReverseDetail) {
        state.issueQRPaymentReverseDetail = issueQRPaymentReverseDetail;
      },
      //get claim detail
      GET_CLAIMDETAIL(state, claimDetail) {
        state.claimDetail = claimDetail;
      },
      GET_CLAIMREVERSEDETAIL(state, claimReverseDetail) {
        state.claimReverseDetail = claimReverseDetail;
      },
      GET_QRCLAIMDETAIL(state, issueQRClaimDetail) {
        state.issueQRClaimDetail = issueQRClaimDetail;
      },
      GET_QRCLAIMREVERSEDETAIL(state, issueQRClaimReverseDetail) {
        state.issueQRClaimReverseDetail = issueQRClaimReverseDetail;
      },

      GET_CASHOUTDETAIL(state, cashOutDetail) {
        state.cashOutDetail = cashOutDetail;
      },
      GET_CASHOUTREVERSEDETAIL(state, cashOutReverseDetail) {
        state.cashOutReverseDetail = cashOutReverseDetail;
      },
      GET_QRCASHOUTDETAIL(state, issueQRCashOutDetail) {
        state.issueQRCashOutDetail = issueQRCashOutDetail;
      },
      GET_QRCASHOUTREVERSEDETAIL(state, issueQRCashOutReverseDetail) {
        state.issueQRCashOutReverseDetail = issueQRCashOutReverseDetail;
      },

      GET_PAYOUTDETAIL(state, payOutDetail) {
        state.payOutDetail = payOutDetail;
      },
      GET_PAYOUTREVERSEDETAIL(state, payOutReverseDetail) {
        state.payOutReverseDetail = payOutReverseDetail;
      },

      CREATE_QRCODE(state, payload) {
        state.QRCode = payload;
      },
      CREATE_QRCODECLAIM(state, payload) {
        state.QRCodeToClaim = payload;
      },
      CREATE_QRCODETOCASHOUT(state, payload) {
        state.QRCodeToCashOut = payload;
      },

      READ_QRCODE(state, payload) {
        state.QRtext = payload;
      },

      DECODE_QRCODE(state, decodedQRCode) {
        state.decodedQRCode = decodedQRCode;
      },
      RESET_PAYMENTDETAIL(state) {
        state.paymentDetail = "";
      },
      RESET_PAYMENTREVERSEDETAIL(state) {
        state.paymentReverseDetail = "";
      },
      RESET_DEODEDQRCODE(state) {
        state.decodedQRCode = "";
      },
    },

    actions: {
      getExtraTransferDetail({ commit, state }, extraTransferDetail) {
        commit("GET_EXTRATRANSFERDETAIL", extraTransferDetail);
        console.log(extraTransferDetail);
      },
      loadPrimaryTransactionList({ commit }, payload) {
        axios({
          url: `${path}account/transactions/primaryTransactionList`,
          method: "GET",
          params: {
            maxNumberOfItem: payload,
          },
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((primaryTransactionList) => {
            commit("GET_PRIMARYACCOUNTTRANSACTIONS", primaryTransactionList);
            console.log(primaryTransactionList);
          })
          .catch((err) => {
            console.log(err.response.data);
          });
      },
      loadBusinessTransactionList({ commit }, payload) {
        axios({
          url: `${path}account/transactions/businessTransactionList`,
          method: "GET",
          params: {
            maxNumberOfItem: payload,
          },
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((businessTransactionList) => {
            commit("GET_BUSINSSACCOUNTTRANSACTIONS", businessTransactionList);
            console.log(businessTransactionList);
          })
          .catch((err) => {
            console.log(err.response.data);
          });
      },

      topupMyAccount({ commit, rootState }, payload) {
        axios({
          url: `${path}account/topUpMyAccount`,
          method: "POST",
          params: {
            accountNumber: payload.accountnumber,
            accountType: payload.accountType,
            userId: rootState.auth.profile.sub,
          },
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((topuptransaction) => {
            console.log(topuptransaction);
            commit("TOPUPMYACCOUNT", topuptransaction);
          })
          .catch((err) => {
            // commit("QUERY_ERROR_PHONE_CHECK", err.response.data);
            console.log(err.response.data);
            // reject(err)
          });
      },

      topupBetweenAccounts({ commit, rootState }, payload) {
        axios({
          url: `${path}account/topUpBetweenMyAccounts`,
          method: "POST",
          params: {
            fromAccountNumber: payload.fromAccountNumber,
            fromAccountType: payload.fromAccountType,
            toAccountNumber: payload.toAccountNumber,
            toAccountType: payload.toAccountType,
            transferAmount: payload.transferAmount,
            userId: app.config.globalProperties.$keycloak.subject,
          },
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((payload) => {
            commit("TOPUPBETWEENACCOUNTS", payload);
            console.log(payload);
          })
          .catch((err) => {
            // commit("QUERY_ERROR", err.response.data);
            console.log(err.response.data);
            // reject(err)
          });
      },

      updateCountry({ commit }, payload) {
        const instance = axios.create();
        instance
          .get(
            "https://api.currconv.com/api/v6/convert?q=" +
              payload +
              "&compact=ultra&apiKey=f51fb7199eb328857908"
          )
          .then((r) => r.data)
          .then((grossexchangerate) => {
            commit("GET_GROSSXRATE", grossexchangerate);
            console.log(grossexchangerate);
          })
          .catch((err) => {
            console.log(err.response.data.message);
          });
        // console.log(payload);
      },

      getCurrencyRate({ commit }, payload) {
        const instance = axios.create();
        instance
          .get(
            "https://api.coindesk.com/v1/bpi/currentprice/" +
              payload.fromCurrencyCode +
              ".json"
          )
          .then((r) => r.data)
          .then((response) => {
            commit(
              "GET_CURRENCYRATE1",
              response.bpi[payload.fromCurrencyCode].rate_float
            );
            console.log(response.bpi[payload.fromCurrencyCode].rate_float);
          })
          .catch((err) => {
            console.log(err);
            console.log(err.response.data);
          });
        instance
          .get(
            "https://api.coindesk.com/v1/bpi/currentprice/" +
              payload.toCurrencyCode +
              ".json"
          )
          .then((r) => r.data)
          .then((response) => {
            commit(
              "GET_CURRENCYRATE2",
              response.bpi[payload.toCurrencyCode].rate_float
            );
            console.log(response.bpi[payload.toCurrencyCode].rate_float);
          })
          .catch((err) => {
            console.log(err);
            console.log(err.response.data);
          });
      },

      loadDestinationCtry({ commit, dispatch }, payload) {
        console.log(payload);
        if (app.config.globalProperties.$keycloak.authenticated == false) {
          axios({
            url: `${path}/rate/countryCode/byOriginCountry`,
            method: "GET",
            params: {
              countryCode: payload,
            },
          })
            .then((r) => r.data)
            .then((destinationCountry) => {
              commit("SET_DESTINATIONCTRY", destinationCountry);
              console.log(destinationCountry);
              if (destinationCountry && destinationCountry.length > 0) {
                dispatch(
                  "location/loadDestinationCity",
                  destinationCountry[0].fromCountryCode2,
                  {
                    root: true,
                  }
                );
              }
            })
            .catch((err) => {
              console.log(err);
              console.log(err.response.data);
            });
        } else if (
          app.config.globalProperties.$keycloak.authenticated == true &&
          app.config.globalProperties.$keycloak.userInfo &&
          app.config.globalProperties.$keycloak.userInfo.countryCode
        ) {
          axios({
            url: `${path}/rate/userId/byOriginCountry`,
            method: "GET",
            params: {
              countryCode:
                app.config.globalProperties.$keycloak.userInfo.countryCode,
            },
            headers: {
              Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
            },
          })
            .then((r) => r.data)
            .then((destinationCountry) => {
              commit("SET_DESTINATIONCTRY", destinationCountry);
              console.log(destinationCountry);
            })
            .catch((err) => {
              console.log(err);
              console.log(err.response.data);
            });
        }
      },

      loadOriginCountry({ commit, dispatch, state, rootState }, payload) {
        if (app.config.globalProperties.$keycloak.authenticated == false) {
          axios({
            url: `${path}/rate/countryCode/byDestinationCountry`,
            method: "GET",
            params: {
              countryCode: payload,
            },
          })
            .then((r) => r.data)
            .then((originCountry) => {
              commit("SET_ORIGINCTRY", originCountry);
              console.log(originCountry);
              //   dispatch('location/loadDestinationCity', originCountry.fromCountryId, { root: true });
            })
            .catch((err) => {
              console.log(err);
            });
        } else if (
          app.config.globalProperties.$keycloak.authenticated == true &&
          app.config.globalProperties.$keycloak.userInfo &&
          app.config.globalProperties.$keycloak.userInfo.countryCode
        ) {
          axios({
            url: `${path}/rate/userId/byDestinationCountry`,
            method: "GET",
            params: {
              countryCode:
                app.config.globalProperties.$keycloak.userInfo.countryCode,
            },
            headers: {
              Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
            },
          })
            .then((r) => r.data)
            .then((originCountry) => {
              commit("SET_ORIGINCTRY", originCountry);
              //   dispatch('location/loadDestinationCity', originCountry.fromCountryId, { root: true });
              console.log(originCountry);
            })
            .catch((err) => {
              console.log(err);
              console.log(err.response.data);
            });
        }
      },

      //Help get the possible destination possible for a payer which is not the account logged in user, in case of payment claim
      loadPayerDestinationCtry({ commit }, payload) {
        axios({
          url: `${path}/transferRate`,
          method: "GET",
          params: {
            transferRateId: payload,
          },
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((payerDestinationCountry) => {
            commit("GET_PAYERDESTINATIONCTRY", payerDestinationCountry);
            console.log(payerDestinationCountry);
          })
          .catch((err) => {
            console.log(err);
            console.log(err.response.data);
          });
      },

      calculateTransfer({ commit }, payload) {
        if (app.config.globalProperties.$keycloak.authenticated == false) {
          axios({
            url: `${path}/estimate`,
            method: "GET",
            params: {
              fromCountryCode: payload.fromCountryCode,
              toCountryCode: payload.toCountryCode,
              pickupMethod: payload.pickupMethod,
              amount: parseFloat(payload.amount),
            },
          })
            .then((r) => r.data)
            .then((transferDetail) => {
              commit("GET_TRANSFERDETAIL", transferDetail);
              console.log(transferDetail);
            })
            .catch((err) => {
              console.log(err);
              console.log(err.response.data);
            });
        } else {
          axios({
            url: `${path}/estimate`,
            method: "GET",
            params: {
              fromCountryCode: payload.fromCountryCode,
              toCountryCode: payload.toCountryCode,
              pickupMethod: payload.pickupMethod,
              amount: parseFloat(payload.amount),
            },
            headers: {
              Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
            },
          })
            .then((r) => r.data)
            .then((transferDetail) => {
              commit("GET_TRANSFERDETAIL", transferDetail);
              console.log(transferDetail);
            })
            .catch((err) => {
              console.log(err);
              console.log(err.response.data);
            });
        }
      },

      calculateTransferReverse({ commit }, payload) {
        if (app.config.globalProperties.$keycloak.authenticated == false) {
          axios({
            url: `${path}/reverse/estimate`,
            method: "GET",
            params: {
              fromCountryCode: payload.fromCountryCode,
              toCountryCode: payload.toCountryCode,
              pickupMethod: payload.pickupMethod,
              amount: parseFloat(payload.amount),
            },
            headers: {
              Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
            },
          })
            .then((r) => r.data)
            .then((transferDetail) => {
              commit("GET_TRANSFERDETAIL", transferDetail);
              console.log(transferDetail);
            })
            .catch((err) => {
              console.log(err);
              console.log(err.response.data);
            });
        } else {
          axios({
            url: `${path}/reverse/estimate`,
            method: "GET",
            params: {
              fromCountryCode: payload.fromCountryCode,
              toCountryCode: payload.toCountryCode,
              pickupMethod: payload.pickupMethod,
              amount: parseFloat(payload.amount),
            },
            headers: {
              Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
            },
          })
            .then((r) => r.data)
            .then((transferDetail) => {
              commit("GET_TRANSFERDETAIL", transferDetail);
              console.log(transferDetail);
            })
            .catch((err) => {
              console.log(err);
              console.log(err.response.data);
            });
        }
      },

      calculatePayment({ commit }, payload) {
        axios({
          url: `${path}/estimate`,
          method: "GET",
          params: {
            fromCountryCode: payload.fromCountryCode,
            toCountryCode: payload.toCountryCode,
            pickupMethod: payload.pickupMethod,
            amount: parseFloat(payload.amount),
          },
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((paymentDetail) => {
            commit("GET_PAYMENTDETAIL", paymentDetail);
            console.log(paymentDetail);
          })
          .catch((err) => {
            console.log(err);
            console.log(err.response.data);
          });
      },

      calculatePaymentReverse({ commit }, payload) {
        axios({
          url: `${path}/reverse/estimate`,
          method: "GET",
          params: {
            fromCountryCode: payload.fromCountryCode,
            toCountryCode: payload.toCountryCode,
            pickupMethod: payload.pickupMethod,
            amount: parseFloat(payload.amount),
          },
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((paymentReverseDetail) => {
            commit("GET_PAYMENTREVERSEDETAIL", paymentReverseDetail);
            console.log(paymentReverseDetail);
          })
          .catch((err) => {
            console.log(err);
            console.log(err.response.data);
          });
      },

      calculateQRPayment({ commit }, payload) {
        axios({
          url: `${path}/estimate`,
          method: "GET",
          params: {
            fromCountryCode: payload.fromCountryCode,
            toCountryCode: payload.toCountryCode,
            pickupMethod: payload.pickupMethod,
            amount: parseFloat(payload.amount),
          },
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((issueQRPaymentDetail) => {
            commit("GET_QRPAYMENTDETAIL", issueQRPaymentDetail);
            console.log(issueQRPaymentDetail);
          })
          .catch((err) => {
            console.log(err);
            console.log(err.response.data);
          });
      },

      calculateQRPaymentReverse({ commit }, payload) {
        axios({
          url: `${path}/reverse/estimate`,
          method: "GET",
          params: {
            fromCountryCode: payload.fromCountryCode,
            toCountryCode: payload.toCountryCode,
            pickupMethod: payload.pickupMethod,
            amount: parseFloat(payload.amount),
          },
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((issueQRPaymentReverseDetail) => {
            commit("GET_QRPAYMENTREVERSEDETAIL", issueQRPaymentReverseDetail);
            console.log(issueQRPaymentReverseDetail);
          })
          .catch((err) => {
            console.log(err);
            console.log(err.response.data);
          });
      },

      // Get the claim/cash-in detail

      calculateClaim({ commit }, payload) {
        axios({
          url: `${path}/estimate`,
          method: "GET",
          params: {
            fromCountryCode: payload.fromCountryCode,
            toCountryCode: payload.toCountryCode,
            pickupMethod: payload.pickupMethod,
            amount: parseFloat(payload.amount),
          },
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((claimDetail) => {
            commit("GET_CLAIMDETAIL", claimDetail);
            console.log(claimDetail);
          })
          .catch((err) => {
            console.log(err);
            console.log(err.response.data);
          });
      },

      calculateClaimReverse({ commit }, payload) {
        axios({
          url: `${path}/reverse/estimate`,
          method: "GET",
          params: {
            fromCountryCode: payload.fromCountryCode,
            toCountryCode: payload.toCountryCode,
            pickupMethod: payload.pickupMethod,
            amount: parseFloat(payload.amount),
          },
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((claimReverseDetail) => {
            commit("GET_CLAIMREVERSEDETAIL", claimReverseDetail);
            console.log(claimReverseDetail);
          })
          .catch((err) => {
            console.log(err);
            console.log(err.response.data);
          });
      },

      calculateQRClaim({ commit }, payload) {
        axios({
          url: `${path}/estimate`,
          method: "GET",
          params: {
            fromCountryCode: payload.fromCountryCode,
            toCountryCode: payload.toCountryCode,
            pickupMethod: payload.pickupMethod,
            amount: parseFloat(payload.amount),
          },
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((issueQRClaimDetail) => {
            commit("GET_QRCLAIMDETAIL", issueQRClaimDetail);
            console.log(issueQRClaimDetail);
          })
          .catch((err) => {
            console.log(err);
            console.log(err.response.data);
          });
      },

      calculateQRClaimReverse({ commit }, payload) {
        axios({
          url: `${path}/reverse/estimate`,
          method: "GET",
          params: {
            fromCountryCode: payload.fromCountryCode,
            toCountryCode: payload.toCountryCode,
            pickupMethod: payload.pickupMethod,
            amount: parseFloat(payload.amount),
          },
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((issueQRClaimReverseDetail) => {
            commit("GET_QRCLAIMREVERSEDETAIL", issueQRClaimReverseDetail);
            console.log(issueQRClaimReverseDetail);
          })
          .catch((err) => {
            console.log(err);
            console.log(err.response.data);
          });
      },

      //Calculate Cashout with accounter number detail
      calculateCashOut({ commit }, payload) {
        axios({
          url: `${path}/estimate`,
          method: "GET",
          params: {
            fromCountryCode: payload.fromCountryCode,
            toCountryCode: payload.toCountryCode,
            pickupMethod: payload.pickupMethod,
            amount: parseFloat(payload.amount),
          },
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((cashOutDetail) => {
            commit("GET_CASHOUTDETAIL", cashOutDetail);
            console.log(cashOutDetail);
          })
          .catch((err) => {
            console.log(err);
            console.log(err.response.data);
          });
      },

      calculateCashOutReverse({ commit }, payload) {
        axios({
          url: `${path}/reverse/estimate`,
          method: "GET",
          params: {
            fromCountryCode: payload.fromCountryCode,
            toCountryCode: payload.toCountryCode,
            pickupMethod: payload.pickupMethod,
            amount: parseFloat(payload.amount),
          },
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((cashOutReverseDetail) => {
            commit("GET_CASHOUTREVERSEDETAIL", cashOutReverseDetail);
            console.log(cashOutReverseDetail);
          })
          .catch((err) => {
            console.log(err);
            console.log(err.response.data);
          });
      },

      //Calculate cashout QR amount

      calculateQRCashOut({ commit }, payload) {
        axios({
          url: `${path}/estimate`,
          method: "GET",
          params: {
            fromCountryCode: payload.fromCountryCode,
            toCountryCode: payload.toCountryCode,
            pickupMethod: payload.pickupMethod,
            amount: parseFloat(payload.amount),
          },
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((issueQRCashOutDetail) => {
            commit("GET_QRCASHOUTDETAIL", issueQRCashOutDetail);
            console.log(issueQRCashOutDetail);
          })
          .catch((err) => {
            console.log(err);
            console.log(err.response.data);
          });
      },

      calculateQRCashOutReverse({ commit }, payload) {
        axios({
          url: `${path}/reverse/estimate`,
          method: "GET",
          params: {
            fromCountryCode: payload.fromCountryCode,
            toCountryCode: payload.toCountryCode,
            pickupMethod: payload.pickupMethod,
            amount: parseFloat(payload.amount),
          },
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((issueQRCashOutReverseDetail) => {
            commit("GET_QRCASHOUTREVERSEDETAIL", issueQRCashOutReverseDetail);
            console.log(issueQRCashOutReverseDetail);
          })
          .catch((err) => {
            console.log(err);
            console.log(err.response.data);
          });
      },

      //Calculate PayOut with accounter number detail
      calculatePayOut({ commit }, payload) {
        axios({
          url: `${path}/estimate`,
          method: "GET",
          params: {
            fromCountryCode: payload.fromCountryCode,
            toCountryCode: payload.toCountryCode,
            pickupMethod: payload.pickupMethod,
            amount: parseFloat(payload.amount),
          },
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((payOutDetail) => {
            commit("GET_PAYOUTDETAIL", payOutDetail);
            console.log(payOutDetail);
          })
          .catch((err) => {
            console.log(err);
            console.log(err.response.data);
          });
      },

      calculatePayOutReverse({ commit }, payload) {
        axios({
          url: `${path}/reverse/estimate`,
          method: "GET",
          params: {
            fromCountryCode: payload.fromCountryCode,
            toCountryCode: payload.toCountryCode,
            pickupMethod: payload.pickupMethod,
            amount: parseFloat(payload.amount),
          },
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((payOutReverseDetail) => {
            commit("GET_PAYOUTREVERSEDETAIL", payOutReverseDetail);
            console.log(payOutReverseDetail);
          })
          .catch((err) => {
            console.log(err);
            console.log(err.response.data);
          });
      },
      createQRcode({ commit }, payload) {
        axios({
          url: `${path}/createQrCode`,
          data: payload,
          method: "POST",
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
          responseType: "arraybuffer",
        })
          .then((res) => {
            `data:${res.headers["content-type"]};base64,${Buffer.from(
              String.fromCharCode(...new Uint8Array(res.data)),
              "binary"
            ).toString("base64")}`;
            if (payload.mode === "avocash-pay") {
              commit(
                "CREATE_QRCODE",
                `data:${res.headers["content-type"]};base64,${Buffer.from(
                  String.fromCharCode(...new Uint8Array(res.data)),
                  "binary"
                ).toString("base64")}`
              );
            } else if (payload.mode === "avocash-claim") {
              commit(
                "CREATE_QRCODECLAIM",
                `data:${res.headers["content-type"]};base64,${Buffer.from(
                  String.fromCharCode(...new Uint8Array(res.data)),
                  "binary"
                ).toString("base64")}`
              );
            } else if (payload.mode === "avocash-out") {
              commit(
                "CREATE_QRCODETOCASHOUT",
                `data:${res.headers["content-type"]};base64,${Buffer.from(
                  String.fromCharCode(...new Uint8Array(res.data)),
                  "binary"
                ).toString("base64")}`
              );
            }
            // else if (payload.mode === 'avocash-payout') {
            //   commit('CREATE_QRCODETOCASHOUT', `data:${res.headers['content-type']};base64,${Buffer.from(String.fromCharCode(...new Uint8Array(res.data)), 'binary')
            //     .toString('base64')}`);
            // }
          })
          .catch((err) => {
            console.log(err);
            console.log(err.response.data);
          });
      },

      readQRcode({ commit, dispatch }, payload) {
        axios({
          url: `${path}/card/decodeQRCode`,
          method: "GET",
          params: {
            qrCodeText: payload,
          },
        })
          .then((r) => r.data)
          .then((QRtext) => {
            commit("READ_QRCODE", QRtext);
            console.log(QRtext);
          })
          .catch((err) => {
            console.log(err);
            console.log(err.response.data);
          });
      },

      decodeQRCode({ commit, dispatch }, payload) {
        axios({
          url: `${path}/decodeQRCode`,
          method: "GET",
          params: {
            qrCodeText: payload,
          },
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((decodedQRCode) => {
            console.log(decodedQRCode);
            commit("DECODE_QRCODE", decodedQRCode);
          })
          .catch((err) => {
            console.log(err);
            console.log(err.response.data);
          });
      },
      resetPaymentDetail({ commit }) {
        commit("RESET_PAYMENTDETAIL");
      },
      resetPaymentReverseDetail({ commit }) {
        commit("RESET_PAYMENTREVERSEDETAIL");
      },
      resetDecodedQRCode({ commit }) {
        commit("RESET_DEODEDQRCODE");
      },
    },
  };
};
// export default transaction;
