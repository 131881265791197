<template>
  <f7-page hide-navbar-on-scroll no-toolbar infinite :infinite-distance="10" :infinite-preloader="showPreloader"
    @infinite="loadMore">
    <!-- infinite
    :infinite-distance="10"
    :infinite-preloader="showPreloader"
    @infinite="loadMore" -->

    <f7-navbar sliding no-shadow>
      <f7-nav-left :back-link="$t('common.backButton')" :back-link-show-text="false"></f7-nav-left>
      <f7-nav-title>{{ $t("groupbuydetail.groupBuy") }}</f7-nav-title>
      <f7-nav-right>
        <navbarright></navbarright>
      </f7-nav-right>
    </f7-navbar>
    <div class="layout_content">
      <div class="left_layout"></div>
      <div class="main_layout">
        <div style="padding-bottom: 80px" no-gap v-if="!this.groupBuyList">
          <f7-card class="skeleton-text skeleton-effect-blink" v-for="n in 6" :key="n">
            <f7-card-header class="no-border">
              <div class="demo-facebook-avatar padding-right">
                <f7-badge slot="media" color="gray"
                  style="height: 40px; width: 40px; font-size: 16px; border-radius: 50%">GT</f7-badge>
              </div>
              <div class="tontineTitle demo-facebook-name">Savings name</div>
              <div class="demo-facebook-date tontineTitle">Savings tag</div>
            </f7-card-header>
            <f7-card-content style="width: auto; height: 400px">
              <div class="timeline">
                <div class="timeline-item" v-for="x in 6" :key="x">
                  <div class="timeline-item-date">2021-11>2022-12</div>
                  <div class="timeline-item-divider"></div>
                  <div class="timeline-item-content">
                    <div class="timeline-item-title">Savings name</div>
                    <div class="timeline-item-inner">
                      Collected
                      <span style="font-weight: bold; color: green"> 250 EUR</span><br />Balance
                      <span style="font-weight: bold"> 200 EUR </span>
                    </div>
                  </div>
                </div>
              </div>
            </f7-card-content>
            <f7-list dividers-ios strong-ios accordion-list media-list>
              <f7-list-item accordion-item :title="$t('common.description')">
                <f7-accordion-content>
                  <f7-block> </f7-block>
                </f7-accordion-content>
              </f7-list-item>
            </f7-list>
            <f7-button fill large style="border-radius: 0px" color="gray" class="buttons">{{ $t("common.seeDetail")
              }}</f7-button>
          </f7-card>
        </div>
        <div v-else-if="this.groupBuyList === '' || this.groupBuyList.length < 1">
          <span class="padding-left" style="text-align: center">{{
            $t("common.nothingFound")
          }}</span>
          <a href="#" class="createGroup" @click="createNewGroupBuy()" style="display: flex; justify-content: center">
            <f7-chip color="red" outline :text="$t('groupbuydetail.addNewGroupBuy')" href="#">
              <template #media>
                <f7-icon color="red" ios="f7:plus_circle_fill" aurora="f7:plus_circle_fill" md="material:add_circle">
                </f7-icon>
              </template>
            </f7-chip>
          </a>
        </div>
        <div style="padding-bottom: 80px" no-gap v-else-if="!!this.groupBuyList && this.groupBuyList.length >= 1">
          <f7-card header-divider class="demo-facebook-card" v-for="(groupBuy, index) in this.groupBuyList"
            :key="index">
            <f7-card-header :padding="false" class="no-margin">
              <div class="demo-facebook-avatar padding-right">
                <!-- <img v-if="association.logo !== null" :src="`${association.logo}`" width="40"
                      height="40" style="border-radius: 50%" /> -->
                <f7-badge slot="media" color="auto"
                  style="height: 40px; width: 40px; font-size: 16px; border-radius: 50%">{{
                    $filtersGetInitial.getInitial(groupBuy.name) }}</f7-badge>
              </div>

              <div class="associationTitle demo-facebook-name">
                {{ groupBuy.name }}
              </div>
              <div class="demo-facebook-date associationTitle" v-html="groupBuy.tag"></div>
            </f7-card-header>

            <f7-card-content :padding="false">
              <swiper-container :pagination="isTablet ? true : true" :slidesPerView="1" :spaceBetween="0"
                :centeredSlides="true" :style="{
                  background:
                    'linear-gradient(90deg, rgba(131,58,180,1) 0%, rgba(29,253,36,1) 50%, rgba(252,176,69,1) 100%)',
                  width: '100%',
                  height: '400px',
                  'overflow-y': 'auto',
                }">
                <swiper-slide v-if="groupBuy.projectList && groupBuy.projectList.length > 0">
                  <div class="timeline timeline-horizontal grid grid-cols-1 medium-grid-cols-2"
                    v-if="groupBuy.projectList && groupBuy.projectList.length > 0">
                    <div v-for="(project, index) in groupBuy.projectList" href="#" :key="project.id"
                      class="timeline-item">
                      <div class="timeline-item-date">
                        <f7-badge slot="media" color="auto" width="24" height="24px"
                          style="height: 20px; width: 20px; font-size: 12px">{{ project.projectNumber }}</f7-badge>
                        {{ project.name }}
                      </div>
                      <div class="timeline-item-content" style="height: max-content">
                        <div class="timeline-item-inner">
                          <div class="timeline-item-title" style="font-weight: bold; color: auto">
                            {{ $t("common.contribution") }}:
                            {{
                              $filtersToCurrency.toCurrency(
                                project.collectedAmount,
                                null,
                                project.currencyCode
                              )
                            }}
                          </div>
                          <div class="timeline-item-title" style="font-weight: bold; color: auto">
                            {{ $t("common.balance") }}:
                            {{
                              $filtersToCurrency.toCurrency(
                                project.accountBalance,
                                null,
                                project.currencyCode
                              )
                            }}
                          </div>
                          <span class="timeline-item-subtitle">{{ $t("common.fundingTarget") }}:
                            <span>
                              {{
                                $filtersToCurrency.toCurrency(
                                  parseFloat(project.fundingTarget) *
                                  project.participantList.length,
                                  null,
                                  project.currencyCode
                                )
                              }}</span>
                            <f7-badge color="green" class="padding-left-half">{{
                              project.collectedAmount > 0
                                ? (parseFloat(project.fundingTarget) *
                                  project.participantList.length *
                                  100) /
                                project.collectedAmount
                                : 0
                            }}%</f7-badge> </span><br v-if="projectContribution(project) > 0" />

                          <small class="timeline-item-text" v-if="project.nbrOfFrequency > 0">{{ $t("common.frequency")
                            }}:
                            {{
                              $filtersToCurrency.toCurrency(
                                project.frequencyAmount,
                                null,
                                project.currencyCode
                              )
                            }}
                            X {{ project.nbrOfFrequency }}
                            {{ project.frequencyType }}</small><br />
                          <small class="timeline-item-text" v-if="!!project.startDate">{{ project.startDate.substr(0, 7)
                            }} >
                            {{ project.endDate.substr(0, 7) }}</small>
                        </div>

                        <div v-if="
                          project.participantList && project.participantList.length > 0
                        " class="timeline-item-inner" v-for="(participant, index) in project.participantList">
                          <div class="timeline-item-title">
                            <f7-badge slot="media" color="auto" width="24" height="24px"
                              style="height: 20px; width: 20px; font-size: 12px">{{
                                $filtersGetInitial.getInitial(participant.name)
                              }}</f7-badge>
                            {{ participant.name }}
                          </div>
                          <div class="timeline-item-subtitle">
                            {{ $t("common.contribution") }}:
                            <span style="font-weight: bold; color: auto" v-html="$filtersToCurrency.toCurrency(
                              participant.paidContrib,
                              null,
                              participant.currencyCode
                            )
                              "></span>
                            <div class="timeline-item-text">
                              {{ $t("common.fundingTarget") }}:
                              <span style="font-weight: bold" v-html="$filtersToCurrency.toCurrency(
                                participant.contribAmount !== null
                                  ? participant.contribAmount
                                  : project.fundingTarget,
                                null,
                                participant.currencyCode
                              )
                                ">
                              </span>
                              <span class="padding-left-half"><f7-badge color="green">{{
                                (
                                  (parseFloat(participant.paidContrib) * 100) /
                                  parseFloat(
                                    participant.contribAmount !== null
                                      ? participant.contribAmount
                                      : project.fundingTarget
                                  )
                                ).toFixed(2)
                              }}%</f7-badge>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </swiper-slide>
                <swiper-slide class="align-content-center">
                  <a link="#" @click="$refs.popup.open()"
                    style="display: flex; align-items: center; justify-content: center">
                    <img :src="`${groupBuy.qr}`" :style="{
                      display: 'block',
                      margin: 'auto',
                      height: 'auto',
                      position: 'absolute',
                      top: 0,
                      bottom: 0,
                    }" alt="qr code" />
                  </a>
                </swiper-slide>
              </swiper-container>
            </f7-card-content>
            <!-- </span> -->
            <f7-list dividers-ios accordion-list media-list v-if="groupBuy.description !== null">
              <f7-list-item accordion-item :title="$t('common.description')">
                <f7-accordion-content>
                  <f7-block>
                    <p v-html="groupBuy.description"></p>
                  </f7-block>
                </f7-accordion-content>
              </f7-list-item>
            </f7-list>
            <f7-button fill large class="card_button buttons" color="red" @click="viewDetail(groupBuy)">{{
              $t("common.seeDetail") }}</f7-button>
          </f7-card>
        </div>
      </div>
      <div class="right_layout"></div>
    </div>
    <template #fixed>
      <f7-fab position="center-bottom createGroup" @click="createNewGroupBuy()"
        :text="$t('groupbuydetail.addNewGroupBuy')" color="red" href="#">
        <f7-icon ios="f7:plus" aurora="f7:plus" md="material:add"></f7-icon>
      </f7-fab>
    </template>
  </f7-page>
</template>
<style scoped></style>

<script>
import {
  f7Page,
  f7Navbar,
  f7NavLeft,
  f7NavRight,
  f7NavTitle,
  f7Chip,
  f7Icon,
  f7Card,
  f7CardHeader,
  f7Badge,
  f7CardContent,
  f7List,
  f7ListItem,
  f7AccordionContent,
  f7Block,
  f7Button,
  f7Fab,
  theme,
  f7,
} from "framework7-vue";
import { mapGetters, mapState } from "vuex";
import Navbarright from "../components/navbarright.vue";
import { groupbuy } from "../js/mixins/groupBuy.js";
import _ from "lodash";
export default {
  props: {
    f7route: Object,
    f7router: Object,
  },
  components: {
    navbarright: Navbarright,
    f7Page,
    f7Navbar,
    f7NavLeft,
    f7NavRight,
    f7NavTitle,
    f7Chip,
    f7Icon,

    f7Card,
    f7CardHeader,
    f7Badge,
    f7CardContent,

    f7List,
    f7ListItem,
    f7AccordionContent,
    f7Block,
    f7Button,
    f7Fab,
  },
  mixins: [groupbuy],
  data() {
    return {
      theme,
      f7,
      searchWallet: "",
      visibleItem: 6,
      allowInfinite: true,
      showPreloader: true,
    };
  },

  mounted() {
    const self = this;

    if (!this.userAccount && this.$keycloak.authenticated) {
      self.$store.dispatch("account/loadAccount", this.profile.sub);
    }
    if (this.country === "" && !this.country) {
      self.$store.dispatch("location/getCountry");
    }
    // self.$store.dispatch("groupBuy/getUserGroupBuyList", {
    //   maxNumberOfItem: this.visibleItem,
    //   userId: this.profile.sub,
    // });
  },
  methods: {
    viewDetail(groupBuy) {
      const self = this;

      f7.preloader.show("multi");
      self.$store
        .dispatch("groupBuy/getGroupBuy", groupBuy.id)
        .then((result) => {
          // setTimeout(() => {
          this.f7router.navigate(`/groupBuy/${groupBuy.id}`);
          f7.preloader.hide();
          // }, 1000);
        })
        .catch((err) => {
          f7.preloader.hide();
        });
    },

    loadMore() {
      const self = this;
      if (!self.allowInfinite) return;
      self.allowInfinite = false;
      if (self.groupBuyList && self.groupBuyList.length < this.visibleItem) {
        self.showPreloader = false;
        return;
      }
      setTimeout(() => {
        this.visibleItem += 6;
        self.$store.dispatch("groupBuy/getUserGroupBuyList", {
          maxNumberOfItem: this.visibleItem,
          userId: this.profile.sub,
        });
        self.allowInfinite = true;
      }, 1000);
      self.showPreloader = false;
    },
    pageInit() {
      //   const self = this;
      //   if (this.groupBuyList === "") {
      //     self.$store.dispatch("groupBuy/getUserGroupBuyList", {
      //       maxNumberOfItem: this.visibleItem,
      //       userId: this.profile.sub,
      //     });
      //   }
    },
  },

  computed: {
    ...mapState("groupBuy", [
      "groupBuy",
      "groupBuyList",
      "project",
      "participant",
      "participantList",
      "admin",
      "adminList",
      "groupBuyTransaction",
    ]),

    ...mapState("transaction", [
      "destinationCountry",
      "destinationCity",
      "grossexchangerate",
      "transferDetail",
      "fromCurrencyRate",
      "toCurrencyRate",
      "transferDetail",
      "paymentDetail",
      "paymentReverseDetail",
    ]),

    ...mapState("location", [
      "destinationCity",
      "country",
      "countryStates",
      "countryCities",
      "currentUserCountry",
    ]),
    ...mapState("account", ["userAccount", "userDetail", "searchedUser"]),
    ...mapState("auth", ["phoneNumberDetail", "mobilePhoneError", "profile", "isTablet"]),
    ...mapState("transaction", ["destinationCountry", "originCountry"]),

    ...mapGetters("auth", [
      "getProfile",
      "isAuthenticated",
      "isProfileLoaded",
      "getusererror",
    ]),
    ...mapGetters("transaction", [
      "getusererror",
      "fromCurrencyRate",
      "toCurrencyRate",
      "grossexchangerate2",
    ]),

    ...mapGetters("remit", ["recipients"]),
    ...mapGetters("account", ["userByAccountNumber"]),
  },
};
</script>
