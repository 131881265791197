<template>
  <f7-page :page-content="false">
    <f7-navbar sliding no-shadow>
      <f7-nav-left :back-link="$t('common.backButton')" :back-link-show-text="false"></f7-nav-left>
      <f7-nav-title v-if="!!this.wallet">{{ this.wallet.name }}</f7-nav-title>
      <f7-nav-title v-if="!this.wallet">{{ $t("walletdetail.detail") }}</f7-nav-title>
      <f7-nav-right v-if="!!this.wallet && $keycloak.authenticated && this.currentUserIsAdmin">
        <f7-link icon-aurora="f7:person_crop_circle_fill_badge_plus" icon-ios="f7:person_crop_circle_fill_badge_plus"
          icon-md="material:person_add" color="red" href="#" sheet-open=".addNewMember"></f7-link>

        <f7-link icon-aurora="f7:pencil" icon-ios="f7:pencil" icon-md="material:edit"
          :href="'/wallet/edit/' + this.wallet.id"></f7-link>
      </f7-nav-right>
    </f7-navbar>

    <f7-toolbar tabbar labels top class="detailToolbar" :scrollable="this.wallet.campaignList.length > 6 ? true : false"
      no-shadow v-if="
        this.wallet && this.wallet.campaignList && this.wallet.campaignList.length >= 1
      ">
      <f7-link tab-link="#mainPage" tab-link-active :text="isTablet ? this.wallet.name : ''">
        <img v-if="this.wallet.logo" :src="`${this.wallet.logo}`" width="40" height="40" slot="media"
          style="border-radius: 50%" class="margin-left-half" />
        <f7-badge v-else color="auto" slot="media" class="margin-left-half" :style="{
          height: theme.md ? '27px' : '27px',
          width: theme.md ? '27px' : '27px',
          'min-width': theme.md ? '27px' : '27px',
          'border-radius': '50%',
        }">{{ $filtersGetInitial.getInitial(wallet.name) }}</f7-badge>
      </f7-link>
      <f7-link v-if="
        this.wallet && this.wallet.campaignList && this.wallet.campaignList.length > 0
      " v-for="(campaign, index) in this.wallet.campaignList" :key="index + 1" :text="isTablet ? campaign.name : ''"
        :tab-link="`#tab-${campaign.campaignNumber}`" @tab:show="selectCampaign(campaign)">
        <f7-badge slot="media" color="auto" :style="{
          height: theme.md ? '27px' : '27px',
          width: theme.md ? '27px' : '27px',
          'min-width': theme.md ? '27px' : '27px',
          'border-radius': '50%',
        }"><span v-html="isTablet
              ? campaign.campaignNumber
              : $filtersGetInitial.getInitial(campaign.name)
            "></span></f7-badge>
      </f7-link>
    </f7-toolbar>

    <f7-tabs swipeable animated class="detailTabs">
      <f7-tab id="mainPage" tab-active class="page-content" style="padding-bottom: 80px !important">
        <div class="layout_content">
          <div class="left_layout">
            <f7-card outline class="sticky with_content">
              <f7-card-header href="#" sheet-open=".addNewMember"
                v-if="this.$keycloak.authenticated && this.currentWalletOwner">
                <div>{{ $t("common.members") }}</div>
                <div></div>
                <f7-badge v-if="currentUserIsAdmin" bg-color="red" :style="{
                  position: 'absolute',
                  right: '16px',
                  top: '8px',
                  'border-radius': '50px',
                  height: '32px',
                  width: '32px',
                }" class="elevation-3">
                  <f7-link icon-aurora="f7:person_crop_circle_fill_badge_plus"
                    icon-ios="f7:person_crop_circle_fill_badge_plus" icon-md="material:person_add" color="white"
                    href="#" sheet-open=".addNewMember"></f7-link>
                </f7-badge>
              </f7-card-header>
              <f7-list dividers-ios strong-ios accordion-list class="no-margin-top" :no-chevron="!currentUserIsAdmin"
                v-if="this.$keycloak.authenticated && this.currentWalletOwner">
                <f7-list-item v-for="(owner, index) in this.wallet.walletOwnerList" :key="index" accordion-item
                  :title="owner.name" :subtitle="'+' + owner.phoneNumber" :text="owner.email" :badge="wallet.walletAdminList.find((x) => x.userId === owner.userId)
                      ? $t('common.admin')
                      : ''
                    " badge-color="primary">
                  <f7-accordion-content v-if="
                    currentUserIsAdmin && owner.userId !== wallet.walletPrimeOwnerUserId
                  ">
                    <f7-button raised fill small ripple color="red" class="margin-horizontal margin-vertical-half"
                      :text="$t('common.removeUser')" @click="removeWalletOwner(owner)">
                    </f7-button>

                    <f7-button @click="grantAdminRole(owner)" raised fill small ripple color="green"
                      class="margin-horizontal margin-vertical-half" :text="$t('common.grantAdminRights')" v-if="
                        !wallet.walletAdminList.find((x) => x.userId === owner.userId)
                      ">
                    </f7-button>
                    <f7-button v-if="wallet.walletAdminList.find((x) => x.userId === owner.userId)"
                      @click="withdrawAdminRole(owner)" raised fill small ripple
                      class="margin-horizontal margin-vertical-half" :text="$t('common.withdrawAdminRights')"
                      color="orange">
                    </f7-button>
                  </f7-accordion-content>
                  <template #media>
                    <img v-if="owner.avatar" :src="`${owner.avatar}`" width="40"
                      style="height: 48px; width: 48px; border-radius: 25px" />

                    <f7-badge color="auto" v-else style="
                        height: 48px;
                        width: 48px;
                        font-size: 16px;
                        border-radius: 25px;
                      ">{{ $filtersGetInitial.getInitial(owner.name) }}</f7-badge>
                  </template>
                </f7-list-item>
              </f7-list>
            </f7-card>
          </div>
          <div class="main_layout">
            <f7-card class="demo-facebook-card skeleton-text skeleton-effect-blink" v-if="!this.wallet">
              <f7-card-header class="no-border">
                <div class="demo-facebook-avatar padding-right">
                  <f7-badge slot="media" style="
                      height: 40px;
                      width: 40px;
                      font-size: 16px;
                      border-radius: 50%;
                      background-color: light-gray;
                    ">RS</f7-badge>
                </div>
                <div class="campaignTitle demo-facebook-name">Project title</div>
                <div class="demo-facebook-date">Project tag</div>
              </f7-card-header>
              <f7-card-content :padding="false" style="width: auto; height: 400px">
                <span :style="{
                  display: 'block',
                  margin: 'auto',
                  height: '100%',
                  width: '100%',
                  'background-color': 'light-gray',
                }">
                </span>
              </f7-card-content>
              <f7-list dividers-ios strong-ios accordion-list media-list>
                <li class="item-content">
                  <div class="item-inner">
                    <div class="item-title-row">
                      <div class="item-title" style="color: gray">
                        {{ $t("common.collected") }}
                      </div>
                      <div class="item-title" style="font-weight: bold; color: gray">
                        500 EUR
                        <f7-badge color="gray">20</f7-badge>
                      </div>
                    </div>
                    <div class="item-title-row">
                      <div class="item-title" style="color: gray">
                        {{ $t("common.balance") }}
                      </div>
                      <div class="item-title" style="font-weight: bold; color: gray">
                        2500 EUR
                      </div>
                    </div>
                    <div class="item-title-row">
                      <div class="item-title" style="color: gray">
                        {{ $t("common.target") }}
                      </div>
                      <div class="item-title" style="font-weight: bold; color: gray">
                        2000 EUR
                      </div>
                    </div>
                    <div class="item-title-row">
                      <div class="item-title" style="color: gray">
                        {{ $t("common.end") }}
                      </div>
                      <div class="item-title" style="font-weight: bold; color: gray">
                        28 days
                      </div>
                    </div>
                  </div>
                </li>
              </f7-list>
              <f7-button raised fill large style="border-radius: 0px" class="no-margin-horiztontal buttons"
                color="gray">{{
                  $t("common.contribute") }}</f7-button>
            </f7-card>
            <span v-if="!!this.wallet">
              <f7-card class="demo-facebook-card">
                <f7-card-header class="no-border">
                  <div class="demo-facebook-avatar padding-right">
                    <img v-if="!!wallet.logo" :src="`${wallet.logo}`" width="40" height="40"
                      style="border-radius: 50%" />
                    <f7-badge v-else slot="media" color="auto" style="
                        height: 40px;
                        width: 40px;
                        font-size: 16px;
                        border-radius: 50%;
                      ">
                      {{ $filtersGetInitial.getInitial(wallet.name) }}</f7-badge>
                  </div>
                  <div class="campaignTitle demo-facebook-name">
                    {{ this.wallet.name }}
                  </div>
                  <div class="demo-facebook-date" v-html="this.wallet.tag"></div>
                </f7-card-header>
                <f7-card-content :padding="false" v-if="this.wallet" :style="{
                  background:
                    'linear-gradient(90deg, rgba(131,58,180,1) 0%, rgba(29,253,36,1) 50%, rgba(252,176,69,1) 100%)',
                  height: '400px',
                  width: '100%',
                }">
                  <swiper-container v-if="!!this.wallet.walletImageList" :pagination="this.isTablet ? false : true"
                    :slidesPerView="this.isTablet ? '1' : '1'" :spaceBetween="0" :disableOnInteraction="true">
                    <f7-photo-browser :photos="this.walletPhotos" ref="popup"></f7-photo-browser>
                    <swiper-slide>
                      <a link="#" @click="$refs.popup.open()">
                        <img :style="{
                          display: 'block',
                          margin: 'auto',
                          width: 'auto',
                          'margin-top': '55px',
                          'margin-bottom': '55px',
                        }" :src="this.wallet.qr" />
                      </a>
                    </swiper-slide>
                    <swiper-slide v-for="(imageUrl, index) in this.walletPhotos" :key="index"
                      class="align-content-center">
                      <a link="#" @click="$refs.popup.open()" style="
                          display: flex;
                          align-items: center;
                          justify-content: center;
                        ">
                        <img :src="imageUrl.url" v-if="imageUrl.fileType === 'image'" :style="{
                          display: 'block',
                          margin: 'auto',
                          height: 'auto',
                          position: 'absolute',
                          top: 0,
                          bottom: 0,
                          width: '100%',
                        }" :alt="imageUrl.fileName" />
                        <video v-if="imageUrl.fileType === 'video'" :src="imageUrl.url" controls
                          :alt="imageUrl.fileName" />
                      </a>
                    </swiper-slide>
                  </swiper-container>
                </f7-card-content>
                <f7-list dividers-ios strong-ios media-list>
                  <li class="item-content">
                    <div class="item-inner">
                      <div class="item-title-row">
                        <div class="item-title" style="color: gray">
                          {{ $t("common.collected") }}
                        </div>
                        <div class="item-title" style="font-weight: bold; color: green">
                          <span v-html="$filtersToCurrency.toCurrency(
                            (this.wallet.collectedAmount || 0).toFixed(2),
                            null,
                            this.wallet.currencyCode
                          )
                            "></span>
                        </div>
                      </div>
                      <div class="item-title-row" v-if="currentWalletOwner">
                        <div class="item-title" style="color: gray">
                          {{ $t("common.balance") }}
                        </div>
                        <div class="item-title" style="font-weight: bold; color: teal">
                          <span v-html="$filtersToCurrency.toCurrency(
                            (this.wallet.accountBalance || 0).toFixed(2),
                            null,
                            this.wallet.currencyCode
                          )
                            "></span>
                        </div>
                      </div>
                    </div>
                  </li>
                </f7-list>
                <f7-button v-if="this.wallet.campaignList && this.wallet.campaignList.length > 0" raised fill ripple
                  large @click="goToContributeNoCampaign()" class="card_button buttons" color="red">{{
                    $t("common.contribute") }}
                </f7-button>
              </f7-card>
              <!-- <p class="margin-left">
            {{ $t("walletdetail.fundraiser")
            }}<b class="campaignTitle"> {{ wallet.walletPrimeOwnerName }}</b>
          </p>
          <p v-if="wallet.beneficiaryName" class="margin-left">
            {{ $t("walletdetail.campaignOnBehalfOf")
            }}<b class="campaignTitle"> {{ wallet.beneficiaryName }}</b>
          </p> -->
              <f7-list dividers-ios strong-ios accordion-list media-list>
                <f7-list-item :title="$t('walletdetail.fundraiser')"
                  :after="wallet.type === 'charities' ? wallet.name : wallet.ownerName"></f7-list-item>
                <f7-list-item accordion-item :title="$t('common.description')" :padding="false">
                  <f7-accordion-content>
                    <f7-block>
                      <p class="campaignTitle" v-html="this.wallet.description"></p>
                    </f7-block>
                  </f7-accordion-content>
                </f7-list-item>
              </f7-list>
              <div outline class="right not_sticky_with_content">
                <campaignRounds :entity="this.wallet" :entityType="'wallet'" :isAdmin="this.currentUserIsAdmin">
                </campaignRounds>

                <!-- <f7-list dividers-ios strong-ios media-list class="no-margin-vertical">
                  <f7-list-item :title="$t('walletdetail.campaigns')"></f7-list-item>
                  <li v-for="(campaign, index) in this.wallet.campaignList" :key="index">
                    <a href="#" class="item-content" style="color: inherit" @click="selectCampaign(campaign)">
                      <div class="item-media">
                        <f7-badge color="auto" style="
                            height: 32px;
                            width: 32px;
                            font-size: 16px;
                            min-width: 32px;
                          ">{{ campaign.campaignNumber }}</f7-badge>
                      </div>
                      <div class="item-inner">
                        <div class="item-title-row">
                          <div class="item-title">{{ campaign.name }}</div>
                          <div class="item-after"></div>
                        </div>
                        <div class="item-title-row">
                          <div class="item-subtitle">
                            {{ $t("common.collected") }}
                          </div>
                          <div class="item-after">
                            <span v-html="$filtersToCurrency.toCurrency(
                              campaign.collectedAmount,
                              null,
                              campaign.currencyCode
                            )
                              "></span>
                          </div>
                        </div>
                      </div>
                    </a>
                  </li>
                </f7-list> -->
              </div>
              <f7-card outline header-divider v-if="this.$keycloak.authenticated && this.currentWalletOwner"
                class="left not_sticky_with_content">
                <f7-card-header href="#" sheet-open=".addNewMember">
                  <div>{{ $t("common.members") }}</div>
                  <div></div>
                  <f7-badge v-if="currentUserIsAdmin" bg-color="red" :style="{
                    position: 'absolute',
                    right: '16px',
                    top: '8px',
                    'border-radius': '50px',
                    height: '32px',
                    width: '32px',
                  }" class="elevation-3">
                    <f7-link icon-aurora="f7:person_crop_circle_fill_badge_plus"
                      icon-ios="f7:person_crop_circle_fill_badge_plus" icon-md="material:person_add" color="white"
                      href="#" sheet-open=".addNewMember"></f7-link>
                  </f7-badge>
                </f7-card-header>
                <f7-list dividers-ios strong-ios accordion-list class="no-margin-top" :no-chevron="!currentUserIsAdmin"
                  v-if="this.$keycloak.authenticated && this.currentWalletOwner">
                  <f7-list-item v-for="(owner, index) in this.wallet.walletOwnerList" :key="index" accordion-item
                    :title="owner.name" :subtitle="'+' + owner.phoneNumber" :text="owner.email" :badge="wallet.walletAdminList.find((x) => x.userId === owner.userId)
                        ? $t('common.admin')
                        : ''
                      " badge-color="primary">
                    <f7-accordion-content v-if="
                      currentUserIsAdmin &&
                      owner.userId !== wallet.walletPrimeOwnerUserId
                    ">
                      <f7-button raised fill small ripple color="red" class="margin-horizontal margin-vertical-half"
                        :text="$t('common.removeUser')" @click="removeWalletOwner(owner)">
                      </f7-button>

                      <f7-button @click="grantAdminRole(owner)" raised fill small ripple color="green"
                        class="margin-horizontal margin-vertical-half" :text="$t('common.grantAdminRights')" v-if="
                          !wallet.walletAdminList.find((x) => x.userId === owner.userId)
                        ">
                      </f7-button>
                      <f7-button v-if="
                        wallet.walletAdminList.find((x) => x.userId === owner.userId)
                      " @click="withdrawAdminRole(owner)" raised fill small ripple
                        class="margin-horizontal margin-vertical-half" :text="$t('common.withdrawAdminRights')"
                        color="orange">
                      </f7-button>
                    </f7-accordion-content>
                    <template #media>
                      <img v-if="owner.avatar" :src="`${owner.avatar}`" width="40"
                        style="height: 48px; width: 48px; border-radius: 25px" />

                      <f7-badge color="auto" v-else style="
                          height: 48px;
                          width: 48px;
                          font-size: 16px;
                          border-radius: 25px;
                        ">{{ $filtersGetInitial.getInitial(owner.name) }}</f7-badge>
                    </template>
                  </f7-list-item>
                </f7-list>
              </f7-card>
            </span>
          </div>
          <div class="right_layout">
            <f7-card outline class="sticky with_content">
              <campaignRounds :entity="this.wallet" :entityType="'wallet'" :isAdmin="this.currentUserIsAdmin">
              </campaignRounds>

              <!-- <f7-list dividers-ios strong-ios media-list class="no-margin-vertical">
                <f7-list-item :title="$t('walletdetail.campaigns')"></f7-list-item>

                <li v-for="(campaign, index) in this.wallet.campaignList" :key="index">
                  <a href="#" class="item-content" style="color: inherit" @click="selectCampaign(campaign)">
                    <div class="item-media">
                      <f7-badge color="auto" style="
                          height: 32px;
                          width: 32px;
                          font-size: 16px;
                          min-width: 32px;
                        ">{{ campaign.campaignNumber }}</f7-badge>
                    </div>
                    <div class="item-inner">
                      <div class="item-title-row">
                        <div class="item-title">{{ campaign.name }}</div>
                        <div class="item-after"></div>
                      </div>
                      <div class="item-title-row">
                        <div class="item-subtitle">
                          {{ $t("common.collected") }}
                        </div>
                        <div class="item-after">
                          <span v-html="$filtersToCurrency.toCurrency(
                            campaign.collectedAmount,
                            null,
                            campaign.currencyCode
                          )
                            "></span>
                        </div>
                      </div>
                    </div>
                  </a>
                </li>
              </f7-list> -->
            </f7-card>
          </div>
        </div>
      </f7-tab>

      <f7-tab v-if="
        this.wallet && this.wallet.campaignList && this.wallet.campaignList.length > 0
      " v-for="(campaign, index) in this.wallet.campaignList" :key="index" :id="`tab-${campaign.campaignNumber}`"
        @tab:show="selectedTab(campaign)" class="page-content" style="padding-bottom: 80px !important">
        <div class="layout_content">
          <div class="left_layout"></div>
          <div class="main_layout">
            <f7-card class="demo-facebook-card" :id="`campaingView-${campaign.campaignNumber}`" header-divider>
              <f7-card-header text-color="auto">
                <div class="demo-facebook-avatar padding-right">
                  <f7-badge slot="media" color="auto"
                    style="height: 40px; width: 40px; font-size: 16px; border-radius: 50%">{{
                      $filtersGetInitial.getInitial(campaign.name) }}</f7-badge>
                </div>
                <div style="white-space: normal; word-wrap: break-word; font-weight: 90%" class="demo-facebook-name">
                  {{ campaign.name }}
                </div>
                <div class="demo-facebook-date item-label" style="font-size: small">
                  {{
                    $t("associationdetail.fromto", {
                      startDate: campaign.startDate.substr(0, 10),
                      endDate: campaign.endDate.substr(0, 10),
                    })
                  }}
                </div>
              </f7-card-header>

              <f7-card-content>
                <div class="grid xsmall-grid-cols-1 small-grid-cols-2">
                  <span>
                    <small :style="{ opacity: 0.7 }">{{ $t("common.collected") }}</small>
                    <b>{{
                      $filtersToCurrency.toCurrency(
                        campaign.collectedAmount,
                        null,
                        campaign.currencyCode
                      )
                    }}
                    </b>
                    <br />

                    <small :style="{ opacity: 0.7 }">{{ $t("associationdetail.balance") }}
                      <b>
                        {{
                          $filtersToCurrency.toCurrency(
                            campaign.accountBalance,
                            null,
                            campaign.currencyCode
                          )
                        }}
                      </b></small>
                  </span>
                  <f7-gauge type="circle" :value="parseFloat(campaign.collectedAmount) /
                    parseFloat(campaign.fundingTarget)
                    " :labelText="$t('common.collected')" :valueText="$filtersToCurrency.toCurrency(
                      campaign.collectedAmount,
                      null,
                      campaign.currencyCode
                    )
                      " valueTextColor="auto" border-color="green" labelTextColor="#4caf50" :labelFontWeight="600"
                    :labelFontSize="12" :borderWidth="30" bg-color="white" />
                </div>
                <f7-list dividers-ios strong-ios media-list v-if="wallet && authenticated && currentUserIsAdmin"
                  class="margin-top">
                  <f7-list-group>
                    <f7-list-item :title="campaign.name" smart-select :smart-select-params="{
                      openIn: isTablet ? 'popover' : 'sheet',
                      sheetSwipeToClose: true,
                      closeOnSelect: true,
                    }" @smartselect:close="changeCampaignManager()" v-model="campaignManager">
                      <select name="campaignManager">
                        <option v-for="owner in wallet.ownerList" :key="owner.id" :value="owner.userId">
                          {{ owner.name }}
                        </option>
                      </select>
                    </f7-list-item>
                  </f7-list-group>
                </f7-list>
              </f7-card-content>
              <div class="grid grid-cols-2 grid-gap" v-if="campaign.accountBalance > 0 && currentUserIsAdmin">
                <f7-button raised fill large style="border-radius: 0px"
                  class="no-margin-horiztontal contributeButton buttons" @click="goToContribute(campaign)"
                  color="red">{{ $t("tontinedetail.contribute") }}</f7-button>

                <f7-button raised fill large class="no-margin-horiztontal contributeButton buttons"
                  style="border-radius: 0px" color="red" @click="redeemWallet(campaign)">{{ $t("common.redeem")
                  }}</f7-button>
              </div>

              <f7-button raised fill ripple large class="card_button buttons" @click="goToContribute(campaign)"
                color="red">{{
                  $t("associationdetail.contribute") }}</f7-button>
            </f7-card>
          </div>

          <div class="right_layout"></div>
        </div>
      </f7-tab>
    </f7-tabs>

    <addNewCampaign v-if="$keycloak.authenticated" />

    <template #fixed>
      <f7-fab v-if="this.wallet && this.currentUserIsAdmin && !this.currentCampaign" position="center-bottom"
        :text="$t('walletdetail.addNewCampaign')" color="red" @click="addNewCampaignPopup()">
        <f7-icon ios="f7:plus" aurora="f7:plus" md="material:add"></f7-icon>
      </f7-fab>
      <f7-fab position="left-bottom" color="auto" @click="goToMessageGroup()"
        v-if="this.$keycloak.authenticated && !!this.wallet">
        <f7-icon ios="f7:ellipses_bubble_fill" aurora="f7:ellipses_bubble_fill" md="material:chat"></f7-icon>
      </f7-fab>
      <f7-fab position="right-bottom">
        <f7-icon ios="f7:plus" md="material:add"></f7-icon>
        <f7-icon ios="f7:xmark" md="material:close"></f7-icon>
        <f7-fab-buttons position="top">
          <f7-fab-button class="shareButton" @click="socialShare(sharedContent)" label="Share"><f7-icon
              ios="f7:square_arrow_up" md="material:share"></f7-icon></f7-fab-button>
          <f7-fab-button @clik="sendMessageToUser()" label="Send Message"
            popup-open=".sendMessageToUserPopup">2</f7-fab-button>
          <f7-fab-button label="Payment link or request">3</f7-fab-button>
          <f7-fab-button label="Invite">3</f7-fab-button>
        </f7-fab-buttons>
      </f7-fab>
    </template>
    <sendMessage v-if="
      this.wallet &&
      this.wallet.walletOwnerList &&
      this.wallet.walletOwnerList.length > 0
    " :entityId="this.wallet.id" :memberList="this.wallet.walletOwnerList" :entityType="'wallet'"
      :entity="this.wallet" :currentUser="this.wallet.walletOwnerList.find((user) => user.userId === this.profile.sub)
        "></sendMessage>
    <groupDocuments v-if="
      this.wallet &&
      this.wallet.walletOwnerList &&
      this.wallet.walletOwnerList.length > 0
    " :entityId="this.wallet.id" :projectList="this.wallet.campaignList" :entityType="'wallet'" :entity="this.wallet"
      :isAdmin="this.currentUserIsAdmin" :currentUser="this.wallet.walletOwnerList.find((user) => user.userId === this.profile.sub)
        "></groupDocuments>
  </f7-page>
</template>
<style scoped></style>

<script>
import {
  f7Page,
  f7Navbar,
  f7NavLeft,
  f7NavTitle,
  f7NavRight,
  f7Link,
  f7Card,
  f7CardContent,
  f7CardHeader,
  f7Badge,
  f7PhotoBrowser,
  f7List,
  f7Button,
  f7AccordionContent,
  f7Block,
  f7ListItem,
  f7ListGroup,
  f7ListInput,
  f7Input,
  f7Popup,
  f7Toolbar,
  f7BlockTitle,
  f7Chip,
  f7Fab,
  f7Icon,
  theme,
  f7,
} from "framework7-vue";
import { mapGetters, mapState } from "vuex";
import Navbarright from "../components/navbarright.vue";
import addNewCampaign from "../components/walletAddNew.vue";
import campaignRounds from "../components/modules/campaignRounds.vue";
import UsersPage from "./users.vue";
import sendMessage from "../components/modules/sendMessageToMembers.vue";
import groupDocuments from "../components/modules/groupDocumentsView.vue";
//import { calculateTransferPayment } from "../js/mixins/transaction.js";
import { walletMixin } from "../js/mixins/walletMixins.js";
import { keyPadMixin } from "../js/mixins/keyPadMixin.js";
import { socialShare } from "../js/mixins/cordovaPlugins.js";
import { contributeRedeem } from "../js/mixins/contributeRedeem.js";

import _ from "lodash";
export default {
  props: {
    f7route: Object,
    f7router: Object,
  },
  components: {
    navbarright: Navbarright,
    userslist: UsersPage,
    addNewCampaign,
    sendMessage,
    groupDocuments,
    campaignRounds,
    f7Page,
    f7Navbar,
    f7NavLeft,
    f7NavTitle,
    f7NavRight,
    f7Link,
    f7Card,
    f7CardContent,
    f7CardHeader,
    f7Badge,

    f7PhotoBrowser,

    f7List,
    f7Button,
    f7AccordionContent,
    f7Block,
    f7ListItem,
    f7ListGroup,
    f7ListInput,
    f7Input,

    f7Popup,
    f7Toolbar,
    f7BlockTitle,
    f7Chip,
    f7Fab,
    f7Icon,
  },
  mixins: [walletMixin, keyPadMixin, socialShare, contributeRedeem],
  data() {
    return {
      theme,
      f7,
      countryCode: "",
      phoneCode: "",
      amountToPay: "",
      paymentCurrency: "",
      payerComment: "",
      fundingTarget: "",
      redeemedAmount: "",
      nocardNumberOrPINError: "",
      cardNumber: "",
      cardPIN: "",
      transferPIN: "",
      sharedContent: "",
      fileToSave: [],
      description: "",
      type: "wallet",
      amount: "",
      setAmount: null,
      walletCampaignBeneficiary: "",
      project_type: "",
      isAdmin: "",
      isMember: "",
      trxType: "",
      referenceId: "",
      receiver_wallet_id: "",
      sender_wallet_id: "",
      tag: "",
      return_url: "",
      return_url_fail: "",
      countryCode: "",
      project: "",
      entity: "",
      campaignManager: "",
      currentCampaign: "",
    };
  },

  async mounted() {
    if (!this.wallet) {
      this.$store.dispatch("wallet/getWallet", this.f7route.params.entityId);
    }
    console.log(this.wallet);
    if (!this.userAccount && this.$keycloak.authenticated) {
      this.$store.dispatch("account/loadAccount", this.profile.sub);
    }

    if (this.country === null) {
      this.$store.dispatch("location/getCountry");
    }
    this.countryId = this.currentUserCountry.countryId;
    this.paymentCurrency = this.wallet.currencyCode;
    this.searchCriteria = "email";
  },

  methods: {
    // pageBeforeIn() {
    //   if (!this.wallet) {
    //     this.$store.dispatch("wallet/getWallet", this.f7route.params.entityId);
    //   }
    // },

    async selectCampaign(campaign) {
      f7.tab.show(`#tab-${campaign.campaignNumber}`, true);
      this.currentCampaign = campaign;
      // this.hiddenFrom = campaign.hiddenFrom;
      // this.battleManager = campaign.battleManager;
    },
    selectedTab(campaign) {
      this.currentCampaign = campaign;
    },

    updateWallet() {
      this.$store.dispatch("wallet/getWallet", this.wallet.id);
    },

    goToContributeNoCampaign() {
      const self = this;
      if (
        this.wallet &&
        this.wallet.campaignList &&
        this.wallet.campaignList.length == 1
      ) {
        this.goToContribute(this.wallet.campaignList[0]);
        return;
      }
      const butt = [];
      const label = {
        text: self.$t("walletdetail.selectCampaignToContribute"),
        color: "auto",
        bold: true,
      };

      butt.push(label);
      for (const campaign of this.wallet.campaignList) {
        butt.push({
          text:
            ' <span class="badge color-auto">' +
            campaign.campaignNumber +
            "</span> " +
            campaign.name,

          onClick() {
            self.goToContribute(campaign);
          },
        });
      }
      if (!self.contribToPopover) {
        self.contribToPopover = f7.actions.create({
          convertToPopover: true,
          buttons: [
            butt,
            [
              {
                text: self.$t("common.cancel"),
                color: "red",
              },
            ],
          ],
          targetEl: self.$el.querySelector(".contributeButton"),
        });
      }
      self.contribToPopover.open();
    },
    goToContribute(campaign) {
      this.project_type = "contribute";
      this.preparePayment(campaign);
      setTimeout(() => {
        this.contribute();
      }, 500);
    },
    redeemWallet(campaign) {
      this.project_type = "redeem";
      this.preparePayment(campaign);
      setTimeout(() => {
        this.redeem();
      }, 500);
    },

    async goToMessageGroup() {
      const chatId =
        this.wallet.chatGroupId === "" ? this.wallet.id : this.wallet.chatGroupId;
      f7.preloader.show("multi");
      if (this.currentWalletOwner || this.wallet.isPublic == true) {
        setTimeout(() => {
          this.f7router.navigate(`/message/${chatId}/GROUP`, {
            reloadAll: true,
          });
          f7.preloader.hide();
        }, 300);
      }
    },
    async selectUserCountryCode() {
      if (this.country === "" || !this.country) {
        await this.$store.dispatch("location/getCountry");
      }
      f7.sheet.open(".selectUserCountryCode");
      console.log(this.countryCode);
    },
    async selectDestinationCountry(destination) {
      this.countryCode = await destination.countryCode2;
      this.phoneCode = await destination.phoneCode;
      console.log(this.countryCode);
      f7.sheet.close(".selectUserCountryCode");
    },
    addNewCampaignPopup() {
      f7.popup.open(".addNewWalletCampaign");
      console.log("current wallet", this.wallet);
      this.campaignNumber = parseFloat(this.wallet.campaignList.length) + 1;
      this.fundingTarget = "";
      this.get3rdPartyWallet();
    },
    sendMessageToUser() {
      console.log("sendMessageToUserPopup");
      f7.popup.open(".sendMessageToUserPopup");
    },
  },

  computed: {
    ...mapState("wallet", [
      "wallet",
      "projectCategories",
      "walletOwner",
      "walletList",
      "publicWalletList",
      "walletTransaction",
      "thirdPartyAllowedWalletList",
      "privateWalletTransactionList",
      "publicWalletTransactionList",
      "walletTransactionList",
      "walletImages",
      // "walletQRImage",
    ]),
    ...mapState("transaction", [
      "destinationCountry",
      "originCountry",
      "destinationCity",
      "grossexchangerate",
      "transferDetail",
      "fromCurrencyRate",
      "toCurrencyRate",
      "transferDetail",
      "paymentDetail",
      "paymentReverseDetail",
    ]),
    ...mapState("location", [
      "destinationCity",
      "country",
      "countryStates",
      "countryCities",
      "currentUserCountry",
      "latitude",
      "longitude",
      "ipAddress",
    ]),
    ...mapState("account", ["userAccount", "searchedUser"]),
    ...mapState("card", ["carditemlist", "cardbasket", "country", "queryerror"]),
    ...mapState("auth", ["profile", "isDeskTop", "isTablet", "authenticated"]),

    walletCountry() {
      if (this.wallet) {
        return _.find(this.country, ["countryCode2", this.wallet.countryCode]);
      }
    },

    beneficiaryWallet() {
      if (this.beneficiaryWalletId !== " ") {
        return _.find(this.publicWalletList, ["id", this.beneficiaryWalletId]);
      }
    },

    currentCampaignDays() {
      const currentDate = new Date();
      const endDate = new Date(this.wallet.endDate);
      return endDate < currentDate
        ? "Ended " +
        ((currentDate - endDate) / (1000 * 3600 * 24)).toFixed(0) +
        " day(s) ago"
        : `${((endDate - currentDate) / (1000 * 3600 * 24)).toFixed(0)} day(s) left`;
    },
    campaignIsActive() {
      const currentDate = new Date();
      const endDate = new Date(this.wallet.endDate);
      return endDate > currentDate;
    },

    selectCriteria() {
      if (this.memberSearchParams && this.searchCriteria) {
        return _.find(this.memberSearchParams, ["value", this.searchCriteria]);
      }
    },
  },
};
</script>
