<template>
  <!-- <f7-sheet
    class="contributeOrRedeemSheet"
    :swipe-to-close="false"
    :tablet-fullscreen="true"
    :backdrop="true"
    :close-by-backdrop-click="false"
    :close-on-escape="false"
    :style="{ '--f7-sheet-bg-color': '#fff', 'min-height': isTablet ? '100%' : '75%' }"
    @sheet:open="sheetOpenActions()"
  > -->
  <f7-page @page:beforein="sheetOpenActions()">
    <f7-navbar sliding no-shadow v-if="this.project_type === 'contribute'">
      <f7-nav-left :back-link="$t('common.backButton')" :back-link-show-text="false" sheet-close></f7-nav-left>
      <f7-nav-title>{{ $t("common.contribute") }}</f7-nav-title>
      <f7-nav-right>
        <navbarright></navbarright>
      </f7-nav-right>
    </f7-navbar>
    <div class="layout_content" v-if="this.project_type === 'contribute'">
      <div class="left_layout"></div>
      <div class="main_layout">
        <f7-block-title large v-if="this.type === 'tontine'" style="white-space: normal; word-wrap: break-word">
          {{
            $t("tontinecontribute.contributeToRound", {
              tontine: this.entity.name,
            })
          }}<f7-badge color="auto" style="height: 32px; width: 32px; font-size: 16px, padding-right:8px">{{
            this.currentProject.roundNumber }} </f7-badge><br /><span>
            {{ $t("tontinedetail.roundTaker") }}:
            {{
              this.currentEntity.tontineParticipantList.find(
                (x) => x.id === this.currentProject.roundTaker
              ).name
            }}</span>
        </f7-block-title>
        <f7-block-title large style="white-space: normal; word-wrap: break-word" v-else>
          {{
            $t("associationcontribute.contributeTo", {
              campaignName: this.currentProject.name,
            })
          }}
        </f7-block-title>

        <f7-list dividers-ios strong-ios outline-ios form id="contributeForm">
          <f7-list-group>
            <span class="large-cols20_80 cols30_70">
              <li class="item-content item-input no-padding-right">
                <div class="item-inner no-padding-right">
                  <div v-if="paymentCurrency" class="item-title item-label">
                    {{ $t("common.currency") }}
                  </div>
                  <div v-else class="item-title item-floating-label">
                    {{ $t("common.currency") }}
                  </div>
                  <div :class="`item-input-wrap ${userAccount !== null &&
                    this.currentProject.currencyCode !== this.userAccount.currencyCode
                    } ?  'input-dropdown-wrap':''`">
                    <select v-model="paymentCurrency" v-if="this.authenticated &&
                      this.userAccount !== null &&
                      this.currentProject.currencyCode !== this.userAccount.currencyCode
                    " :defaultValue="this.currentProject.currencyCode">
                      <option :selected="this.currentProject.currencyCode" :value="this.currentProject.currencyCode">
                        {{ this.currentProject.currencyCode }}
                      </option>
                      <option v-if="userAccount !== null &&
                        this.currentProject.currencyCode !==
                        this.userAccount.currencyCode
                      " :value="this.userAccount.currencyCode" selected>
                        {{ this.userAccount.currencyCode }}
                      </option>
                    </select>
                    <input v-else type="text" resizable name="currency" :value="paymentCurrency" readonly
                      @input="paymentCurrency = $event.target.value" />
                  </div>
                </div>
              </li>
              <f7-list-input :label="$t('common.amount')" :floating-label="this.setAmount == 0 ? true : false"
                :placeholder="$t('common.enterAmount')" min="0" pattern="[0-9]*" :info="$t('common.enterAmount')"
                :error-message="$t('common.enterAmount')" v-model:value="this.amount" type="number" name="amount"
                class="inputNumber" clear-button validate required></f7-list-input>
            </span>
            <f7-list-input floating-label :label="$t('common.tag')" :info="$t('common.tagInfo')"
              :error-message="$t('common.tagInfo')" v-model:value="this.tag" name="tag" validate required
              type="texteditor" resizable></f7-list-input>
            <f7-list-input floating-label :label="$t('common.description')" :info="$t('common.transferDescInfo')"
              :error-message="$t('common.descriptionInfo')" v-model:value="this.description" name="description" validate
              required type="text"></f7-list-input>
          </f7-list-group>
          <f7-button raised fill large class="margin buttons" color="red" @click="contribute()">{{
            $t("common.contribute")
            }}</f7-button>
        </f7-list>
        <!-- maxlength="15"  pattern="^[a-zA-Z0-9 _:\-]{0,15}$"  -->
      </div>
      <div class="right_layout"></div>
    </div>
  </f7-page>

  <f7-page v-if="this.project_type === 'redeem' && this.$keycloak.authenticated" @page:beforein="sheetOpenActions()">
    <f7-navbar sliding no-shadow>
      <f7-nav-left v-if="this.isTablet" :back-link="$t('common.backButton')" :back-link-show-text="false"
        sheet-close></f7-nav-left>
      <f7-nav-title>{{ $t("common.redeem") }}</f7-nav-title>
      <f7-nav-right v-if="!this.isTablet">
        <f7-link sheet-close icon-f7="multiply"></f7-link></f7-nav-right>
    </f7-navbar>
    <div class="layout_content">
      <div class="left_layout"></div>
      <div class="main_layout">
        <f7-block-title large v-if="this.type === 'tontine'" style="white-space: normal; word-wrap: break-word">
          {{ $t("tontinedetail.redeemRound") }} {{ this.entity.name
          }}<f7-badge color="auto" style="height: 32px; width: 32px; font-size: 16px; padding-right: 8px">{{
            this.currentProject.roundNumber }} </f7-badge><br /><span>
            {{ $t("tontinedetail.roundTaker") }}:
            {{
              this.currentEntity.tontineParticipantList.find(
                (x) => x.id === this.currentProject.roundTaker
              ).name
            }}</span>
        </f7-block-title>
        <f7-block-title large style="white-space: normal; word-wrap: break-word" v-else>
          {{ $t("common.redeem") }} {{ this.currentProject.name }}
        </f7-block-title>
        <f7-list dividers-ios strong-ios outline-ios form id="redeemForm">
          <f7-list-group v-if="this.type === 'wallet'">
            <f7-list-item v-if="this.currentProject.beneficiaryCampaign === null"
              :title="$t('walletredeem.selectBeneficiary')" smart-select :smart-select-params="{
                openIn: isTablet ? 'popover' : 'sheet',
                sheetSwipeToClose: true,
                closeOnSelect: true,
              }" @smartselect:close="selectWalletBeneficiary()">
              <select name="walletCampaignBeneficiary" v-model="walletCampaignBeneficiary">
                <option v-for="owner in this.wallet.walletOwnerList" :key="owner.id" :value="owner.userId">
                  {{ owner.name }}
                </option>
              </select>
            </f7-list-item>
            <f7-list-item v-if="this.currentProject.beneficiaryCampaign !== null"
              :header="$t('walletredeem.beneficiaryWallet')" :title="this.currentProject.beneficiaryName"
              :subtitle="this.currentProject.beneficiaryWalletId">
            </f7-list-item>
          </f7-list-group>
          <f7-list-group v-if="this.type === 'association'">
            <f7-list-item v-if="this.currentProject.campaignBeneficiary === null"
              :title="$t('walletredeem.selectBeneficiary')" smart-select :smart-select-params="{
                openIn: isTablet ? 'popover' : 'sheet',
                sheetSwipeToClose: true,
                closeOnSelect: true,
              }" @smartselect:close="selectWalletBeneficiary()">
              <select name="walletCampaignBeneficiary" v-model="walletCampaignBeneficiary">
                <option v-for="owner in this.association.associationAssoMemberList" :key="owner.id"
                  :value="owner.userId">
                  {{ owner.name }}
                </option>
              </select>
            </f7-list-item>
            <f7-list-item v-if="this.currentProject.campaignBeneficiary !== null &&
              this.userDetail !== null
            " :header="$t('walletredeem.beneficiaryWallet')" :title="this.userDetail.name" :subtitle="this.userDetail.standardWallet !== null
                ? this.userDetail.standardWallet.id
                : ''
                ">
            </f7-list-item>
          </f7-list-group>

          <f7-list-group v-if="this.type === 'tontine'">
            <f7-list-item v-if="this.userDetail !== null" :header="$t('tontinedetail.roundTaker')"
              :title="this.userDetail.name" :subtitle="this.userDetail.standardWallet !== null
                ? this.userDetail.standardWallet.id
                : ''
                ">
            </f7-list-item>
          </f7-list-group>
          <f7-list-group v-if="this.type === 'savings'">
            <f7-list-item :title="$t('common.select')" smart-select :smart-select-params="{
              openIn: isTablet ? 'popover' : 'sheet',
              sheetSwipeToClose: true,
              closeOnSelect: true,
            }">
              <select name="receiver_wallet_id" v-model="this.receiver_wallet_id">
                <optgroup v-for="(apiWalletList, accountType, index) in this.userAccount
                  .userAccountWallets" :key="index" :label="getAccountType(accountType)">
                  <option v-for="actWallet in apiWalletList" :key="actWallet.id" :value="actWallet.id">
                    {{ actWallet.id + " " + actWallet.tag }}
                  </option>
                </optgroup>
              </select>
            </f7-list-item>
          </f7-list-group>
          <f7-list-group>
            <f7-list-input :label="$t('common.amount')" :floating-label="this.setAmount === '' ? true : false"
              :placeholder="this.setAmount" min="0" pattern="[0-9]*" :info="$t('common.enterAmount')"
              :error-message="$t('common.enterAmount')" v-model:value="this.amount" type="number" name="amount"
              class="inputNumber" clear-button><template #media>
                {{ this.currentProject.currencyCode }}</template></f7-list-input>
            <!-- </span> -->
            <f7-list-input floating-label :label="$t('common.tag')" :info="$t('common.tagInfo')"
              :error-message="$t('common.tagInfo')" v-model:value="this.tag" name="tag" validate required
              type="texteditor" resizable></f7-list-input>
            <f7-list-input :label="$t('common.description')" floating-label :info="$t('common.descriptionInfo')"
              :error-message="$t('common.descriptionInfo')" name="description" v-model:value="description"
              type="text"></f7-list-input>
          </f7-list-group>
        </f7-list>

        <f7-button raised fill large class="margin buttons" color="red" @click="redeem()">{{ $t("common.redeem")
          }}</f7-button>
      </div>
      <div class="right_layout"></div>
    </div>
  </f7-page>
  <!-- </f7-sheet> -->
</template>

<script>
import {
  f7Page,
  f7Navbar,
  f7NavLeft,
  f7NavRight,
  f7NavTitle,
  f7BlockTitle,
  f7BlockHeader,
  f7ListItem,
  f7BlockFooter,
  f7Block,
  f7Button,
  f7Card,
  f7CardContent,
  f7Link,
  f7Popup,
  f7Toolbar,
  f7PageContent,
  f7CardFooter,
  f7ListGroup,
  theme,
  f7,
} from "framework7-vue";
import { mapGetters, mapState } from "vuex";
import Navbarright from "../components/navbarright.vue";
import _ from "lodash";
export default {
  props: {
    f7route: Object,
    f7router: Object,
    entity: Object,
    project: Object,
  },
  components: {
    navbarright: Navbarright,
    f7Page,
    f7Navbar,
    f7NavLeft,
    f7NavRight,
    f7NavTitle,
    f7BlockTitle,
    f7BlockHeader,

    f7ListItem,
    f7BlockFooter,
    f7Block,
    f7Button,
    f7Card,
    f7CardContent,
    f7Link,
    f7Popup,
    f7Toolbar,
    f7PageContent,
    f7CardFooter,
    f7ListGroup,
  },
  mixins: [],
  data() {
    return {
      theme,
      f7,
      description: "",
      fundingTarget: "",
      amount: "",
      setAmount: null,
      paymentCurrency: "",
      walletCampaignBeneficiary: "",
      type: "",
      project_type: "",
      entityId: "",
      projectId: "",
      currentEntity: "",
      currentProject: "",
      isAdmin: "",
      isMember: "",
      trxType: "",
      referenceId: "",
      receiver_wallet_id: "",
      sender_wallet_id: "",
      tag: "",
      return_url: "",
      return_url_fail: "",
      countryCode: "",
    };
  },

  mounted() {
    if (!this.userAccount && this.$keycloak.authenticated) {
      this.$store.dispatch("account/loadAccount", this.profile.sub);
    }
    this.currentProject = this.project;
    this.currentEntity = this.entity;
    if (this.currentProject && this.currentProject.currencyCode !== "") {
      this.paymentCurrency = this.currentProject.currencyCode;
      this.countryCode = this.currentProject.countryCode;
    } else if (this.currentEntity && this.currentEntity.currencyCode !== "") {
      this.paymentCurrency = this.currentEntity.currencyCode;
      this.countryCode = this.currentEntity.countryCode;
    }
  },
  methods: {
    contribute() {
      f7.preloader.show("multi");
      if (f7.input.validateInputs("#contributeForm")) {
        const idempotency_key = f7.utils.id("xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx");
        const baseTransfer = {
          amount: this.amount,
          trxType: this.trxType,
          currency: this.paymentCurrency
            ? this.paymentCurrency
            : this.userAccount.currencyCode,
          countryCode:
            this.project.countryCode === null
              ? this.entity.countryCode
              : this.project.countryCode,
          lang: this.locale,
          referenceId: this.referenceId,
          receiver_wallet_id: this.receiver_wallet_id,
          sender_wallet_id: this.$keycloak.authenticated
            ? this.userAccount.standardWallet.id
            : "",
          payer_account_id: this.$keycloak.authenticated
            ? this.userAccount.standard.id
            : "",
          description: this.description,
          tag: this.tag,
          partner_ref: idempotency_key,
          idempotency_key,
          return_url: this.return_url,
          return_url_fail: this.return_url_fail,
        };
        this.$store.dispatch("apimoney/setBaseTransfer", baseTransfer);
        this.f7router.navigate("/pay/", {
          props: {
            payment_detail: {
              paymentCurrency: this.paymentCurrency
                ? this.paymentCurrency
                : this.userAccount.currencyCode,
              entity: this.currentEntity,
              project: this.currentProject,
              tranaction_type: "cash_in",
              tag: this.tag,
              wallet: this.apiWallet,
            },
          },
        });
        // f7.sheet.close(".contributeOrRedeemSheet");
      }
      f7.preloader.hide();
      // }
    },

    async sheetOpenActions() {
      console.log("entity", this.entity);
      console.log("project", this.project);
      this.type = this.f7route.path.split("/")[1];
      this.project_type = this.f7route.path.split("/")[3];
      this.entityId = this.f7route.params.entityId;
      this.projectId = this.f7route.params.projectId;
      this.currentProject = this.project;
      this.currentEntity = this.entity;

      this.return_url = `${window.location.origin}/transaction/success`;
      this.return_url_fail = `${window.location.origin}/transaction/failure`;
      const dispatchMap = {
        wallet: "wallet/getWallet",
        association: "asso/getAssociationById",
        tontine: "tontine/getTontine",
        saving: "savings/getSavings",
        groupBuy: "groupBuy/getGroupBuy",
      };

      if (dispatchMap[this.type]) {
        await this.$store.dispatch(dispatchMap[this.type], this.entityId);
      } else {
        console.log("No entity found");
        return;
      }
      switch (this.type) {
        case "wallet":
          this.$store.dispatch("wallet/getWallet", this.entityId);
          setTimeout(() => {
            this.currentEntity = this.wallet;
            this.currentProject =
              this.project ||
              this.wallet.campaignList.find((cp) => cp.id === this.projectId);
            if (this.$keycloak.authenticated) {
              const findUser = (cp) => cp.userId === this.profile.sub;
              this.isAdmin = this.currentEntity.walletAdminList.some(findUser);
              this.isMember = this.currentEntity.walletOwnerList.some(findUser);
            }

            this.trxType = "WALL";
            this.referenceId = this.currentProject.id;
            this.tag = `${this.currentProject.name} - ${this.currentEntity.name}`;

            const isContribute = this.project_type === "contribute";
            this.setAmount = isContribute
              ? this.currentProject.fundingTarget
              : this.currentProject.accountBalance;
            this.amount = this.setAmount;
            this.receiver_wallet_id = this.currentEntity.walletId;

            if (!isContribute) {
              this.sender_wallet_id = this.currentEntity.walletId;
              this.receiver_wallet_id = this.currentProject.beneficiarywalletId;
            }
          }, 300);
          console.log(
            "this.trxType",
            this.trxType,
            this.currentProject,
            this.receiver_wallet_id
          );
          break;
        case "association":
          setTimeout(() => {
            const findUser = (cp) => cp.userId === this.profile.sub;
            this.isAdmin = this.currentEntity.associationAdminList.some(findUser);
            this.isMember = this.currentEntity.associationMemberList.some(findUser);
            this.trxType = "ASSO";
            this.referenceId = this.currentProject.id;
            this.tag = `${this.currentProject.name} - ${this.currentEntity.name}`.substring(
              0,
              100
            );
            const isContribute = this.project_type === "contribute";
            this.setAmount = isContribute
              ? this.currentProject.campaignAmount
              : this.currentProject.accountBalance;
            this.amount = this.setAmount;
            this.receiver_wallet_id =
              this.currentProject.walletId === null
                ? this.currentEntity.walletId
                : this.currentProject.walletId;
            if (
              !isContribute &&
              this.currentProject.campaignBeneficiary !== null &&
              this.$keycloak.authenticated
            ) {
              this.sender_wallet_id = this.currentEntity.walletId;
              this.getSelectedUser(this.currentProject.campaignBeneficiary);
              setTimeout(() => {
                this.receiver_wallet_id = this.userDetail.standardWallet.id;
              }, 200);
            }
          }, 300);
          console.log(
            "this.trxType",
            this.trxType,
            this.currentProject,
            this.receiver_wallet_id
          );
          break;
        case "tontine":
          setTimeout(() => {
            const findUser = (cp) => cp.userId === this.profile.sub;
            this.isAdmin = this.currentEntity.tontineAdminList.some(findUser);
            this.isMember = this.currentEntity.tontineParticipantList.some(findUser);
            const roundTaker = this.tontine.tontineParticipantList.find(
              (cp) => cp.id === this.currentProject.roundTaker
            );
            this.tag = `${this.$t("tontinedetail.roundTaker")}: ${roundTaker.name} ${this.currentEntity.name
              } ${this.$t("tontinedetail.round")}-${this.currentProject.roundNumber
              }`.substring(0, 100);
            this.trxType = "TONT";
            this.referenceId = this.currentProject.id;
            const isContribute = this.project_type === "contribute";
            this.setAmount = isContribute
              ? this.currentProject.tontineRoundAmount
              : this.currentProject.accountBalance;
            this.amount = this.setAmount;
            this.receiver_wallet_id = isContribute
              ? this.currentEntity.walletId
              : this.userDetail.standardWallet.id;
            if (!isContribute) {
              this.sender_wallet_id = this.currentEntity.walletId;
              if (this.$keycloak.authenticated) {
                setTimeout(() => this.getSelectedUser(roundTaker.userId), 200);
              }
            }
          }, 300);
          break;
        case "saving":
          setTimeout(() => {
            const participant = this.currentProject.participantList.find(
              (cp) => cp.userId === this.profile.sub
            );
            this.setAmount = this.amount = participant.contribAmount;
            const findUser = (cp) => cp.userId === this.profile.sub;
            this.isAdmin = this.currentEntity.savingsGroupAdminList.some(findUser);
            this.isMember = this.currentProject.participantList.some(findUser);
            this.trxType = "SAVB";
            this.referenceId = participant.id;
            this.tag = `${this.currentProject.name} - ${this.currentEntity.name}`.substring(
              0,
              100
            );
            this.receiver_wallet_id = participant.walletId;
          }, 300);
          break;
        case "groupBuy":
          setTimeout(() => {
            const participantGB = this.currentProject.participantList.find(
              (cp) => cp.userId === this.profile.sub
            );
            this.setAmount = this.amount = participantGB.contribAmount;
            const findUser = (cp) => cp.userId === this.profile.sub;
            this.isAdmin = this.entity.adminList.some(findUser);
            this.isMember = this.currentProject.participantList.some(findUser);
            this.trxType = "GBUY";
            this.referenceId = participantGB.id;
            this.receiver_wallet_id = this.groupBuy.walletId;
            this.tag = `${this.currentProject.name} - ${this.currentEntity.name}`.substring(
              0,
              100
            );
          }, 300);
          break;
        default:
          console.log("No entity found");
          break;
      }
      setTimeout(() => {
        if (this.receiver_wallet_id !== "") {
          this.getReceiverApiWallet();
        }
      }, 500);
    },
    selectWalletBeneficiary() {
      this.getSelectedUser(this.walletCampaignBeneficiary);
      setTimeout(() => {
        this.receiver_wallet_id = this.userDetail.standardWallet.id;
      }, 500);
    },

    getSelectedUser(userId) {
      if (this.$keycloak.authenticated) {
        this.$store.dispatch("account/loadUser", { userId, type: "userId" });
      }
    },
    getReceiverApiWallet() {
      this.$store.dispatch("apimoney/getApiWallet", {
        walletId: this.receiver_wallet_id,
      });
    },
    getAccountType(type) {
      return type === "STANDARD"
        ? this.$t("common.standard")
        : this.$t("common.business");
    },
    changeAmountRedeem() {
      if (this.setAmount != null && this.setAmount < this.amount) {
        this.amount = this.setAmount;
      }
    },
    async redeem() {
      if (f7.input.validateInputs("#redeemForm")) {
        const idempotency_key = f7.utils.id("xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx");
        const baseTransfer = {
          amount: this.amount,
          trxType: this.trxType,
          referenceId: this.referenceId,
          lang: this.locale,
          currency: this.paymentCurrency
            ? this.paymentCurrency
            : this.userAccount.currencyCode,
          countryCode:
            this.project.countryCode === null
              ? this.entity.countryCode
              : this.project.countryCode,
          receiver_wallet_id: this.receiver_wallet_id,
          sender_wallet_id: this.sender_wallet_id,
          description: this.description.substring(0, 15),
          tag: this.tag.substring(0, 100),
          partner_ref: idempotency_key,
          idempotency_key,
          return_url: this.return_url,
          return_url_fail: this.return_url_fail,
        };
        await this.$store.dispatch("apimoney/setBaseTransfer", baseTransfer);
        await this.f7router.navigate(`/pay`, {
          props: {
            payment_detail: {
              paymentCurrency: this.paymentCurrency
                ? this.paymentCurrency
                : this.userAccount.currencyCode,
              entity: this.currentEntity,
              project: this.currentProject,
              tranaction_type: "transfer",
              tag: this.tag,
              wallet: this.apiWallet,
            },
          },
        });
      }
    },
  },

  computed: {
    ...mapState("account", ["userAccount", "searchedUser", "userDetail"]),
    ...mapState("auth", [
      "phoneNumberDetail",
      "mobilePhoneError",
      "profile",
      "isTablet",
      "authenticated",
      "locale",
    ]),
    ...mapState("savings", ["savings", "savingsList"]),
    ...mapState("groupBuy", ["groupBuy", "groupBuyList"]),
    ...mapState("wallet", ["wallet"]),
    ...mapState("tontine", ["tontine"]),
    ...mapState("asso", ["association"]),
    ...mapState("apimoney", ["baseTransfer", "apiWallet"]),
    ...mapState("location", ["country", "apiCode", "currentUserCountry"]),
    ...mapGetters("transaction", [
      "getusererror",
      "fromCurrencyRate",
      "toCurrencyRate",
      "grossexchangerate2",
    ]),
  },
};
</script>
