// import base_url from "../base_url.js";
import axios from "axios";
const path = import.meta.env.VITE_APP_URL + "asso";
import _ from "lodash";
import { f7 } from "framework7-vue";

// const asso = {
export default (app) => {
  return {
    namespaced: true,
    state: {
      association: "",
      campaign: "",
      member: "",
      memberList: "",
      admin: "",
      associationTransaction: "",
      associationList: "",
      activeassociantionList: "",
      adminList: "",
      assoImages: "",
      assoQRImage: "",
      campaignTransactionList: "",
      associationTransactionList: "",
    },
    getters: {},
    mutations: {
      CREATE_ASSOCIATION(state, association) {
        state.association = association;
      },
      GET_ASSOCIATION(state, association) {
        state.association = association;
      },
      GET_ASSOIMAGES(state, assoImages) {
        state.assoImages = assoImages;
      },

      EDIT_ASSOCIATION(state, association) {
        state.association = association;
      },
      GET_ASSOCIATIONLIST(state, associationList) {
        state.associationList = associationList;
      },
      GET_ACTIVEASSOCIATIONLIST(state, activeassociationList) {
        state.activeassociationList = activeassociationList;
      },
      DELETE_ASSOIMAGES(state, payload) {
        state.id = payload;
      },
      GET_MEMBER(state, member) {
        state.member = member;
      },
      GET_MEMBERLIST(state, memberList) {
        state.memberList = memberList;
      },
      REMOVE_MEMBER(state, payload) {
        state.id = payload;
      },
      GET_ASSOCIATIONTRANSACTION(state, associationTransaction) {
        state.associationTransaction = associationTransaction;
      },

      GET_ASSOCIATIONTRANSACTION_LIST(state, associationTransactionList) {
        state.associationTransactionList = associationTransactionList;
      },

      CREATE_QRCODE(state, payload) {
        state.assoQRImage = payload;
      },
      READ_QRCODE(state, payload) {
        state.association = payload;
      },
    },

    actions: {
      createNewAssociation({ commit }, payload) {
        console.log(payload);
        axios({
          url: `${path}`,
          method: "POST",
          data: payload,
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((association) => {
            commit("CREATE_ASSOCIATION", association);
            console.log("CREATE_ASSOCIATION", association);
            f7.views.main.router.navigate(`/association/${association.id}`);
          })
          .catch((err) => {
            console.log(err);
          });
      },
      getAssociation({ commit }, payload) {
        axios({
          url: `${path}`,
          method: "GET",
          params: { associationId: payload },
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((association) => {
            commit("GET_ASSOCIATION", association);
          })
          .catch((err) => {
            console.log(err);
          });
      },
      editAssociation({ commit }, payload) {
        console.log(payload);
        axios({
          url: path,
          method: "PUT",
          data: payload,
          params: {
            userId: app.config.globalProperties.$keycloak.subject,
          },
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((association) => {
            commit("GET_ASSOCIATION", association);
          })
          .catch((err) => {
            console.log(err);
          });
      },

      createCampaign({ commit }, payload) {
        console.log(payload);
        axios({
          url: `${path}/campaign`,
          method: "POST",
          data: payload.campaign,
          params: {
            userId: app.config.globalProperties.$keycloak.subject,
            memberId: payload.memberId,
            associationId: payload.associationId,
          },
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((association) => {
            commit("GET_ASSOCIATION", association);
          })
          .catch((err) => {
            console.log(err.errorMessage);
          });
      },
      editCampaign({ commit }, payload) {
        axios({
          url: `${path}/campaign`,
          method: "PUT",
          data: payload,
          params: {
            userId: app.config.globalProperties.$keycloak.subject,
          },
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((association) => {
            commit("GET_ASSOCIATION", association);
          })
          .catch((err) => {
            console.log(err.errorMessage);
          });
      },
      addCampaignManager({ commit }, payload) {
        axios({
          url: `${path}/campaign/add/manager`,
          method: "PUT",
          data: payload,
          // params: {
          //   userId: app.config.globalProperties.$keycloak.subject,
          // },
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((association) => {
            commit("GET_ASSOCIATION", association);
          })
          .catch((err) => {
            console.log(err.errorMessage);
          });
      },
      updateBeneficiary({ commit }, payload) {
        axios({
          url: `${path}/campaign/update/beneficiary`,
          method: "PUT",
          data: payload,
          // params: {
          //   userId: app.config.globalProperties.$keycloak.subject,
          // },
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((association) => {
            commit("GET_ASSOCIATION", association);
          })
          .catch((err) => {
            console.log(err.errorMessage);
          });
      },
      getAssociationById({ commit }, payload) {
        axios({
          url: path,
          params: { associationId: payload },
          method: "GET",
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((association) => {
            commit("GET_ASSOCIATION", association);
          })
          .catch((err) => {
            console.log(err);
          });
      },
      getAssoImagesById({ commit }, payload) {
        axios({
          url: `${path}/getImages/${payload}`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((assoImages) => {
            commit("GET_ASSOIMAGES", assoImages);
          })
          .catch((err) => {
            console.log(err);
          });
      },
      getUserAssociationsByuserId({ commit }, payload) {
        axios({
          url: `${path}/member/${app.config.globalProperties.$keycloak.subject}`,
          method: "GET",
          params: {
            maxNumberOfItem: payload.maxNumberOfItem,
          },
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((associationList) => {
            console.log(associationList);
            commit("GET_ASSOCIATIONLIST", associationList);
          })
          .catch((err) => {
            console.log(err);
          });
      },
      getUserComemberList({ commit }) {
        axios({
          url: `${path}/memberList/${app.config.globalProperties.$keycloak.subject}`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((memberList) => {
            commit("GET_MEMBERLIST", memberList);
          })
          .catch((err) => {
            console.log(err);
          });
      },
      getUserActiveAssociationList({ commit }, payload) {
        axios({
          url: `${path}/member/active/${payload.userId}`,
          method: "GET",
          params: {
            maxNumberOfItem: payload.maxNumberOfItem,
          },
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((activeAssociationList) => {
            console.log(activeAssociationList);
            commit("GET_ACTIVEASSOCIATIONLIST", activeAssociationList);
          })
          .catch((err) => {
            console.log(err);
          });
      },

      editAssoImages({ commit }, payload) {
        axios({
          url: `${path}/image`,
          method: "PUT",
          data: payload,
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((association) => {
            commit("GET_ASSOCIATION", association);
          })
          .catch((err) => {
            console.log(err);
          });
      },
      deleteAssoImages({ commit }, payload) {
        axios({
          url: `${path}/image`,
          method: "DELETE",
          params: {
            imageId: payload.imageId,
            associationId: payload.entityId,
          },
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((association) => {
            commit("GET_ASSOCIATION", association);
          })
          .catch((err) => {
            console.log(err);
          });
      },
      addMember({ commit }, payload) {
        axios({
          url: `${path}/member`,
          method: "POST",
          params: {
            userId: payload.userId,
            associationId: payload.associationId,
          },
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((association) => {
            commit("GET_ASSOCIATION", association);
          })
          .catch((err) => {
            console.log(err);
          });
      },
      removeParticipant({ commit }, payload) {
        axios({
          url: `${path}/member`,
          method: "PUT",
          params: {
            userId: payload.userId,
            associationId: payload.entityId,
          },
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((association) => {
            commit("GET_ASSOCIATION", association);
          })
          .catch((err) => {
            console.log(err);
          });
      },
      changeCampaignBeneficiary({ commit }, payload) {
        axios({
          url: `${path}/campaign/change/beneficiary`,
          method: "PUT",
          data: payload,
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((association) => {
            commit("GET_ASSOCIATION", association);
          })
          .catch((err) => {
            console.log(err);
          });
      },
      changeHiddenMember({ commit }, payload) {
        axios({
          url: `${path}/campaign/change/hidden`,
          method: "PUT",
          data: payload,
          params: { campaignId: payload.id },
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((association) => {
            commit("GET_ASSOCIATION", association);
          })
          .catch((err) => {
            console.log(err);
          });
      },
      topupCampaignFromNewCards({ commit }, payload) {
        console.log(payload);
        axios({
          url: `${path}/card/top-up-campaign`,
          method: "POST",
          params: {
            userId: app.config.globalProperties.$keycloak.subject,
            campaignId: payload.campaignId,
            memberId: payload.memberId,
          },
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((association) => {
            commit("GET_ASSOCIATION", association);
          })
          .catch((err) => {
            console.log(err.response.data);
          });
      },

      topupCampaignFromBalance({ commit }, payload) {
        console.log(payload);
        axios({
          url: `${path}/account/top-up-campaign`,
          method: "POST",
          params: {
            userId: app.config.globalProperties.$keycloak.subject,
            amount: payload.amount,
            paymentCurrency: payload.paymentCurrency,
            campaignId: payload.campaignId,
            memberAccountNumber: payload.memberAccountNumber,
          },
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((association) => {
            commit("GET_ASSOCIATION", association);
          })
          .catch((err) => {
            console.log(err);
            console.log(err.response.data);
          });
      },

      redeemCampaign({ commit }, payload) {
        axios({
          url: `${path}/redeem/association/campaign`,
          method: "POST",
          params: {
            userId: app.config.globalProperties.$keycloak.subject,
            amount: payload.amount,
            campaignId: payload.campaignId,
            memberId: payload.memberId,
            beneficiaryAccountNumber: payload.beneficiaryAccountNumber,
          },
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((associationTransaction) => {
            commit("GET_ASSOCIATIONTRANSACTION", associationTransaction);
          })
          .catch((err) => {
            console.log(err);
          });
      },
      validateCampaignRedeem({ commit }, payload) {
        axios({
          url: `${path}/redeem/campaign/validate`,
          method: "POST",
          params: {
            userId: app.config.globalProperties.$keycloak.subject,
            transferCode: payload.transferCode,
            transferPIN: payload.transferPIN,
          },
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((associationTransaction) => {
            commit("GET_ASSOCIATIONTRANSACTION", associationTransaction);
          })
          .catch((err) => {
            console.log(err);
          });
      },

      getAssociationTransactionList({ commit }, payload) {
        axios({
          url: `${path}/transactions/campaignTransaction`,
          method: "GET",
          params: {
            userId: app.config.globalProperties.$keycloak.subject,
            associationId: payload.associationId,
            maxNumberOfItem: payload.maxNumberOfItem,
          },
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((associationTransactionList) => {
            commit(
              "GET_ASSOCIATIONTRANSACTION_LIST",
              associationTransactionList
            );
          })
          .catch((err) => {
            console.log(err);
          });
      },

      grantAdminRole({ commit }, payload) {
        axios({
          url: `${path}/grantAdminRole`,
          method: "POST",
          params: {
            userId: payload.userId,
            associationId: payload.entityId,
          },
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((association) => {
            commit("GET_ASSOCIATION", association);
            console.log(association);
          })
          .catch((err) => {
            console.log(err);
          });
      },
      withdrawAdminRole({ commit }, payload) {
        axios({
          url: `${path}/withdrawAdminRole`,
          method: "PUT",
          params: {
            userId: payload.userId,
            associationId: payload.entityId,
          },
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((association) => {
            commit("GET_ASSOCIATION", association);
            console.log(association);
          })
          .catch((err) => {
            console.log(err);
          });
      },

      readQRcode({ commit }, payload) {
        axios({
          url: `${path}/decodeQRCode`,
          method: "GET",
          params: {
            qrCodeText: payload,
          },
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((association) => {
            commit("READ_QRCODE", association);
            console.log(association);
          })
          .catch((err) => {
            console.log(err);
            console.log(err.response.data);
          });
      },
    },
  };
};
// export default asso;
