<template>
  <f7-page hide-bars-on-scroll ptr :ptr-mousewheel="true" ptr-bottom @ptr:refresh="loadMore" @page:init="pageInit()">
    <f7-navbar sliding no-shadow class="staticQrCodeNav">
      <f7-nav-left :back-link-show-text="false" :back-link="this.f7.width < 768 ? true : false"
        v-if="this.f7.width < 768">
      </f7-nav-left>
      <f7-nav-title>{{ $t("staticqrcodedetail.qrCode", { trx: this.staticQRCode.name })
        }}<f7-badge slot="media" color="auto" class="margin-left" style="height: 32px; width: 32px; font-size: 16px">
          n°{{ this.staticQRCode.qrNumber }}</f7-badge>
      </f7-nav-title>
      <f7-nav-right>
        <f7-link class="searchbar-enable" data-searchbar=".searchbar-staticQrCode" icon-ios="f7:search"
          icon-aurora="f7:search" icon-md="material:search"></f7-link>
      </f7-nav-right>
      <f7-searchbar class="searchbar-staticQrCode" :placeholder="$t('staticqrcodedetail.searchAUser')" expandable
        search-container=".paymentTransaction-list" search-in=".item-title, item-after, .item-footer"
        :disable-button="!theme.aurora" clear-button></f7-searchbar>
    </f7-navbar>
    <div style="padding-bottom: 80px; width: auto; max-width: 568px; margin: auto">
      <f7-list dividers-ios strong-ios class="searchbar-not-found">
        <f7-list-item :title="$t('common.nothingFound')" style="font-size: 18px; color: red"></f7-list-item>
      </f7-list>

      <f7-list dividers-ios strong-ios class="paymentTransaction-list searchbar-found">
        <f7-photo-browser :photos="[`data:image/png;base64,${this.staticQRCode.qrCode}`]"
          ref="popup"></f7-photo-browser>
        <a link="#" @click="$refs.popup.open()">
          <f7-card-content :padding="false">
            <img :src="`data:image/png;base64,${this.staticQRCode.qrCode}`" class="QrCode margin-vertical" alt="QR Code"
              style="display: block; margin: auto" />
          </f7-card-content>
        </a>
        <f7-card-footer>
          <f7-link @click="saveFile(fileToSave)" icon-aurora="f7:square_arrow_down" icon-ios="f7:square_arrow_down"
            icon-md="material:save"></f7-link>

          <f7-link @click="socialShare(sharedContent)" icon-aurora="f7:square_arrow_up" icon-ios="f7:square_arrow_up"
            icon-md="material:share"></f7-link>
        </f7-card-footer>

        <f7-card-header>
          <f7-block-title medium>{{
            $t("staticqrcodedetail.qrCodeUsers")
          }}</f7-block-title>
          <div></div>
          <f7-badge v-if="this.profile.sub === this.staticQRCode.userId" bg-color="red" :style="{
            position: 'absolute',
            right: '16px',
            'border-radius': '50px',
            height: '32px',
            width: '32px',
          }" class="addParticipantToolTip">
            <f7-link icon-aurora="f7:person_crop_circle_fill_badge_plus"
              icon-ios="f7:person_crop_circle_fill_badge_plus" icon-md="material:person_add" color="white" href="#"
              panel-open=".addNewQRCodeUser"></f7-link>
          </f7-badge>
        </f7-card-header>
        <f7-list-item no-chevron :swipeout="this.profile.sub === this.staticQRCode.userId ? true : false" link="#"
          v-for="(qrUser, index) in staticQRCode.staticQrCodeUserList" :key="index" v-if="qrUser !== null"
          :title="qrUser.name" :after="qrUser.phoneNumber" :footer="qrUser.email" @clik="checkRemoveuser(qrUser)">
          <f7-swipeout-actions right v-if="qrUser.userId !== staticQRCode.userId">
            <f7-swipeout-button @click="removeQrCodeUser(qrUser)" color="red" overswipe>{{
              $t("staticqrcodedetail.removeAsUser", { removeUser: qrUser.name })
            }}</f7-swipeout-button> </f7-swipeout-actions><template #media>
            <f7-badge v-if="qrUser.name" color="auto" style="height: 32px; width: 32px; font-size: 16px">
              {{ $filtersGetInitial.getInitial(qrUser.name) }}</f7-badge>
          </template>
        </f7-list-item>
      </f7-list>
      <f7-card outline><f7-card-header>{{ $t("staticqrcodedetail.totalTransactions") }}:
          {{
            $filtersToCurrency.toCurrency(
              staticQRCode.totalAmount,
              null,
              staticQRCode.currencyCode
            )
          }}</f7-card-header>
        <getTransactions :transactionList="this.staticQRCode.paymentTransactionList"
          v-if="this.staticQRCode.paymentTransactionList > 0"></getTransactions>
      </f7-card>
    </div>
    <f7-panel right cover :backdrop="false" class="addNewQRCodeUser" :style="{
      width: f7.width >= 768 ? '418px' : '100%',
      'min-width': f7.width >= 768 ? '418px' : '100%',
      'border-right':
        'var(--f7-page-master-border-width) solid var(--f7-page-master-border-color)',
    }">
      <f7-navbar no-shadow>
        <f7-nav-left>
          <f7-link panel-close>
            <f7-icon ios="f7:multiply" aurora="f7:multiply" md="material:close"></f7-icon>
          </f7-link>
        </f7-nav-left>
        <div class="title">{{ $t("staticqrcodedetail.addQrCodeUser") }}</div>
      </f7-navbar>

      <f7-page>
        <f7-block-title large class="padding-left">{{
          $t("staticqrcodedetail.addQrCodeUser")
        }}</f7-block-title>
        <userslist> </userslist>
        <f7-toolbar tabbar bottom style="position: fixed; bottom: 0" bg-color="red" v-if="
          this.searchedUser &&
          this.searchedUser.userId !== this.profile.sub &&
          !this.staticQRCode.staticQrCodeUserList.find(
            (user) => user !== null && serId === this.searchedUser.userId
          )
        ">
          <f7-link raised fill class="link buttons" large text-color="white" strong :text="$t('staticqrcodedetail.addAsuser', {
            asUser: this.searchedUser.name,
          })
            " @click="addQrCodeUser(searchedUser)"></f7-link>
        </f7-toolbar>
        <f7-chip class="margin" outline style="display: flex; justify-content: center" v-if="
          this.searchedUser &&
          this.staticQRCode.staticQrCodeUserList.find(
            (user) => user !== null && serId === this.searchedUser.userId
          )
        " color="red">{{
            $t("staticqrcodedetail.isAlreadyAUser", {
              isUser: this.searchedUser.name,
            })
          }}</f7-chip>
      </f7-page>
    </f7-panel>
    <template #fixed>
      <f7-fab position="right-bottom" class="shareButton" color="auto" @click="socialShare(sharedContent)">
        <f7-icon aurora="f7:square_arrow_up" ios="f7:square_arrow_up" md="material:share"></f7-icon>
      </f7-fab>
    </template>
  </f7-page>
</template>
<style scoped>
.transactionValidatedfalse {
  border: 1px orange solid;
}
</style>

<script>
import {
  f7Page,
  f7Navbar,
  f7NavLeft,
  f7NavRight,
  f7NavTitle,
  f7Link,
  f7Searchbar,
  f7List,
  f7ListItem,
  f7PhotoBrowser,
  f7CardContent,
  f7CardFooter,
  f7CardHeader,
  f7BlockHeader,
  f7Badge,
  f7SwipeoutActions,
  f7SwipeoutButton,
  f7AccordionContent,
  f7ListGroup,
  f7ListInput,
  f7Button,
  f7Panel,
  f7Icon,
  f7BlockTitle,
  f7Toolbar,
  f7Chip,
  f7Fab,
  theme,
  f7,
} from "framework7-vue";
import { mapGetters, mapState } from "vuex";
import Navbarright from "../components/navbarright.vue";
import getTransactions from "../components/modules/transactions.vue";
// import { transferAgain } from "../js/mixins/transaction.js";
import { keyPadMixin } from "../js/mixins/keyPadMixin.js";
import { socialShare } from "../js/mixins/cordovaPlugins.js";
import UsersPage from "./users.vue";
import _ from "lodash";
export default {
  props: {
    f7route: Object,
    f7router: Object,
  },
  components: {
    getTransactions,
    navbarright: Navbarright,
    userslist: UsersPage,
    f7Page,
    f7Navbar,
    f7NavLeft,
    f7NavRight,
    f7NavTitle,
    f7Link,
    f7Searchbar,
    f7List,
    f7ListItem,
    f7PhotoBrowser,
    f7CardContent,
    f7CardFooter,
    f7CardHeader,
    f7BlockHeader,
    f7Badge,
    f7SwipeoutActions,
    f7SwipeoutButton,
    f7AccordionContent,
    f7ListGroup,
    f7ListInput,

    f7Button,
    f7Panel,
    f7Icon,
    f7BlockTitle,
    f7Toolbar,
    f7Chip,
    f7Fab,
  },
  mixins: [keyPadMixin, socialShare],
  data() {
    return {
      theme,
      f7,
      amountToPay: "",
      currentStaticCode: "",
      visibleItem: 25,
      transferPIN: "",
      detailPageWidth: "",
      sharedContent: "",
      fileToSave: [],
    };
  },

  mounted() {
    if (!this.userAccount && this.$keycloak.authenticated) {
      this.$store.dispatch("account/loadAccount", this.profile.sub);
    }
    const root = document.documentElement;
    root.style.setProperty("--f7-panel-shadow", "transparent");
    if (f7.width >= 768) {
      this.detailPageWidth = `${f7.width * 0.4}px`;
      root.style.setProperty("--f7-panel-width", this.detailPageWidth);
    } else {
      this.detailPageWidth = `${f7.width}px`;
    }
    if (this.staticQRCode) {
      this.share();
    }

    // self.$store.dispatch("payment/getQrTransctionList", {
    //   id: this.staticQRCode.id || this.f7route.params.id,
    //   maxNumberOfItem: this.visibleItem,
    // });
  },
  methods: {
    updateQrAmount(staticCode) {
      this.amountToPay == f7.$(`#${staticCode.id}`).val();
      this.$store.dispatch("payment/updateQRCodeAmount", {
        id: staticCode.id,
        amount: staticCode.amount,
        geolocation: `${this.latitude}-${this.longitude}`,
        ipAddress: this.ipAddress,
      });
    },

    editOrCancel(staticCode) {
      this.$store.dispatch("payment/cancelQRAmount", staticCode.id);
    },

    async addQrCodeUser(searchedUser) {
      console.log("searchedUser", searchedUser);
      await this.$store.dispatch("payment/addQrCodeUser", {
        userId: searchedUser.userId,
        id: this.staticQRCode.id,
      });
      f7.panel.close(".addNewQRCodeUser");
    },
    async removeQrCodeUser(qrCodeUser) {
      await this.$store.dispatch("payment/removeQrCodeUser", {
        userId: qrCodeUser.userId,
        id: this.staticQRCode.id,
      });
    },
    checkRemoveuser(qrUser) {
      f7.dialog.confirm(
        this.$t("staticqrcodedetail.removeAsUser", { removeUser: qrUser.name }),
        function () {
          this.removeQrCodeUser(qrUser);
        }
      );
    },
    loadMore(done) {
      this.visibleItem += 25;
      setTimeout(() => {
        this.$store.dispatch("payment/getStaticQrCode", {
          id: this.f7route.params.id || this.staticQRCode.id,
        });
        this.$store.dispatch("payment/getQrTransctionList", {
          id: this.staticQRCode.id || this.f7route.params.id,
          maxNumberOfItem: this.visibleItem,
        });
        done();
      }, 1000);
    },
    pageInit() {
      this.$store.dispatch("payment/getStaticQrCode", {
        id: this.f7route.params.id || this.staticQRCode.id,
      });
      setTimeout(() => {
        this.share();
      }, 500);
    },

    async share() {
      const files = [];
      const base64Files = [];
      let file = "";
      if (this.staticQRCode && this.staticQRCode.qrCode !== "") {
        const base64Url = `data:image/png;base64,${this.staticQRCode.qrCode}`;
        const blob = await (
          await fetch(`data:image/png;base64,${this.staticQRCode.qrCode}`)
        ).blob();
        // const blob = await (await fetch(base64Url)).blob();
        const termination = blob.type.split("/")[1];
        file = new File(
          [blob],
          `${this.staticQRCode.name}-${this.staticQRCode.qrNumber}.${termination}`,
          { type: blob.type }
        );
        this.fileToSave.push(file);
        files.push(file);
        base64Files.push(`data:image/png;base64,${this.staticQRCode.qrCode}`);
      }

      this.sharedContent = {
        message: `${this.staticQRCode.name} n°${this.staticQRCode.qrNumber}`,
        subject: this.staticQRCode.name,
        base64Files,
        files,
        // url: `https://avocash.com/#/static-QrCode/${this.staticQRCode.id}`,
        link: `${this.f7router.currentRoute.path}`,
        short: false,
        linkBase: "links",
        imgUrl: "https://avocash.com/34x34.png",
      };

      // self.$store.dispatch("auth/createDynamicLink", this.sharedContent);
    },
  },

  computed: {
    ...mapState("payment", [
      "vendorQRCodeList",
      "decodedQRCode",
      "QRCode",
      "paymentError",
      "qrCodeUser",
      "qrTransaction",
      "allQrTransactions",
      "qrUser",
      "staticQRCode",
    ]),

    ...mapState("transaction", [
      "destinationCountry",
      "destinationCity",
      "grossexchangerate",
      "transferDetail",
      "fromCurrencyRate",
      "toCurrencyRate",
      "transferDetail",
      "paymentDetail",
      "paymentReverseDetail",
    ]),

    ...mapState("location", [
      "destinationCity",
      "country",
      "countryStates",
      "countryCities",
      "currentUserCountry",
      "latitude",
      "longitude",
      "ipAddress",
    ]),
    ...mapState("account", ["userAccount", "userDetail", "users", "searchedUser"]),
    ...mapState("auth", ["profile", "isDeskTop", "isTablet", "dynamicLink"]),
    ...mapState("transaction", ["destinationCountry", "originCountry"]),

    ...mapGetters("auth", [
      "getProfile",
      "isAuthenticated",
      "isProfileLoaded",
      "getusererror",
    ]),
    ...mapGetters("transaction", [
      "getusererror",
      "fromCurrencyRate",
      "toCurrencyRate",
      "grossexchangerate2",
    ]),

    ...mapGetters("remit", ["recipients"]),
    ...mapGetters("account", ["userByAccountNumber"]),
    // staticQRCode() {
    //   const self = this;
    //
    //   const id = this.f7route.params.id;
    //   for (var i = 0; i < this.vendorQRCodeList.length; i += 1) {
    //     if (this.vendorQRCodeList[i].id == id) {
    //       return this.vendorQRCodeList[i];
    //     }
    //   }
    // },

    destinationCtry() {
      return _.groupBy(this.country, "continentName");
    },
  },
};
</script>
