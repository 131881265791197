<template>
  <f7-link @click="scanQRCode()" icon-aurora="f7:qrcode_viewfinder" icon-ios="f7:qrcode_viewfinder"
    icon-md="material:qr_code_scanner">
  </f7-link>
</template>
<script>
import { f7Link, f7, theme } from "framework7-vue";
import { scanQRCodeGeneral } from "../js/mixins/scanPayQRCode.js";
import { mapGetters, mapState } from "vuex";
import _ from "lodash";
export default {
  props: {
    f7route: Object,
    f7router: Object,
  },
  components: { f7Link },
  mixins: [scanQRCodeGeneral],

  data() {
    return { theme, f7 };
  },
  // mounted() {},

  // methods: {},

  computed: {
    ...mapState("account", ["userAccount"]),
    ...mapGetters("auth", ["getProfile", "isAuthenticated", "isProfileLoaded"]),
    ...mapState("auth", ["profile"]),
  },
};
</script>
