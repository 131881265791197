import { f7, theme } from "framework7-vue";
import intlTelInput from "intl-tel-input";
import "intl-tel-input/build/css/intlTelInput.min.css";
import "intl-tel-input/build/js/intlTelInput.js";
import { mapGetters, mapState } from "vuex";
export const telephoneUtils = {
  data() {
    return {
      theme,
      f7,
      telephone: "",
      // selectedCountry: "",
      // itiPhoneNumber: "",
    };
  },
  mounted() {
    const self = this;
    this.selectedCountry = this.$keycloak.authenticated
      ? this.profile.countryCode
      : this.currentUserCountry.countryCode2;
    console.log("this.selectedCountry", this.selectedCountry);
    const phoneNumberInput = document.querySelector("#phoneNumber");
    if (this.selectedCountry) {
      this.itiPhoneNumber = intlTelInput(phoneNumberInput, {
        separateDialCode: true,
        nationalMode: true,
        initialCountry: this.selectedCountry,
        localizedCountries: this.selectedCountry,
        dropdownContainer: document.body,
        autoInsertDialCode: true,
        preferredCountries: [],
        utilsScript:
          "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/18.1.6/js/utils.js",
      });
      console.log(this.itiPhoneNumber);
      window.intlTelInputGlobals.loadUtils(
        "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/18.1.6/js/utils.js"
      );

      phoneNumberInput.addEventListener("blur, change, keyup", function () {
        if (this.itiPhoneNumber.isValidNumber()) {
          this.telephone = this.itiPhoneNumber.getNumber(
            intlTelInputUtils.numberFormat.NATIONAL
          );
          console.log(
            "Intern telephone ",
            this.itiPhoneNumber.getNumber().replace("+", "")
          );
        } else {
          console.log("phone number not valid");
        }
      });
      if (this.itiPhoneNumber !== "") {
        this.itiPhoneNumber.setCountry(this.selectedCountry);
        this.changePhoneNumber();
      }
    }
    const smartSelect = f7.smartSelect.get(".selectCountry .smart-select");
    f7.$(".iti__flag-container").click(function () {
      smartSelect.open();
      if (self.telephone !== null) {
        self.telephone = null;
      }
      smartSelect.setValue(this.selectedCountry);
    });
    f7.$(".selectCountry .smart-select").on("smartselect:open", () => {
      self.telephone = null;
      self.selectedCountry = null;
    });
    f7.$(".selectCountry .smart-select").on("smartselect:close", function () {
      this.selectedCountry = smartSelect.getValue();
      console.log(this.selectedCountry);
      self.itiPhoneNumber.setCountry(this.selectedCountry);
      console.log("self.itiPhoneNumber", self.itiPhoneNumber);
      self.changePhoneNumber();
    });
  },
  methods: {
    async changePhoneNumber() {
      if (this.telephone && this.telephone.length > 6) {
        await this.$store.dispatch("location/checkMobilePhone", {
          phone: this.telephone,
          countryCode2: this.selectedCountry,
        });
      }
    },
  },
  computed: {
    ...mapState("location", [
      "destinationCity",
      "country",
      "countryStates",
      "countryCities",
      "currentUserCountry",
    ]),
    ...mapState("auth", ["phoneNumberDetail", "mobilePhoneError", "profile"]),
    countries() {
      return _.groupBy(this.country, "continentName");
    },
    userPhoneNumber() {
      const usrPhone = this.itiPhoneNumber.getNumber(
        intlTelInputUtils.numberFormat.E164
      );
      return usrPhone.replace("+", "");
    },
  },
};
