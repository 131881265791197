<template>
  <f7-page hide-navbar-on-scroll ptr-bottom ptr @ptr:refresh="loadMore" @page:beforeout="beforeOut">
    <f7-navbar sliding no-shadow>
      <f7-nav-left :back-link="$t('common.backButton')" :back-link-show-text="false"></f7-nav-left>
      <f7-nav-title sliding>
        {{ this.currentStore.storeName }}
        {{ this.currentStore.description }}
      </f7-nav-title>
      <f7-nav-right>
        <f7-link class="searchbar-enable" data-searchbar=".searchbar-item" icon-aurora="f7:search_strong"
          icon-ios="f7:search_strong" icon-md="material:search"></f7-link>
        <f7-link href="/cart/" v-if="this.cart && this.cart.length">
          <f7-icon ios="f7:cart" aurora="f7:cart" md="material:shopping_cart" color="none">
            <f7-badge bg-color="none" class="text-align-center"
              style="font-size: 50%; margin-left: -23px; top: -5px; font-weight: bold" text-color="red"
              v-if="this.cart && this.cart.length > 0 && this.totalItemCount > 9">{{ this.totalItemCount }}</f7-badge>
            <f7-badge bg-color="none" class="text-align-center"
              style="font-size: 50%; margin-left: -20px; top: -5px; font-weight: bold" text-color="red"
              v-if="this.cart && this.cart.length > 0 && this.totalItemCount < 10">{{ this.totalItemCount }}</f7-badge>
          </f7-icon>
          <!-- <navbarright></navbarright> -->
        </f7-link>
      </f7-nav-right>

      <f7-searchbar class="searchbar-item" expandable :value="storeItem" @input="storeItem = $event.target.value"
        search-container=".search-list" search-item="div.searchList" search-in=".itemTags"
        :placeholder="$t('storedetail.searchItem')" :clear-button="true" :disable-button="!theme.aurora"></f7-searchbar>
    </f7-navbar>

    <f7-card expandable swipe-to-close card-tablet-fullscreen expandable-animate-width="true" v-if="!this.storeItem">
      <f7-card-content :padding="false">
        <f7-card-header :style="{ height: '60px' }">{{
          this.currentStore.storeName
          }}</f7-card-header>
        <div :style="{
          background:
            'url(' + this.selectedStore[0].ImageSrc ||
            this.selectedStore[1].ImageSrc + ') no-repeat center bottom',
          'background-size': 'cover',
          height: '240px',
        }"></div>
        <f7-link card-close color="inherit" class="card-opened-fade-in"
          :style="{ position: 'absolute', right: '15px', top: '15px' }" icon-f7="multiply"></f7-link>

        <div class="card-content-padding">
          <p>{{ this.currentStore.description }}</p>
        </div>
      </f7-card-content>
    </f7-card>

    <f7-block-title style="font-weight: bold" divider v-if="!this.storeItem">{{
      $t("storedetail.todaysDeals")
      }}</f7-block-title>
    <swiper-container v-if="!this.storeItem" :speed="500" :slides-per-view="3" :space_between="5" :loop="true"
      :autoplay="true" :delay="3000" :disableOnInteraction="true">
      <swiper-slide v-for="(item, index) in this.selectedStore.slice(0, visibleItem)" :key="index"
        class="dealsContainer">
        <a class="text-color-black" :href="itemUrl(item)" style="max-height: 200px; width: 100%">
          <img :src="item.ImageSrc" v-if="!!item.ImageSrc" style="max-height: 150px; object-fit: cover" />

          <div class="no-margin-vertical margin-horizontal-half" style="font-size: 75%; height: 50px">
            <p class="no-margin-vertical">
              <b>{{ item.Title }}</b>
            </p>
            <p class="no-margin-top">
              <b>
                <sup style="color: red; font-size: 90%">
                  €{{ (item["Variant Price"] * 0.8).toFixed(2) }} EUR</sup>
              </b>
            </p>
          </div>
        </a>
        <div class="grid cols30_70">
          <f7-link icon-ios="f7:cart_badge_plus" icon-aurora="f7:cart_badge_plus" icon-md="material:add_shopping_cart"
            color="auto" @click="addToCart(item, 1)"></f7-link>
          <f7-button large small raised fill color="red" style="font-size: 75%" @click="buyNow(item, 1)">{{
            $t("storedetail.buyNow") }}</f7-button>
        </div>
      </swiper-slide>
    </swiper-container>

    <f7-block-title style="font-weight: bold">{{
      $t("storedetail.viewProducts")
      }}</f7-block-title>
    <f7-list dividers-ios strong-ios class="searchbar-not-found">
      <f7-list-item :title="$t('storedetail.noSuchItemFound')"></f7-list-item>
    </f7-list>
    <f7-list dividers-ios strong-ios class="search-list searchbar-found no-margin-top" no-chevron>
      <span class="itemImageContainer searchList grid grid-cols-2 medium-grid-cols-3">
        <f7-card v-for="(item, index) in this.selectedStore.slice(0, visibleItem)" :key="index">
          <f7-card-header style="font-size: 80%">{{ item.Title }}</f7-card-header>
          <f7-card-content :padding="false">
            <a class="text-color-black" :href="itemUrl(item)" style="height: 300px; width: 100%"><span
                style="height: 200px; width: 100%">
                <img :src="item.ImageSrc" class="itemIamge no-margin-vertical" v-if="!!item.ImageSrc"
                  style="max-height: 200px" />
              </span>
              <!-- style="height: 100%; max-height: 100%; max-width: none" -->
              <f7-list-group>
                <!-- <p class="itemTags padding-left">
                    <b>{{ item.Title }}</b>
                    <i>by {{ item.Vendor }}</i>
                  </p> -->
                <p class="padding-left">
                  <b>€{{ item["Variant Price"] }} EUR</b>
                </p>
                <f7-list-item :style="{ 'font-size': isTablet ? '100%' : '80%' }" divider
                  :title="$t('storedetail.communityBuy')"></f7-list-item>
                <li v-for="(offer, value, index) in currentStore.socBuy" :key="index + 1">
                  <div class="item-content">
                    <div class="item-media">
                      <f7-badge style="width: 24px; height: 24px" slot="media" color="primary"
                        class="no-padding no-margin">{{ value }}+</f7-badge>
                    </div>
                    <div class="item-inner no-padding-vertical">
                      <div class="item-after" style="font-size: 80%">
                        <sup>€</sup>
                        {{
                          parseFloat(
                            (item["Variant Price"] * (100 - offer)) / 100
                          ).toFixed(2)
                        }}
                        EUR
                      </div>
                    </div>
                  </div>
                </li>
                <!-- <f7-list-item
                  no-chevron

                  v-for="(offer, value, index) in currentStore.socBuy"
                  :key="index + 1"
                >
                  <f7-badge
                    slot="media"
                    color="primary"
                    class="no-padding no-margin"
                    >{{ value }}</f7-badge
                  >
                  <h5 slot="after" class="no-padding no-margin">
                    <sup>€</sup>
                    {{
                      parseFloat(
                        (item["Variant Price"] * (100 - offer)) / 100
                      ).toFixed(2)
                    }}
                    EUR
                  </h5>
                </f7-list-item> -->
              </f7-list-group>
            </a>
          </f7-card-content>
        </f7-card>
      </span>
    </f7-list>
    <!-- <f7-button
      @click="loadMore()"
      v-if="this.selectedStore && this.visibleItem < this.selectedStore.length"
      class="text-align-center"
    >
      <i>{{ $t('storedetail.loadMore') }}</i>
    </f7-button> -->

    <div class="ptr-preloader">
      <div class="preloader"></div>
      <div class="ptr-arrow"></div>
    </div>
  </f7-page>
</template>
<style scoped>
.demo-card-header-pic .card-header {
  height: 100px;
  background-size: cover;
  background-position: center;
  color: #fff;
}

.demo-card-header-pic .card-content-padding .date {
  color: #8e8e93;
}

.itemImageContainer img {
  height: "auto";
  margin: auto;
  display: block;
  width: 100%;
  object-fit: cover;
  /* position: relative;
  background-repeat: no-repeat;
  background-size: cover; */
}

.dealsContainer img {
  /* height: 125px; */
  margin: auto;
  display: block;
  width: 100%;
  /* position: relative;
  background-repeat: no-repeat;
  background-size: cover; */
}

/* .dealsContainer {
  height: "auto";
  width: 100%;
} */

/* .itemIamge {
  position: relative;
  width: 100px;
  height: 100px;
  background-repeat: no-repeat;
  background-size: cover;
}*/
</style>
<script>
import { f7, theme } from "framework7-vue";
import Navbarright from "../components/navbarright.vue";
import apparel from "../js/data/apparel.json";
import apparel2 from "../js/data/apparel2.json";
import homeandgarden from "../js/data/homeandgarden.json";
import bicycles from "../js/data/bicycles.json";
import fashion from "../js/data/fashion.json";
import jewelry from "../js/data/jewelry.json";
// import jewelry2 from "../js/data/jewelry2.json";
import snowdevil from "../js/data/snowdevil.json";

import { mapGetters, mapState } from "vuex";
import { shopping } from "../js/mixins/shopping.js";

import _ from "lodash";
export default {
  props: {
    f7route: Object,
    f7router: Object,
  },
  components: {
    navbarright: Navbarright,
  },
  mixins: [shopping],
  data() {
    return {
      theme,
      f7,
      theme,
      title: "<span class='avocash'>avo¢ash</span> stores",
      quantity: "",
      storeItem: "",
      visibleItem: 8,
      storeId: "",
      apparel: apparel,
      apparel2: apparel2,
      bicycles: bicycles,
      fashion: fashion,
      jewelry: jewelry,
      // jewelry2: jewelry2,
      homeandgarden: homeandgarden,
      snowdevil: snowdevil,
      currencySymbol: "€",
      avocashStores: [
        {
          storeId: 1,
          storeName: "HeShop",
          storeSource: apparel,
          description: "Men shop",
          socBuy: {
            5: 3,
            10: 5,
            15: 7.5,
            20: 10,
          },
        },
        {
          storeId: 2,
          storeName: "Weshop",
          storeSource: apparel2,
          description: "Men & Women shop",
          socBuy: {
            5: 5,
            10: 7,
            15: 10,
            20: 12.5,
          },
        },
        {
          storeId: 3,
          storeName: "Velo",
          storeSource: bicycles,
          description: "bicycles",
          socBuy: {
            5: 2.5,
            10: 4,
            15: 7,
            20: 10,
          },
        },
        {
          storeId: 4,
          storeName: "Fashionist",
          storeSource: fashion,
          description: "Men & Women shop",
          socBuy: {
            5: 5,
            10: 8,
            15: 10,
            20: 12.5,
          },
        },
        {
          storeId: 5,
          storeName: "BijouX",
          storeSource: jewelry,
          description: "Lux jewelry",
          socBuy: {
            5: 2.5,
            10: 4,
            15: 7,
            20: 10,
          },
        },
        // {
        //   storeId: 6,
        //   storeName: "Accessoires",
        //   storeSource: jewelry2,
        //   description: "Fashion accessoiries"
        // },
        {
          storeId: 7,
          storeName: "Home&Garden",
          storeSource: homeandgarden,
          description: "Home and garden",
          socBuy: {
            5: 5,
            10: 8,
            15: 10,
            20: 12.5,
          },
        },
        {
          storeId: 8,
          storeName: "skiHigh",
          storeSource: snowdevil,
          description: "Ski wear & accessories",
          socBuy: {
            5: 5,
            10: 7,
            15: 10,
            20: 12.5,
          },
        },
      ],
    };
  },
  mounted() {
    this.storeId = this.f7route.params.storeId;
  },
  methods: {
    loadMore(done) {
      setTimeout(() => {
        this.visibleItem += 8;
        done();
      }, 1000);
    },

    itemUrl(item) {
      return `/itemDetail/${this.f7route.params.storeId}/${item.Handle}`;
    },
    beforeOut() {
      f7.$(".mainToolbar").css("display", "block");
    },
  },

  computed: {
    ...mapState("shoppingCart", ["cart", "cartItemCount"]),
    ...mapState("auth", ["isTablet", "isDeskTop", "profile"]),
    ...mapGetters("shoppingCart", ["totalCartAmount", "totalItemCount"]),
    ...mapGetters("auth", [
      "getProfile",
      "isAuthenticated",
      "isProfileLoaded",
      "getusererror",
    ]),

    selectedStore() {
      if (this.currentStore) {
        return _.chain(this.currentStore.storeSource)
          .filter((o) => o.Title !== "")
          .reject(["ImageSrc", null])
          .shuffle()
          .value();
      }
    },
    currentStore() {
      const id = this.f7route.params.storeId;
      for (var i = 0; i < this.avocashStores.length; i += 1) {
        if (this.avocashStores[i].storeId == id) {
          return this.avocashStores[i];
        }
      }
    },
  },
};
</script>
