<template>
  <f7-page hide-bars-on-scroll :no-toolbar="this.isTablet == true ? false : true" :page-content="false">
    <!-- @page:afterout="afterOut()" -->

    <f7-navbar sliding no-shadow>
      <f7-nav-left :back-link="$t('common.backButton')" :back-link-show-text="false">
      </f7-nav-left>
      <f7-nav-title>
        {{ this.groupBuy.name }}
      </f7-nav-title>
      <f7-nav-right v-if="this.$keycloak.authenticated && this.currentParticipantIsAdmin">
        <f7-link icon-aurora="f7:person_crop_circle_fill_badge_plus" icon-ios="f7:person_crop_circle_fill_badge_plus"
          icon-md="material:person_add" color="red" href="#" popup-open=".addNewMember"></f7-link>
        <f7-link icon-aurora="f7:pencil" icon-ios="f7:pencil" icon-md="material:edit"
          :href="'/groupBuy/edit/' + this.groupBuy.id"></f7-link>
      </f7-nav-right>
    </f7-navbar>
    <f7-toolbar tabbar top :scrollable="this.groupBuy.projectList.length > 3 ? true : false" no-shadow
      class="detailToolbar" v-if="this.groupBuy">
      <f7-link tab-link="#mainPage" tab-link-active :text="this.groupBuy.name">
        <img v-if="this.groupBuy.logo" :src="`${this.groupBuy.logo}`" width="40" height="40" slot="media"
          style="border-radius: 50%" class="margin-left-half" />
        <f7-badge v-else color="auto" slot="media" class="margin-left-half" :style="{
          height: theme.md ? '27px' : '27px',
          width: theme.md ? '27px' : '27px',
          'min-width': theme.md ? '27px' : '27px',
          'border-radius': '50%',
        }">{{ $filtersGetInitial.getInitial(groupBuy.name) }}</f7-badge>
      </f7-link>
      <f7-link v-if="
        this.groupBuy &&
        this.groupBuy.projectList &&
        this.groupBuy.projectList.length > 0
      " v-for="(project, index) in this.groupBuy.projectList" :key="index + 1" :text="project.name"
        :tab-link="`#tab-${project.projectNumber}`" @tab:show="selectProject(project)">
        <f7-badge slot="media" color="auto" :style="{
          height: theme.md ? '27px' : '27px',
          width: theme.md ? '27px' : '27px',
          'min-width': theme.md ? '27px' : '27px',
          'border-radius': '50%',
        }">{{ project.projectNumber }}</f7-badge>
      </f7-link>
    </f7-toolbar>

    <f7-tabs swipeable class="detailTabs">
      <f7-tab id="mainPage" class="page-content" style="padding-bottom: 120px !important" tab-active>
        <div class="layout_content">
          <div class="left_layout">
            <div class="sticky with_content">
              <f7-card outline header-divider v-if="
                this.groupBuy &&
                this.$keycloak.authenticated &&
                this.currentParticipant &&
                this.groupBuy.adminList !== null
              ">
                <f7-card-header>
                  <div>{{ $t("common.participants") }}</div>
                  <div></div>
                  <f7-badge v-if="currentParticipantIsAdmin" bg-color="red" :style="{
                    position: 'absolute',
                    right: '16px',
                    top: '8px',
                    'border-radius': '50px',
                    height: '32px',
                    width: '32px',
                    'min-width': '32px',
                  }" class="elevation-3">
                    <f7-link icon-aurora="f7:person_crop_circle_fill_badge_plus"
                      icon-ios="f7:person_crop_circle_fill_badge_plus" icon-md="material:person_add" color="white"
                      href="#" popup-open=".addNewMember"></f7-link>
                  </f7-badge>
                </f7-card-header>

                <membersComponent :entityId="this.groupBuy.id" :memberList="this.groupBuy.customerList"
                  :adminList="groupBuy.adminList" :entityType="'groupBuy'" :isAdmin="currentParticipantIsAdmin"
                  :mainUserId="groupBuy.groupOwnerUserId"></membersComponent>
              </f7-card>
            </div>
          </div>
          <div class="main_layout">
            <f7-card v-if="!this.groupBuy" class="skeleton-text skeleton-effect-blink elevation-3">
              <f7-card-header class="no-border">
                <div class="demo-facebook-avatar padding-right">
                  <f7-badge slot="media" color="gray"
                    style="height: 40px; width: 40px; font-size: 16px; border-radius: 50%">GT</f7-badge>
                </div>
                <div class="tontineTitle demo-facebook-name">Savings name</div>
                <div class="demo-facebook-date tontineTitle">Savings tag</div>
              </f7-card-header>
              <f7-card-content style="width: auto; height: 400px">
                <div class="timeline">
                  <div class="timeline-item" v-for="x in 6" :key="x">
                    <div class="timeline-item-date">2021-11>2022-12</div>
                    <div class="timeline-item-divider"></div>
                    <div class="timeline-item-content">
                      <div class="timeline-item-title">Savings name</div>
                      <div class="timeline-item-inner">
                        Collected
                        <span style="font-weight: bold; color: green"> 250 EUR</span><br />Balance
                        <span style="font-weight: bold"> 200 EUR </span>
                      </div>
                    </div>
                  </div>
                </div>
              </f7-card-content>
              <f7-list dividers-ios strong-ios accordion-list media-list>
                <f7-list-item accordion-item :title="$t('common.description')">
                  <f7-accordion-content>
                    <f7-block> </f7-block>
                  </f7-accordion-content>
                </f7-list-item>
              </f7-list>
              <f7-button fill large style="border-radius: 0px" color="gray" class="buttons">{{ $t("common.seeDetail")
                }}</f7-button>
            </f7-card>
            <f7-card class="demo-facebook-card" v-else>
              <f7-card-content :padding="false" v-if="this.groupBuy">
                <swiper-container slidesPerView="1" :spaceBetween="0" :disableOnInteraction="true">
                  <swiper-slide v-if="
                    this.groupBuy.projectList && this.groupBuy.projectList.length > 0
                  " class="align-content-center" :style="{
                      background:
                        'linear-gradient(90deg, rgba(131,58,180,1) 0%, rgba(29,253,36,1) 50%, rgba(252,176,69,1) 100%)',

                      height: '400px',
                    }">
                    <div class="timeline timeline-horizontal grid grid-cols-1 medium-grid-cols-2">
                      <div v-for="project in this.groupBuy.projectList" href="#" :key="project.id"
                        class="timeline-item">
                        <div class="timeline-item-date" @click="selectProject(project)">
                          <f7-badge slot="media" color="auto" width="24" height="24px"
                            style="height: 20px; width: 20px; font-size: 12px">{{ project.projectNumber }}</f7-badge>
                          {{ project.name }}
                        </div>
                        <div class="timeline-item-content" style="height: max-content">
                          <div class="timeline-item-inner">
                            <div class="timeline-item-title" style="font-weight: bold; color: auto">
                              {{ $t("common.contribution") }}:
                              {{
                                $filtersToCurrency.toCurrency(
                                  projectContribution(project),
                                  null,
                                  project.currencyCode
                                )
                              }}
                            </div>
                            <span class="timeline-item-subtitle" v-if="projectContribution(project) > 0">{{
                              $t("common.fundingTarget") }}:
                              <f7-badge color="green">{{
                                (parseFloat(project.fundingTarget) *
                                  project.participantList.length *
                                  100) /
                                projectContribution(project)
                              }}%</f7-badge> </span><br v-if="projectContribution(project) > 0" />

                            <small class="timeline-item-text" v-if="!!project.startDate">{{ project.startDate.substr(0,
                              7) }}
                              >
                              {{ project.endDate.substr(0, 7) }}</small>
                          </div>

                          <div v-if="
                            project.participantList &&
                            project.participantList.length > 0
                          " class="timeline-item-inner" v-for="(participant, index) in project.participantList">
                            <div class="timeline-item-title">
                              <f7-badge slot="media" color="auto" width="24" height="24px"
                                style="height: 20px; width: 20px; font-size: 12px">{{ project.projectNumber
                                }}</f7-badge>
                              {{ participant.name }}
                            </div>
                            <div class="timeline-item-subtitle">
                              {{ $t("common.contribution") }}:
                              <span style="font-weight: bold; color: auto" v-html="$filtersToCurrency.toCurrency(
                                participant.paidAmount,
                                null,
                                participant.currencyCode
                              )
                                "></span>
                              <div class="timeline-item-text">
                                {{ $t("common.fundingTarget") }}:
                                <span style="font-weight: bold" v-html="$filtersToCurrency.toCurrency(
                                  project.fundingTarget,
                                  null,
                                  project.currencyCode
                                )
                                  ">
                                </span>
                                <span class="padding-left-half"><f7-badge color="green">{{
                                  (
                                    (parseFloat(participant.paidAmount) * 100) /
                                    parseFloat(project.fundingTarget)
                                  ).toFixed(0)
                                }}%</f7-badge>
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </swiper-slide>
                  <swiper-slide v-if="this.groupBuy && this.groupBuy.qr !== null" style="height: 400px; width: 100%">
                    <a link="#" @click="$refs.popup.open()"
                      style="display: flex; align-items: center; justify-content: center">
                      <img :src="`${this.groupBuy.qr}`" style="
                          display: block;
                          margin: auto;
                          position: absolute;
                          top: 0;
                          bottom: 0;
                        " />
                    </a>
                  </swiper-slide>
                </swiper-container>
              </f7-card-content>
              <f7-button v-if="this.groupBuy.projectList && this.groupBuy.projectList.length > 0" raised fill ripple
                large @click="goToContributeNoProject()" style="border-radius: 0px" class="card_button buttons"
                color="red">{{ $t("common.contribute") }}
              </f7-button>
              <f7-button v-if="
                this.currentParticipantIsAdmin &&
                this.groupBuy.projectList &&
                this.groupBuy.projectList.length == 0
              " raised fill ripple large sheet-open=".addNewCampaign" style="border-radius: 0px"
                class="card_button buttons" color="red">{{ $t("groupbuydetail.addNewProject") }}</f7-button>
            </f7-card>

            <f7-list dividers-ios strong-ios accordion-list media-list class="no-margin-top">
              <f7-list-item accordion-item :title="$t('common.description')">
                <f7-accordion-content>
                  <f7-block>
                    <p v-html="this.groupBuy.description"></p>
                  </f7-block>
                </f7-accordion-content>
              </f7-list-item>
            </f7-list>
            <div class="right not_sticky_with_content">
              <campaignRounds :entity="this.groupBuy" :entityType="'groupBuy'"
                :isAdmin="this.currentParticipantIsAdmin"></campaignRounds>
            </div>

            <f7-card outline header-divider class="left not_sticky_with_content" v-if="
              this.groupBuy &&
              this.$keycloak.authenticated &&
              this.currentParticipant &&
              this.groupBuy.adminList !== null
            ">
              <f7-card-header>
                <div>{{ $t("common.participants") }}</div>
                <div></div>
                <f7-badge v-if="currentParticipantIsAdmin" bg-color="red" :style="{
                  position: 'absolute',
                  right: '16px',
                  top: '8px',
                  'border-radius': '50px',
                  height: '32px',
                  width: '32px',
                  'min-width': '32px',
                }" class="elevation-3">
                  <f7-link icon-aurora="f7:person_crop_circle_fill_badge_plus"
                    icon-ios="f7:person_crop_circle_fill_badge_plus" icon-md="material:person_add" color="white"
                    href="#" popup-open=".addNewMember"></f7-link>
                </f7-badge>
              </f7-card-header>

              <membersComponent :entityId="this.groupBuy.id" :memberList="this.groupBuy.customerList"
                :adminList="groupBuy.adminList" :entityType="'groupBuy'" :isAdmin="currentParticipantIsAdmin"
                :mainUserId="groupBuy.groupOwnerUserId"></membersComponent>
            </f7-card>
          </div>
          <div class="right_layout">
            <div class="sticky with_content">
              <campaignRounds :entity="this.groupBuy" :entityType="'groupBuy'"
                :isAdmin="this.currentParticipantIsAdmin"></campaignRounds>
            </div>
          </div>
        </div>
      </f7-tab>

      <f7-tab v-if="
        this.groupBuy &&
        this.groupBuy.projectList &&
        this.groupBuy.projectList.length > 0
      " class="page-content margin-bottom padding-bottom" v-for="(project, index) in this.groupBuy.projectList"
        :key="index" :id="`tab-${project.projectNumber}`" @tab:show="selectedTab(project)">
        <div class="layout_content">
          <div class="left_layout"></div>
          <div class="main_layout">
            <f7-card class="demo-facebook-card" header-divider :id="`campaingView-${project.projectNumber}`">
              <f7-card-header text-color="auto">
                <div class="demo-facebook-avatar padding-right">
                  <f7-badge slot="media" color="auto"
                    style="height: 40px; width: 40px; font-size: 16px; border-radius: 50%">{{
                      $filtersGetInitial.getInitial(project.name) }}</f7-badge>
                </div>
                <div style="white-space: normal; word-wrap: break-word; font-weight: 90%" class="demo-facebook-name">
                  {{ project.name }}
                </div>

                <div class="demo-facebook-date item-label" style="font-size: small">
                  {{
                    $t("common.fromto", {
                      startDate: project.startDate.substr(0, 10),
                      endDate: project.endDate.substr(0, 10),
                    })
                  }}
                </div>
              </f7-card-header>

              <f7-card-content>
                <div class="grid grid-cols-1 medium-grid-cols-2">
                  <span>
                    <small :style="{ opacity: 0.7 }">{{ $t("common.amount") }}: </small>
                    <b>
                      <span v-html="$filtersToCurrency.toCurrency(
                        projectContribution(project),
                        null,
                        project.currencyCode
                      )
                        "></span> </b><br />
                    <!--

              <small :style="{ opacity: 0.7 }">{{ $t("common.balance") }}</small> -->
                    <br /><f7-gauge v-if="
                      project.participantList !== null &&
                      project.participantList.length !== null
                    " type="circle" :value="(parseFloat(projectContribution(project)) /
                          parseFloat(project.fundingTarget)) *
                        project.participantList.length
                        " :valueText="$filtersToCurrency.toCurrency(
                        projectContribution(project),
                        null,
                        project.currencyCode
                      )
                        " valueTextColor="auto" border-color="green" labelText="collected" labelTextColor="#4caf50"
                      :labelFontWeight="600" :labelFontSize="12" :borderWidth="30" bg-color="white" /></span>

                  <f7-list dividers-ios strong-ios media-list>
                    <f7-list-item v-for="(participant, index) in project.participantList" :title="participant.name"
                      :subtitle="$filtersToCurrency.toCurrency(
                        participant.contribAmount,
                        null,
                        participant.currencyCode
                      )
                        ">
                      <template #media>
                        <f7-badge color="auto" style="
                            height: 32px;
                            width: 32px;
                            font-size: 16px;
                            border-radius: 25px;
                          ">{{ index + 1 }}</f7-badge>
                        <img v-if="participant.avatar" :src="`${participant.avatar}`" width="40"
                          style="height: 28px; width: 28px; border-radius: 25px" />

                        <f7-badge color="auto" v-else style="
                            height: 28px;
                            width: 28px;
                            font-size: 12px;
                            border-radius: 25px;
                          ">{{ $filtersGetInitial.getInitial(participant.name) }}</f7-badge>
                      </template></f7-list-item>
                  </f7-list>
                </div>

                <f7-list dividers-ios strong-ios media-list v-if="savings && isAuthenticated && currentParticipant"
                  class="margin-top">
                  <f7-list-group>
                    <f7-list-item :title="project.name" smart-select :smart-select-params="{
                      openIn: isTablet ? 'popover' : 'sheet',
                      sheetSwipeToClose: true,
                      closeOnSelect: true,
                    }" @smartselect:close="changeProjectmanager()" v-model="projectManager">
                      <select name="projectManager">
                        <option v-for="participant in project.participantList" :key="participant.id"
                          :value="participant.userId" :selected="projectManager === participant.userId"
                          :data-option-color="projectManager === participant.userId ? 'primary' : ''
                            ">
                          {{ participant.name }}
                        </option>
                      </select>
                    </f7-list-item>
                  </f7-list-group>
                </f7-list>
              </f7-card-content>
              <transactionCharts v-if="project.transactionList.length > 0"></transactionCharts>
              <f7-button raised fill ripple large class="card_button buttons" @click="goToContribute(project)"
                color="red">{{
                  $t("common.contribute") }}</f7-button>
            </f7-card>
            <div class="left not_sticky_with_content">
              <getTransactions :transactionList="project.transactionList" v-if="project.transactionList > 0">
              </getTransactions>
            </div>
          </div>
          <div class="right_layout">
            <div class="sticky with_content">
              <getTransactions :transactionList="project.transactionList" v-if="project.transactionList > 0">
              </getTransactions>
            </div>
          </div>
        </div>
      </f7-tab>
    </f7-tabs>

    <addNewProject :groupBuy="this.groupBuy" />
    <template #fixed>
      <f7-fab v-if="this.groupBuy && this.currentParticipantIsAdmin && !this.currentProject" position="center-bottom"
        :text="$t('groupbuydetail.addNewProject')" color="red" @click="addNewBattlePopup()">
        <f7-icon ios="f7:plus" aurora="f7:plus" md="material:add"></f7-icon>
      </f7-fab>
      <f7-fab v-if="this.groupBuy && this.currentProject && !this.currentParticipantIsInBattle" position="center-bottom"
        :text="$t('groupbuydetail.joinProject')" color="red" @click="joinBattle()">
        <f7-icon ios="f7:plus" aurora="f7:plus" md="material:add"></f7-icon>
      </f7-fab>
      <f7-fab position="left-bottom" color="auto" @click="goToMessageGroup()">
        <f7-icon ios="f7:ellipses_bubble_fill" aurora="f7:ellipses_bubble_fill" md="material:chat"></f7-icon>
      </f7-fab>
      <f7-fab position="right-bottom" color="auto" class="shareButton" @click="socialShare(sharedContent)">
        <f7-icon aurora="f7:square_arrow_up" ios="f7:square_arrow_up" md="material:share"></f7-icon>
      </f7-fab>
    </template>
    <sendMessage :entityId="this.groupBuy.id" :memberList="this.groupBuy.customerList" :entityType="'groupBuy'"
      :entity="this.groupBuy" :currentUser="this.groupBuy.customerList.find((user) => user.userId === this.profile.sub)
        "></sendMessage>
    <groupDocuments :entityId="this.groupBuy.id" :projectList="this.groupBuy.projectList" :entityType="'groupBuy'"
      :entity="this.groupBuy" :isAdmin="this.currentParticipantIsAdmin" :currentUser="this.groupBuy.customerList.find((user) => user.userId === this.profile.sub)
        "></groupDocuments>
  </f7-page>
</template>

<style scoped>
/*  */
</style>

<script>
import {
  f7Page,
  f7Navbar,
  f7NavLeft,
  f7NavTitle,
  f7List,
  f7Link,
  f7Tabs,
  f7Tab,
  f7Card,
  f7CardHeader,
  f7Badge,
  f7CardContent,
  f7PhotoBrowser,
  f7Button,
  f7AccordionContent,
  f7AccordionItem,
  f7Block,
  f7Gauge,
  f7Input,
  f7ListInput,
  f7Popup,
  f7ListGroup,
  f7BlockTitle,
  f7PageContent,
  f7Toolbar,
  f7Chip,
  f7TextEditor,
  f7Fab,
  f7Icon,
  f7ListItem,
  f7,
  theme,
} from "framework7-vue";
import { mapGetters, mapState } from "vuex";
import Navbarright from "../components/navbarright.vue";
import addNewProject from "../components/groupBuyAddProject.vue";
import sendMessage from "../components/modules/sendMessageToMembers.vue";
import groupDocuments from "../components/modules/groupDocumentsView.vue";
//import { calculateTransferPayment } from "../js/mixins/transaction.js";
import UsersPage from "./users.vue";
import { socialShare } from "../js/mixins/cordovaPlugins.js";
import { groupbuy } from "../js/mixins/groupBuy.js";
import { contributeRedeem } from "../js/mixins/contributeRedeem.js";
import membersComponent from "../components/modules/members.vue";
import campaignRounds from "../components/modules/campaignRounds.vue";
import getTransactions from "../components/modules/transactions.vue";
import transactionCharts from "../components/modules/charts.vue";
import _ from "lodash";
export default {
  props: {
    f7route: Object,
    f7router: Object,
  },
  components: {
    navbarright: Navbarright,
    userslist: UsersPage,
    addNewProject,
    getTransactions,
    transactionCharts,
    membersComponent,
    campaignRounds,
    sendMessage,
    groupDocuments,
    f7Page,
    f7Navbar,
    f7NavLeft,
    f7NavTitle,
    f7List,
    f7Link,
    f7Tabs,
    f7Tab,
    f7Card,
    f7CardHeader,
    f7Badge,
    f7CardContent,
    f7PhotoBrowser,
    f7Button,
    f7AccordionContent,
    f7AccordionItem,
    f7Block,
    f7Gauge,
    f7Input,
    f7ListInput,
    f7Popup,
    f7ListGroup,
    f7BlockTitle,
    f7PageContent,
    f7Toolbar,
    f7Chip,
    f7TextEditor,
    f7Fab,
    f7Icon,
    f7ListItem,
  },
  mixins: [groupbuy, socialShare, contributeRedeem],
  data() {
    return {
      theme,
      f7,
      title: "",
      description: "",
      fundingTarget: "",
      campaignAmount: "",
      projectManager: "",
      cardNumber: "",
      transferCode: "",
      transferPIN: "",
      nocardNumberOrPINError: "",
      currentProject: "",
      hideOrShowcampaign: "",
      isVisible: false,
      campaignBeneficiary: "",
      hiddenFrom: [],
      sharedContent: "",
      fileToSave: [],
      newParticipants: [],
      type: "groupBuy",
      amount: "",
      setAmount: null,
      paymentCurrency: "",
      project_type: "",
      isMember: "",
      trxType: "",
      referenceId: "",
      receiver_wallet_id: "",
      sender_wallet_id: "",
      tag: "",
      return_url: "",
      return_url_fail: "",
      countryCode: "",
      project: "",
      entity: "",
    };
  },

  mounted() {
    this.hideOrShowcampaign = this.$t("associationdetail.hideCampaignFromBeneficiary");
    if (!this.userAccount && this.$keycloak.authenticated) {
      this.$store.dispatch("account/loadAccount", this.profile.sub);
    }
    if (this.country === null) {
      this.$store.dispatch("location/getCountry");
    }
    if (!this.groupBuy) {
      this.$store.dispatch("groupBuy/getGroupBuy", this.f7route.params.entityId);
    }
  },
  methods: {
    goToContributeNoProject() {
      const self = this;

      if (
        this.groupBuy &&
        this.groupBuy.projectList &&
        this.groupBuy.projectList.length == 1
      ) {
        this.goToContribute(this.groupBuy.projectList[0]);
        return;
      }
      const butt = [];
      const label = {
        text: self.$t("common.select"),
        color: "auto",
        bold: true,
      };

      butt.push(label);
      for (const project of this.groupBuy.projectList) {
        butt.push({
          text:
            '<span class="badge color-auto">' +
            project.projectNumber +
            "</span>" +
            " " +
            project.name,
          onClick() {
            self.goToContribute(project);
          },
        });
      }
      if (!self.contribToPopover) {
        self.contribToPopover = f7.actions.create({
          convertToPopover: true,
          buttons: [
            butt,
            [
              {
                text: self.$t("common.cancel"),
                color: "red",
              },
            ],
          ],
          targetEl: self.$el.querySelector(".contributeButton"),
        });
      }
      self.contribToPopover.open();
    },
    goToContribute(project) {
      this.project_type = "contribute";
      this.preparePayment(project);
      setTimeout(() => {
        this.contribute();
      }, 500);
    },
    redeemCampaign(project) {
      this.project_type = "redeem";
      this.preparePayment(project);
      setTimeout(() => {
        this.redeem();
      }, 500);
    },
    async goToMessageGroup() {
      f7.preloader.show("multi");
      const chatId =
        this.groupBuy.chatGroupId === "" ? this.groupBuy.id : this.groupBuy.chatGroupId;
      setTimeout(() => {
        this.f7router.navigate(`/message/${chatId}/GROUP`, {
          reloadAll: true,
        });
        f7.preloader.hide();
      }, 2000);
    },

    updateSavings() {
      this.$store.dispatch("groupBuy/getGroupbuy", this.groupBuy.id);
    },

    addNewBattlePopup() {
      f7.sheet.open(".addNewCampaign");
      this.projectNumber = parseFloat(this.groupBuy.projectList.length) + 1;
      this.fundingTarget = "";
    },

    selectedTab(project) {
      this.currentProject = project;
    },
    joinBattle() {
      const currentUser = this.groupBuy.customerList.find(
        (user) => user.userId === this.profile.sub
      );
      this.newParticipants.push(currentUser);
      this.$store.dispatch("groupBuy/joinProject", {
        newParticipants: this.newParticipants,
        projectId: this.currentProject.id,
      });
    },

    openMemberList(project) {
      const self = this;
      this.currentProject = project;
      const members = [];
      const label = {
        text: self.$t("common.select"),
        color: "auto",
        bold: true,
      };

      participants.push(label);
      for (const participant of project.participantList) {
        addParticipantsVue.push({
          text:
            participant.name +
            "<br>" +
            "" +
            participant.phoneNumber +
            "<br>" +
            participant.email,
          bold: true,
          onClick() {
            self.changeProjectmanager(member);
          },
        });
      }
      if (!self.changeCampaignManagerToPopover) {
        self.changeCampaignManagerToPopover = f7.actions.create({
          convertToPopover: true,
          buttons: [
            members,
            [
              {
                text: self.$t("common.cancel"),
                color: "red",
              },
            ],
          ],
          targetEl: self.$el.querySelector(`.selectChangeBattleManager${project.id}`),
        });
      }
      self.changeCampaignManagerToPopover.open();
    },

    async changeProjectmanager() {
      if (this.projectManager !== this.currentProject.projectManager) {
        await this.$store.dispatch("groupbuy/addCampaignManager", {
          id: this.currentProject.id,
          projectManager: this.projectManager,
        });
      }
    },

    getProjectManager(value) {
      const participant = _.find(this.project.participantList, ["userId", value]);
      if (participant) {
        return participant.name;
      } else {
        return;
      }
    },
  },

  computed: {
    ...mapState("groupBuy", [
      "groupBuy",
      "groupBuyList",
      "project",
      "participant",
      "participantList",
      "admin",
      "adminList",
      "groupBuyTransaction",
    ]),
    ...mapState("transaction", [
      "destinationCountry",
      "destinationCity",
      "grossexchangerate",
      "transferDetail",
      "fromCurrencyRate",
      "toCurrencyRate",
      "transferDetail",
      "paymentDetail",
      "paymentReverseDetail",
    ]),

    ...mapState("location", [
      "destinationCity",
      "country",
      "countryStates",
      "countryCities",
      "currentUserCountry",
    ]),
    ...mapState("account", ["userAccount", "userDetail", "searchedUser"]),
    ...mapState("card", ["carditemlist", "cardbasket", "country", "queryerror"]),
    ...mapState("auth", [
      "phoneNumberDetail",
      "mobilePhoneError",
      "profile",
      "isDeskTop",
      "isTablet",
      "dynamicLink",
    ]),
    ...mapState("transaction", ["destinationCountry", "originCountry"]),

    ...mapGetters("auth", [
      "getProfile",
      "isAuthenticated",
      "isProfileLoaded",
      "getusererror",
    ]),
    ...mapGetters("transaction", [
      "getusererror",
      "fromCurrencyRate",
      "toCurrencyRate",
      "grossexchangerate2",
    ]),
    ...mapState("message", [
      "chatUser",
      "message",
      "chatUserList",
      "messageList",
      "group",
      "groupList",
      "subGroup",
      "subGroupList",
    ]),

    ...mapGetters("account", ["userByAccountNumber"]),

    destinationCtry() {
      return _.groupBy(this.country, "continentName");
    },

    currentParticipant() {
      if (this.profile.sub) {
        return _.find(this.groupBuy.customerList, ["userId", this.profile.sub]);
      }
    },
    currentParticipantIsInBattle() {
      if (this.profile.sub) {
        return _.find(this.currentProject.participantList, ["userId", this.profile.sub]);
      }
    },
    currentParticipantIsAdmin() {
      if (this.profile.sub) {
        return _.find(this.groupBuy.adminList, ["userId", this.profile.sub]);
      }
    },

    daysLeft() {
      return (
        (new Date(this.associationEndDate).getTime() - new Date().getTime()) /
        (1000 * 3600 * 24)
      ).toFixed(0);
    },

    sortedBattleList() {
      return _.orderBy(this.groupBuy.projectList, ["projectNumber"], ["desc"]);
    },
  },
};
</script>
