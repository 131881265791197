import { f7 } from "framework7-vue";
import _ from "lodash";
import openUrl from "@/js/mixins/cordovaPlugins.js";
import axios from "axios";
const path = import.meta.env.VITE_APP_URL + "stripe";
// const auth = {
export default (app) => {
  return {
    namespaced: true,
    state: {
      publishableKey: "",
      accountSession: "",
      checkout_session: "",
    },
    getters: {
      // isAuthenticated: (state) => state.token,
      // getProfile: (state) => state.profile,
    },
    mutations: {
      GET_STRIPE_PUBLIC_KEY(state, publishableKey) {
        state.publishableKey = publishableKey;
      },
      CREATE_ACCOUNT_SESSION(state, accountSession) {
        state.accountSession = accountSession;
      },
      GET_CHECKOUT_SESSION(state, checkout_session) {
        state.checkout_session = checkout_session;
      },
    },
    actions: {
      getStripePublicKey({ commit }) {
        axios({
          url: `${path}/public-key`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((publishableKey) => {
            console.log("publishableKey", publishableKey);
            commit("GET_STRIPE_PUBLIC_KEY", publishableKey);
          })
          .catch((err) => {
            console.log(err.response.data);
          });
      },

      stripeOnbordingRedirect({}, payload) {
        axios({
          url: `${path}/onboard/refresh`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
          params: { accountId: payload },
        })
          .then((r) => r.data.body)
          .then((accountLink) => {
            console.log(accountLink);
            f7.dialog.confirm(
              `Click on link to add your bank account to receive payout <a href="${accountLink.url}">${accountLink.url}</a>?`,
              () => {
                if (accountLink && accountLink.url !== null) {
                  // window.location = accountLink.url;
                  openUrl.openLink(accountLink.url);
                }
              }
            );
            console.log(accountLink);
          })
          .catch((err) => {
            console.log(err);
          });
      },
      createStripeAccountSession({ commit }, payload) {
        axios({
          url: `${path}/account_session`,
          method: "POST",
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
          params: { accountId: payload },
        })
          .then((r) => r.data)
          .then((accountSession) => {
            commit("CREATE_ACCOUNT_SESSION", accountSession);
            console.log(accountSession);
          })
          .catch((err) => {
            console.log(err.response.data);
          });
      },
      deleteStripeAccount({ commit }, payload) {
        axios({
          url: `${path}/delete_account`,
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
          params: { accountId: payload },
        })
          .then((r) => r.data)
          .then((stripeAccount) => {
            commit("GET_STRIEPE_ACCOUNT", stripeAccount);
            console.log(stripeAccount);
          })
          .catch((err) => {
            console.log(err);
          });
      },
      createCheckOutSession({ commit }, payload) {
        axios({
          url: `${path}/checkout_session`,
          method: "POST",
          data: payload,
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((checkout_session) => {
            commit("GET_CHECKOUT_SESSION", checkout_session);
            console.log(checkout_session);
            if (checkout_session && checkout_session.url !== null) {
              openUrl.openLink(checkout_session.url);
            }
            console.log(checkout_session);
          })
          .catch((err) => {
            console.log(err);
          });
      },
      getCheckOutSessionStatus({ commit }, payload) {
        axios({
          url: `${path}/checkout_session`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
          params: { session_id: payload },
        })
          .then((r) => r.data)
          .then((checkout_session) => {
            commit("GET_CHECKOUT_SESSION", checkout_session);
            console.log(checkout_session);
          })
          .catch((err) => {
            console.log(err);
          });
      },
    },
  };
};
// export default auth;
