<template>
  <f7-page hide-bars-on-scroll>
    <f7-navbar v-if="this.isNotTab === 'false'" sliding no-shadow :back-link="$t('common.backButton')"
      :back-link-show-text="false">
      <f7-nav-title sliding>{{ $t("homepage.remitPickup") }}</f7-nav-title>
      <f7-nav-right>
        <navbarright></navbarright>
      </f7-nav-right>
    </f7-navbar>
    <div class="layout_content">
      <div class="left_layout"></div>
      <div class="main_layout">
        <f7-block-title medium style="white-space: normal; word-wrap: break-word">{{
          $t("avocashatmpickup.enterTransferCodeToPickup")
          }}</f7-block-title>
        <f7-list dividers-ios strong-ios media-list>
          <f7-list-group v-if="this.pickupTransfer === '' && this.cashOut === ''">
            <block class="cols75_25" id="checkPickupNbr">
              <li class="item-content item-input">
                <div class="item-inner">
                  <div class="item-title item-floating-label">
                    {{ $t("avocashatmpickup.enterTransferCode") }}
                  </div>

                  <div class="item-input-wrap">
                    <input type="text" :placeholder-ios="$t('avocashatmpickup.enterTransferCode')" required validate
                      id="transferCode" inputmode="numeric" @input="transferCode = $event.target.value"
                      @keyup.enter="checkPickup()" @change="checkPickup()" @blur="checkPickup()" maxlength="19"
                      size="19" :data-error-message="$t('avocashatmpickup.transferCodeRequired')" />
                    <span v-if="this.transferCode == ''" class="input-clear-button" style="padding-right: 38px"
                      @click="clearCardButton()"></span>
                    <span v-else @click="clearCardButton()" class="input-clear-button"
                      style="padding-right: 5px"></span>
                  </div>
                </div>
                <f7-link v-if="this.transferCode == ''" class="item-media" @click="scanQRCode(type)"
                  icon-aurora="f7:qrcode_viewfinder" icon-ios="f7:qrcode_viewfinder" icon-md="f7:qrcode_viewfinder">
                </f7-link>
              </li>

              <f7-button @click="checkPickup()" raised fill ripple large medium color="red" id="checkPickup"
                class="margin">{{ $t("avocashatmpickup.check") }}</f7-button>
            </block>
            <f7-block class="no-margin" v-if="this.pickupqueryerror !== null && this.transferCode !== ''">
              <p style="font-size: 10px; font-style: italic; color: red">
                {{ this.pickupqueryerror }}
              </p>
            </f7-block>
          </f7-list-group>
          <f7-list-group v-if="this.pickupTransfer !== '' && this.cashOut === ''">
            <h5 style="color: red; font-size: 75%" class="margin-horizontal">
              {{ $t("avocashatmpickup.pleaseCheckRecipient") }}
            </h5>
            <li class="item-content">
              <div class="item-inner">
                <div class="item-title-row">
                  <div class="item-title" style="color: gray; font-size: 75%">
                    {{ $t("avocashatmpickup.recipient") }}
                  </div>
                  <div class="item-title" style="color: gray; font-size: 75%">
                    {{ $t("common.transferCode") }}
                  </div>
                </div>
                <div class="item-title-row">
                  <div class="item-title" style="font-weight: bold">
                    {{ this.pickupTransfer.firstName }}
                    {{ this.pickupTransfer.lastName }}
                  </div>
                  <div class="item-title" style="font-weight: bold">
                    {{ this.transferCode.substr(0, 4) }}
                  </div>
                </div>
              </div>
            </li>
            <li class="item-content">
              <div class="item-inner">
                <div class="item-title-row">
                  <div class="item-title" style="color: gray; font-size: 75%">
                    {{ $t("avocashatmpickup.sender") }}
                  </div>
                  <div class="item-title" style="color: gray; font-size: 75%">
                    {{ $t("common.country") }}
                  </div>
                </div>
                <div class="item-title-row">
                  <div class="item-title" style="font-weight: bold">
                    {{ this.pickupTransfer.sender }}
                  </div>
                  <div class="item-title" style="font-weight: bold">
                    {{ this.pickupTransfer.fromCountry }}
                  </div>
                </div>
              </div>
            </li>
            <h5 class="margin-vertical-half margin-horizontal" style="font-weight: normal">
              {{ $t("avocashatmpickup.pleaaseEnterAmount") }}
            </h5>
            <block class="cols70_30" form id="payTransfer">
              <!-- <f7-col width="60" medium="70"> -->
              <li class="item-content item-input">
                <div class="item-media" v-if="this.pickupTransfer.receivedAmount != ''">
                  {{ this.pickupTransfer.toCurrencyCode }}
                </div>
                <div class="item-inner">
                  <div class="item-title item-label">
                    {{ $t("avocashatmpickup.enterAmoutToWithdraw") }}
                  </div>
                  <div class="item-input-wrap">
                    <input type="number" min="0" pattern="[0-9]*" required validate name="amountToWithdraw"
                      :data-error-message="$t('avocashatmpickup.enterAmoutToWithdraw')" :value="amountToWithdraw"
                      @input="amountToWithdraw = $event.target.value" @change="amountToWithdrawChange()"
                      @keyup="amountToWithdrawChange()" @blur="amountToWithdrawChange()"
                      @keyup.enter="payTransferNow()" />
                    <span class="input-clear-button"></span>
                  </div>
                </div>
              </li>

              <li class="item-content item-input">
                <div class="item-inner">
                  <div class="item-title item-label">
                    {{ $t("common.pin") }}
                  </div>
                  <div class="item-input-wrap">
                    <input type="text" required value="°°°°" id="transferPIN" validate inputmode="numeric"
                      pattern="[0-9]*" @input="transferPIN = $event.target.value" @keyup.enter="payTransferNow()"
                      maxlength="4" size="4" autocomplete="off" :data-error-message="$t('common.noPin')" />
                    <span class="input-clear-button"></span>
                  </div>
                </div>
              </li>
            </block>
            <f7-block class="no-margin" v-if="this.amountToWithdraw != ''">
              <p style="font-size: 10px; font-style: italic; color: red" v-if="this.cashOutError !== null">
                {{ this.cashOutError }}
              </p>
            </f7-block>
            <f7-button raised fill large ripple class="margin" color="red" @click="payTransferNow()"
              v-if="this.pickupTransferamount.transferAmount !== ''">{{ $t("avocashatmpickup.payTransferNow")
              }}</f7-button>

            <f7-button outline raised large ripple color="red" class="margin-top-half margin-horizontal"
              @click="cancelTransfer()">{{ $t("avocashatmpickup.cancelPickup") }}</f7-button>

            <!--If no amount is entered, alert to confirm that amount taken from main account account and to be confirmed with PIN-->
          </f7-list-group>
        </f7-list>
      </div>
      <div class="right_layout"></div>
    </div>

    <f7-popup class="cashOutSuccess" swipe-to-close :params="{
      backdrop: true,
      animate: true,
      swipeToClose: true,
      closeByBackdropClick: true,
      closeOnEscape: true,
    }">
      <f7-page class="text-align-center">
        <f7-navbar>
          <div class="right">
            <f7-link popup-close icon-f7="multiply"></f7-link>
          </div>
        </f7-navbar>
        <div class="layout_content">
          <div class="left_layout"></div>
          <div class="main_layout">
            <f7-icon ios="f7:checkmark_circle" aurora="f7:checkmark_circle" md="material:check_circle_outline"
              size="300px" color="green" class="text-align-center"></f7-icon>
            <h1 color="green" class="text-align-center">
              {{ $t("avocashatmpickup.cashoutSuccessful") }}
            </h1>
            <br />
            <h4 text-color="green" class="text-align-center margin-horizontal" v-if="this.cashOut">
              {{ $t("avocashatmpickup.cashedoutAmount") }}
              <span v-html="$filtersToCurrency.toCurrency(
                this.cashOut.amount,
                null,
                this.cashOut.currencyCode
              )
                "></span><br />{{ $t("avocashatmpickup.cashedoutFees") }}
              <span v-html="$filtersToCurrency.toCurrency(
                this.cashOut.cashOutFee,
                null,
                this.cashOut.currencyCode
              )
                "></span><br />{{ $t("avocashatmpickup.cashedoutNetAmount") }}
              <span v-html="$filtersToCurrency.toCurrency(
                this.cashOut.netCashOutAmount,
                null,
                this.cashOut.currencyCode
              )
                "></span><br />
              <span v-if="this.cashOut.pickupTransaction">{{ $t("avocashatmpickup.recipientName") }}
                {{ this.cashOut.pickupTransaction.mainRecipient.name }}</span>
              <br />{{ $t("avocashatmpickup.cashierName") }}
              {{ this.cashOut.cashierName }}
            </h4>
          </div>
          <div class="right_layout"></div>
        </div>
      </f7-page>
    </f7-popup>
  </f7-page>
</template>
<script>
import { mapGetters, mapState } from "vuex";
import Navbarright from "../components/navbarright.vue";
import { scanQRCodeGeneral } from "../js/mixins/scanPayQRCode.js";
import { keyPadMixin } from "../js/mixins/keyPadMixin.js";
import { theme, f7 } from "framework7-vue";
import _ from "lodash";
export default {
  props: {
    f7route: Object,
    f7router: Object,
    isNotTab: String,
  },
  components: {
    navbarright: Navbarright,
  },
  mixins: [scanQRCodeGeneral, keyPadMixin],
  data() {
    return {
      theme,
      f7,
      amountToPayOut: "",
      accountNumber: "",
      payOutAccountNumber: "",
      amountToWithdraw: "",
      transferCode: "",
      transferPIN: "",
      recipientuser: "",
      amountToHigh: "",
      type: "pick-up page",
    };
  },
  mounted() {
    const self = this;
    if (!this.userAccount && this.$keycloak.authenticated) {
      self.$store.dispatch("account/loadAccount", this.profile.sub);
    }
    console.log("isNotTab", this.isNotTab);
  },
  methods: {
    checkPickup() {
      const self = this;
      self.$store.state.remit.pickupqueryerror = "";
      this.transferCode = this.cardNumberKeypad.value;
      if (
        f7.input.validateInputs("#checkPickupNbr") &&
        this.transferCode &&
        this.transferCode.length === 19
      ) {
        self.$store.dispatch("remit/getPickupTransfer", {
          transferCode: this.cardNumberKeypad.inputVal || this.transferCode,
        });
      } else {
        var valid = f7.$("#checkPickupNbr .input-invalid").length === 0;
      }
    },

    amountToWithdrawChange() {
      const self = this;
      if (
        parseFloat(this.amountToWithdraw) > parseFloat(this.pickupTransfer.receivedAmount)
      ) {
        this.amountToHigh = this.$t("avocashatmpickup.amountToHigh");
        this.amountToWithdraw = "";
      } else {
        this.amountToHigh = "";
      }
    },

    payTransferNow() {
      const self = this;
      if (f7.input.validateInputs("#payTransfer")) {
        self.$store
          .dispatch("remit/cashOutPickup", {
            transferCode: this.transferCode,
            transferPIN: this.transferPIN,
            amount: this.amountToWithdraw,
            pickupTransactionId: this.pickupTransfer.id,
          })
          .then((result) => {
            f7.popup.open(".cashOutSuccess");
            setTimeout(() => {
              this.amountToWithdraw = "";
              this.transferCode = "";
              this.transferPIN = "";
              self.$store.state.remit.cashOut = "";
              self.$store.dispatch("remit/resetCashout");
              f7.popup.close(".cashOutSuccess");
            }, 30000);
          })
          .catch((err) => { });
      } else {
        var valid = f7.$("#payTransfer .input-invalid").length === 0;
      }
    },

    cancelTransfer() {
      const self = this;
      f7.dialog.confirm(self.$t("avocashatmpickup.doYouWantTocancel"), () => {
        this.transferCode = "";
        this.transferPIN = "";
        this.amountToWithdraw = "";
        self.$store.dispatch("remit/resetCashout");

        // self.$store.dispatch('getPickupTransfer', {
        //   transferCode: ''
        // });
        // self.$store.dispatch('getPickupTransferWithAmount', {
        //   transferCode: '',
        //   transferPIN:''
        // });
      });
    },
  },
  computed: {
    ...mapState("transaction", [
      "destinationCountry",
      "originCountry",
      "destinationCity",
      "grossexchangerate",
      "grossexchangerate2",
      "transferDetail",
      "fromCurrencyRate",
      "toCurrencyRate",
    ]),
    ...mapState("remit", [
      "recipients",
      "allrecipients",
      "userDetail",
      "pickupqueryerror",
      "pickupamountqueryerror",
      "pickupTransfer",
      "pickupTransferamount",
      "cashOut",
      "cashOutError",
      "remitQRCode",
    ]),
    ...mapState("account", ["userAccount", "userByAccountNumber", "getusererror"]),
    ...mapState("auth", ["profile", "isDeskTop", "isTablet"]),

    ...mapGetters("auth", ["getProfile", "isAuthenticated", "isProfileLoaded"]),
    ...mapGetters("remit", ["recipients", "recipientsList"]),
    ...mapGetters("transaction", [
      "allCountriesByCountryId",
      "allCountriesByCountryName",
      "fromCurrencyRate",
      "toCurrencyRate",
      "grossexchangerate2",
    ]),
    payerCountry() {
      return _.find(this.originCountry, [
        "fromCountryId",
        this.userByAccountNumber.address.country.countryId,
      ]);
    },
  },
};
</script>
