<template>
  <f7-page page-with-subnavbar ptr :ptr-mousewheel="true" ptr-bottom @ptr:refresh="loadMore()" @page:init="pageInit()">
    <f7-navbar sliding no-shadow>
      <f7-nav-left :back-link="$t('common.backButton')" :back-link-show-text="false"></f7-nav-left>
      <f7-nav-title>{{ $t("businessacceptcash.qrCodes") }} </f7-nav-title>
      <f7-nav-right>
        <f7-link @click="loadMore()" icon-ios="f7:arrow_clockwise" icon-aurora="f7:arrow_clockwise"
          icon-md="material:refresh"></f7-link>
        <f7-link popover-open=".sortingPopup" icon-ios="f7:sort_up" icon-aurora="f7:sort_up"
          icon-md="material:filter_list" class="sortingGroup"></f7-link>
      </f7-nav-right>
      <f7-subnavbar :inner="false" no-shadow>
        <f7-searchbar search-container=".qrCodeList" :placeholder="$t('businessacceptcash.searchAQrCode')"
          :backdrop="false" search-item=".qrCodeItem" search-in=".card-header, .item-title, .item-after"
          search-group-title=".list-group-title" @input="searchQrCode = $event.target.value" :clear-button="true"
          style="max-width: 600px; margin: auto"></f7-searchbar>
      </f7-subnavbar>
    </f7-navbar>
    <!-- <div class="layout_content">
      <div class="left_layout"></div>
      <div class="main_layout"> -->
    <div class="toastMsg" v-html="$t('businessacceptcash.toastMsg')" style="max-width: 568px; margin: auto"></div>
    <div v-show="vendorQRCodeList && vendorQRCodeList.length == 0" style="width: auto; max-width: 568px; margin: auto">
      <span class="margin" style="text-align: center">{{
        $t("businessacceptcash.noQrCode")
      }}</span>
      <br />
      <a v-if="
        this.userAccount &&
        this.userAccount.business !== null &&
        this.userAccount.business.status === 'ACTIVE'
      " href="#" style="display: flex; justify-content: center">
        <f7-chip color="red" outline a :text="$t('businessacceptcash.addNewQrCode')" @click="createNewQR()">
          <template #media>
            <f7-icon color="red" ios="f7:plus_circle_fill" aurora="f7:plus_circle_fill" md="material:add_circle">
            </f7-icon>
          </template>
        </f7-chip>
      </a>
      <a v-else href="/account/business/activate" style="display: flex; justify-content: center">
        <f7-chip color="auto" raised outline :text="$t('businessacceptcash.activateMyBusinessAccount')">
          <template #media>
            <f7-icon ios="f7:checkmark_alt_circle_fill" color="auto" aurora="f7:checkmark" md="material:check_circle">
            </f7-icon>
          </template>
        </f7-chip>
      </a>
    </div>
    <f7-block style="position: sticky; top: 0; width: auto; max-width: 600px; margin: auto" class="margin-vertical-half"
      v-if="vendorQRCodeList !== '' || vendorQRCodeList.length > 0">
      <a v-for="(item, index) in sortingCriteria" :key="index" href="#" class="margin-right-half"
        @click="selectQrList(item.value)">
        <f7-chip :text="item.name" :color="item.color"></f7-chip>
      </a>
    </f7-block>
    <f7-list dividers-ios strong-ios class="searchbar-not-found">
      <f7-list-item :subtitle="$t('businessacceptcash.noQrCode')"></f7-list-item>
    </f7-list>
    <f7-list dividers-ios strong-ios style="padding-bottom: 80px; width: auto; max-width: 568px; margin: auto"
      class="qrCodeList" v-for="(accountStaticCodeList, name, index) in this.structuredvendorQRCodeList" :key="index">
      <f7-list-group>
        <f7-list-item group-title text-color="primary" style="white-space: normal; word-wrap: break-word">
          {{ name }} {{ accountStaticCodeList[0].accountId }}</f7-list-item>
        <f7-card outline raised :class="` qrCodeItem padding-vertical margin-vertical ${staticCode.status}`"
          v-for="(staticCode, index) in accountStaticCodeList" :key="index">
          <f7-card-header style="display: flex" :padding="false">
            <div style="display: block">
              <span class="padding-right-half">{{ staticCode.name
                }}<span v-if="isTablet">{{ $t("businessacceptcash.qrCode") }}</span></span>
              <f7-badge color="auto" :style="{
                'border-radius': '50px',
                height: '32px',
                width: '32px',
              }" class="elevation-3">n°{{ staticCode.qrNumber }}</f7-badge>
            </div>
            <div>
              <span v-if="isTablet" class="padding-right-half">{{
                $t("businessacceptcash.status")
              }}</span>

              <f7-badge :color="getStatusColor(staticCode)" v-text="getStatusName(staticCode)">
              </f7-badge>
            </div>
          </f7-card-header>
          <div class="grid grid-cols-1 medium-grid-cols-2 qrCodeImage grid-gap">
            <a :href="'/business-qr/' + staticCode.accountId + '/' + staticCode.id" :reload-detail="true">
              <f7-card-content :padding="false">
                <img :src="`data:image/png;base64,${staticCode.qrCode}`" class="QrCode margin-vertical" alt="QR Code"
                  style="display: block; margin: auto; width: auto" />
              </f7-card-content>
            </a>

            <f7-card-content :padding="false" class="qrCodeDetail">
              <f7-list-item :header="$t('businessacceptcash.totalTransactions')" :title="$filtersToCurrency.toCurrency(
                staticCode.totalAmount,
                null,
                staticCode.currencyCode
              )
                "></f7-list-item>

              <li class="item-content item-input" v-if="staticCode.status !== 'yesAmount'">
                <div class="item-media" v-if="staticCode.currencyCode !== ''">
                  {{ staticCode.currencyCode }}
                </div>
                <div class="item-inner">
                  <div class="item-title item-label">
                    {{ $t("common.amount") }}
                  </div>
                  <div class="item-input-wrap">
                    <input type="text" :name="staticCode.id" :id="'amountToPay' + staticCode.id" min="0"
                      inputmode="numeric" required :placeholder="$t('businessacceptcash.pleaseEnterAmount')" validate
                      :data-error-message="$t('businessacceptcash.pleaseEnterAmount')"
                      :value="parseFloat(amountToPay || staticCode.amount) || ''"
                      @input="staticCode.amount = $event.target.value" @keyup.enter="addQrAmount(staticCode)"
                      @focus="addAmount(staticCode)" @change="addAmount(staticCode)" />
                    <span class="input-clear-button"></span>
                  </div>
                </div>
              </li>
              <f7-list-input v-if="staticCode.status !== 'yesAmount'" :label="$t('common.description')" floating-label
                type="textarea" resizable clear-button name="description"
                :placeholder="$t('businessacceptcash.enterPaymentDescription')"
                :input-id="'description' + staticCode.id" :value="staticCode.description"
                @input="staticCode.description = $event.target.value" @keyup.enter="addQrAmount(staticCode)">
              </f7-list-input>
              <f7-list-item v-if="staticCode.status === 'yesAmount'" :header="$t('businessacceptcash.amountToClaim')"
                :title="$filtersToCurrency.toCurrency(
                  staticCode.amount,
                  staticCode.currencySymbol,
                  staticCode.currencyCode
                )
                  "></f7-list-item>
              <f7-list-item :header="$t('common.description')"
                v-if="staticCode.tag !== '' && staticCode.status === 'yesAmount'"
                :title="staticCode.tag"></f7-list-item>
              <f7-list-item v-if="
                isTablet &&
                parseFloat(staticCode.amount || amountToPay) === 0 &&
                staticCode.status === 'idle'
              " :reload-detail="true" :link="'/business-qr/' + staticCode.accountId + '/' + staticCode.id"
                :title="$t('common.seeDetail')"></f7-list-item>
              <span v-if="isTablet">
                <f7-button v-if="
                  parseFloat(staticCode.amount || amountToPay) > 0 &&
                  staticCode.status === 'idle'
                " raised fill small style="border-radius: 0px" class="margin-bottom-half" color="red" outline
                  border-color="red" @click="addQrAmount(staticCode)">{{ $t("common.validate") }}</f7-button>
                <div class="grid grid-cols-2 medium-grid-cols-2" v-if="staticCode.status === 'editable'">
                  <f7-button ripple raised fill small class="margin-vertical-half" style="border-radius: 0px"
                    color="red" border-color="red" @click="addQrAmount(staticCode)">{{ $t("common.validate")
                    }}</f7-button>

                  <f7-button ripple outline raised small bordr-color="red" class="margin-vertical-half"
                    style="border-radius: 0px; border-width: 2px; border-color: red" color="red"
                    @click="cancel(staticCode)">{{ $t("common.cancel") }}
                  </f7-button>
                </div>
                <div class="grid grid-cols-2 medium-grid-cols-2 grid-gap">
                  <f7-button v-if="
                    parseFloat(staticCode.amount || amountToPay) > 0 &&
                    staticCode.status === 'yesAmount'
                  " ripple outline raised small bordr-color="primary" class="margin-vertical-half"
                    style="border-radius: 0px; border-width: 2px; border-color: blue" color="auto"
                    @click="edit(staticCode)">{{ $t("common.update") }}</f7-button>

                  <f7-button v-if="
                    parseFloat(staticCode.amount || amountToPay) > 0 &&
                    staticCode.status === 'yesAmount'
                  " ripple outline raised small class="margin-vertical-half" bordr-color="red"
                    style="border-radius: 0px; border-width: 2px; border-color: red" color="red"
                    @click="cancel(staticCode)">{{ $t("common.cancel") }}</f7-button>
                </div>
              </span>
            </f7-card-content>
          </div>

          <span v-if="!isTablet">
            <f7-button v-if="
              parseFloat(staticCode.amount || amountToPay) > 0 &&
              staticCode.status === 'idle'
            " raised fill small style="border-radius: 0px" class="" color="red" @click="addQrAmount(staticCode)">{{
                $t("common.validate") }}</f7-button>
            <f7-link v-else :reload-detail="true" :link="'/business-qr/' + staticCode.accountId + '/' + staticCode.id"
              :title="$t('common.seeDetail')"></f7-link>
            <div class="grid grid-cols-2 medium-grid-cols-2" no-gap v-if="staticCode.status === 'editable'">
              <f7-button ripple raised fill small style="border-radius: 0px" color="red"
                @click="addQrAmount(staticCode)">{{
                  $t("common.validate") }}</f7-button>

              <f7-button ripple outline raised small bordr-color="red"
                style="border-radius: 0px; border-width: 2px; border-color: red" color="red"
                @click="cancel(staticCode)">{{
                  $t("common.cancel") }}</f7-button>
            </div>
            <div class="grid grid-cols-2 medium-grid-cols-2" no-gap v-if="
              parseFloat(staticCode.amount || amountToPay) > 0 &&
              staticCode.status === 'yesAmount'
            ">
              <f7-button ripple outline raised small bordr-color="primary"
                style="border-radius: 0px; border-width: 2px; border-color: blue" color="auto"
                @click="edit(staticCode)">{{
                  $t("common.update") }}</f7-button>

              <f7-button ripple outline raised small bordr-color="red"
                style="border-radius: 0px; border-width: 2px; border-color: red" color="red"
                @click="cancel(staticCode)">{{
                  $t("common.cancel") }}</f7-button>
            </div>
            <f7-card-footer class="no-border" v-if="
              parseFloat(staticCode.amount || amountToPay) === 0 &&
              staticCode.status === 'idle'
            ">
              <f7-link></f7-link>
              <f7-link :reload-detail="true" :link="'/business-qr/' + staticCode.accountId + '/' + staticCode.id">{{
                $t("common.seeDetail") }}</f7-link>
            </f7-card-footer>
          </span>
        </f7-card>
      </f7-list-group>
    </f7-list>
    <!-- </div>
      <div class="right_layout"></div>
    </div> -->
    <!-- <div class="ptr-preloader">
      <div class="preloader"></div>
      <div class="ptr-arrow"></div>
    </div> -->
    <f7-popover class="sortingPopup" :backdrop="false" :params="{
      closeByBackdropClick: true,
      closeOnEscape: true,
    }">
      <f7-block-title medium>{{ $t("businessacceptcash.selectBy") }}</f7-block-title>
      <f7-list dividers-ios strong-ios media-list>
        <f7-list-item v-for="criteria in sortingCriteria" :key="criteria.index" radio class="popover-close"
          :value="criteria.value" :title="criteria.name" @change="(e) => {
              if (e.target.checked) searchQrCode = criteria.value;
            }
            " name="searchQrCode"></f7-list-item>
      </f7-list>
    </f7-popover>
  </f7-page>
</template>
<style lang="css" scoped>
div.toastMsg div.block-title.block-title-medium,
div.toastMsg div.block.block-strong.inset p {
  width: auto;
  margin: auto;
  max-width: 568px;
}

/*
.idle {
  border: 5px solid lightgray;
}

.qrCodeItem {
  width: auto;
  height: auto;
  border-radius: 10px;
  padding: 2rem;
  margin: auto;
  border: 5px solid lightgray;
  display: grid;
  place-content: center;
  text-align: center;
  max-width: 568px;

  font-size: 1.5em;
}

.Pending .on .approval,
.editable,
.yesAmount {
  border: 5px solid red;
  animation: blinkNoAmount 2s infinite;
  -webkit-animation: blinkNoAmount 2s infinite;
}

.warning {
  margin: 2em;
  padding: 1em;
  border: 1px solid #ccc;
}

.warning p {
  margin: 0;
  padding: 0;
  text-align: center;
} */
</style>
<style lang="scss" scoped>
.yesAmount,
.editable {
  border: 10px solid white;
  animation: blinkYesAmount 2s infinite;
  -webkit-animation: blinkYesAmount 2s infinite;
  --border-size: 0.3rem;
  border: var(--border-size) solid transparent;

  /* Paint an image in the border */
  border-image: conic-gradient(from var(--angle),
      red 0deg 90deg,
      yellow 90deg 180deg,
      #0009ff 180deg 270deg,
      teal 270deg 360deg) 1 stretch;
}

@keyframes blinkYesAmount {
  0% {
    border-color: white;
  }

  50% {
    border-color: #0009ff;
  }

  100% {
    border-color: white;
  }
}

@-webkit-keyframes blinkYesAmount {
  0% {
    border-color: white;
  }

  50% {
    border-color: #0009ff;
  }

  100% {
    border-color: white;
  }
}

.noAmount {
  border: 10px solid white;
  animation: blinkNoAmount 2s infinite;
  -webkit-animation: blinkNoAmount 2s infinite;
}

@keyframes blinkNoAmount {
  0% {
    border-color: white;
  }

  50% {
    border-color: red;
  }

  100% {
    border-color: white;
  }
}

@-webkit-keyframes blinkNoAmount {
  0% {
    border-color: white;
  }

  50% {
    border-color: red;
  }

  100% {
    border-color: white;
  }
}

.Paid {
  border: 10px solid white;
  animation: blinkPaid 2s 8;
  -webkit-animation: blinkPaid 2s 8;
}

@keyframes blinkNoAmount {
  0% {
    border-color: white;
  }

  50% {
    border-color: green;
  }

  100% {
    border-color: white;
  }
}

@-webkit-keyframes blinkNoAmount {
  0% {
    border-color: white;
  }

  50% {
    border-color: green;
  }

  100% {
    border-color: white;
  }
}

.Pending .on .approval {
  border: 10px solid white;
  animation: blinkPending 2s infinite;
  -webkit-animation: blinkPending 2s infinite;
}

@keyframes blinkPending {
  0% {
    border-color: white;
  }

  50% {
    border-color: orange;
  }

  100% {
    border-color: white;
  }
}

@-webkit-keyframes blinkPending {
  0% {
    border-color: white;
  }

  50% {
    border-color: orange;
  }

  100% {
    border-color: white;
  }
}

.idle {
  border: 5px solid lightgray;
}

.qrCodeItem {
  width: auto;
  height: auto;
  border-radius: 10px;
  padding: 2rem;
  margin: auto;

  display: grid;
  place-content: center;
  text-align: center;

  font-size: 1.5em;
}

/** show a warning in browers that don't support it **/
.warning {
  margin: 2em;
  padding: 1em;
  border: 1px solid #ccc;
}

.warning p {
  margin: 0;
  padding: 0;
  text-align: center;
}

:root {
  --angle: 45deg;
  --opacity: 0.5;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Animate when Houdini is available  */
@supports (background: paint(houdini)) {
  @property --opacity {
    syntax: "<number>";
    initial-value: 0.5;
    inherits: false;
  }

  @property --angle {
    syntax: "<angle>";
    initial-value: 0deg;
    inherits: false;
  }

  @keyframes opacityChange {
    to {
      --opacity: 1;
    }
  }

  @keyframes rotate {
    to {
      --angle: 360deg;
    }
  }

  .qrCodeItem {
    animation: rotate 4s linear infinite, opacityChange 3s infinite alternate;
  }

  /* Hide the warning */
  .warning {
    display: none;
  }
}
</style>

<script>
import {
  f7Page,
  f7Navbar,
  f7NavLeft,
  f7NavRight,
  f7NavTitle,
  f7Link,
  f7Subnavbar,
  f7Searchbar,
  f7Chip,
  f7Block,
  f7List,
  f7ListItem,
  f7ListGroup,
  f7Card,
  f7CardHeader,
  f7Badge,
  f7CardContent,
  f7ListInput,
  f7Button,
  f7Segmented,
  f7CardFooter,
  f7Popup,
  f7PageContent,
  f7Sheet,
  f7Fab,
  f7Icon,
  f7Popover,
  f7BlockTitle,
  theme,
  f7,
} from "framework7-vue";
import { mapGetters, mapState } from "vuex";
import Navbarright from "../components/navbarright.vue";
//import { calculateTransferPayment } from "../js/mixins/transaction.js";
import { keyPadMixin } from "../js/mixins/keyPadMixin.js";
import { messageSocket } from "../js/websocket/websocketConnect.js";
import _ from "lodash";
export default {
  props: {
    f7route: Object,
    f7router: Object,
  },
  components: {
    navbarright: Navbarright,
    f7Page,
    f7Navbar,
    f7NavLeft,
    f7NavRight,
    f7NavTitle,
    f7Link,
    f7Subnavbar,
    f7Searchbar,
    f7Chip,
    f7Block,
    f7List,
    f7ListItem,
    f7ListGroup,
    f7Card,
    f7CardHeader,
    f7Badge,
    f7CardContent,
    f7ListInput,
    f7Button,
    f7Segmented,
    f7CardFooter,
    f7Popup,
    f7PageContent,
    f7Sheet,
    f7Fab,
    f7Icon,
    f7Popover,
    f7BlockTitle,
  },
  mixins: [keyPadMixin, messageSocket],
  data() {
    return {
      f7,
      theme,
      amountToPay: "",
      currentInputAmount: "",
      description: "",
      currentStaticCode: "",
      searchQrCode: "",
      sortingCriteria: [
        { value: "", name: this.$t("common.all"), color: "" },
        {
          value: "idle",
          name: this.$t("common.notinuse"),
          color: "",
        },
        {
          value: "yesAmount",
          name: this.$t("common.withamount"),
          color: "auto",
        },
        {
          value: "editable",
          name: this.$t("common.beingedited"),
          color: "auto",
        },
        {
          value: "noAmount",
          name: this.$t("common.noamount"),
          color: "red",
        },
        {
          value: "Pending on approval",
          name: this.$t("common.pending"),
          color: "orange",
        },
        {
          value: "Paid",
          name: this.$t("common.paid"),
          color: "green",
        },
      ],
    };
  },

  mounted() {
    if (!this.msgCltConnected) {
      this.connectMessageSocket();
    }
    if (this.userAccount === null && this.$keycloak.authenticated) {
      this.$store.dispatch("account/loadAccount", this.profile.sub);
    }
    this.$store.dispatch("payment/getVendorQRCodeList");

    this.$store.dispatch("location/loadDestinationCity", this.profile.countryCode);
    const root = document.documentElement;

    // if (this.isTablet == true) {
    //   const pageWidth = `${f7.width * 0.6}px`;

    //   root.style.setProperty("--f7-page-master-width", pageWidth);
    // }
    f7.$(".toastMsg").css({ "max-width": "568px", margin: "auto" });
    setInterval(() => {
      if (
        this.vendorQRCodeList &&
        this.vendorQRCodeList.length >= 1 &&
        !!f7.$(".toastMsg")
      ) {
        f7.$(".toastMsg").hide();
      }
    }, 50000);
  },
  methods: {
    async createNewQR() {
      f7.dialog.confirm(this.$t("businessacceptcash.addANew"), () => {
        f7.preloader.show("multi");
        this.$store.dispatch("payment/createVendorQRCode", {
          amount: this.amountToPay,
          isActive: true,
          status: "idle",
          type: "avo¢ash vendor",
        });
      });
      setTimeout(() => {
        this.$store.dispatch("payment/getVendorQRCodeList");
        f7.preloader.hide();
      }, 300);
    },
    addAmount(staticCode) {
      f7.keypad.create({
        inputEl: `#amountToPay${staticCode.id}`,
        type: "calculator",
        toolbarCloseText: "OK",
        on: {
          change(keypad, value) {
            f7.$(`#amountToPay${staticCode.id}`).value = value;
            staticCode.amount = value;
          },
        },
      });
    },
    async addQrAmount(staticCode) {
      this.amountToPay == f7.$(`#amountToPay${staticCode.id}`).val();
      const descript = f7.$(`#description${staticCode.id}`).val();
      f7.preloader.show("multi");
      await this.$store.dispatch("payment/addQRCodeAmount", {
        id: staticCode.id,
        amount: staticCode.amount || this.amountToPay,
        geolocation: `${this.latitude}-${this.longitude}`,
        ipAddress: this.ipAddress,
        tag: descript,
      });
      f7.preloader.hide();
    },
    async edit(staticCode) {
      f7.preloader.show("multi");
      await this.$store.dispatch("payment/editAmountDescription", staticCode.id);
      f7.preloader.hide();
    },
    async cancel(staticCode) {
      f7.preloader.show("multi");
      await this.$store.dispatch("payment/cancelQRAmount", staticCode.id);
      f7.preloader.hide();
    },
    selectQrList(item) {
      this.searchQrCode = item;
      if (
        item !== "" &&
        this.vendorQRCodeList.length > 0 &&
        _.isEmpty(this.structuredvendorQRCodeList)
      ) {
        f7.dialog.preloader(this.$t("businessacceptcash.noresult"), "multi");
        setTimeout(() => {
          this.searchQrCode = "";
          f7.dialog.close();
        }, 1000);
      }
    },
    // async getQrTransactions(staticCode) {
    //   const self = this;
    //   await self.$store.dispatch("payment/getStaticQrCode", staticCode.id);
    //   setTimeout(() => {
    //     f7.popup.open(".qrTransactionPopup");
    //     this.currentStaticCode = staticCode;
    //   }, 200);
    // },

    getStatusName(staticCode) {
      return _.find(this.sortingCriteria, ["value", staticCode.status]).name;
    },
    getStatusColor(staticCode) {
      return _.find(this.sortingCriteria, ["value", staticCode.status]).color;
    },

    loadMore(done) {
      setTimeout(() => {
        this.$store.dispatch("payment/getVendorQRCodeList");
        done();
      }, 1000);
    },
    pageInit() {
      this.$store.dispatch("payment/getVendorQRCodeList");
    },
  },

  computed: {
    ...mapState("payment", [
      "staticQRCode",
      "vendorQRCodeList",
      "decodedQRCode",
      "QRCode",
      "paymentError",
      "qrCodeUser",
      "qrTransaction",
      "allQrTransactions",
      "qrUser",
    ]),
    ...mapState("transaction", [
      "destinationCountry",
      "destinationCity",
      "grossexchangerate",
      "transferDetail",
      "fromCurrencyRate",
      "toCurrencyRate",
      "transferDetail",
      "paymentDetail",
      "paymentReverseDetail",
    ]),

    ...mapState("location", [
      "destinationCity",
      "country",
      "countryStates",
      "countryCities",
      "currentUserCountry",
      "latitude",
      "longitude",
      "ipAddress",
    ]),
    ...mapState("account", ["userAccount", "userDetail", "searchedUser"]),
    ...mapState("auth", [
      "phoneNumberDetail",
      "mobilePhoneError",
      "profile",
      "isDeskTop",
      "isTablet",
    ]),
    ...mapState("transaction", ["destinationCountry", "originCountry"]),

    ...mapGetters("auth", [
      "getProfile",
      "isAuthenticated",
      "isProfileLoaded",
      "getusererror",
    ]),
    ...mapGetters("transaction", [
      "getusererror",
      "fromCurrencyRate",
      "toCurrencyRate",
      "grossexchangerate2",
    ]),
    ...mapState("message", ["messageSocketClient", "msgCltConnected"]),

    ...mapGetters("remit", ["recipients"]),
    ...mapGetters("account", ["userByAccountNumber"]),

    destinationCtry() {
      return _.groupBy(this.country, "continentName");
    },

    structuredvendorQRCodeList() {
      return this.searchQrCode === ""
        ? _.chain(this.vendorQRCodeList).groupBy("name").value()
        : _.chain(this.vendorQRCodeList)
          .filter(["status", this.searchQrCode])
          .groupBy("name")
          .value();
    },
  },
};
</script>
