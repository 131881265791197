// import base_url from "../base_url.js";
import axios from "axios";
const path = import.meta.env.VITE_APP_URL + "groupBuy";
import _ from "lodash";
import { f7 } from "framework7-vue";

//   const groupBuy={
export default (app) => {
  return {
    namespaced: true,
    state: {
      groupBuy: "",
      project: "",
      participant: "",
      participantList: "",
      admin: "",
      groupBuyTransaction: "",
      groupBuyList: "",
      adminList: "",
      qrCode: "",
    },
    getters: {},
    mutations: {
      CREATE_GROUPBUY(state, groupBuy) {
        state.groupBuy = groupBuy;
      },
      GET_GROUPBUY(state, groupBuy) {
        state.groupBuy = groupBuy;
      },

      EDIT_GROUPBUY(state, groupBuy) {
        state.groupBuy = groupBuy;
      },
      GET_GROUPBUYLIST(state, groupBuyList) {
        state.groupBuyList = groupBuyList;
      },
      GET_GROUPBUYTRANSACTION(state, groupBuyTransaction) {
        state.groupBuyTransaction = groupBuyTransaction;
      },

      GET_GROUPBUYTRANSACTION_LIST(state, groupBuyTransactionList) {
        state.groupBuyTransactionList = groupBuyTransactionList;
      },
    },

    actions: {
      createGroupBuy({ commit }, payload) {
        console.log(payload);
        axios({
          url: path,
          method: "POST",
          data: payload,
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((groupBuy) => {
            commit("CREATE_SAVINGS", groupBuy);
            f7.views.main.router.navigate(`/groupBuy/${groupBuy.id}`);
          })
          .catch((err) => {
            console.log(err);
          });
      },
      getGroupBuy({ commit }, payload) {
        axios({
          url: path,
          method: "GET",
          params: { groupBuyId: payload },
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((groupBuy) => {
            console.log("groupBuy", groupBuy);
            commit("GET_GROUPBUY", groupBuy);
          })
          .catch((err) => {
            console.log(err);
          });
      },
      getUserGroupBuyList({ commit }, payload) {
        console.log(payload);
        axios({
          url: `${path}/user`,
          method: "GET",
          params: {
            // userId: payload.userId,
            maxNumberOfItem: payload.maxNumberOfItem,
          },
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((groupBuyList) => {
            console.log("groupBuyList", groupBuyList);
            commit("GET_GROUPBUYLIST", groupBuyList);
          })
          .catch((err) => {
            console.log(err);
            commit("GET_GROUPBUYLIST", []);
          });
      },
      editGroupBuy({ commit }, payload) {
        console.log(payload);
        axios({
          url: path,
          method: "PUT",
          data: payload,
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((groupBuy) => {
            commit("EDIT_SAVINGS", groupBuy);
          })
          .catch((err) => {
            console.log(err);
          });
      },

      createProject({ commit }, payload) {
        console.log(payload);
        axios({
          url: `${path}/project`,
          method: "POST",
          data: payload.project,
          params: {
            groupBuyId: payload.groupBuyId,
          },
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((groupBuy) => {
            commit("GET_GROUPBUY", groupBuy);
          })
          .catch((err) => {
            console.log(err.errorMessage);
          });
      },
      getProject({ commit }, payload) {
        axios({
          url: `${path}/project`,
          method: "GET",
          params: payload,
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((project) => {
            commit("GET_GROUPBUY", groupBuy);
          })
          .catch((err) => {
            console.log(err);
          });
      },
      editProject({ commit }, payload) {
        axios({
          url: `${path}/project`,
          method: "PUT",
          data: payload,
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((groupBuy) => {
            commit("GET_GROUPBUY", groupBuy);
          })
          .catch((err) => {
            console.log(err.errorMessage);
          });
      },
      joinProject({ commit }, payload) {
        console.log("payload", payload);
        axios({
          url: `${path}/project/join`,
          method: "POST",
          params: {
            projectId: payload.projectId,
          },
          data: payload.newParticipants,
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((groupBuy) => {
            console.log("groupBuy", groupBuy);
            commit("GET_GROUPBUY", groupBuy);
          })
          .catch((err) => {
            console.log(err.errorMessage);
          });
      },
      leaveProject({ commit }, payload) {
        axios({
          url: `${path}/project/leave`,
          method: "PUT",
          params: {
            participantId: payload,
          },
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((groupBuy) => {
            commit("GET_GROUPBUY", groupBuy);
          })
          .catch((err) => {
            console.log(err.errorMessage);
          });
      },
      getProjectParticipant({ commit }, payload) {
        axios({
          url: `${path}/project/participant`,
          method: "GET",
          params: {
            participantId: payload,
          },
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((groupBuy) => {
            commit("GET_GROUPBUY", groupBuy);
          })
          .catch((err) => {
            console.log(err.errorMessage);
          });
      },
      addProjectManager({ commit }, payload) {
        axios({
          url: `${path}/project/manager`,
          method: "PUT",
          params: {
            userId: payload.userId,
            projectId: payload.projectId,
          },
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((groupBuy) => {
            commit("GET_GROUPBUY", groupBuy);
          })
          .catch((err) => {
            console.log(err.errorMessage);
          });
      },

      addParticipant({ commit }, payload) {
        axios({
          url: `${path}/customer`,
          method: "POST",
          params: {
            userId: payload.userId,
            groupBuyId: payload.groupBuyId,
          },
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((groupBuy) => {
            commit("GET_GROUPBUY", groupBuy);
          })
          .catch((err) => {
            console.log(err);
          });
      },
      removeParticipant({ commit }, payload) {
        axios({
          url: `${path}/customer`,
          method: "PUT",
          params: {
            userId: payload.userId,
            groupBuyId: payload.entityId,
          },
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((groupBuy) => {
            commit("GET_GROUPBUY", groupBuy);
          })
          .catch((err) => {
            console.log(err);
          });
      },

      getSavingsTransactionList({ commit }, payload) {
        axios({
          url: `${path}/transactions`,
          method: "GET",
          params: {
            userId: app.config.globalProperties.$keycloak.subject,
            groupBuyId: payload.groupBuyId,
            maxNumberOfItem: payload.maxNumberOfItem,
          },
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((groupBuyTransactionList) => {
            commit("GET_GROUPBUYTRANSACTION_LIST", groupBuyTransactionList);
          })
          .catch((err) => {
            console.log(err);
          });
      },

      grantAdminRole({ commit }, payload) {
        axios({
          url: `${path}/admin`,
          method: "POST",
          params: {
            userId: payload.userId,
            groupBuyId: payload.entityId,
          },
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((groupBuy) => {
            commit("GET_GROUPBUY", groupBuy);
            console.log(groupBuy);
          })
          .catch((err) => {
            console.log(err);
          });
      },
      withdrawAdminRole({ commit }, payload) {
        axios({
          url: `${path}/admin`,
          method: "PUT",
          params: {
            userId: payload.userId,
            groupBuyId: payload.entityId,
          },
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((groupBuy) => {
            commit("GET_GROUPBUY", groupBuy);
            console.log(groupBuy);
          })
          .catch((err) => {
            console.log(err);
          });
      },
      assignContribAmount({ commit }, payload) {
        axios({
          url: `${path}/project/contrib`,
          method: "POST",
          params: {
            participantId: payload.participantId,
            amount: payload.amount,
          },
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((groupBuy) => {
            commit("GET_GROUPBUY", groupBuy);
            console.log(groupBuy);
          })
          .catch((err) => {
            console.log(err);
          });
      },
      assignCartItem({ commit }, payload) {
        axios({
          url: `${path}/project/cart`,
          method: "POST",
          data: payload.cartItemList,
          params: {
            participantId: payload.participantId,
          },
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((groupBuy) => {
            commit("GET_GROUPBUY", groupBuy);
            console.log(groupBuy);
          })
          .catch((err) => {
            console.log(err);
          });
      },
      assignOrderItem({ commit }, payload) {
        axios({
          url: `${path}/project/order`,
          method: "POST",
          data: payload.orderItemList,
          params: {
            participantId: payload.participantId,
          },
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((groupBuy) => {
            commit("GET_GROUPBUY", groupBuy);
            console.log(groupBuy);
          })
          .catch((err) => {
            console.log(err);
          });
      },
    },
  };
};
// export default groupBuy;
