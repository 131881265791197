import { createStore } from "framework7";
import { f7 } from "framework7-vue";
const store = createStore({
  // start with the state (store data)
  state: {
    $keycloak: "",
    authenticated: false,
    // ...
  },

  // actions to operate with state and for async manipulations
  actions: {
    // context object containing store state will be passed as an argument
    setKeycloak({ state }, keycloak) {
      // assign new users to store state.users
      state.$keycloak = keycloak;
    },
    setAuthenticated({ state }, authenticated) {
      // assign new users to store state.users
      state.authenticated = authenticated;
    },
    // ...
  },

  // getters to retrieve the state
  getters: {
    // context object containing store state will be passed as an argument
    $keycloak({ state }) {
      console.log("f7", f7);
      return state.$keycloak;
    },
  },
});

// export store
export default store;
