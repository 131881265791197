<template>
  <h3>{{ $t("common.kyc_level") }}</h3>
  <f7-list dividers strong inset media-list accordion-list>
    <f7-list-group v-if="this.getAccountKYC_Level()">
      <f7-list-item :title="this.getAccountKYC_Level().translated" accordion-item
        class="bg-color-blue text-color-white">
        <!-- <f7-list-item > -->
        <f7-accordion-content>
          <f7-list-item v-if="this.getAccountKYC_Level().mandatory_data.length > 0"
            :title="$t('common.mandatory_data')"><template #text><span
                v-for="mndata in this.getAccountKYC_Level().mandatory_data">{{
                  mndata
                }}</span></template></f7-list-item>
          <f7-list-item v-if="this.getAccountKYC_Level().document.length > 0" :title="$t('common.documents')"><template
              #text><span v-for="doument in this.getAccountKYC_Level().document"><span v-html="`${document}, `"
                  style="margin-right: 8px"></span></span></template></f7-list-item>
          <f7-list-item :title="$t('common.maximum_balance')" :after="$filtersToCurrency.toCurrency(
            this.getAccountKYC_Level().max_balance,
            null,
            this.getAccountKYC_Level().currency
          )
            " />
          <f7-list-item :title="$t('common.maximum_cash_in')" :after="$filtersToCurrency.toCurrency(
            this.getAccountKYC_Level().max_cash_in,
            null,
            this.getAccountKYC_Level().currency
          )
            " :footer="$t('common.per_calendar_month')" />
          <f7-list-item :title="$t('common.maximum_cash_out')" :after="$filtersToCurrency.toCurrency(
            this.getAccountKYC_Level().max_cash_out,
            null,
            this.getAccountKYC_Level().currency
          )
            " :footer="$t('common.per_transaction')" />
          <f7-list-item :footer="this.getAccountKYC_Level().other_details" />
        </f7-accordion-content>
      </f7-list-item>
    </f7-list-group>

    <!-- </f7-list> -->
    <f7-list-group v-if="this.getAccountKYC_Level_Up()">
      <f7-list-item :title="this.getAccountKYC_Level_Up().translated" accordion-item>
        <f7-accordion-content>
          <f7-list-item v-if="this.getAccountKYC_Level_Up().mandatory_data.length > 0"
            :title="$t('common.mandatory_data')"><template #text><span
                v-for="mndata in this.getAccountKYC_Level_Up().mandatory_data">{{
                  mndata
                }}</span></template></f7-list-item>

          <f7-list-item v-if="this.getAccountKYC_Level_Up().document.length > 0"
            :title="$t('common.documents')"><template #text><span
                v-for="doument in this.getAccountKYC_Level_Up().document">{{
                  doument
                }}</span></template></f7-list-item>
          <f7-list-item :title="$t('common.maximum_balance')" :after="$filtersToCurrency.toCurrency(
            this.getAccountKYC_Level_Up().max_balance,
            null,
            this.getAccountKYC_Level_Up().currency
          )
            " />
          <f7-list-item :title="$t('common.maximum_cash_in')" :after="$filtersToCurrency.toCurrency(
            this.getAccountKYC_Level_Up().max_cash_in,
            null,
            this.getAccountKYC_Level_Up().currency
          )
            " :footer="$t('common.per_calendar_month')" />
          <f7-list-item :title="$t('common.maximum_cash_out')" :after="$filtersToCurrency.toCurrency(
            this.getAccountKYC_Level_Up().max_cash_out,
            null,
            this.getAccountKYC_Level_Up().currency
          )
            " :footer="$t('common.per_transaction')" />
          <f7-list-item :footer="this.getAccountKYC_Level_Up().other_details" />
        </f7-accordion-content>
      </f7-list-item>
    </f7-list-group>
    <f7-list-group>
      <f7-list-item v-if="this.getAccountKYC_Level_Up()" link="#" :title="$t('common.upgradetoNextLevel')" />
      <!-- <f7-list> -->
      <f7-list-item v-if="this.getAccountKYC_Level_Up()" link="#" popup-open=".viewAllKYCLevels"
        :title="$t('common.viewKYCDetail')" /></f7-list-group></f7-list>
  <f7-popup class="viewAllKYCLevels" style="min-height: 100%; --f7-popup-bg-color: #fff" swipe-swipe-to-close
    :tablet-fullscreen="true" :backdrop="false" :close-by-backdrop-click="false" :close-on-escape="false">
    <f7-page>
      <f7-navbar no-shadow>
        <div class="left"></div>
        <div class="title">
          {{ $t("common.kyc_level") }}
        </div>
        <div class="right">
          <f7-link popup-close icon-f7="multiply"></f7-link>
        </div>
      </f7-navbar>
      <div class="layout_content">
        <div class="left_layout"></div>
        <div class="main_layout">
          <f7-block-title large>{{ $t("common.kyc_level") }}</f7-block-title>
          <span v-for="kyclevel in this.KYC_Detail">
            <f7-card outline header-divider v-if="activeAccount.type !== null &&
              kyclevel.account_type === activeAccount.type
            " :class="kyclevel.name === activeAccount.kyc_level
                ? 'bg-color-blue text-color-white'
                : ''
                ">
              <f7-list dividers strong inset>
                <f7-list-item :title="kyclevel.translated" />
                <f7-list-item v-if="kyclevel.mandatory_data.length > 0" :title="$t('common.mandatory_data')"><template
                    #text><span v-for="mndata in kyclevel.mandatory_data">{{
                      mndata
                      }}</span></template></f7-list-item>
                <f7-list-item v-if="kyclevel.document.length > 0" :title="$t('common.documents')"><template #text><span
                      v-for="doument in kyclevel.document">{{
                        doument
                      }}</span></template></f7-list-item>
                <f7-list-item :title="$t('common.maximum_balance')" :after="$filtersToCurrency.toCurrency(
                  kyclevel.max_balance,
                  null,
                  kyclevel.currency
                )
                  " />
                <f7-list-item :title="$t('common.maximum_cash_in')" :after="$filtersToCurrency.toCurrency(
                  kyclevel.max_cash_in,
                  null,
                  kyclevel.currency
                )
                  " :footer="$t('common.per_calendar_month')" />
                <f7-list-item :title="$t('common.maximum_cash_out')" :after="$filtersToCurrency.toCurrency(
                  kyclevel.max_cash_out,
                  null,
                  kyclevel.currency
                )
                  " :footer="$t('common.per_transaction')" />
                <f7-list-item :footer="kyclevel.other_details" />
              </f7-list>
            </f7-card>
          </span>
        </div>
        <div class="right_layout"></div>
      </div>
    </f7-page>
  </f7-popup>
</template>
<style scoped></style>
<script>
import {
  f7Popover,
  f7Block,
  f7Button,
  f7Link,
  f7Sheet,
  f7PageContent,
  f7,
  theme,
} from "framework7-vue";
import { mapGetters, mapState } from "vuex";
import { account } from "../../js/mixins/account.js";
import _ from "lodash";
export default {
  props: {
    f7route: Object,
    f7router: Object,
    activeAccount: Object,
  },
  mixins: [account],
  components: {
    f7Popover,
    f7Block,

    f7Button,
    f7Link,
    f7Sheet,
    f7PageContent,
  },
  data() {
    return {
      theme,
      f7,
    };
  },

  methods: {},

  computed: {
    ...mapState("account", [
      "primaryAccount",
      "businessAccount",
      "userDetail",
      "userAccount",
    ]),
    ...mapState("auth", ["video", "isDeskTop", "isTablet", "device", "profile"]),
  },
};
</script>
