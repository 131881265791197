<template>
  <f7-page class="stores_page withMainToolbar" name="stores">
    <f7-navbar sliding no-shadow v-if="!isTablet">
      <f7-nav-title v-html="title"></f7-nav-title>
      <f7-nav-right>
        <f7-link class="searchbar-enable" data-searchbar=".searchbar-stores" icon-aurora="f7:search_strong"
          icon-ios="f7:search_strong" icon-md="material:search"></f7-link>
        <!-- <f7-link
          class="margin-right-half"
          href="/cart/"
          v-if="cart && cart.length > 0 && isTablet == false"
        >
          <f7-icon
            ios="f7:cart"
            aurora="f7:cart"
            md="material:shopping_cart"
            color="none"
          >
            <f7-badge
              bg-color="none"
              class="text-align-center"
              style="font-size: 50%; margin-left: -23px; top: -5px; font-weight: bold"
              text-color="red"
              v-if="cart && cart.length > 0 && totalItemCount > 9"
              >{{ totalItemCount }}</f7-badge
            >
            <f7-badge
              bg-color="none"
              class="text-align-center"
              style="font-size: 50%; margin-left: -20px; top: -5px; font-weight: bold"
              text-color="red"
              v-if="cart && cart.length > 0 && totalItemCount < 10"
              >{{ totalItemCount }}</f7-badge
            >
          </f7-icon>
        </f7-link> -->
        <navbarright></navbarright>
      </f7-nav-right>
      <f7-searchbar class="searchbar-stores" expandable :value="storeSearch" @input="storeSearch = $event.target.value"
        :placeholder="$t('avocashstores.searchStores')" search-container=".search-list"
        search-item="div.itemImageContainer" search-in=".storeTags" :clear-button="true"
        :disable-button="!theme.aurora"></f7-searchbar>
      <f7-subnavbar :inner="false" no-shadow v-if="!this.isTablet">
        <swiper-container class="no-margin-horizontal shortLinks" :slidesPerView="'auto'" :spaceBetween="2"
          :disableOnInteraction="true"><swiper-slide>
            <a href="/shop/new">
              <f7-chip :text="$t('avocashstores.createMyStore')" color="gray"></f7-chip>
            </a>
          </swiper-slide>
          <swiper-slide>
            <a :href="'/product/new/' + shopId">
              <f7-chip outline :text="$t('avocashstores.listAnItem')"></f7-chip>
            </a>
          </swiper-slide>
          <swiper-slide>
            <a href="/categories/">
              <f7-chip outline :text="$t('avocashstores.categories')" color="pink"></f7-chip></a>
          </swiper-slide>
          <swiper-slide>
            <a href="/shops">
              <f7-chip outline :text="$t('avocashstores.myCurrentShops')" color="pink"></f7-chip>
            </a>
          </swiper-slide>
        </swiper-container>
      </f7-subnavbar>
    </f7-navbar>
    <div v-if="this.isTablet" class="margin-vertical-half no-margin-horizontal">
      <span class="cols95_05">
        <f7-searchbar class="searchbar-stores no-margin-horizontal" :value="storeSearch"
          :placeholder="$t('avocashstores.searchStores')" @input="storeSearch = $event.target.value"
          search-container=".search-list" search-item="div.itemImageContainer" search-in=".storeTags"></f7-searchbar>
        <span></span>
      </span>
      <swiper-container v-if="!storeSearch" class="margin-left" :slidesPerView="5" :spaceBetween="2"
        :disableOnInteraction="true"><swiper-slide>
          <a href="/shop/new">
            <f7-chip :text="$t('avocashstores.createMyStore')" color="gray"></f7-chip>
          </a>
        </swiper-slide>
        <swiper-slide>
          <a :href="'/product/new/' + shopId">
            <f7-chip outline :text="$t('avocashstores.listAnItem')"></f7-chip>
          </a> </swiper-slide><swiper-slide>
          <a href="/categories/">
            <f7-chip outline :text="$t('avocashstores.categories')" color="pink"></f7-chip></a>
        </swiper-slide>
        <swiper-slide>
          <a href="/shops">
            <f7-chip outline :text="$t('avocashstores.myCurrentShops')" color="pink"></f7-chip>
          </a>
        </swiper-slide>
      </swiper-container>
    </div>
    <f7-block-title v-if="!storeSearch" style="font-weight: bold" divider>{{
      $t("avocashstores.featuredStores")
    }}</f7-block-title>
    <swiper-container v-if="!storeSearch" class="margin-left" :slidesPerView="isTablet ? 5 : 1" :speed="500"
      :spaceBetween="0" :loop="true" :autoplay="true" :delay="3000">
      <swiper-slide v-for="(stores, index) in shuffledStores" :key="index" class="featuredStoresContainer">
        <a class="text-color-black text-align-center" :href="'/storeDetail/' + stores.storeId">
          <p>
            <b>{{ stores.storeName }}</b>
          </p>
          <img v-if="!!stores.storeSource[0].ImageSrc" :src="stores.storeSource[0].ImageSrc"
            style="object-fit: cover; width: 100%" />
          <img v-else-if="!!stores.storeSource[1].ImageSrc" :src="stores.storeSource[1].ImageSrc"
            style="object-fit: cover; width: 100%" />
        </a>
      </swiper-slide>
    </swiper-container>
    <f7-block-title divider class="margin-top" style="font-weight: bold">{{
      $t("avocashstores.viewStores")
    }}</f7-block-title>
    <f7-list dividers-ios strong-ios recipients-list class="searchbar-not-found" media-list>
      <f7-list-item :title="$t('avocashstores.noSuchStore')" style="font-size: 18px; color: red"></f7-list-item>
    </f7-list>
    <f7-list dividers-ios strong-ios class="search-list searchbar-found">
      <div class="grid grid-cols-2">
        <span width="50" medium="20" v-for="(stores, index) in shuffledStores" :key="index" class="itemImageContainer">
          <a class="text-color-black text-align-center" :href="'/storeDetail/' + stores.storeId">
            <h2 class="no-margin-vertical storeTags">{{ stores.storeName }}</h2>
            <img :src="stores.storeSource[0].ImageSrc" style="object-fit: cover; width: 100%"
              v-if="!!stores.storeSource[0].ImageSrc" class="itemIamge no-margin-vertical" />
            <img :src="stores.storeSource[1].ImageSrc" style="object-fit: cover; width: 100%"
              v-else-if="!!stores.storeSource[1].ImageSrc" class="itemIamge no-margin-vertical" />
            <div class="no-margin-vertical" style="font-size: 90%">
              <p class="no-margin-top storeTags">
                <b>{{ stores.description }}</b>
              </p>
            </div>
          </a>
        </span>
      </div>
    </f7-list>
  </f7-page>
</template>
<style scoped>
.shortLinks swiper-slide {
  width: max-content !important;
}

.featuredStoresContainer img {
  height: 200px;
  /* width: 100%; */
  margin: auto;
  display: block;
}

/* aurora .featuredStoresContainer img {
  height: 125px;
  width: 100%;
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
} */
.itemImageContainer {
  border-left: 1px solid #f4f4f4;
  border-bottom: 1px solid #f4f4f4;
}

.itemImageContainer img {
  height: 200px;
  /* width: 100%; */
  margin: auto;
  display: block;
}
</style>
<script>
import { theme, f7 } from "framework7-vue";
import Navbarright from "../components/navbarright.vue";
import apparel from "../js/data/apparel.json";
import apparel2 from "../js/data/apparel2.json";
import homeandgarden from "../js/data/homeandgarden.json";
import bicycles from "../js/data/bicycles.json";
import fashion from "../js/data/fashion.json";
import jewelry from "../js/data/jewelry.json";
// import jewelry2 from "../js/data/jewelry2.json";
import snowdevil from "../js/data/snowdevil.json";

import { mapGetters, mapState } from "vuex";
import { shopping } from "../js/mixins/shopping.js";

import _ from "lodash";
export default {
  props: {
    f7route: Object,
    f7router: Object,
  },
  components: {
    navbarright: Navbarright,
  },
  mixins: [shopping],
  data() {
    return {
      theme,
      f7,
      title: "<span class='avocash'>avo¢ash</span> stores",
      visibleItem: 8,
      theme,
      quantity: "",
      shopId: "select",
      storeSearch: "",
      apparel,
      apparel2,
      bicycles,
      fashion,
      jewelry,
      // jewelry2: jewelry2,
      homeandgarden,
      snowdevil,
      currencySymbol: "€",
      avocashStores: [
        {
          storeId: 1,
          storeName: "HeShop",
          storeSource: apparel,
          description: "Men shop",
        },
        {
          storeId: 2,
          storeName: "Weshop",
          storeSource: apparel2,
          description: "Men & Women shop",
        },
        {
          storeId: 3,
          storeName: "Velo",
          storeSource: bicycles,
          description: "bicycles",
        },
        {
          storeId: 4,
          storeName: "Fashionist",
          storeSource: fashion,
          description: "Men & Women shop",
        },
        {
          storeId: 5,
          storeName: "BijouX",
          storeSource: jewelry,
          description: "Lux jewelry",
        },
        // {
        //   storeId: 6,
        //   storeName: "Accessoires",
        //   storeSource: jewelry2,
        //   description: "Fashion accessoiries"
        // },
        {
          storeId: 7,
          storeName: "Home&Garden",
          storeSource: homeandgarden,
          description: "Home and garden",
        },
        {
          storeId: 8,
          storeName: "skiHigh",
          storeSource: snowdevil,
          description: "Ski wear & accessories",
        },
      ],
    };
  },
  mounted() {
    // f7.setColorTheme("#9c27b0");
    if (this.isTablet == true) {
      //   f7.$("form.searchbar-stores.searchbar").css({
      //     "min-width": "30%",
      //     width: "50%",
      //     "max-width": "70%",
      //   });

      // f7.$(".stores_page .page .navbar ~ *").css(
      //   "--f7-page-navbar-offset",
      //   "0px !important"
      // );
      f7.$("div.stores_page.page .navbar").css(
        "margin-top",
        "calc(var(--f7-tabbar-labels-height) + var(--f7-safe-area-top))"
      );
      // f7.$(".stores_page .page-content").css("padding-top", "16px");
    }
    if (this.isTablet == false) {
      f7.$("div.stores_page.page").addClass("page-with-subnavbar");
      f7.$("div.stores_page.page-content").addClass("hide-bars-on-scroll");
    }
    f7.$(".mainToolbar").css("display", "block !important");
  },
  methods: {
    loadMore(done) {
      setTimeout(() => {
        this.visibleItem += 8;
        done();
      }, 1000);
    },
  },

  computed: {
    ...mapState("shoppingCart", ["cart", "cartItemCount"]),
    ...mapGetters("shoppingCart", ["totalCartAmount", "totalItemCount"]),
    ...mapState("auth", [
      "phoneNumberDetail",
      "mobilePhoneError",
      "profile",
      "isDeskTop",
      "isTablet",
    ]),
    ...mapGetters("auth", [
      "getProfile",
      "isAuthenticated",
      "isProfileLoaded",
      "getusererror",
    ]),

    shuffledStores() {
      return _.shuffle(this.avocashStores);
    },
  },
};
</script>
