import { f7 } from "framework7-vue";
import _ from "lodash";
export const association = {
  data() {
    return {
      accountType: "",
    };
  },
  mounted() {
    if (this.association) {
      this.share();
    }
  },
  methods: {
    //Tontine List
    async share() {
      const files = [];
      // const base64Files = [];
      // let file = "";
      // if (this.association && this.association.assoImageList.length > 0) {
      //   for (const assoImageItem of this.association.assoImageList) {
      //     const blob = await (await fetch(assoImageItem.url)).blob();
      //     const termination = blob.type.split("/")[1];
      //     file = new File([blob], `${assoImageItem.name}.${termination}`, {
      //       type: blob.type,
      //     });
      //     files.push(file);
      //     if (this.fileToSave) {
      //       this.fileToSave.push(file);
      //     }
      //     base64Files.push(assoImageItem.url);
      //   }
      // }

      this.sharedContent = {
        message: `${this.association.tag}\n\n${this.association.description}`,
        subject: this.association.title,
        // base64Files,
        files: [this.association.qr],
        link: `${f7.views.main.router.currentRoute.path}`,
        short: false,
        linkBase: "links",
        url: this.association.url,
        imgUrl: "https://avocash.com/34x34.png",
      };
    },

    createNewGroup() {
      const self = this;

      if (!self.actionsCampaign) {
        self.actionsCampaign = f7.actions.create({
          convertToPopover: true,
          closeByOutsideClick: true,
          closeOnEscape: true,
          //  grid: self.isTablet ? false : true,
          buttons: [
            {
              text: self.$t("common.selectAction"),
              label: true,
            },
            {
              text: self.$t("homepage.groupFriendFamily"),
              icon: `<i class="material-icons color-teal md-only">groups</i><i class="f7-icons color-teal if-not-md">person_3_fill</i>`,
              color: "teal",
              onClick() {
                self.f7router.navigate("/association/new/group");
              },
            },
            {
              text: self.$t("homepage.associations"),
              icon: `<i class="material-icons color-green">group_work</i>`,
              color: "green",
              onClick() {
                self.f7router.navigate("/association/new/association");
              },
            },
          ],
          // Need to specify popover target
          targetEl: self.$el.querySelector(".createGroup"),
        });
      }

      // Open
      self.actionsCampaign.open();
    },
    async removeMember(member) {
      await this.$store.dispatch("asso/removeParticipant", {
        userId: member.userId,
        associationId: this.association.id,
      });
    },
    grantAdminRole(member) {
      this.$store.dispatch("asso/grantAdminRole", {
        associationId: this.association.id,
        userId: member.userId,
      });
    },
    withdrawAdminRole(member) {
      this.$store.dispatch("asso/withdrawAdminRole", {
        associationId: this.association.id,
        userId: member.userId,
      });
    },

    async selectCampaign(campaign) {
      f7.tab.show(`#tab-${campaign.campaignNumber}`, true);
      this.currentCampaign = campaign;
      this.hiddenFrom = campaign.hiddenFrom;
      this.campaignManager = campaign.campaignManager;
      this.campaignBeneficiary = campaign.campaignBeneficiary;
      console.log("campaign", campaign);
      await this.$store.dispatch("apimoney/getApiWalletTransactions", {
        walletId: campaign.walletId,
        start_date: null,
        end_date: null,
      });
    },
  },
  computed: {
    getType() {
      return this.accountType === "association"
        ? this.$t("associationcreatenew.association")
        : this.$t("associationcreatenew.group");
    },
    getCampaignType() {
      return this.accountType === "association"
        ? this.$t("associationdetail.campaign")
        : this.$t("associationdetail.project");
    },
    capGetType() {
      return _.capitalize(this.getType);
    },
    recipientCountry() {
      if (this.association && !this.campaign) {
        return _.find(this.destinationCountry, [
          "toCountryCode2",
          this.association.countryCode,
        ]);
      }
      // else if (this.M) {
      //   return _.find(this.destinationCountry, [
      //     "toCountryCode2",
      //     this.M.countryCode,
      //   ]);
      // }
    },

    destinationCtry() {
      return _.groupBy(this.country, "continentName");
    },

    assoPhotos() {
      this.association.assoImageList?.map((item) => {
        return item.fileType === "video"
          ? {
              html: `<video src="${item.url}" controls ></video>`,
              caption: item.fileName,
            }
          : {
              url: item.url,
              caption: item.fileName,
            };
      });
    },

    assoQRImage() {
      return _.find(this.assoPhotos, ["name", "QRImage"]);
    },
    currentMember() {
      if (this.profile.sub) {
        return (
          _.find(this.association.associationMemberList, [
            "userId",
            this.profile.sub,
          ]) !== null
        );
      }
    },
    currentMemberIsAdmin() {
      if (this.profile.sub) {
        return (
          _.find(this.association.associationAdminList, [
            "userId",
            this.profile.sub,
          ]) !== null
        );
      }
    },

    daysLeft() {
      return (
        (new Date(this.associationEndDate).getTime() - new Date().getTime()) /
        (1000 * 3600 * 24)
      ).toFixed(0);
    },

    totalCardbasket() {
      return _.reduce(
        this.carditemlist,
        (amount, carditem) => {
          return amount + parseFloat(carditem.cardAmount || 0);
        },
        0
      );
    },

    sortedCampaignList() {
      return _.orderBy(
        this.association.associationCampaignList,
        ["campaignNumber"],
        ["desc"]
      );
    },

    myCampaignsList() {
      return _.chain(this.association.associationCampaignList)
        .filter(
          (campaign) => !_.includes(campaign.hiddenFrom, this.profile.sub)
        )
        .orderBy(["campaignNumber"], ["desc"])
        .value();
    },
    campaignDays() {
      if (this.campaignPeriod && this.campaignPeriod.length > 1) {
        return (
          (this.campaignPeriod[1].getTime() -
            this.campaignPeriod[0].getTime()) /
            (1000 * 3600 * 24) +
          " days"
        );
      }
    },
  },
};
