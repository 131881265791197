<template>
  <f7-card outline header-divider v-if="
    this.entityType === 'tontine' &&
    this.entity.tontineRoundList &&
    this.entity.tontineRoundList.length > 0
  ">
    <f7-card-header>
      {{ $t("tontinedetail.tontineRounds") }}
    </f7-card-header>
    <f7-card-content>
      <f7-list dividers-ios strong-ios media-list chevron-center>
        <li v-for="(round, index) in this.entity.tontineRoundList" :key="index">
          <a href="#" class="item-content" style="color: inherit" @click="selectRound(round)">
            <div class="item-media">
              <f7-badge color="auto" style="height: 32px; width: 32px; font-size: 16px">{{ round.roundNumber }}
              </f7-badge>
            </div>
            <div class="item-inner">
              <div class="item-title-row">
                <div class="item-title">
                  {{ $t("common.collected") }}
                </div>
                <div class="item-after">
                  <span v-html="$filtersToCurrency.toCurrency(
                    (round.collectedAmount || 0).toFixed(2),
                    null,
                    round.currencyCode
                  )
                    "></span>
                </div>
              </div>

              <div class="item-title-row" v-if="round.roundTaker">
                <div class="item-title">
                  {{ $t("tontinedetail.roundTaker") }}
                </div>
                <div class="item-after">
                  {{
                    entity.tontineParticipantList.find((x) => x.id === round.roundTaker)
                      .name
                  }}
                </div>
              </div>
            </div>
          </a>
        </li>
      </f7-list>
    </f7-card-content>
  </f7-card>
  <f7-card outline header-divider v-if="
    this.entityType === 'asso' &&
    this.entity.associationCampaignList &&
    this.entity.associationCampaignList.length > 0
  ">
    <f7-card-header>
      {{
        $t("associationdetail.campaignsprojects", {
          campaignType: this.getCampaignType,
        })
      }}
    </f7-card-header>
    <f7-card-content>
      <f7-list dividers-ios strong-ios media-list class="no-margin-vertical">
        <li v-for="(campaign, index) in this.entity.associationCampaignList" :key="index">
          <a href="#" class="item-content" style="color: inherit" @click="selectCampaign(campaign)">
            <div class="item-media">
              <f7-badge color="auto" style="height: 32px; width: 32px; font-size: 16px; min-width: 32px">{{
                campaign.campaignNumber }}</f7-badge>
            </div>
            <div class="item-inner">
              <div class="item-title-row">
                <div class="item-title">{{ campaign.name }}</div>
                <div class="item-after"></div>
              </div>
              <div class="item-title-row">
                <div class="item-subtitle">
                  {{ $t("common.collected") }}
                </div>
                <div class="item-after">
                  <span v-html="$filtersToCurrency.toCurrency(
                    (campaign.collectedAmount || 0).toFixed(2),
                    null,
                    campaign.currencyCode
                  )
                    "></span>
                </div>
              </div>
              <div class="item-title-row" v-if="!!campaign.campaignBeneficiary">
                <div class="item-subtitle">
                  {{ $t("common.beneficiary") }}
                </div>
                <div class="item-after">
                  {{
                    entity.associationMemberList.find(
                      (x) => x.userId === campaign.campaignBeneficiary
                    ).name
                  }}
                </div>
              </div>
            </div>
          </a>
        </li>
      </f7-list>
    </f7-card-content>
  </f7-card>
  <f7-card outline header-divider v-if="
    this.entityType === 'savings' &&
    this.entity.battleList &&
    this.entity.battleList.length > 0
  ">
    <f7-card-header>
      {{ $t("savingsdetail.battles") }}
    </f7-card-header>
    <f7-card-content>
      <f7-list media-list dividers-ios strong-ios class="no-margin-vertical"
        v-if="this.entity.battleList && this.entity.battleList.length > 0">
        <li v-for="(battle, index) in this.entity.battleList" :key="index">
          <a href="#" class="item-content" style="color: inherit" @click="selectBattle(battle)">
            <div class="item-media">
              <f7-badge color="auto" style="height: 32px; width: 32px; font-size: 16px; min-width: 32px">{{
                battle.battleNumber }}</f7-badge>
            </div>
            <div class="item-inner">
              <div class="item-title-row">
                <div class="item-title">{{ battle.name }}</div>
                <div class="item-after"></div>
              </div>
              <div class="item-title-row">
                <div class="item-subtitle">
                  {{ $t("common.contribution") }}
                </div>
                <div class="item-after">
                  <span v-html="$filtersToCurrency.toCurrency(
                    battleContribution(battle),
                    null,
                    battle.currencyCode
                  )
                    "></span>
                </div>
              </div>
            </div>
          </a>
        </li>
      </f7-list>
    </f7-card-content>
  </f7-card>

  <f7-card outline header-divider v-if="
    this.entityType === 'groupBuy' &&
    this.entity.projectList &&
    this.entity.projectList.length > 0
  ">
    <f7-card-header>
      {{ $t("groupbuydetail.projects") }}
    </f7-card-header>
    <f7-card-content>
      <f7-list dividers-ios strong-ios media-list class="no-margin-vertical">
        <li v-for="(project, index) in this.entity.projectList" :key="index">
          <a href="#" class="item-content" style="color: inherit" @click="selectProject(project)">
            <div class="item-media">
              <f7-badge color="auto" style="height: 32px; width: 32px; font-size: 16px; min-width: 32px">{{
                project.projectNumber }}</f7-badge>
            </div>
            <div class="item-inner">
              <div class="item-title-row">
                <div class="item-title">{{ project.name }}</div>
                <div class="item-after"></div>
              </div>
              <div class="item-title-row">
                <div class="item-subtitle">
                  {{ $t("common.contribution") }}
                </div>
                <div class="item-after">
                  <span v-html="$filtersToCurrency.toCurrency(
                    projectContribution(project),
                    null,
                    project.currencyCode
                  )
                    "></span>
                </div>
              </div>
            </div>
          </a>
        </li>
      </f7-list>
    </f7-card-content>
  </f7-card>
  <f7-card outline header-divider v-if="
    this.entityType === 'wallet' &&
    this.entity.campaignList &&
    this.entity.campaignList.length > 0
  ">
    <f7-card-header>
      {{ $t("walletdetail.campaigns") }}
    </f7-card-header>
    <f7-card-content>
      <f7-list dividers-ios strong-ios media-list class="no-margin-vertical">
        <li v-for="(project, index) in this.entity.campaignList" :key="index">
          <a href="#" class="item-content" style="color: inherit" @click="selectProject(project)">
            <div class="item-media">
              <f7-badge color="auto" style="height: 32px; width: 32px; font-size: 16px; min-width: 32px">{{
                project.campaignNumber }}</f7-badge>
            </div>
            <div class="item-inner">
              <div class="item-title-row">
                <div class="item-title">{{ project.name }}</div>
                <div class="item-after"></div>
              </div>
              <div class="item-title-row">
                <div class="item-subtitle">
                  {{ $t("common.collected") }}
                </div>
                <div class="item-after">
                  <span v-html="$filtersToCurrency.toCurrency(
                    project.collectedAmount,
                    null,
                    project.currencyCode
                  )
                    "></span>
                </div>
              </div>
            </div>
          </a>
        </li>
      </f7-list>
    </f7-card-content>
  </f7-card>
  <f7-card outline header-divider v-if="
    this.entityType === 'shop' &&
    this.entity.shopCampaignList &&
    this.entity.shopCampaignList.length > 0
  ">
    <f7-card-header>
      {{ $t("groupbuydetail.projects") }}
    </f7-card-header>
    <f7-card-content>
      <f7-list dividers-ios strong-ios media-list class="no-margin-vertical">
        <li v-for="(project, index) in this.entity.shopCampaignList" :key="index">
          <a href="#" class="item-content" style="color: inherit" @click="selectProject(project)">
            <div class="item-media">
              <f7-badge color="auto" style="height: 32px; width: 32px; font-size: 16px; min-width: 32px">{{ index + 1
                }}</f7-badge>
            </div>
            <div class="item-inner">
              <div class="item-title-row">
                <div class="item-title">{{ project.name }}</div>
                <div class="item-after"></div>
              </div>
              <div class="item-title-row">
                <div class="item-subtitle">
                  {{ $t("common.contribution") }}
                </div>
                <div class="item-after">
                  <span v-html="$filtersToCurrency.toCurrency(
                    projectContribution(project),
                    null,
                    project.currencyCode
                  )
                    "></span>
                </div>
              </div>
            </div>
          </a>
        </li>
      </f7-list>
    </f7-card-content>
  </f7-card>
  <f7-card outline header-divider v-if="this.$keycloak.authenticated">
    <f7-card-content>
      <f7-list dividers-ios strong-ios>
        <f7-list-item link="#" title="Share" :class="'shareButton ' + this.isAdmin ? '' : 'isDisabled'"
          @click="socialShare(sharedContent)">
          <template #media>
            <f7-icon ios="f7:square_arrow_up" md="material:share"></f7-icon>
          </template>
        </f7-list-item>
        <f7-list-item link="#" :class="+this.isAdmin ? '' : 'isDisabled'" title="Send message"
          @clik="sendMessageToUser()" popup-open=".sendMessageToUserPopup">
          <template #media>
            <f7-icon ios="f7:paperplane_fill" md="material:send"></f7-icon>
          </template>
        </f7-list-item>
        <f7-list-item link="#" title="Create payment link" :class="+this.isAdmin ? '' : 'isDisabled'">
          <template #media>
            <f7-icon ios="f7:link" md="material:add_link"></f7-icon>
          </template>
        </f7-list-item>
        <f7-list-item link="#" title="Create invitation link" :class="+this.isAdmin ? '' : 'isDisabled'">
          <template #media>
            <f7-icon ios="f7:circle_grid_hex_fill" md="material:hub"></f7-icon>
          </template>
        </f7-list-item>
        <f7-list-item link="#" title="Add and view group's documents" popup-open=".groupDocumentsViewAddPopup"
          :class="+this.isAdmin ? '' : 'isDisabled'">
          <template #media>
            <f7-icon ios="f7:rectangle_paperclip" md="material:file_present"></f7-icon>
          </template>
        </f7-list-item>
      </f7-list>
    </f7-card-content>
  </f7-card>
</template>
<style scoped>
.isDisabled {
  pointer-events: none;
  opacity: 0.5;
  cursor: not-allowed;
  text-decoration: none;
}
</style>
<script>
import { f7, theme } from "framework7-vue";
import { tontine } from "../../js/mixins/tontine.js";
import { association } from "../../js/mixins/association.js";
import { saving } from "../../js/mixins/savings.js";
import { groupbuy } from "../../js/mixins/groupBuy.js";
import { walletMixin } from "../../js/mixins/walletMixins.js";
import { mapGetters, mapState } from "vuex";
import _ from "lodash";
export default {
  mixins: [tontine, association, saving, groupbuy, walletMixin],
  props: {
    f7route: Object,
    f7router: Object,
    entityType: String,
    entity: Object,
    isAdmin: Boolean,
  },
  data() {
    return {
      theme,
      f7,
    };
  },
  mounted() { },

  methods: {
    projectContribution(project) {
      return _.reduce(
        project.participantList,
        (amount, participant) => {
          return amount + parseFloat(participant.paidContrib || 0);
        },
        0
      );
    },
  },

  computed: {
    ...mapState("account", ["userAccount", "users"]),
    ...mapState("auth", [
      "profile",
      "documentsUrl",
      "document",
      "isTablet",
      "authenticated",
    ]),
  },
};
</script>
